<div style="text-align: center; background-color: #fff; opacity: 0.9; width: 400px; margin: 0 auto; border-radius: 8px;">
  <h3 style="padding-top: 20px;" i18n="Password Reset header | The header on the Password Reset page">{{"PASSWORD_RESET_COMPONENT.TITLE" | translate}}</h3>
  <p i18n="Password Reset header subtitle | The subtitle in the Password Reset header">{{"PASSWORD_RESET_COMPONENT.SUBTITLE" | translate}}</p>
  <form class="form-signin" [formGroup]="passwordResetForm">
    <input type="password" id="newPassword" class="form-control" i18n-placeholder placeholder="{{'PASSWORD_RESET_COMPONENT.NEW_PASSWORD' | translate}}" (keyup)="checkPasswordMissmatch()" required autofocus formControlName="newPassword">
    <div *ngIf="newPassword.invalid && (newPassword.dirty || newPassword.touched)"
      class="alert alert-danger" i18n="Password Reset password errors">
      <div *ngIf="newPassword.errors.required">{{"PASSWORD_RESET_COMPONENT.ERRORS.PASSWORD_REQUIRED" | translate}}</div>
      <div *ngIf="newPassword.errors.minlength">{{"PASSWORD_RESET_COMPONENT.ERRORS.PASSWORD_LENGTH" | translate}}</div>
    </div>

    <input type="password" id="newPasswordConfirmation" class="form-control" i18n-placeholder placeholder="{{'PASSWORD_RESET_COMPONENT.CONFIRM_NEW_PASSWORD' | translate}}" (keyup)="checkPasswordMissmatch()" required formControlName="newPasswordConfirmation">
    <div *ngIf="newPasswordConfirmation.invalid && (newPasswordConfirmation.dirty || newPasswordConfirmation.touched)"class="alert alert-danger">
      <div *ngIf="newPasswordConfirmation.errors.required" i18n="Password Reset password not confirmed error">{{"PASSWORD_RESET_COMPONENT.ERRORS.PASSWORD_CONFIRMATION" | translate}}</div>
    </div>
    <div *ngIf="passwordMissmatch" class="alert alert-danger" i18n="Password Reset password dont match error">{{"PASSWORD_RESET_COMPONENT.ERRORS.PASSWORD_MATCHING" | translate}}</div>
    <button class="btn btn-lg btn-primary btn-block" type="submit" (click)="onSubmit($event)" [disabled]="passwordResetForm.invalid" i18n="Submit New Password Button | The submit button on the password reset page.">{{"PASSWORD_RESET_COMPONENT.SUBMIT_BUTTON" | translate}}</button>
  </form>
  <div *ngIf="showSuccessMessage" style="background-color: greenyellow;z-index:1000; padding: 10px; height: 40px; width: 300px; display: inline-block; margin-bottom: 10px;" i18n="Password Reset success message | The success message on the Password Reset page">
    {{"PASSWORD_RESET_COMPONENT.RESET_SUCCESSFUL_MESSAGE" | translate}}
  </div>
</div>