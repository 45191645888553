export const allCountries = [
  {
    code: 'AF',
    name: 'AFGHANISTAN'
  }, {
    code: 'AL',
    name: 'ALBANIA'
  }, {
    code: 'DZ',
    name: 'ALGERIA'
  }, {
    code: 'AS',
    name: 'AMERICAN SAMOA'
  }, {
    code: 'AD',
    name: 'ANDORRA'
  }, {
    code: 'AO',
    name: 'ANGOLA'
  }, {
    code: 'AI',
    name: 'ANGUILLA'
  }, {
    code: 'AQ',
    name: 'ANTARCTICA'
  }, {
    code: 'AG',
    name: 'ANTIGUA AND BARBUDA'
  }, {
    code: 'AR',
    name: 'ARGENTINA'
  }, {
    code: 'AM',
    name: 'ARMENIA'
  }, {
    code: 'AW',
    name: 'ARUBA'
  }, {
    code: 'AU',
    name: 'AUSTRALIA'
  }, {
    code: 'AT',
    name: 'AUSTRIA'
  }, {
    code: 'AZ',
    name: 'AZERBAIJAN'
  }, {
    code: 'BS',
    name: 'BAHAMAS'
  }, {
    code: 'BH',
    name: 'BAHRAIN'
  }, {
    code: 'BD',
    name: 'BANGLADESH'
  }, {
    code: 'BB',
    name: 'BARBADOS'
  }, {
    code: 'BY',
    name: 'BELARUS'
  }, {
    code: 'BE',
    name: 'BELGIUM'
  }, {
    code: 'BZ',
    name: 'BELIZE'
  }, {
    code: 'BJ',
    name: 'BENIN'
  }, {
    code: 'BM',
    name: 'BERMUDA'
  }, {
    code: 'BT',
    name: 'BHUTAN'
  }, {
    code: 'BO',
    name: 'BOLIVIA'
  }, {
    code: 'BQ',
    name: 'BONAIRE, SINT EUSTATIUS AND SABA' 
  }, {
    code: 'BA',
    name: 'BOSNIA AND HERZEGOVINA'
  }, {
    code: 'BW',
    name: 'BOTSWANA'
  }, {
    code: 'BV',
    name: 'BOUVET ISLAND' 
  }, {
    code: 'BR',
    name: 'BRAZIL'
  }, {
    code: 'IO',
    name: 'BRITISH INDIAN OCEAN TERRITORY' 
  }, {
    code: 'VG',
    name: 'BRITISH VIRGIN ISLANDS' 
  }, {
    code: 'BN',
    name: 'BRUNEI DARUSSALAM'
  }, {
    code: 'BG',
    name: 'BULGARIA'
  }, {
    code: 'BF',
    name: 'BURKINA FASO'
  }, {
    code: 'BI',
    name: 'BURUNDI'
  }, {
    code: 'KH',
    name: 'CAMBODIA'
  }, {
    code: 'CM',
    name: 'CAMEROON'
  }, {
    code: 'CA',
    name: 'CANADA'
  }, {
    code: 'CV',
    name: 'CAPE VERDE'
  }, {
    code: 'KY',
    name: 'CAYMAN ISLANDS'
  }, {
    code: 'CF',
    name: 'CENTRAL AFRICAN REPUBLIC'
  }, {
    code: 'TD',
    name: 'CHAD'
  }, {
    code: 'CL',
    name: 'CHILE'
  }, {
    code: 'CN',
    name: 'CHINA'
  }, {
    code: 'CX',
    name: 'CHRISTMAS ISLAND'
  }, {
    code: 'CC',
    name: 'COCOS (KEELING) ISLANDS'
  }, {
    code: 'CO',
    name: 'COLOMBIA'
  }, {
    code: 'KM',
    name: 'COMOROS'
  }, {
    code: 'CG',
    name: 'CONGO'
  }, {
    code: 'CD',
    name: 'CONGO (Dem. Rep.)' 
  }, {
    code: 'CK',
    name: 'COOK ISLANDS'
  }, {
    code: 'CR',
    name: 'COSTA RICA'
  }, {
    code: 'ME',
    name: 'CRNA GORA'  
  }, {
    code: 'HR',
    name: 'CROATIA'
  }, {
    code: 'CU',
    name: 'CUBA'
  }, {
    code: 'CW',
    name: 'CURAÇAO'  //NOT in DB
  }, {
    code: 'CY',
    name: 'CYPRUS'
  }, {
    code: 'CZ',
    name: 'CZECH REPUBLIC'
  }, {
    code: 'CI',
    name: "CÔTE D'IVOIRE"  //Not in DB
  }, {
    code: 'DK',
    name: 'DENMARK'
  }, {
    code: 'DJ',
    name: 'DJIBOUTI'
  }, {
    code: 'DM',
    name: 'DOMINICA'
  }, {
    code: 'DO',
    name: 'DOMINICAN REPUBLIC'
  }, {
    code: 'TL',
    name: 'EAST TIMOR'
  }, {
    code: 'EC',
    name: 'ECUADOR'
  }, {
    code: 'EG',
    name: 'EGYPT'
  }, {
    code: 'SV',
    name: 'EL SALVADOR'
  }, {
    code: 'GQ',
    name: 'EQUATORIAL GUINEA'
  }, {
    code: 'ER',
    name: 'ERITREA'
  }, {
    code: 'EE',
    name: 'ESTONIA'
  }, {
    code: 'ET',
    name: 'ETHIOPIA'
  }, {
    code: 'FK',
    name: 'FALKLAND ISLANDS'
  }, {
    code: 'FO',
    name: 'FAROE ISLANDS'
  }, {
    code: 'FJ',
    name: 'FIJI'
  }, {
    code: 'FI',
    name: 'FINLAND'
  }, {
    code: 'FR',
    name: 'FRANCE'
  }, {
    code: 'GF',
    name: 'FRENCH GUIANA'
  }, {
    code: 'PF',
    name: 'FRENCH POLYNESIA'
  }, {
    code: 'TF',
    name: 'FRENCH SOUTHERN TERRITORIES' 
  }, {
    code: 'GA',
    name: 'GABON'
  }, {
    code: 'GM',
    name: 'GAMBIA'
  }, {
    code: 'GE',
    name: 'GEORGIA'
  }, {
    code: 'DE',
    name: 'GERMANY'
  }, {
    code: 'GH',
    name: 'GHANA'
  }, {
    code: 'GI',
    name: 'GIBRALTAR'
  }, {
    code: 'GR',
    name: 'GREECE'
  }, {
    code: 'GL',
    name: 'GREENLAND'
  }, {
    code: 'GD',
    name: 'GRENADA'
  }, {
    code: 'GP',
    name: 'GUADELOUPE'
  }, {
    code: 'GU',
    name: 'GUAM'
  }, {
    code: 'GT',
    name: 'GUATEMALA'
  }, {
    code: 'GG',
    name: 'GUERNSEY AND ALDERNEY'  
  }, {
    code: 'GN',
    name: 'GUINEA'
  }, {
    code: 'GW',
    name: 'GUINEA-BISSAU'
  }, {
    code: 'GY',
    name: 'GUYANA'
  }, {
    code: 'HT',
    name: 'HAITI'
  }, {
    code: 'HM',
    name: 'HEARD AND MCDONALD ISLANDS' 
  }, {
    code: 'HN',
    name: 'HONDURAS'
  }, {
    code: 'HK',
    name: 'HONG KONG'
  }, {
    code: 'HU',
    name: 'HUNGARY'
  }, {
    code: 'IS',
    name: 'ICELAND'
  }, {
    code: 'IN',
    name: 'INDIA'
  }, {
    code: 'ID',
    name: 'INDONESIA'
  }, {
    code: 'IR',
    name: 'IRAN'           
  }, {
    code: 'IQ',
    name: 'IRAQ'
  }, {
    code: 'IE',
    name: 'IRELAND'
  }, {
    code: 'IM',
    name: 'ISLE OF MAN' 
  }, {
    code: 'IL',
    name: 'ISRAEL'
  }, {
    code: 'IT',
    name: 'ITALY'
  }, {
    code: 'JM',
    name: 'JAMAICA'
  }, {
    code: 'JP',
    name: 'JAPAN'
  }, {
    code: 'JE',
    name: 'JERSEY'  
  }, {
    code: 'JO',
    name: 'JORDAN'
  }, {
    code: 'KZ',
    name: 'KAZAKHSTAN'
  }, {
    code: 'KE',
    name: 'KENYA'
  }, {
    code: 'KI',
    name: 'KIRIBATI'
  }, {
    code: 'KP',
    name: 'KOREA (North)'
  }, {
    code: 'KR',
    name: 'KOREA (South)'
  }, {
    code: 'KW',
    name: 'KUWAIT'
  }, {
    code: 'KG',
    name: 'KYRGYZSTAN'
  }, {
    code: 'LA',
    name: 'LAOS' 
  }, {
    code: 'LV',
    name: 'LATVIA'
  }, {
    code: 'LB',
    name: 'LEBANON'
  }, {
    code: 'LS',
    name: 'LESOTHO'
  }, {
    code: 'LR',
    name: 'LIBERIA'
  }, {
    code: 'LY',
    name: 'LIBYA'  
  }, {
    code: 'LI',
    name: 'LIECHTENSTEIN'
  }, {
    code: 'LT',
    name: 'LITHUANIA'
  }, {
    code: 'LU',
    name: 'LUXEMBOURG'
  }, {
    code: 'MO',
    name: 'MACAO'
  }, {
    code: 'MK',
    name: 'MACEDONIA' 
  }, {
    code: 'MG',
    name: 'MADAGASCAR'
  }, {
    code: 'MW',
    name: 'MALAWI'
  }, {
    code: 'MY',
    name: 'MALAYSIA'
  }, {
    code: 'MV',
    name: 'MALDIVES'
  }, {
    code: 'ML',
    name: 'MALI'
  }, {
    code: 'MT',
    name: 'MALTA'
  }, {
    code: 'MH',
    name: 'MARSHALL ISLANDS'
  }, {
    code: 'MQ',
    name: 'MARTINIQUE'
  }, {
    code: 'MR',
    name: 'MAURITANIA'
  }, {
    code: 'MU',
    name: 'MAURITIUS'
  }, {
    code: 'YT',
    name: 'MAYOTTE'
  }, {
    code: 'MX',
    name: 'MEXICO'
  }, {
    code: 'FM',
    name: 'MICRONESIA'   
  }, {
    code: 'MD',
    name: 'MOLDOVA' 
  }, {
    code: 'MC',
    name: 'MONACO'
  }, {
    code: 'MN',
    name: 'MONGOLIA'
  }, {
    code: 'MS',
    name: 'MONTSERRAT'
  }, {
    code: 'MA',
    name: 'MOROCCO'
  }, {
    code: 'MZ',
    name: 'MOZAMBIQUE'
  }, {
    code: 'MM',
    name: 'MYANMAR'
  }, {
    code: 'NA',
    name: 'NAMIBIA'
  }, {
    code: 'NR',
    name: 'NAURU'
  }, {
    code: 'NP',
    name: 'NEPAL'
  }, {
    code: 'NL',
    name: 'NETHERLANDS'
  }, {
    code: 'AN',
    name: 'NETHERLANDS ANTILLES'
  }, {
    code: 'NC',
    name: 'NEW CALEDONIA'
  }, {
    code: 'NZ',
    name: 'NEW ZEALAND'
  }, {
    code: 'NI',
    name: 'NICARAGUA'
  }, {
    code: 'NE',
    name: 'NIGER'
  }, {
    code: 'NG',
    name: 'NIGERIA'
  }, {
    code: 'NU',
    name: 'NIUE'
  }, {
    code: 'NF',
    name: 'NORFOLK ISLAND'
  }, {
    code: 'MP',
    name: 'NORTHERN MARIANA ISLANDS'
  }, {
    code: 'NO',
    name: 'NORWAY'
  }, {
    code: 'OM',
    name: 'OMAN'
  }, {
    code: 'PK',
    name: 'PAKISTAN'
  }, {
    code: 'PW',
    name: 'PALAU'
  }, {
    code: 'PS',
    name: 'PALESTINE'  
  }, {
    code: 'PA',
    name: 'PANAMA'
  }, {
    code: 'PG',
    name: 'PAPUA NEW GUINEA'
  }, {
    code: 'PY',
    name: 'PARAGUAY'
  }, {
    code: 'PE',
    name: 'PERU'
  }, {
    code: 'PH',
    name: 'PHILIPPINES'
  }, {
    code: 'PN',
    name: 'PITCAIRN'
  }, {
    code: 'PL',
    name: 'POLAND'
  }, {
    code: 'PT',
    name: 'PORTUGAL'
  }, {
    code: 'PR',
    name: 'PUERTO RICO'
  }, {
    code: 'QA',
    name: 'QATAR'
  }, {
    code: 'RO',
    name: 'ROMANIA'
  }, {
    code: 'RU',
    name: 'RUSSIA' 
  }, {
    code: 'RW',
    name: 'RWANDA'
  }, {
    code: 'RE',
    name: 'RÉUNION'
  }, {
    code: 'BL',
    name: 'SAINT BARTHÉLEMY'
  }, {
    code: 'SH',
    name: 'SAINT HELENA' 
  }, {
    code: 'KN',
    name: 'SAINT KITTS AND NEVIS'
  }, {
    code: 'LC',
    name: 'SAINT LUCIA'
  }, {
    code: 'MF',
    name: 'SAINT MARTIN'
  }, {
    code: 'PM',
    name: 'SAINT PIERRE AND MIQUELON' 
  }, {
    code: 'VC',
    name: 'SAINT VINCENT AND THE GRENADINES'
  }, {
    code: 'WS',
    name: 'SAMOA'
  }, {
    code: 'SM',
    name: 'SAN MARINO'
  }, {
    code: 'SA',
    name: 'SAUDI ARABIA'
  }, {
    code: 'SN',
    name: 'SENEGAL'
  }, {
    code: 'RS',
    name: 'SERBIA' 
  }, {
    code: 'SC',
    name: 'SEYCHELLES'
  }, {
    code: 'SL',
    name: 'SIERRA LEONE'
  }, {
    code: 'SG',
    name: 'SINGAPORE'
  }, {
    code: 'SX',
    name: 'SINT MAARTEN' 
  }, {
    code: 'SK',
    name: 'SLOVAKIA'
  }, {
    code: 'SI',
    name: 'SLOVENIA'
  }, {
    code: 'SB',
    name: 'SOLOMON ISLANDS'
  }, {
    code: 'SO',
    name: 'SOMALIA'
  }, {
    code: 'ZA',
    name: 'SOUTH AFRICA'
  }, {
    code: 'GS',
    name: 'SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS'  
  }, {
    code: 'SS',
    name: 'SOUTH SUDAN'
  }, {
    code: 'ES',
    name: 'SPAIN'
  }, {
    code: 'LK',
    name: 'SRILANKA'
  }, {
    code: 'SD',
    name: 'SUDAN'
  }, {
    code: 'SR',
    name: 'SURINAME'
  }, {
    code: 'SJ',
    name: 'SVALBARD AND JAN MAYEN'  
  }, {
    code: 'SZ',
    name: 'SWAZILAND'
  }, {
    code: 'SE',
    name: 'SWEDEN'
  }, {
    code: 'CH',
    name: 'SWITZERLAND'
  }, {
    code: 'SY',
    name: 'SYRIA'  
  }, {
    code: 'ST',
    name: 'SÃO TOMÉ AND PRÍNCIPE' 
  }, {
    code: 'TW',
    name: 'TAIWAN'
  }, {
    code: 'TJ',
    name: 'TAJIKISTAN'
  }, {
    code: 'TZ',
    name: 'TANZANIA'  
  }, {
    code: 'TH',
    name: 'THAILAND'
  }, {
    code: 'TG',
    name: 'TOGO' 
  }, {
    code: 'TK',
    name: 'TOKELAU'
  }, {
    code: 'TO',
    name: 'TONGA'
  }, {
    code: 'TT',
    name: 'TRINIDAD AND TOBAGO'
  }, {
    code: 'TN',
    name: 'TUNISIA'
  }, {
    code: 'TR',
    name: 'TURKEY'
  }, {
    code: 'TM',
    name: 'TURKMENISTAN'
  }, {
    code: 'TC',
    name: 'TURKS AND CAICOS ISLANDS'
  }, {
    code: 'TV',
    name: 'TUVALU'
  }, {
    code: 'UG',
    name: 'UGANDA'
  }, {
    code: 'UA',
    name: 'UKRAINE'
  }, {
    code: 'AE',
    name: 'UNITED ARAB EMIRATES'
  }, {
    code: 'GB',
    name: 'UNITED KINGDOM'
  }, {
    code: 'UM',
    name: 'UNITED STATES MINOR OUTLYING ISLANDS'
  }, {
    code: 'US',
    name: 'UNITED STATES OF AMERICA'
  }, {
    code: 'UY',
    name: 'URUGUAY'
  }, {
    code: 'UZ',
    name: 'UZBEKISTAN'
  }, {
    code: 'VU',
    name: 'VANUATU'
  }, {
    code: 'VA',
    name: 'VATICAN CITY'
  }, {
    code: 'VE',
    name: 'VENEZUELA'
  }, {
    code: 'VN',
    name: 'VIETNAM'
  }, {
    code: 'VI',
    name: 'VIRGIN ISLANDS of the United States'  
  }, {
    code: 'WF',
    name: 'WALLIS AND FUTUNA'
  }, {
    code: 'EH',
    name: 'WESTERN SAHARA'
  }, {
    code: 'YE',
    name: 'YEMEN'
  }, {
    code: 'ZM',
    name: 'ZAMBIA'
  }, {
    code: 'ZW',
    name: 'ZIMBABWE'
  }, {
    code: 'AX',
    name: 'ÅLAND ISLANDS'
  }
];



export const factoryTypesEnglish = {
  FABRIC_DYING_MILL: 'Fabric Dyeing Mill',
  YARN_DYEING_MILL: 'Yarn Dyeing Mill',
  FABRIC_PRINTING_MILL: 'Fabric Printing Mill',
  WASHING_MILL: 'Washing Mill',
  DENIM_WASHING: 'Denim Washing',
  PIECE_PRINTING: 'Piece Printing',
  GARMENT_FACTORY: 'Garment Factory (CMT)',
  SHOE_ASSEMBLY: 'Shoe Assembly',
  ACCESSORY_FACTORY: 'Accessory Factory',
  OTHER: 'Other (please enter)'
};



export const companyTypesEnglish = {
BRAND: {name:'Brand', role: 2}, 
FACTORY: {name:'Factory', role: 1}, 
IMPORTER: {name:'Supplier/Importer', role: 3}
};    