import { Injectable } from '@angular/core';

@Injectable()
export class SessionStateService {

  constructor() { }

  // old inventories view with accordion menu
  setChemInvYearAccordionState(index) {
    sessionStorage.setItem( "ChemInvYearAccordionState", index );
  }

  setChemInvMonthAccordionState(index) {
    sessionStorage.setItem( "ChemInvMonthAccordionState", index );
  }

  setChemInvCountryAccordionState(index) {
    sessionStorage.setItem( "ChemInvCountryAccordionState", index );
  }

  setChemInvSupplierAccordionState(index) {
    sessionStorage.setItem( "ChemInvSupplierAccordionState", index );
  }

  getChemInvYearAccordionState(){
    return sessionStorage.getItem( "ChemInvYearAccordionState" );
  }

  getChemInvMonthAccordionState(){
    return sessionStorage.getItem( "ChemInvMonthAccordionState");
  }

  getChemInvCountryAccordionState() {
    return sessionStorage.getItem( "ChemInvCountryAccordionState" );
  }

  getChemInvSupplierAccordionState() {
    return sessionStorage.getItem( "ChemInvSupplierAccordionState" );
  }

  // new inventories view with select boxes
  setChemInvYear(year: string) {
    sessionStorage.setItem( "ChemInvYear", year );
  }

  setChemInvMonthOrQuarter(monthOrQuarter: string) {
    sessionStorage.setItem( "ChemInvMonthOrQuarter", monthOrQuarter );
  }

  setChemInvCountry(country: string) {
    sessionStorage.setItem( "ChemInvCountry", country );
  }

  setChemInvSupplier(supplier: string) {
    sessionStorage.setItem( "ChemInvSupplier", supplier );
  }

  getChemInvYear(){
    return sessionStorage.getItem( "ChemInvYear" );
  }

  getChemInvMonthOrQuarter(){
    return sessionStorage.getItem( "ChemInvMonthOrQuarter");
  }

  getChemInvCountry() {
    return sessionStorage.getItem( "ChemInvCountry" );
  }

  getChemInvSupplier() {
    return sessionStorage.getItem( "ChemInvSupplier" );
  }

  setReportTypeForHigg(reportType: string){
    sessionStorage.setItem( "ReportTypeForHigg", reportType );
  }

  getReportTypeForHigg() {
    return sessionStorage.getItem( "ReportTypeForHigg" );
  }

}
