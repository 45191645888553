<div class="flex flex-col flex-auto min-w-0">
    <div class="flex-auto p-2 sm:p-2">
        <div>
            <div class="content">
                <div *ngIf="showSuccessMessage" style="
                        background-color: greenyellow;
                        z-index: 1000;
                        position: fixed;
                        padding: 10px;
                        height: 40px;
                        display: block;
                        right: 30px;
                        top: 70px;
                    " i18n="Document Center 'Saved Successfully' message">
                    {{
                    "DOCUMENT_CENTER_COMPONENT.SAVED_SUCCESSFUL_MESSAGE"
                    | translate
                    }}
                </div>
                <div *ngIf="showErrorMessage" style="
                        background-color: hsl(14, 100%, 53%);
                        z-index: 1000;
                        position: fixed;
                        padding: 10px;
                        height: 40px;
                        display: block;
                        right: 30px;
                        top: 70px;
                    " i18n="Document Center 'Exceeded file size limit' message">
                    {{
                    "DOCUMENT_CENTER_COMPONENT.FILE_SIZE_EXCEEDED_MESSAGE"
                    | translate
                    }}
                </div>
                <div *ngIf="showDeleteMessage" style="
                        background-color: greenyellow;
                        z-index: 1000;
                        position: fixed;
                        padding: 10px;
                        height: 40px;
                        display: block;
                        right: 30px;
                        top: 70px;
                    " i18n="Document Center 'Deleted successfully' message">
                    {{
                    "DOCUMENT_CENTER_COMPONENT.DELETED_SUCCESSFUL_MESSAGE"
                    | translate
                    }}
                </div>
                <div *ngIf="showDataSaved" style="
                        background-color: greenyellow;
                        z-index: 1000;
                        position: fixed;
                        padding: 10px;
                        height: 40px;
                        display: block;
                        right: 30px;
                        top: 70px;
                    " i18n="Profile Component saved successfully - message">
                    {{
                    "DOCUMENT_CENTER_COMPONENT.DATA_SAVE_SUCCESSFUL_MESSAGE"
                    | translate
                    }}
                </div>
                <!-- <div>
    <h3 style="display: inline-block" i18n="Document Center header"> {{"DOCUMENT_CENTER_COMPONENT.HEADER" | translate}} </h3>
  </div> -->

              <div class="h5 p-2"> {{
                'DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.LABEL'
                | translate
                }}</div> 

                <!-- <mat-tab-group>
                    <mat-tab label="{{
                            'DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.LABEL'
                                | translate
                        }}"> -->
                <mat-tab-group [selectedIndex]="selectedSubTabIndex">
                    <mat-tab label="{{
                                    'DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.CHEMICAL'
                                        | translate
                                }}">
                        <div class="topic-div">
                            <mat-accordion>
                                <mat-expansion-panel class="document-accordion" [expanded]="step === 0" (opened)="
                                                fetchDocuments(
                                                    true,
                                                    true,
                                                    'Chemical'
                                                );
                                                setStep(0)
                                            " #factoryChemicals="matExpansionPanel" style="overflow-x: scroll">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            {{
                                            "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.DOCUMENT_INCHECK_HEADER"
                                            | translate
                                            }}
                                            &nbsp;
                                            <a #tooltip="matTooltip" matTooltip="{{
                                                            'DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.CHEMICALTOOL'
                                                                | translate
                                                        }}" matTooltipPosition="below" style="
                                                            text-decoration: none;
                                                            font-size: xx-small;
                                                        ">
                                                <mat-icon class="info-icon">info_outline</mat-icon>
                                            </a>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <table class="table table-striped tablefixed">
                                        <thead>
                                            <tr>
                                                <th>
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.NAME"
                                                    | translate
                                                    }}
                                                </th>
                                                <th>
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.TYPE"
                                                    | translate
                                                    }}
                                                </th>
                                                <!-- <th>
                                                            {{
                                                            "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.RESULT"
                                                            | translate
                                                            }}
                                                        </th> -->
                                                <th>
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.UPLOAD_DATE"
                                                    | translate
                                                    }}
                                                </th>
                                                <th>
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.ISSUE_DATE"
                                                    | translate
                                                    }}
                                                </th>
                                                <!-- <th>
                                                            {{
                                                            "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.EXPIRY_DATE"
                                                            | translate
                                                            }}
                                                        </th> -->
                                                <th>
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.COMMENT"
                                                    | translate
                                                    }}
                                                </th>
                                                <th *ngIf="
                                                                dataSensitivity ==
                                                                1
                                                            ">
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.DOWNLOAD"
                                                    | translate
                                                    }}
                                                </th>
                                                <th *ngIf="!viewMode">
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.DELETE"
                                                    | translate
                                                    }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="
                                                            let document of documents[
                                                                'Chemical'
                                                            ]
                                                        ">
                                                <td>
                                                    {{
                                                    document["name"]
                                                    }}
                                                </td>
                                                <td>
                                                    {{
                                                    translateDocumentType(
                                                    document[
                                                    "category"
                                                    ]
                                                    )
                                                    }}
                                                </td>
                                                <!-- <td>
                                                            {{
                                                            convertPassfail(
                                                            document[
                                                            "passfail"
                                                            ]
                                                            )
                                                            }}
                                                        </td> -->
                                                <td>
                                                    {{
                                                    document[
                                                    "upload"
                                                    ] | date
                                                    }}
                                                </td>
                                                <td>
                                                    {{
                                                    document[
                                                    "issue"
                                                    ] | date
                                                    }}
                                                </td>
                                                <!-- <td>
                                                            {{
                                                            document[
                                                            "expiry"
                                                            ] | date
                                                            }}
                                                        </td> -->
                                                <td>
                                                    {{
                                                    document[
                                                    "comment"
                                                    ]
                                                    }}
                                                </td>

                                                <td>
                                                    <a *ngIf="
                                                                    dataSensitivity ==
                                                                    1
                                                                " (click)="
                                                                    downloadDocument(
                                                                        document[
                                                                            'id'
                                                                        ],
                                                                        'Chemical'
                                                                    )
                                                                " class="grid justify-center">
                                                        <mat-icon class="table-icon">download
                                                        </mat-icon>
                                                    </a>
                                                </td>

                                                <td *ngIf="!viewMode">
                                                    <a (click)="
                                                                    deleteDocument(
                                                                        document[
                                                                            'id'
                                                                        ],
                                                                        'Chemical'
                                                                    )
                                                                " class="grid justify-center">
                                                        <mat-icon class="table-icon">delete_outline</mat-icon>
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <button *ngIf="!viewMode" class="btn btn-primary" (click)="
                                                    openDocumentUploadDialog(
                                                        'Chemical'
                                                    )
                                                ">
                                        {{
                                        "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.ADD_DOCUMENT_BUTTON"
                                        | translate
                                        }}
                                        <mat-icon class="add-icon">add</mat-icon>
                                    </button>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </mat-tab>

                    <mat-tab label="{{
                                    'DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.WATER'
                                        | translate
                                }}">
                        <div class="topic-div">
                            <h4>
                                {{
                                "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.WATER"
                                | translate
                                }}
                            </h4>

                            <div class="topic-container">
                                <strong>{{
                                    "DOCUMENT_CENTER_COMPONENT.WATER_DATA.WATER_SOURCE.TITLE"
                                    | translate
                                    }}</strong>
                                &nbsp;
                                <a #tooltip="matTooltip" matTooltip="{{
                                                'DOCUMENT_CENTER_COMPONENT.WATER_DATA.WATER_SOURCE.TOOL'
                                                    | translate
                                            }}" matTooltipPosition="below" style="
                                                text-decoration: none;
                                                font-size: xx-col;
                                            ">
                                    <mat-icon class="info-icon">info_outline</mat-icon><br />
                                </a>
                                <div *ngFor="let source of waterSources">
                                    <p-checkbox name="waterSource" value="{{ source['english'] }}" [disabled]="viewMode"
                                        (onChange)="saveWaterData()" [(ngModel)]="
                                                    selectedWaterSources
                                                "></p-checkbox>
                                    {{ source["translation"] }}
                                </div>
                            </div>
                            <div class="topic-container">
                                <strong>{{
                                    "DOCUMENT_CENTER_COMPONENT.WATER_DATA.WASTE_WATER.TITLE"
                                    | translate
                                    }}</strong>
                                &nbsp;
                                <a #tooltip="matTooltip" matTooltip="{{
                                                'DOCUMENT_CENTER_COMPONENT.WATER_DATA.WASTE_WATER.TOOL'
                                                    | translate
                                            }}" matTooltipPosition="below" style="
                                                text-decoration: none;
                                                font-size: xx-small;
                                            ">
                                    <mat-icon class="info-icon">info_outline</mat-icon><br />
                                </a>

                                <br />
                                <div *ngFor="
                                                let treatment of wasteWaterTreatments
                                            ">
                                    <p-checkbox name="wasteWaterTreatment" value="{{
                                                    treatment['english']
                                                }}" [disabled]="viewMode" (onChange)="
                                                    saveWastewaterData()
                                                " [(ngModel)]="
                                                    selectedWasteWaterTreatments
                                                "></p-checkbox>
                                    {{ treatment["translation"] }}
                                </div>
                            </div>

                            <mat-accordion>
                                <mat-expansion-panel class="document-accordion" (opened)="
                                                fetchConsumptionData('Water')
                                            ">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            {{
                                            "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.WATER_CONSUMPTION"
                                            | translate
                                            }}
                                            &nbsp;
                                            <a #tooltip="matTooltip" matTooltip="{{
                                                            'DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.WATER_CONSUMPTIONTOOL'
                                                                | translate
                                                        }}" matTooltipPosition="below" style="
                                                            text-decoration: none;
                                                            font-size: xx-small;
                                                        ">
                                                <mat-icon class="info-icon">info_outline</mat-icon><br />
                                            </a>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <table class="table table-striped tablefixed table-waste-water">
                                        <thead>
                                            <tr>
                                                <th>
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.YEAR"
                                                    | translate
                                                    }}
                                                </th>
                                                <th>
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.CONSUMPTION"
                                                    | translate
                                                    }}
                                                </th>
                                                <th>
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.WATER_SOURCE"
                                                    | translate
                                                    }}
                                                </th>
                                                <th>
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.COMMENT"
                                                    | translate
                                                    }}
                                                </th>
                                                <th *ngIf="
                                                                dataSensitivity ==
                                                                1
                                                            ">
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.DOWNLOAD"
                                                    | translate
                                                    }}
                                                </th>
                                                <!-- <th>{{ "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.EDIT" | translate }}</th> -->
                                                <th *ngIf="!viewMode">
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.DELETE"
                                                    | translate
                                                    }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="
                                                            let data of consumptionData[
                                                                'Water'
                                                            ]
                                                        ">
                                                <td>
                                                    {{ data["year"] }}
                                                </td>
                                                <td>
                                                    {{
                                                    data[
                                                    "consumption"
                                                    ] | number
                                                    }}
                                                    {{
                                                    data[
                                                    "consumptionUnit"
                                                    ]
                                                    }}/{{
                                                    data["timeUnit"]
                                                    }}
                                                </td>
                                                <td>
                                                    {{ data["source"] }}
                                                </td>
                                                <td>
                                                    {{
                                                    data["comment"]
                                                    }}
                                                </td>
                                                <td *ngIf="
                                                                dataSensitivity ==
                                                                1
                                                            ">
                                                    <a (click)="
                                                                    downloadConsumptionDocument(
                                                                        data[
                                                                            'id'
                                                                        ]
                                                                    )
                                                                " class="grid justify-center" [ngClass]="{
                                                                    'disabled-link':
                                                                        !data[
                                                                            'file'
                                                                        ]
                                                                }">
                                                        <mat-icon class="table-icon">download
                                                        </mat-icon>
                                                    </a>
                                                </td>
                                                <!-- <td><a (click)="downloadConsumptionDocument()" class="grid justify-center"><mat-icon class="table-icon">edit_outline</mat-icon></a></td> -->
                                                <td *ngIf="!viewMode">
                                                    <a (click)="
                                                                    deleteConsumptionDocument(
                                                                        data[
                                                                            'id'
                                                                        ],
                                                                        'Water'
                                                                    )
                                                                " class="grid justify-center">
                                                        <mat-icon class="table-icon">delete_outline</mat-icon>
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <button *ngIf="!viewMode" class="btn btn-primary" (click)="
                                                    openConsumptionUploadDialog(
                                                        'Water'
                                                    )
                                                ">
                                        {{
                                        "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.ADD_CONSUMPTION_BUTTON"
                                        | translate
                                        }}
                                        <mat-icon class="add-icon">add</mat-icon>
                                    </button>
                                </mat-expansion-panel>

                                <mat-expansion-panel (opened)="
                                                fetchDocuments(
                                                    true,
                                                    true,
                                                    'Water'
                                                )
                                            ">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            {{
                                            "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.DOCUMENTS_HEADER"
                                            | translate
                                            }}
                                            &nbsp;
                                            <a #tooltip="matTooltip" matTooltip="{{
                                                            'DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.WATER_CONSUMPTION_DOCUMENTS_TOOL'
                                                                | translate
                                                        }}" matTooltipPosition="below" style="
                                                            text-decoration: none;
                                                            font-size: xx-small;
                                                        ">
                                                <mat-icon class="info-icon">info_outline</mat-icon><br />
                                            </a>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <table class="table table-striped tablefixed table-waste-water">
                                        <thead>
                                            <tr>
                                                <th>
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.NAME"
                                                    | translate
                                                    }}
                                                </th>
                                                <th>
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.TYPE"
                                                    | translate
                                                    }}
                                                </th>
                                                <th>
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.RESULT"
                                                    | translate
                                                    }}
                                                </th>
                                                <th>
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.UPLOAD_DATE"
                                                    | translate
                                                    }}
                                                </th>
                                                <th>
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.ISSUE_DATE"
                                                    | translate
                                                    }}
                                                </th>
                                                <th>
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.EXPIRY_DATE"
                                                    | translate
                                                    }}
                                                </th>
                                                <th>
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.COMMENT"
                                                    | translate
                                                    }}
                                                </th>
                                                <th *ngIf="
                                                                dataSensitivity ==
                                                                1
                                                            ">
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.DOWNLOAD"
                                                    | translate
                                                    }}
                                                </th>
                                                <th *ngIf="!viewMode">
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.DELETE"
                                                    | translate
                                                    }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="
                                                            let document of documents[
                                                                'Water'
                                                            ]
                                                        ">
                                                <td>
                                                    {{
                                                    document["name"]
                                                    }}
                                                </td>
                                                <td>
                                                    {{
                                                    translateDocumentType(
                                                    document[
                                                    "category"
                                                    ]
                                                    )
                                                    }}
                                                </td>
                                                <td>
                                                    {{
                                                    convertPassfail(
                                                    document[
                                                    "passfail"
                                                    ]
                                                    )
                                                    }}
                                                </td>
                                                <td>
                                                    {{
                                                    document[
                                                    "upload"
                                                    ] | date
                                                    }}
                                                </td>
                                                <td>
                                                    {{
                                                    document[
                                                    "issue"
                                                    ] | date
                                                    }}
                                                </td>
                                                <td>
                                                    {{
                                                    document[
                                                    "expiry"
                                                    ] | date
                                                    }}
                                                </td>
                                                <td>
                                                    {{
                                                    document[
                                                    "comment"
                                                    ]
                                                    }}
                                                </td>
                                                <td *ngIf="
                                                                dataSensitivity ==
                                                                1
                                                            ">
                                                    <a (click)="
                                                                    downloadDocument(
                                                                        document[
                                                                            'id'
                                                                        ],
                                                                        'Water'
                                                                    )
                                                                " class="grid justify-center">
                                                        <mat-icon class="table-icon">download
                                                        </mat-icon>
                                                    </a>
                                                </td>
                                                <td *ngIf="!viewMode">
                                                    <a (click)="
                                                                    deleteDocument(
                                                                        document[
                                                                            'id'
                                                                        ],
                                                                        'Water'
                                                                    )
                                                                " class="grid justify-center">
                                                        <mat-icon class="table-icon">delete_outline</mat-icon>
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <button *ngIf="!viewMode" class="btn btn-primary" (click)="
                                                    openDocumentUploadDialog(
                                                        'Water'
                                                    )
                                                ">
                                        {{
                                        "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.ADD_DOCUMENT_BUTTON"
                                        | translate
                                        }}
                                        <mat-icon class="add-icon">add</mat-icon>
                                    </button>
                                </mat-expansion-panel>

                                <mat-expansion-panel *ngIf="isToShowTestWater" (opened)="
                                                fetchDocuments(
                                                    true,
                                                    true,
                                                    'WasteWater'
                                                )
                                            ">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            {{
                                            "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.WASTE_WATER"
                                            | translate
                                            }}
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <table class="table table-striped table-waste-water" style="overflow-x: scroll">
                                        <thead>
                                            <tr>
                                                <th>
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.DOCUMENT_NAME"
                                                    | translate
                                                    }}
                                                </th>
                                                <th>Document ID</th>
                                                <th>
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.ISSUE_DATE"
                                                    | translate
                                                    }}
                                                </th>
                                                <!-- <th>{{ "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.EXPIRY_DATE" | translate }}</th>
                        <th>{{ "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.UPLOAD_DATE" | translate }}</th>
                        <th>{{ "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.COMMENT" | translate }}</th> -->
                                                <th>
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.SUM_PARAMETERS"
                                                    | translate
                                                    }}
                                                    <a #tooltip="matTooltip" matTooltip="{{
                                                                    'DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS_TOOL_TIP.WASTEWATER_DISCLAIMER'
                                                                        | translate
                                                                }}" matTooltipPosition="above" style="
                                                                    text-decoration: none;
                                                                    font-size: xx-small;
                                                                ">
                                                        <mat-icon class="info-icon">info_outline</mat-icon>
                                                    </a>
                                                </th>
                                                <th>
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.ANIONS"
                                                    | translate
                                                    }}
                                                    <a #tooltip="matTooltip" matTooltip="{{
                                                                    'DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS_TOOL_TIP.WASTEWATER_DISCLAIMER'
                                                                        | translate
                                                                }}" matTooltipPosition="above" style="
                                                                    text-decoration: none;
                                                                    font-size: xx-small;
                                                                ">
                                                        <mat-icon class="info-icon">info_outline</mat-icon>
                                                    </a>
                                                </th>
                                                <th>
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.METALS"
                                                    | translate
                                                    }}
                                                    <a #tooltip="matTooltip" matTooltip="{{
                                                                    'DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS_TOOL_TIP.WASTEWATER_DISCLAIMER'
                                                                        | translate
                                                                }}" matTooltipPosition="above" style="
                                                                    text-decoration: none;
                                                                    font-size: xx-small;
                                                                ">
                                                        <mat-icon class="info-icon">info_outline</mat-icon>
                                                    </a>
                                                </th>
                                                <th>
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.ZDHC_MRSL_WASTEWATER_PARAMETER"
                                                    | translate
                                                    }}
                                                    <a #tooltip="matTooltip" matTooltip="{{
                                                                    'DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS_TOOL_TIP.WASTEWATER_DISCLAIMER'
                                                                        | translate
                                                                }}" matTooltipPosition="above" style="
                                                                    text-decoration: none;
                                                                    font-size: xx-small;
                                                                ">
                                                        <mat-icon class="info-icon">info_outline</mat-icon>
                                                    </a>
                                                </th>

                                                <th *ngIf="
                                                                dataSensitivity ==
                                                                1
                                                            ">
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.DOWNLOAD"
                                                    | translate
                                                    }}
                                                </th>

                                                <th *ngIf="!viewMode">
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.DELETE"
                                                    | translate
                                                    }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="
                                                            let data of documents[
                                                                'WasteWater'
                                                            ]
                                                        ">
                                                <td>
                                                    {{ data["name"] }}
                                                </td>
                                                <td>
                                                    {{
                                                    data[
                                                    "documentID"
                                                    ]
                                                    }}
                                                </td>
                                                <td>
                                                    {{
                                                    data["issue"]
                                                    | date
                                                    }}
                                                </td>
                                                <!-- <td>{{data['expiry'] | date}}</td>
                        <td>{{data['uploadDate'] | date}} </td>
                        <td>{{data['comment']}}</td> -->
                                                <td [ngClass]="{
                                                                failed:
                                                                    data[
                                                                        'sumParametersPerformance'
                                                                    ] == 3,
                                                                foundational:
                                                                    data[
                                                                        'sumParametersPerformance'
                                                                    ] == 2,
                                                                progressive:
                                                                    data[
                                                                        'sumParametersPerformance'
                                                                    ] == 1,
                                                                aspirational:
                                                                    data[
                                                                        'sumParametersPerformance'
                                                                    ] == 0
                                                            }">
                                                    <a (click)="
                                                                    openWasteWaterViewDialog(
                                                                        data[
                                                                            'id'
                                                                        ],
                                                                        1
                                                                    )
                                                                ">
                                                        {{
                                                        checkWasteWaterResultConventional(
                                                        data[
                                                        "sumParametersPerformance"
                                                        ]
                                                        )
                                                        | translate
                                                        }}
                                                    </a>
                                                </td>
                                                <td [ngClass]="{
                                                                failed:
                                                                    data[
                                                                        'anionsPerformance'
                                                                    ] == 3,
                                                                foundational:
                                                                    data[
                                                                        'anionsPerformance'
                                                                    ] == 2,
                                                                progressive:
                                                                    data[
                                                                        'anionsPerformance'
                                                                    ] == 1,
                                                                aspirational:
                                                                    data[
                                                                        'anionsPerformance'
                                                                    ] == 0
                                                            }">
                                                    <a (click)="
                                                                    openWasteWaterViewDialog(
                                                                        data[
                                                                            'id'
                                                                        ],
                                                                        2
                                                                    )
                                                                ">
                                                        {{
                                                        checkWasteWaterResultConventional(
                                                        data[
                                                        "anionsPerformance"
                                                        ]
                                                        )
                                                        | translate
                                                        }}
                                                    </a>
                                                </td>
                                                <td [ngClass]="{
                                                                failed:
                                                                    data[
                                                                        'metalsPerformance'
                                                                    ] == 3,
                                                                foundational:
                                                                    data[
                                                                        'metalsPerformance'
                                                                    ] == 2,
                                                                progressive:
                                                                    data[
                                                                        'metalsPerformance'
                                                                    ] == 1,
                                                                aspirational:
                                                                    data[
                                                                        'metalsPerformance'
                                                                    ] == 0
                                                            }">
                                                    <a (click)="
                                                                    openWasteWaterViewDialog(
                                                                        data[
                                                                            'id'
                                                                        ],
                                                                        3
                                                                    )
                                                                ">
                                                        {{
                                                        checkWasteWaterResultConventional(
                                                        data[
                                                        "metalsPerformance"
                                                        ]
                                                        )
                                                        | translate
                                                        }}
                                                    </a>
                                                </td>
                                                <td [ngClass]="{
                                                                failed:
                                                                    data[
                                                                        'zdhcParametersPerformance'
                                                                    ] == 1,
                                                                comply:
                                                                    data[
                                                                        'zdhcParametersPerformance'
                                                                    ] == 0
                                                            }">
                                                    <a (click)="
                                                                    openWasteWaterViewDialog(
                                                                        data[
                                                                            'id'
                                                                        ],
                                                                        4
                                                                    )
                                                                ">
                                                        {{
                                                        checkWasteWaterResultZDHCParameters(
                                                        data[
                                                        "zdhcParametersPerformance"
                                                        ]
                                                        )
                                                        | translate
                                                        }}
                                                    </a>
                                                </td>

                                                <td *ngIf="
                                                                dataSensitivity ==
                                                                1
                                                            ">
                                                    <a (click)="
                                                                    downloadDocument(
                                                                        data[
                                                                            'id'
                                                                        ]
                                                                    )
                                                                " class="grid justify-center">
                                                        <mat-icon class="table-icon">download
                                                        </mat-icon>
                                                    </a>
                                                </td>
                                                <!-- <td><a (click)="downloadConsumptionDocument()" class="grid justify-center"><mat-icon class="table-icon">edit_outline</mat-icon></a></td> -->
                                                <td *ngIf="!viewMode">
                                                    <a (click)="
                                                                    deleteDocument(
                                                                        data[
                                                                            'id'
                                                                        ],
                                                                        'WasteWater'
                                                                    )
                                                                " class="grid justify-center">
                                                        <mat-icon class="table-icon">delete_outline</mat-icon>
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <button *ngIf="!viewMode" class="btn btn-primary" (click)="
                                                    openWasteWaterUploadDialog()
                                                ">
                                        {{
                                        "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.ADD_TEST_REPORT"
                                        | translate
                                        }}<mat-icon class="add-icon">add
                                        </mat-icon>
                                    </button>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </mat-tab>
                    <mat-tab label="{{
                                    'DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.ENERGY'
                                        | translate
                                }}">
                        <div class="topic-div">
                            <h4>
                                {{
                                "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.ENERGY"
                                | translate
                                }}
                            </h4>
                            <div class="topic-container-100">
                                <strong i18n="Energy source">{{
                                    "DOCUMENT_CENTER_COMPONENT.ENERGY_DATA.SUBTITLE"
                                    | translate
                                    }}</strong>
                                &nbsp;
                                <a #tooltip="matTooltip" matTooltip="{{
                                                'DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.ENERGYTOOL'
                                                    | translate
                                            }}" matTooltipPosition="below" style="
                                                text-decoration: none;
                                                font-size: xx-small;
                                            ">
                                    <mat-icon class="info-icon">info_outline</mat-icon><br />
                                </a>

                                <br />
                                <div *ngFor="let source of energySources" class="col-6 col-sm-6 col-md-6 col-lg-4">
                                    <p-checkbox name="energySource" [disabled]="viewMode"
                                        value="{{ source['english'] }}" (onChange)="saveEnergyData()" [(ngModel)]="
                                                    selectedEnergySources
                                                "></p-checkbox>
                                    {{ source["translation"] }}
                                </div>
                            </div>

                            <mat-accordion>
                                <mat-expansion-panel class="document-accordion" (opened)="
                                                fetchConsumptionData('Energy')
                                            ">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            {{
                                            "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.ENERGY_CONSUMPTION"
                                            | translate
                                            }}
                                            &nbsp;
                                            <a #tooltip="matTooltip" matTooltip="{{
                                                            'DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.ENERGY_CONSUMPTION_TOOL'
                                                                | translate
                                                        }}" matTooltipPosition="below" style="
                                                            text-decoration: none;
                                                            font-size: xx-small;
                                                        ">
                                                <mat-icon class="info-icon">info_outline</mat-icon><br />
                                            </a>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <table class="table table-striped tablefixed table-waste-water">
                                        <thead>
                                            <tr>
                                                <th>
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.YEAR"
                                                    | translate
                                                    }}
                                                </th>
                                                <th>
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.CONSUMPTION"
                                                    | translate
                                                    }}
                                                </th>
                                                <th>
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.ENERGY_SOURCE"
                                                    | translate
                                                    }}
                                                </th>
                                                <th>
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.COMMENT"
                                                    | translate
                                                    }}
                                                </th>
                                                <th *ngIf="
                                                                dataSensitivity ==
                                                                1
                                                            ">
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.DOWNLOAD"
                                                    | translate
                                                    }}
                                                </th>
                                                <!-- <th>{{ "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.EDIT" | translate }}</th> -->
                                                <th *ngIf="!viewMode">
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.DELETE"
                                                    | translate
                                                    }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="
                                                            let data of consumptionData[
                                                                'Energy'
                                                            ]
                                                        ">
                                                <td>
                                                    {{ data["year"] }}
                                                </td>
                                                <td>
                                                    {{
                                                    data[
                                                    "consumption"
                                                    ] | number
                                                    }}
                                                    {{
                                                    data[
                                                    "consumptionUnit"
                                                    ]
                                                    }}/{{
                                                    data["timeUnit"]
                                                    }}
                                                </td>
                                                <td>
                                                    {{ data["source"] }}
                                                </td>
                                                <td>
                                                    {{
                                                    data["comment"]
                                                    }}
                                                </td>
                                                <td *ngIf="
                                                                dataSensitivity ==
                                                                1
                                                            ">
                                                    <a (click)="
                                                                    downloadConsumptionDocument(
                                                                        data[
                                                                            'id'
                                                                        ]
                                                                    )
                                                                " class="grid justify-center" [ngClass]="{
                                                                    'disabled-link':
                                                                        !data[
                                                                            'file'
                                                                        ]
                                                                }">
                                                        <mat-icon class="table-icon">download
                                                        </mat-icon>
                                                    </a>
                                                </td>
                                                <!-- <td><a (click)="downloadConsumptionDocument()" class="grid justify-center"><mat-icon class="table-icon">edit_outline</mat-icon></a></td> -->
                                                <td *ngIf="!viewMode">
                                                    <a (click)="
                                                                    deleteConsumptionDocument(
                                                                        data[
                                                                            'id'
                                                                        ],
                                                                        'Energy'
                                                                    )
                                                                " class="grid justify-center">
                                                        <mat-icon class="table-icon">delete_outline</mat-icon>
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <button class="btn btn-primary" *ngIf="!viewMode" (click)="
                                                    openConsumptionUploadDialog(
                                                        'Energy'
                                                    )
                                                ">
                                        {{
                                        "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.ADD_CONSUMPTION_BUTTON"
                                        | translate
                                        }}
                                        <mat-icon class="add-icon">add</mat-icon>
                                    </button>
                                </mat-expansion-panel>

                                <mat-expansion-panel (opened)="
                                                fetchDocuments(
                                                    true,
                                                    true,
                                                    'Energy'
                                                )
                                            ">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            {{
                                            "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.DOCUMENTS_HEADER"
                                            | translate
                                            }}
                                            &nbsp;
                                            <a #tooltip="matTooltip" matTooltip="{{
                                                            'DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.ENERGY_CONSUMPTION_DOCUMENTS_TOOL'
                                                                | translate
                                                        }}" matTooltipPosition="below" style="
                                                            text-decoration: none;
                                                            font-size: xx-small;
                                                        ">
                                                <mat-icon class="info-icon">info_outline</mat-icon><br />
                                            </a>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <table class="table table-striped tablefixed table-waste-water">
                                        <thead>
                                            <tr>
                                                <th>
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.NAME"
                                                    | translate
                                                    }}
                                                </th>
                                                <th>
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.TYPE"
                                                    | translate
                                                    }}
                                                </th>
                                                <th>
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.RESULT"
                                                    | translate
                                                    }}
                                                </th>
                                                <th>
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.UPLOAD_DATE"
                                                    | translate
                                                    }}
                                                </th>
                                                <th>
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.ISSUE_DATE"
                                                    | translate
                                                    }}
                                                </th>
                                                <th>
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.EXPIRY_DATE"
                                                    | translate
                                                    }}
                                                </th>
                                                <th>
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.COMMENT"
                                                    | translate
                                                    }}
                                                </th>
                                                <th *ngIf="
                                                                dataSensitivity ==
                                                                1
                                                            ">
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.DOWNLOAD"
                                                    | translate
                                                    }}
                                                </th>
                                                <th *ngIf="!viewMode">
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.DELETE"
                                                    | translate
                                                    }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="
                                                            let document of documents[
                                                                'Energy'
                                                            ]
                                                        ">
                                                <td>
                                                    {{
                                                    document["name"]
                                                    }}
                                                </td>
                                                <td>
                                                    {{
                                                    translateDocumentType(
                                                    document[
                                                    "category"
                                                    ]
                                                    )
                                                    }}
                                                </td>
                                                <td>
                                                    {{
                                                    convertPassfail(
                                                    document[
                                                    "passfail"
                                                    ]
                                                    )
                                                    }}
                                                </td>
                                                <td>
                                                    {{
                                                    document[
                                                    "upload"
                                                    ] | date
                                                    }}
                                                </td>
                                                <td>
                                                    {{
                                                    document[
                                                    "issue"
                                                    ] | date
                                                    }}
                                                </td>
                                                <td>
                                                    {{
                                                    document[
                                                    "expiry"
                                                    ] | date
                                                    }}
                                                </td>
                                                <td>
                                                    {{
                                                    document[
                                                    "comment"
                                                    ]
                                                    }}
                                                </td>
                                                <td *ngIf="
                                                                dataSensitivity ==
                                                                1
                                                            ">
                                                    <a (click)="
                                                                    downloadDocument(
                                                                        document[
                                                                            'id'
                                                                        ],
                                                                        'Energy'
                                                                    )
                                                                " class="grid justify-center">
                                                        <mat-icon class="table-icon">download
                                                        </mat-icon>
                                                    </a>
                                                </td>
                                                <td *ngIf="!viewMode">
                                                    <a (click)="
                                                                    deleteDocument(
                                                                        document[
                                                                            'id'
                                                                        ],
                                                                        'Energy'
                                                                    )
                                                                " class="grid justify-center">
                                                        <mat-icon class="table-icon">delete_outline</mat-icon>
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <button class="btn btn-primary" *ngIf="!viewMode" (click)="
                                                    openDocumentUploadDialog(
                                                        'Energy'
                                                    )
                                                ">
                                        {{
                                        "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.ADD_DOCUMENT_BUTTON"
                                        | translate
                                        }}
                                        <mat-icon class="add-icon">add</mat-icon>
                                    </button>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </mat-tab>
                    <mat-tab label="{{
                                    'DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.HEALTH_SAFETY'
                                        | translate
                                }}">
                        <div class="topic-div">
                            <h4>
                                {{
                                "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.HEALTH_SAFETY"
                                | translate
                                }}
                            </h4>

                            <mat-accordion>
                                <mat-expansion-panel class="document-accordion" (opened)="
                                                fetchDocuments(
                                                    true,
                                                    true,
                                                    'HealthAndSafety'
                                                )
                                            ">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            {{
                                            "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.DOCUMENTS_HEADER"
                                            | translate
                                            }}
                                            <!--
            &nbsp;
            <a #tooltip="matTooltip"
            matTooltip="{{'DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.HEALTH_SAFETY_TOOL' | translate}}"
            matTooltipPosition="below"
            style= "text-decoration: none; font-size: xx-small;">
              <mat-icon class="info-icon">info_outline</mat-icon><br />
            </a>-->
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <table class="table table-striped tablefixed table-waste-water">
                                        <thead>
                                            <tr>
                                                <th>
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.NAME"
                                                    | translate
                                                    }}
                                                </th>
                                                <th>
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.TYPE"
                                                    | translate
                                                    }}
                                                </th>
                                                <th>
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.RESULT"
                                                    | translate
                                                    }}
                                                </th>
                                                <th>
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.UPLOAD_DATE"
                                                    | translate
                                                    }}
                                                </th>
                                                <th>
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.ISSUE_DATE"
                                                    | translate
                                                    }}
                                                </th>
                                                <th>
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.EXPIRY_DATE"
                                                    | translate
                                                    }}
                                                </th>
                                                <th>
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.COMMENT"
                                                    | translate
                                                    }}
                                                </th>
                                                <th *ngIf="
                                                                dataSensitivity ==
                                                                1
                                                            ">
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.DOWNLOAD"
                                                    | translate
                                                    }}
                                                </th>
                                                <th *ngIf="!viewMode">
                                                    {{
                                                    "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.TABLE_HEADERS.DELETE"
                                                    | translate
                                                    }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="
                                                            let document of documents[
                                                                'HealthAndSafety'
                                                            ]
                                                        ">
                                                <td>
                                                    {{
                                                    document["name"]
                                                    }}
                                                </td>
                                                <td>
                                                    {{
                                                    translateDocumentType(
                                                    document[
                                                    "category"
                                                    ]
                                                    )
                                                    }}
                                                </td>
                                                <td>
                                                    {{
                                                    convertPassfail(
                                                    document[
                                                    "passfail"
                                                    ]
                                                    )
                                                    }}
                                                </td>
                                                <td>
                                                    {{
                                                    document[
                                                    "upload"
                                                    ] | date
                                                    }}
                                                </td>
                                                <td>
                                                    {{
                                                    document[
                                                    "issue"
                                                    ] | date
                                                    }}
                                                </td>
                                                <td>
                                                    {{
                                                    document[
                                                    "expiry"
                                                    ] | date
                                                    }}
                                                </td>
                                                <td>
                                                    {{
                                                    document[
                                                    "comment"
                                                    ]
                                                    }}
                                                </td>
                                                <td *ngIf="
                                                                dataSensitivity ==
                                                                1
                                                            ">
                                                    <a (click)="
                                                                    downloadDocument(
                                                                        document[
                                                                            'id'
                                                                        ],
                                                                        'HealthAndSafety'
                                                                    )
                                                                " class="grid justify-center">
                                                        <mat-icon class="table-icon">download
                                                        </mat-icon>
                                                    </a>
                                                </td>
                                                <td *ngIf="!viewMode">
                                                    <a (click)="
                                                                    deleteDocument(
                                                                        document[
                                                                            'id'
                                                                        ],
                                                                        'HealthAndSafety'
                                                                    )
                                                                " class="grid justify-center">
                                                        <mat-icon class="table-icon">delete_outline</mat-icon>
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <button *ngIf="!viewMode" class="btn btn-primary" (click)="
                                                    openDocumentUploadDialog(
                                                        'HealthAndSafety'
                                                    )
                                                ">
                                        {{
                                        "DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.ADD_DOCUMENT_BUTTON"
                                        | translate
                                        }}
                                        <mat-icon class="add-icon">add</mat-icon>
                                    </button>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </mat-tab>
                </mat-tab-group>

                <!--  </mat-tab>

                   <mat-tab label="{{
                            'DOCUMENT_CENTER_COMPONENT.CHEMICAL_SPECIFIC.LABEL'
                                | translate
                        }}">
                        <div>
                            <div>
                                <mat-form-field appearance="outline" i18n="Document Center Search Field">
                                    <input class="filter" matInput i18n-placeholder placeholder="Search" (keyup)="
                                            applyFilterFormula(
                                                $event.target.value
                                            )
                                        " (focus)="
                                            setupFilter('manufacturer', 'name')
                                        " />
                                    <mat-hint>{{
                                        "DOCUMENT_CENTER_COMPONENT.SEARCH_FIELD_HINT"
                                        | translate
                                        }}</mat-hint>
                                </mat-form-field>
                                &nbsp;
                            </div>
                            <div appMakeSticky [customOffset]="formOffset" class="sticky-header-div"
                                i18n="Document Center Table Header div">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>
                                                {{
                                                "DOCUMENT_CENTER_COMPONENT.TABLE.HEADER.NAME"
                                                | translate
                                                }}
                                            </th>
                                            <th>
                                                {{
                                                "DOCUMENT_CENTER_COMPONENT.TABLE.HEADER.MANUFACTURER"
                                                | translate
                                                }}
                                            </th>
                                            <th>
                                                {{
                                                "DOCUMENT_CENTER_COMPONENT.TABLE.HEADER.SOURCE"
                                                | translate
                                                }}
                                            </th>
                                            <th>
                                                {{
                                                "DOCUMENT_CENTER_COMPONENT.TABLE.HEADER.DOCUMENT"
                                                | translate
                                                }}
                                            </th>
                                            <th *ngIf="!viewMode">
                                                {{
                                                "DOCUMENT_CENTER_COMPONENT.TABLE.HEADER.ACTIONS"
                                                | translate
                                                }}
                                            </th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                            <table mat-table [dataSource]="dataSourceFormula" class="table table-striped"
                                style="overflow-x: scroll">
                                <ng-container matColumnDef="Name">
                                    <th class="hidden" mat-header-cell *matHeaderCellDef></th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.name }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="Manufacturer">
                                    <th class="hidden" mat-header-cell *matHeaderCellDef></th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.manufacturer }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="Source">
                                    <th class="hidden" mat-header-cell *matHeaderCellDef></th>
                                    <td mat-cell *matCellDef="let element">
                                        {{
                                        element.masterid != -1
                                        ? "Database Formula"
                                        : "Manually Added"
                                        }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="Document">
                                    <th class="hidden" mat-header-cell *matHeaderCellDef></th>
                                    <td mat-cell *matCellDef="let element">

                                        <div *ngIf="
                                        !viewMode &&
                                        dataSensitivity == 1">



                                            <button class="btn btn-primary" tooltip="{{
                                                'DOCUMENT_CENTER_COMPONENT.TABLE.BODY.VIEW_BUTTON_TOOLTIP'
                                                    | translate
                                            }}" (click)="
                                                viewChemicalDocuments(element)
                                            ">
                                                {{
                                                "DOCUMENT_CENTER_COMPONENT.TABLE.BODY.VIEW_BUTTON"
                                                | translate
                                                }}
                                                ({{ element.document_count }})
                                            </button>


                                            
                                        </div>



                                        <button *ngIf="
                                                viewMode && dataSensitivity == 1
                                            " [disabled]="
                                                element.document_count == 0
                                            " class="btn btn-primary" tooltip="{{
                                                'DOCUMENT_CENTER_COMPONENT.TABLE.BODY.VIEW_BUTTON_TOOLTIP'
                                                    | translate
                                            }}" (click)="
                                                viewChemicalDocuments(element)
                                            ">
                                            {{
                                            "DOCUMENT_CENTER_COMPONENT.TABLE.BODY.VIEW_BUTTON_BRAND"
                                            | translate
                                            }}
                                        </button>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="Actions">
                                    <th class="hidden" mat-header-cell *matHeaderCellDef></th>
                                    <td mat-cell *matCellDef="let element" align="center" i18n="
                                            Document Center Show Info, Download
                                            and Delete Buttons
                                        ">
                                        <button class="btn btn-primary" tooltip="{{
                                                'DOCUMENT_CENTER_COMPONENT.TABLE.BODY.SHOW_INFO_BUTTON_TOOLTIP'
                                                    | translate
                                            }}" placement="top" type="submit" (click)="openDialog(element)">
                                            {{
                                            "DOCUMENT_CENTER_COMPONENT.TABLE.BODY.SHOW_INFO_BUTTON"
                                            | translate
                                            }}
                                        </button>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="
                                        let row;
                                        columns: displayedColumns
                                    "></tr>
                            </table>
                        </div>
                        <div class="paginator">
                            <mat-paginator [pageSizeOptions]="[10, 20, 30, 40, 50]" showFirstLastButtons
                                ng-disabled="ng-reflect-message">
                            </mat-paginator>
                        </div>
                    </mat-tab> 
                </mat-tab-group>-->

                <ngx-loading [show]="loading"></ngx-loading>
            </div>
        </div>
    </div>
</div>