<div class="content">
    <div class="flex justify-between">
        <div class="h3 m-0 p-2" *ngIf="!isLatestReport">

            {{ "WASTE_WATER_UPLOAD_COMPONENT.HEADER" | translate }}

        </div>

        <div class="h3 m-0 p-2" *ngIf="isLatestReport">

            {{ "WASTE_WATER_UPLOAD_COMPONENT.HEADER1" | translate }}

        </div>

        <div class="w3-large">
            <a (click)="cancel()">
                <mat-icon>close</mat-icon>
            </a>
        </div>
    </div>

    <!-- <div class="h3">{{ "WASTE_WATER_UPLOAD_COMPONENT.HEADER" | translate }}</div> -->

    <div class="buttons-div" *ngIf="!this.viewMode">
        <button class="btn btn-primary buttons" style="width:300px;" [disabled]="!isFormValid()"
            (click)="upload()">Submit</button>
        <button class="btn buttons" (click)="cancel()" style="width:300px;">{{
            "DOCUMENT_UPLOAD_COMPONENT.BUTTONS.CANCEL" | translate }}</button>
    </div>

    <mat-horizontal-stepper [linear]="!this.viewMode" #stepper>
        <mat-step [stepControl]="basicInformation">
            <form [formGroup]="basicInformation">
                <ng-template matStepLabel>{{ "WASTE_WATER_UPLOAD_COMPONENT.DOCUMENT_INFORMATION.GENERAL_INFORMATION" |
                    translate }}</ng-template>
                <div class="table-responsive">
                    <mat-form-field class="main-page-form-control">
                        <mat-label>{{ "WASTE_WATER_UPLOAD_COMPONENT.DOCUMENT_INFORMATION.DOCUMENT_NAME" | translate
                            }}</mat-label>
                        <input matInput placeholder="Name of the Document" formControlName="name" required>
                    </mat-form-field>

                    <mat-form-field class="main-page-form-control">
                        <mat-label>{{ "WASTE_WATER_UPLOAD_COMPONENT.DOCUMENT_INFORMATION.DOCUMENT_ID" | translate
                            }}</mat-label>
                        <input matInput placeholder="Document ID" formControlName="documentID" required>
                    </mat-form-field>


                    <mat-form-field class="main-page-form-control">
                        <mat-label>{{ "WASTE_WATER_UPLOAD_COMPONENT.DOCUMENT_INFORMATION.LABORATORY" | translate }}
                        </mat-label>
                        <mat-select panelClass="lab-options" formControlName="testingLab"
                            (selectionChange)="alignValidators()" required>
                            <mat-option *ngFor="let option of approvedLabs" value="{{option.id}}">
                                {{option.legalLabName}} ({{option.city}})
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="main-page-form-control">
                        <mat-label>{{ "WASTE_WATER_UPLOAD_COMPONENT.DOCUMENT_INFORMATION.OTHERS_LAB" | translate
                            }}</mat-label>
                        <input matInput placeholder="Specify Lab Name" formControlName="testingLabOthers" required>
                    </mat-form-field>

                    <mat-form-field class="main-page-form-control">
                        <mat-label>{{ "WASTE_WATER_UPLOAD_COMPONENT.DOCUMENT_INFORMATION.SAMPLING_LOCATION" | translate
                            }}</mat-label>
                        <mat-select formControlName="samplingLocation" required>
                            <mat-option value="rawWasteWater">{{
                                "WASTE_WATER_UPLOAD_COMPONENT.DOCUMENT_INFORMATION.SAMPLING_LOCATION_OPTIONS.RAW_WASTEWATER"
                                | translate }}</mat-option>
                            <mat-option value="dischargedWasteWater">{{
                                "WASTE_WATER_UPLOAD_COMPONENT.DOCUMENT_INFORMATION.SAMPLING_LOCATION_OPTIONS.DISCHARGED_WASTEWATER"
                                | translate }}</mat-option>
                            <!-- <mat-option value="sludge">Sludge</mat-option> -->
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="main-page-form-control">
                        <mat-label>{{ "WASTE_WATER_UPLOAD_COMPONENT.DOCUMENT_INFORMATION.TYPE_OF_WATER_DISCHARGE" |
                            translate }}</mat-label>
                        <mat-select formControlName="typeOfWaterDischarge" required>
                            <mat-option value="direct">{{
                                "WASTE_WATER_UPLOAD_COMPONENT.DOCUMENT_INFORMATION.TYPE_OF_WATER_DISCHARGE_OPTIONS.DIRECT"
                                | translate }}</mat-option>
                            <mat-option value="indirect">{{
                                "WASTE_WATER_UPLOAD_COMPONENT.DOCUMENT_INFORMATION.TYPE_OF_WATER_DISCHARGE_OPTIONS.INDIRECT"
                                | translate }}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="main-page-form-control">
                        <mat-label>{{ "WASTE_WATER_UPLOAD_COMPONENT.DOCUMENT_INFORMATION.SAMPLING_DATE" | translate
                            }}</mat-label>
                        <input matInput [matDatepicker]="picker1" formControlName="samplingDate"
                            (dateInput)="updateIssueDate($event)" (dateChange)="updateIssueDate($event)" required>
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field class="main-page-form-control">
                        <mat-label>{{ "WASTE_WATER_UPLOAD_COMPONENT.DOCUMENT_INFORMATION.TESTING_DATE" | translate
                            }}</mat-label>
                        <input matInput [matDatepicker]="picker2" formControlName="testingDate"
                            (dateInput)="updateIssueDate($event)" (dateChange)="updateIssueDate($event)" required>
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>


                    <mat-form-field class="main-page-form-control">
                        <mat-label>{{ "WASTE_WATER_UPLOAD_COMPONENT.DOCUMENT_INFORMATION.REPORTING_DATE" | translate
                            }}</mat-label>
                        <input matInput [matDatepicker]="picker3" formControlName="issueDate"
                            (dateInput)="updateIssueDate($event)" (dateChange)="updateIssueDate($event)" required>
                        <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                        <mat-datepicker #picker3></mat-datepicker>
                    </mat-form-field>


                    <mat-form-field class="main-page-form-control">
                        <mat-label>{{ "WASTE_WATER_UPLOAD_COMPONENT.DOCUMENT_INFORMATION.EXPIRY_DATE" | translate
                            }}</mat-label>
                        <input matInput [matDatepicker]="picker4" formControlName="expiry" [min]="mindate">
                        <mat-datepicker-toggle matSuffix [for]="picker4" required></mat-datepicker-toggle>
                        <mat-datepicker #picker4></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field class="main-page-form-control">
                        <mat-label>{{ "WASTE_WATER_UPLOAD_COMPONENT.DOCUMENT_INFORMATION.RESULT" | translate
                            }}</mat-label>
                        <mat-select formControlName="passfail">
                            <mat-option value="true">{{ "WASTE_WATER_UPLOAD_COMPONENT.DOCUMENT_INFORMATION.PASSED" |
                                translate }}</mat-option>
                            <mat-option value="false">{{ "WASTE_WATER_UPLOAD_COMPONENT.DOCUMENT_INFORMATION.FAILED" |
                                translate }}</mat-option>
                        </mat-select>
                    </mat-form-field>


                    <mat-form-field class="main-page-form-control">
                        <mat-label>{{ "WASTE_WATER_UPLOAD_COMPONENT.DOCUMENT_INFORMATION.COMMENT" | translate
                            }}</mat-label>
                        <textarea matInput formControlName="comment" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                            id="comment"></textarea>
                    </mat-form-field>

                    <div class="main-page-form-control file-input-wrapper" *ngIf="!viewMode">
                        <div class="inline-div">
                            <label>{{ "DOCUMENT_UPLOAD_COMPONENT.LABELS.FILE_SELECT" | translate }}</label>
                            <input type="file" (change)="addFile($event.target.files)" id="file" required>
                        </div>
                        <div class="inline-div">
                            <button *ngIf="hasFile()" (click)="clearContent()"><mat-icon
                                    class="clear-button-icon">cancel</mat-icon></button>
                        </div>
                    </div>

                    <div *ngIf="viewMode && dataSensitivity==1" style="display: inline;">
                        <button class="btn btn-primary" (click)="downloadDocument()"><mat-icon
                                class="add-icon" style="color: white;">download</mat-icon> {{
                            "WASTE_WATER_UPLOAD_COMPONENT.DOCUMENT_INFORMATION.DOWNLOAD" | translate }}</button>
                    </div>

                </div>
            </form>
            <button matStepperNext class="btn btn-primary buttons">{{
                "WASTE_WATER_UPLOAD_COMPONENT.DOCUMENT_INFORMATION.NEXT" | translate }}</button>
        </mat-step>
        <mat-step [stepControl]="conventionalSumParameters">
            <div>
                <b>{{ "WASTE_WATER_UPLOAD_COMPONENT.DOCUMENT_INFORMATION.LEGEND" | translate }}:</b>
                &nbsp;&nbsp;&nbsp;
                <b>BL</b>: {{ "WASTE_WATER_UPLOAD_COMPONENT.DOCUMENT_INFORMATION.BELOW_DETECTION_LIMIT" | translate }}
                &nbsp;&nbsp;&nbsp;
                <b>NA</b>:{{ "WASTE_WATER_UPLOAD_COMPONENT.DOCUMENT_INFORMATION.NOT_ANALYZED" | translate }}
                &nbsp;&nbsp;&nbsp;
                <b>ND</b>:{{ "WASTE_WATER_UPLOAD_COMPONENT.DOCUMENT_INFORMATION.NON_DETECTED" | translate }}
            </div>
            <form [formGroup]="conventionalSumParameters">
                <ng-template matStepLabel>{{
                    "WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.CONVENTIONAL_PARAMETERS.SUMPARAMETERS" | translate
                    }}</ng-template>

                <div class="table-responsive">
                    <table mat-table [dataSource]="sumParametersTable" class="table">

                        <ng-container matColumnDef="name" sticky>
                            <th mat-header-cell *matHeaderCellDef
                                style="color: black; text-align:center; font-weight: bold; font-size: 1.05em;">{{
                                "WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.CONVENTIONAL_PARAMETERS.PARAMETER_NAME" |
                                translate }}</th>
                            <td mat-cell *matCellDef="let parameter;">
                                {{parameter.name_trans |translate}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="result">
                            <th mat-header-cell *matHeaderCellDef
                                style="color: black; text-align:center; font-weight: bold; font-size: 1.05em;">{{
                                "WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.CONVENTIONAL_PARAMETERS.RESULT" | translate
                                }}</th>
                            <td mat-cell *matCellDef="let parameter;">
                                <div *ngIf="parameter.controlName!='persistentFoam'">
                                    <mat-form-field class="input-field-wrapper">
                                        <input matInput placeholder="{{parameter.unit}}"
                                            formControlName='{{parameter.controlName}}'
                                            (change)="updateLimitsValue(parameter, 'sumParametersTable');checkSumParameters(parameter, 'sumParametersTable')">
                                    </mat-form-field>
                                </div>
                                <div *ngIf="parameter.controlName =='persistentFoam'">
                                    <mat-form-field class="input-field-wrapper">
                                        <mat-select formControlName='{{parameter.controlName}}'
                                            (selectionChange)="updateLimitsValue(parameter, 'sumParametersTable')">
                                            <mat-option value=""></mat-option>
                                            <mat-option *ngFor="let persistentFoamOption of persistentFoamOptions"
                                                [value]="persistentFoamOption.value">
                                                {{persistentFoamOption.option}}
                                            </mat-option>
                                        </mat-select>


                                    </mat-form-field>

                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="limits">
                            <th mat-header-cell *matHeaderCellDef
                                style="color: black; text-align:center; font-weight: bold; font-size: 1.05em;">{{
                                "WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.CONVENTIONAL_PARAMETERS.LIMITS" | translate
                                }}</th>
                            <td mat-cell *matCellDef="let parameter;">
                                <b>{{parameter.limitReached |translate}}</b> <br>
                                <div *ngIf="parameter.aboveLimit!''">
                                    <i>{{parameter.aboveLimit |translate}}
                                        {{"WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.LIMITES_REACHED.BEYOND_THE_FOUNDATIONAL_LIMIT"
                                        | translate}}
                                    </i>
                                </div>
                            </td>
                        </ng-container>


                        <ng-container matColumnDef="actionplan">
                            <th mat-header-cell *matHeaderCellDef
                                style="color: black; text-align:center; font-weight: bold; font-size: 1.05em;">{{
                                "WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.CONVENTIONAL_PARAMETERS.CORRECTIVE_ACTION_PLAN"
                                | translate }}</th>
                            <td mat-cell *matCellDef="let parameter;">
                                <div *ngIf="isFailed(parameter.limitReached)">
                                    <div *ngIf="!viewMode">
                                        <a (click)="downloadCAPTemplate()"><mat-icon
                                                class="add-icon">download</mat-icon> {{
                                            "WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.CONVENTIONAL_PARAMETERS.TEMPLATE"
                                            | translate }}</a>
                                    </div>
                                    <mat-form-field class="input-field-wrapper">
                                        <mat-select formControlName='{{parameter.controlName+"Status"}}'
                                            (selectionChange)="updateLinkedFormControls(parameter, 'sumParametersTable', 'Status')">
                                            <mat-option value="NoneTaken">{{
                                                "WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.CONVENTIONAL_PARAMETERS.CORRECTIVE_ACTION_PLAN_OPTIONS.NONE_TAKEN"
                                                | translate }}</mat-option>
                                            <mat-option value="InProgress">{{
                                                "WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.CONVENTIONAL_PARAMETERS.CORRECTIVE_ACTION_PLAN_OPTIONS.IN_PROGRESS"
                                                | translate }}</mat-option>
                                            <mat-option value="Done">{{
                                                "WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.CONVENTIONAL_PARAMETERS.CORRECTIVE_ACTION_PLAN_OPTIONS.ACCOMPLISHED"
                                                | translate }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </td>
                        </ng-container>


                        <ng-container matColumnDef="deadline">
                            <th mat-header-cell *matHeaderCellDef
                                style="color: black; text-align:center; font-weight: bold; font-size: 1.05em;">{{
                                "WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.CONVENTIONAL_PARAMETERS.DEADLINE" | translate
                                }}</th>
                            <td mat-cell *matCellDef="let parameter;">
                                <div *ngIf="isFailed(parameter.limitReached)">
                                    <mat-form-field class="input-field-wrapper">
                                        <input matInput [matDatepicker]='deadlinePicker'
                                            formControlName='{{parameter.controlName+"Deadline"}}'
                                            (dateInput)="updateLinkedFormControls(parameter, 'sumParametersTable', 'Deadline')"
                                            (dateChange)="updateLinkedFormControls(parameter, 'sumParametersTable', 'Deadline')"
                                            [min]="mindate">
                                        <mat-datepicker-toggle matSuffix [for]='deadlinePicker'></mat-datepicker-toggle>
                                        <mat-datepicker #deadlinePicker></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="conventionalParameters; sticky: true" sticky></tr>
                        <tr mat-row *matRowDef="let row; columns: conventionalParameters; let element" [ngClass]="{
                            'failed': element.limitReached =='WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.LIMITES_REACHED.FAILED' , 
                            'foundational': element.limitReached == 'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.LIMITES_REACHED.FOUNDATIONAL', 
                            'progressive': element.limitReached == 'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.LIMITES_REACHED.PROGRESSIVE', 
                            'aspirational': element.limitReached == 'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.LIMITES_REACHED.ASPIRATIONAL'
                        }"></tr>

                    </table>
                </div>
            </form>
            <button matStepperPrevious class="btn buttons">{{ "WASTE_WATER_UPLOAD_COMPONENT.DOCUMENT_INFORMATION.BACK" |
                translate }}</button>
            <button matStepperNext class="btn btn-primary buttons">{{
                "WASTE_WATER_UPLOAD_COMPONENT.DOCUMENT_INFORMATION.NEXT" | translate }}</button>
        </mat-step>


        <mat-step [stepControl]="conventionalAnions"
            label="{{ 'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.CONVENTIONAL_PARAMETERS.ANIONS' | translate }}">
            <div *ngIf="othersLabMode==false">
                <b>{{ "WASTE_WATER_UPLOAD_COMPONENT.DOCUMENT_INFORMATION.LEGEND" | translate }}:</b>
                &nbsp;&nbsp;&nbsp;
                <b>BL</b>: {{ "WASTE_WATER_UPLOAD_COMPONENT.DOCUMENT_INFORMATION.BELOW_DETECTION_LIMIT" | translate }}
                &nbsp;&nbsp;&nbsp;
                <b>NA</b>:{{ "WASTE_WATER_UPLOAD_COMPONENT.DOCUMENT_INFORMATION.NOT_ANALYZED" | translate }}
                &nbsp;&nbsp;&nbsp;
                <b>ND</b>:{{ "WASTE_WATER_UPLOAD_COMPONENT.DOCUMENT_INFORMATION.NON_DETECTED" | translate }}
            </div>
            <!-- #enddocregion label -->
            <form [formGroup]="conventionalAnions">
                <div class="table-responsive">
                    <table mat-table [dataSource]="anionsParametersTable" class="table">

                        <ng-container matColumnDef="name" sticky>
                            <th mat-header-cell *matHeaderCellDef
                                style="color: black; text-align:center; font-weight: bold; font-size: 1.05em;">{{
                                'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.CONVENTIONAL_PARAMETERS.PARAMETER_NAME' |
                                translate }}</th>
                            <td mat-cell *matCellDef="let parameter;">
                                {{parameter.name_trans |translate}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="result">
                            <th mat-header-cell *matHeaderCellDef
                                style="color: black; text-align:center; font-weight: bold; font-size: 1.05em;">{{
                                'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.CONVENTIONAL_PARAMETERS.RESULT' | translate
                                }}</th>
                            <td mat-cell *matCellDef="let parameter;">
                                <mat-form-field class="input-field-wrapper">
                                    <input matInput *ngIf="othersLabMode==true" type="number"
                                        placeholder="{{parameter.unit}}" formControlName='{{parameter.controlName}}'
                                        (change)="updateLimitsValue(parameter, 'conventionalAnionsTable')">
                                    <input matInput *ngIf="othersLabMode==false" type="text"
                                        placeholder="{{parameter.unit}}" formControlName='{{parameter.controlName}}'
                                        (change)="updateLimitsValue(parameter, 'conventionalAnionsTable')">

                                </mat-form-field>

                            </td>
                        </ng-container>

                        <ng-container matColumnDef="limits">
                            <th mat-header-cell *matHeaderCellDef
                                style="color: black; text-align:center; font-weight: bold; font-size: 1.05em;">{{
                                'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.CONVENTIONAL_PARAMETERS.LIMITS' | translate
                                }}</th>
                            <td mat-cell *matCellDef="let parameter;">
                                <b>{{parameter.limitReached | translate}}</b> <br>
                                <div *ngIf="parameter.aboveLimit!''">
                                    <i>{{parameter.aboveLimit |translate}}
                                        {{"WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.LIMITES_REACHED.BEYOND_THE_FOUNDATIONAL_LIMIT"
                                        | translate}}
                                    </i>
                                </div>
                            </td>
                        </ng-container>


                        <ng-container matColumnDef="actionplan">
                            <th mat-header-cell *matHeaderCellDef
                                style="color: black; text-align:center; font-weight: bold; font-size: 1.05em;">{{
                                'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.CONVENTIONAL_PARAMETERS.CORRECTIVE_ACTION_PLAN'
                                | translate }}</th>
                            <td mat-cell *matCellDef="let parameter;">
                                <div *ngIf="isFailed(parameter.limitReached)">
                                    <div *ngIf="!viewMode">
                                        <a (click)="downloadCAPTemplate()"><mat-icon
                                                class="add-icon">download</mat-icon> {{
                                            'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.CONVENTIONAL_PARAMETERS.TEMPLATE'
                                            | translate }}</a>
                                    </div>
                                    <mat-form-field class="input-field-wrapper">
                                        <mat-select formControlName='{{parameter.controlName+"Status"}}'
                                            (selectionChange)="updateLinkedFormControls(parameter, 'conventionalAnions', 'Status')"
                                            required>
                                            <mat-option value="NoneTaken">{{
                                                'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.CONVENTIONAL_PARAMETERS.CORRECTIVE_ACTION_PLAN_OPTIONS.NONE_TAKEN'
                                                | translate }}</mat-option>
                                            <mat-option value="InProgress">{{
                                                'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.CONVENTIONAL_PARAMETERS.CORRECTIVE_ACTION_PLAN_OPTIONS.IN_PROGRESS'
                                                | translate }}</mat-option>
                                            <mat-option value="Done">{{
                                                'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.CONVENTIONAL_PARAMETERS.CORRECTIVE_ACTION_PLAN_OPTIONS.ACCOMPLISHED'
                                                | translate }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </td>
                        </ng-container>


                        <ng-container matColumnDef="deadline">
                            <th mat-header-cell *matHeaderCellDef
                                style="color: black; text-align:center; font-weight: bold; font-size: 1.05em;">{{
                                'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.CONVENTIONAL_PARAMETERS.DEADLINE' | translate
                                }}</th>
                            <td mat-cell *matCellDef="let parameter;">
                                <div *ngIf="isFailed(parameter.limitReached)">
                                    <mat-form-field class="input-field-wrapper">
                                        <input matInput [matDatepicker]='deadlinePicker'
                                            formControlName='{{parameter.controlName+"Deadline"}}'
                                            (dateInput)="updateLinkedFormControls(parameter, 'conventionalAnions', 'Deadline')"
                                            (dateChange)="updateLinkedFormControls(parameter, 'conventionalAnions', 'Deadline')"
                                            [min]="mindate">
                                        <mat-datepicker-toggle matSuffix [for]='deadlinePicker'></mat-datepicker-toggle>
                                        <mat-datepicker #deadlinePicker></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </td>
                        </ng-container>


                        <tr mat-header-row *matHeaderRowDef="conventionalParameters; sticky: true" sticky></tr>
                        <tr mat-row *matRowDef="let row; columns: conventionalParameters; let element" [ngClass]="{
                        'failed': element.limitReached =='WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.LIMITES_REACHED.FAILED', 
                        'foundational': element.limitReached == 'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.LIMITES_REACHED.FOUNDATIONAL', 
                        'progressive': element.limitReached == 'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.LIMITES_REACHED.PROGRESSIVE', 
                        'aspirational': element.limitReached == 'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.LIMITES_REACHED.ASPIRATIONAL'
                    }"></tr>

                    </table>
                </div>
            </form>
            <button matStepperPrevious class="btn buttons">{{ "WASTE_WATER_UPLOAD_COMPONENT.DOCUMENT_INFORMATION.BACK" |
                translate }}</button>
            <button matStepperNext class="btn btn-primary buttons">{{
                "WASTE_WATER_UPLOAD_COMPONENT.DOCUMENT_INFORMATION.NEXT" | translate }}</button>
        </mat-step>

        <mat-step [stepControl]="conventionalMetals"
            label="{{ 'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.CONVENTIONAL_PARAMETERS.METALS' | translate }}">
            <div *ngIf="othersLabMode==false">
                <b>{{ "WASTE_WATER_UPLOAD_COMPONENT.DOCUMENT_INFORMATION.LEGEND" | translate }}:</b>
                &nbsp;&nbsp;&nbsp;
                <b>BL</b>: {{ "WASTE_WATER_UPLOAD_COMPONENT.DOCUMENT_INFORMATION.BELOW_DETECTION_LIMIT" | translate }}
                &nbsp;&nbsp;&nbsp;
                <b>NA</b>:{{ "WASTE_WATER_UPLOAD_COMPONENT.DOCUMENT_INFORMATION.NOT_ANALYZED" | translate }}
                &nbsp;&nbsp;&nbsp;
                <b>ND</b>:{{ "WASTE_WATER_UPLOAD_COMPONENT.DOCUMENT_INFORMATION.NON_DETECTED" | translate }}
            </div>
            <form [formGroup]="conventionalMetals">
                <div class="table-responsive">
                    <table mat-table [dataSource]="metalsParametersTable" class="table">

                        <ng-container matColumnDef="name" sticky>
                            <th mat-header-cell *matHeaderCellDef
                                style="color: black; text-align:center; font-weight: bold; font-size: 1.05em;">{{
                                'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.CONVENTIONAL_PARAMETERS.PARAMETER_NAME' |
                                translate }}</th>
                            <td mat-cell *matCellDef="let parameter;">
                                {{parameter.name_trans |translate}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="result">
                            <th mat-header-cell *matHeaderCellDef
                                style="color: black; text-align:center; font-weight: bold; font-size: 1.05em;">{{
                                'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.CONVENTIONAL_PARAMETERS.RESULT' | translate
                                }}</th>
                            <td mat-cell *matCellDef="let parameter;">
                                <mat-form-field class="input-field-wrapper">
                                    <input matInput *ngIf="othersLabMode==true" type="number"
                                        placeholder="{{parameter.unit}}" formControlName='{{parameter.controlName}}'
                                        (change)="updateLimitsValue(parameter, 'metalsParametersTable')">
                                    <input matInput *ngIf="othersLabMode==false" type="text"
                                        placeholder="{{parameter.unit}}" formControlName='{{parameter.controlName}}'
                                        (change)="updateLimitsValue(parameter, 'metalsParametersTable')">
                                </mat-form-field>

                            </td>
                        </ng-container>

                        <ng-container matColumnDef="limits">
                            <th mat-header-cell *matHeaderCellDef
                                style="color: black; text-align:center; font-weight: bold; font-size: 1.05em;">{{
                                'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.CONVENTIONAL_PARAMETERS.LIMITS' | translate
                                }}</th>
                            <td mat-cell *matCellDef="let parameter;">
                                <b>{{parameter.limitReached | translate}}</b> <br>
                                <div *ngIf="parameter.aboveLimit!''">
                                    <i>{{parameter.aboveLimit |translate}}
                                        {{"WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.LIMITES_REACHED.BEYOND_THE_FOUNDATIONAL_LIMIT"
                                        | translate}}
                                    </i>
                                </div>
                            </td>
                        </ng-container>


                        <ng-container matColumnDef="actionplan">
                            <th mat-header-cell *matHeaderCellDef
                                style="color: black; text-align:center; font-weight: bold; font-size: 1.05em;">{{
                                'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.CONVENTIONAL_PARAMETERS.CORRECTIVE_ACTION_PLAN'
                                | translate }}</th>
                            <td mat-cell *matCellDef="let parameter;">
                                <div *ngIf="isFailed(parameter.limitReached)">
                                    <div *ngIf="!viewMode">
                                        <a (click)="downloadCAPTemplate()"><mat-icon
                                                class="add-icon">download</mat-icon> {{
                                            'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.CONVENTIONAL_PARAMETERS.TEMPLATE'
                                            | translate }}</a>
                                    </div>
                                    <mat-form-field class="input-field-wrapper">
                                        <mat-select formControlName='{{parameter.controlName+"Status"}}'
                                            (selectionChange)="updateLinkedFormControls(parameter, 'conventionalMetals', 'Status')"
                                            required>
                                            <mat-option value="NoneTaken">{{
                                                'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.CONVENTIONAL_PARAMETERS.CORRECTIVE_ACTION_PLAN_OPTIONS.NONE_TAKEN'
                                                | translate }}</mat-option>
                                            <mat-option value="InProgress">{{
                                                'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.CONVENTIONAL_PARAMETERS.CORRECTIVE_ACTION_PLAN_OPTIONS.IN_PROGRESS'
                                                | translate }}</mat-option>
                                            <mat-option value="Done">{{
                                                'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.CONVENTIONAL_PARAMETERS.CORRECTIVE_ACTION_PLAN_OPTIONS.ACCOMPLISHED'
                                                | translate }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </td>
                        </ng-container>


                        <ng-container matColumnDef="deadline">
                            <th mat-header-cell *matHeaderCellDef
                                style="color: black; text-align:center; font-weight: bold; font-size: 1.05em;">{{
                                'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.CONVENTIONAL_PARAMETERS.DEADLINE' | translate
                                }}</th>
                            <td mat-cell *matCellDef="let parameter;">
                                <div *ngIf="isFailed(parameter.limitReached)">
                                    <mat-form-field class="input-field-wrapper">
                                        <input matInput [matDatepicker]='deadlinePicker'
                                            formControlName='{{parameter.controlName+"Deadline"}}'
                                            (dateInput)="updateLinkedFormControls(parameter, 'conventionalMetals', 'Deadline')"
                                            (dateChange)="updateLinkedFormControls(parameter, 'conventionalMetals', 'Deadline')"
                                            [min]="mindate">
                                        <mat-datepicker-toggle matSuffix [for]='deadlinePicker'></mat-datepicker-toggle>
                                        <mat-datepicker #deadlinePicker></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </td>
                        </ng-container>


                        <tr mat-header-row *matHeaderRowDef="conventionalParameters; sticky: true" sticky></tr>
                        <tr mat-row *matRowDef="let row; columns: conventionalParameters; let element" [ngClass]="{
                            'failed': element.limitReached == 'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.LIMITES_REACHED.FAILED', 
                            'foundational': element.limitReached == 'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.LIMITES_REACHED.FOUNDATIONAL', 
                            'progressive': element.limitReached == 'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.LIMITES_REACHED.PROGRESSIVE', 
                            'aspirational': element.limitReached == 'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.LIMITES_REACHED.ASPIRATIONAL'
                        }"></tr>

                    </table>
                </div>
            </form>
            <button matStepperPrevious class="btn buttons">{{ "WASTE_WATER_UPLOAD_COMPONENT.DOCUMENT_INFORMATION.BACK" |
                translate }}</button>
            <button matStepperNext
                class="btn btn-primary buttons">{{"WASTE_WATER_UPLOAD_COMPONENT.DOCUMENT_INFORMATION.NEXT" | translate
                }}</button>
        </mat-step>


        <mat-step [stepControl]="zdhcMrslParameters" label="ZDHC MRSL V1.1 Parameters">
            <ng-template
                matStepLabel>{{"WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.ZDHC_MRSL_PARAMETERS_FOR_WASTEWATER.ZDHC_MRSL_PARAMETERS_FOR_WASTEWATER"
                | translate }}</ng-template>
            <form *ngIf="!viewMode" [formGroup]="zdhcMrslParameters">

                <mat-form-field class="input-field-wrapper-main">
                    <mat-label>{{"WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.ZDHC_MRSL_PARAMETERS_FOR_WASTEWATER.SUBSTANCE"
                        | translate }}</mat-label>

                    <input matInput
                        placeholder="{{'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.ZDHC_MRSL_PARAMETERS_FOR_WASTEWATER.SUBSTANCE_NAME' | translate }}"
                        [matAutocomplete]="auto" formControlName="Substance" required>
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                        <mat-option *ngFor="let option of userDisplayedZDHCParametersOptions
                            | async" [value]="option.name">
                            <b>{{option.name_trans |translate}}</b> &nbsp; <i>({{option.group_trans |translate}})</i>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>

                <mat-form-field class="input-field-wrapper">
                    <mat-label>{{'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.ZDHC_MRSL_PARAMETERS_FOR_WASTEWATER.RESULT'
                        | translate }}</mat-label>
                    <input matInput type="number" step="0.1" placeholder="μg/L" formControlName="Result" required>
                </mat-form-field>

                <button class="btn btn-primary" (click)="addParameter()"
                    [disabled]="!checkValidZdhcParameters()|| viewMode">{{'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.ZDHC_MRSL_PARAMETERS_FOR_WASTEWATER.ADD'
                    | translate }}</button>
            </form>
            <br><br>
            <div class="table-responsive-substances" [ngStyle]="{'max-height': viewMode? '60vh' : '40vh'}">
                <table mat-table [dataSource]="zdhcMrslParametersTable" class="table">

                    <ng-container matColumnDef="substanceGroup">
                        <th mat-header-cell *matHeaderCellDef
                            style="color: black; text-align:center; font-weight: bold; font-size: 1.05em;">
                            {{'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.ZDHC_MRSL_PARAMETERS_FOR_WASTEWATER.SUBSTANCE_GROUP'
                            | translate }}</th>
                        <td mat-cell *matCellDef="let entry;">
                            <b>Table {{entry.table}}</b> <br>
                            <i>{{entry.substanceGroup}}</i>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="substance" sticky>
                        <th mat-header-cell *matHeaderCellDef
                            style="color: black; text-align:center; font-weight: bold; font-size: 1.05em;">
                            {{'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.ZDHC_MRSL_PARAMETERS_FOR_WASTEWATER.SUBSTANCE_NAME'
                            | translate }}</th>
                        <td mat-cell *matCellDef="let entry;">
                            {{entry.substance}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="result">
                        <th mat-header-cell *matHeaderCellDef
                            style="color: black; text-align:center; font-weight: bold; font-size: 1.05em;">
                            {{'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.ZDHC_MRSL_PARAMETERS_FOR_WASTEWATER.RESULT' |
                            translate }}</th>
                        <td mat-cell *matCellDef="let entry;">
                            {{entry.result}} {{entry.unit}}
                        </td>
                    </ng-container>


                    <ng-container matColumnDef="aboveLimit">
                        <th mat-header-cell *matHeaderCellDef
                            style="color: black; text-align:center; font-weight: bold; font-size: 1.05em;">
                            {{'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.ZDHC_MRSL_PARAMETERS_FOR_WASTEWATER.LIMITS' |
                            translate }}</th>
                        <td mat-cell *matCellDef="let entry;">
                            <div *ngIf="entry.aboveLimit!=''">
                                <i>{{entry.aboveLimit |translate}}
                                    {{"WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.LIMITES_REACHED.BEYOND_THE_FOUNDATIONAL_LIMIT"
                                    | translate}}
                                </i>
                            </div>
                            <div *ngIf="entry.aboveLimit==''">
                                <i>{{entry.aboveLimit |translate}}
                                    {{"WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.LIMITES_REACHED.WITHIN_LIMIT" |
                                    translate}}
                                </i>
                            </div>
                        </td>
                    </ng-container>


                    <ng-container matColumnDef="actionplan">
                        <th mat-header-cell *matHeaderCellDef
                            style="color: black; text-align:center; font-weight: bold; font-size: 1.05em;">
                            {{'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.ZDHC_MRSL_PARAMETERS_FOR_WASTEWATER.CORRECTIVE_ACTION_PLAN'
                            | translate }}</th>
                        <td mat-cell *matCellDef="let entry; let i = index">
                            <div *ngIf="entry.fail">
                                <div *ngIf="!viewMode">
                                    <a (click)="downloadCAPTemplate()"><mat-icon class="add-icon">download</mat-icon>
                                        {{'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.ZDHC_MRSL_PARAMETERS_FOR_WASTEWATER.TEMPLATE'
                                        | translate }}</a>
                                </div>
                                <mat-form-field class="input-field-wrapper">
                                    <mat-select [(ngModel)]="this.zdhcMrslParametersTable.data[i]['actionplan']"
                                        required [disabled]="this.viewMode">
                                        <mat-option
                                            value="NoneTaken">{{'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.ZDHC_MRSL_PARAMETERS_FOR_WASTEWATER.CORRECTIVE_ACTION_PLAN_OPTIONS.NONE_TAKEN'
                                            | translate }}</mat-option>
                                        <mat-option
                                            value="InProgress">{{'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.ZDHC_MRSL_PARAMETERS_FOR_WASTEWATER.CORRECTIVE_ACTION_PLAN_OPTIONS.IN_PROGRESS'
                                            | translate }}</mat-option>
                                        <mat-option
                                            value="Done">{{'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.ZDHC_MRSL_PARAMETERS_FOR_WASTEWATER.CORRECTIVE_ACTION_PLAN_OPTIONS.ACCOMPLISHED'
                                            | translate }}</mat-option>
                                    </mat-select>
                                </mat-form-field>

                            </div>
                        </td>
                    </ng-container>


                    <ng-container matColumnDef="deadline">
                        <th mat-header-cell *matHeaderCellDef
                            style="color: black; text-align:center; font-weight: bold; font-size: 1.05em;">
                            {{'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.ZDHC_MRSL_PARAMETERS_FOR_WASTEWATER.DEADLINE' |
                            translate }}</th>
                        <td mat-cell *matCellDef="let entry; let i = index">
                            <div *ngIf="entry.fail">
                                <mat-form-field class="input-field-wrapper">
                                    <input matInput [matDatepicker]='deadlinePickerZDHC'
                                        [(ngModel)]="this.zdhcMrslParametersTable.data[i]['deadline']" [min]="mindate"
                                        [disabled]="this.viewMode">
                                    <mat-datepicker-toggle matSuffix [for]='deadlinePickerZDHC'></mat-datepicker-toggle>
                                    <mat-datepicker #deadlinePickerZDHC></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="delete">
                        <th mat-header-cell *matHeaderCellDef
                            style="color: black; text-align:center; font-weight: bold; font-size: 1.05em; width: 20px;">
                            {{'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.ZDHC_MRSL_PARAMETERS_FOR_WASTEWATER.DELETE' |
                            translate }}</th>
                        <td mat-cell *matCellDef="let entry; let i = index" style="width: 20px;">
                            <div *ngIf="!this.viewMode">
                                <a (click)="deleteEntry(i)">
                                    <mat-icon style="display: inline-flex; vertical-align: middle;">delete</mat-icon>
                                </a>
                            </div>
                        </td>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="displayedColumnsZdhcParameters; sticky: true" sticky></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsZdhcParameters; let element" [ngClass]="{
                        'failed': element.fail,
                        'comply': !element.fail
                    }"></tr>

                </table>
            </div>
        </mat-step>
        <!-- <button matStepperPrevious class="btn buttons">Back</button> -->
    </mat-horizontal-stepper>

    <ngx-loading [show]="loading"></ngx-loading>


    <br /><br /><br />
</div>