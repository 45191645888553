import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
// import { ThrowStmt } from '@angular/compiler';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BrandSideNewLaunchDialogComponent } from 'app/brand-side-new-launch-dialog/brand-side-new-launch-dialog.component';
import { GlobalsService } from 'app/globals-services/globals.service';
import { OrganizationType } from '../globals-classes/OrganizationType';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit, AfterViewInit {

    // @ViewChild('introVideo') myVideo: ElementRef;
    showLidlInfos = false;
    showOrsayInfos = false;
    splashScreen: boolean = false;
    waitingPeriod = true;
    showFashionInfos = false;
    showWalmartInfos = false;
    showPumaInfos = false;
    showVFInfos = false
    myName = '';
    iframeURL: string;
    sanitizedURL: SafeUrl;


    //for email verification
    forms: FormGroup;
    emailControl = new FormControl('', [Validators.required, Validators.email]);


    appVersionKey = "brandAppVersion";
    latestDialogVersion = "1.0"

    // tslint:disable-next-line: max-line-length
    constructor(
        private http: HttpClient,
        public globalsService: GlobalsService,
        private sanitizer: DomSanitizer,
        private _formBuilder: FormBuilder,
        private dialog: MatDialog) {
        this.authenticate()
        this.forms = this._formBuilder.group({
            emailControl: ['', [Validators.required, Validators.email]]
        });
    }

    ngOnInit() {



        this.myName = this.globalsService.profile['name'];

        // Brand information Lidl
        if (this.globalsService.profile['name'] === 'Lidl') {

            this.showLidlInfos = true;

        } else {

            for (const brand of this.globalsService.brands) {

                if (brand['name'] === 'Lidl' && brand['sharedWith'] === true && brand['relation'] === 'Connected') {
                    this.showLidlInfos = true;
                }
            }
        }

        // Brand information fashion blue
        if (this.globalsService.profile['name'] === 'FashionBlu') {

            this.showFashionInfos = true;

        } else {

            for (const brand of this.globalsService.brands) {

                if (brand['name'] === 'FashionBlu' && brand['sharedWith'] === true && brand['relation'] === 'Connected') {
                    this.showFashionInfos = true;
                }
            }
        }

        if (this.globalsService.profile['name'] === 'RL Test' || this.globalsService.profile['name'] === 'Walmart Test') {

            this.showWalmartInfos = true;

        } else {

            for (const brand of this.globalsService.brands) {

                if ((brand['name'] === 'RL Test' || this.globalsService.profile['name'] === 'Walmart Test') && brand['sharedWith'] === true && brand['relation'] === 'Connected') {
                    this.showWalmartInfos = true;
                }
            }
        }

        if (this.globalsService.profile['name'] === 'Puma Test') {

            this.showPumaInfos = true;

        } else {

            for (const brand of this.globalsService.brands) {

                if ((brand['name'] === 'Puma Test') && brand['sharedWith'] === true && brand['relation'] === 'Connected') {
                    this.showPumaInfos = true;
                }
            }

        }

        // Brand information Orsay
        if (this.globalsService.profile['name'] === 'Orsay' || this.globalsService.profile['name'] === 'F3 - Fashion Cube Turkey' || this.globalsService.profile['name'] === 'F3 - Fashion Cube Bangladesh' || this.globalsService.profile['name'] === 'F3 - Fashion Cube China' || this.globalsService.profile['name'] === 'F3 - Fashion Cube Global') {
            this.showOrsayInfos = true;

        } else {

            for (const brand of this.globalsService.brands) {

                if (brand['name'] === 'Orsay' && brand['sharedWith'] === true && brand['relation'] === 'Connected') {
                    this.showOrsayInfos = true;
                }
            }
        }

        // VF information Orsay
        if (this.globalsService.profile['name'] === 'VF - MTS - Chem-IQ' || this.globalsService.profile['name'] === 'VF' || this.globalsService.profile['name'] === 'VF Corporation - VF Taiwan Ltd.') {
            this.showVFInfos = true;

        } else {

            for (const brand of this.globalsService.brands) {

                if (brand['name'] === 'VF - MTS - Chem-IQ' && brand['sharedWith'] === true && brand['relation'] === 'Connected') {
                    this.showVFInfos = true;
                }
            }
        }


        this.http.get<any>(this.globalsService.baseHref + '/splashScreen').subscribe((user) => {

            //    console.log(user);

            // this.splashScreen = user.splashScreen;

            this.forms.get('emailControl').setValue(user.email)

            //    console.log(this.splashScreen);

        });


        // let localStorageDialogVersion = localStorage.getItem(this.appVersionKey);
        // let sessionStorageDialogVersion = sessionStorage.getItem(this.appVersionKey);

        // if ((sessionStorageDialogVersion == undefined || sessionStorageDialogVersion == null || sessionStorageDialogVersion != this.latestDialogVersion)
        //     && (localStorageDialogVersion == undefined || localStorageDialogVersion == null || localStorageDialogVersion != this.latestDialogVersion)) {
        //     //show Dialog
        //     this.showNewLaunchInfoDialog();
        // }

    }

    ngAfterViewInit() {



        /*
        Remove Twitter

        (function (d, s, id) {
            var js: any,
                fjs = d.getElementsByTagName(s)[0],
                p = 'https';
            if (!d.getElementById(id)) {
                js = d.createElement(s);
                js.id = id;
                js.src = p + '://platform.twitter.com/widgets.js';
                fjs.parentNode.insertBefore(js, fjs);
            }
        })
            (document, 'script', 'twitter-wjs');

       */


    }
    /*
        getStarted() {
            this.myVideo.nativeElement.play();
        }
    */
    // introEnd() {
    //     this.waitingPeriod = false;
    //     setTimeout(() => { this.splashScreen = false; }, 10000);
    //     this.http.put<any>(this.globalsService.baseHref + '/splashScreen', '').subscribe((user) => {
    //     });
    // }

    // close() {
    //     this.splashScreen = false;
    //     this.http.put<any>(this.globalsService.baseHref + '/splashScreen', '').subscribe((user) => {
    //     });
    // }
    get isMill(): boolean {  //is this user role or organization type

        //console.log(this.authenticationService.authenticationState);
        // return this.authenticationService.authenticationState.jwtAuthenticationToken.roles.indexOf('BRAND') >= 0
        return this.globalsService.profile["organization_type"] == OrganizationType.SUPPLIER
    }

    get isBrand(): boolean {  //is this user role or organization type

        //console.log(this.authenticationService.authenticationState);
        // return this.authenticationService.authenticationState.jwtAuthenticationToken.roles.indexOf('BRAND') >= 0
        return this.globalsService.profile["organization_type"] == OrganizationType.BRAND
    }

    get isImporter(): boolean { //is this user role or organization type

        //console.log(this.authenticationService.authenticationState);
        //return this.authenticationService.authenticationState.jwtAuthenticationToken.roles.indexOf('IMPORTER') >= 0;
        return this.globalsService.profile["organization_type"] == OrganizationType.IMPORTER
    }

    get isAdmin(): boolean { //is this user role Bhive Admin ??

        // return this.authenticationService.authenticationState.jwtAuthenticationToken.roles.indexOf('ADMIN') >= 0;
        return this.globalsService.profile["organization_type"] == OrganizationType.BHIVE_ADMIN
    }

    isChineseSystem() {

        return this.globalsService.serverLocation === 'China' ? true : false;
    }

    authenticate() {

        /*
        this.http.post<any>(this.globalsService.baseHref + '/getDashboardToken', {}).subscribe((response) => {

            const token = response['token'];

            const METABASE_SITE_URL = this.globalsService.metaBaseURL;

            this.iframeURL = METABASE_SITE_URL + '/embed/dashboard/' + token + '#bordered=false&titled=false';

            this.sanitizedURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeURL);

        });
        */

    }

    validateEmail() {
        if (this.forms.get('emailControl').valid) {
            const value = this.forms.get('emailControl').value
            this.http.post(this.globalsService.baseHref + '/checkMail', { mail: value }).subscribe((res) => {
                if (res['status'] !== 'SUCCESS') {
                    this.forms.get('emailControl').setErrors({
                        'emailTaken': true
                    })
                }
            });
        }

    }

    getErrorMessage() {
        if (this.forms.controls['emailControl'].hasError('required')) {
            return 'You must enter a value';
        }

        return this.forms.controls['emailControl'].hasError('email') ? 'Not a valid email' : '';
    }

    saveUserAcceptance() {
        const reqObj = { email: this.forms.controls['emailControl'].value }
        this.http.put<any>(this.globalsService.baseHref + '/splashScreen', reqObj).subscribe((res) => {
            this.splashScreen = false;
        }, (err) => {
            console.log(err)
        })
    }
    showNewLaunchInfoDialog() {
        const dialogRef = this.dialog.open(BrandSideNewLaunchDialogComponent, {
            width: '55%',
            //   height: '30%'
        });

        dialogRef.afterClosed().subscribe(response => {

            if (response) {
                localStorage.setItem(this.appVersionKey, this.latestDialogVersion)
            } else {
                sessionStorage.setItem(this.appVersionKey, this.latestDialogVersion)
            }


        });

    }

}
