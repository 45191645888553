import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ExportDataService } from 'app/shared/services/export-data.service';
import { forkJoin } from 'rxjs';
import { CubejsService } from '../shared/cubejs/cubejs.service';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { GlobalsService } from 'app/globals-services/globals.service';
import { HttpClient } from '@angular/common/http';
import { SessionStateService } from 'app/globals-services/session-state-service/session-state.service';
import { Inventory } from 'app/globals-interface/inventory';


@Component({
  selector: 'app-step-dashboard',
  templateUrl: './step-dashboard.component.html',
  styleUrls: ['./step-dashboard.component.scss']

})
export class StepDashboardComponent implements OnInit {

  showErrorMessage: boolean = false;
  years: number[] = [];
  supplierIsSelected: boolean = false;
  selectedMonthFactory = new FormControl(new Date().getMonth() +1);
  selectedYearFactory =  new FormControl(new Date().getFullYear());
  selectedFactory: string = "";
  selectedFactoryName: string = "";
  selectedFactoryId: string;
  factoryFilter: string[];
  suggestedList: any = []
  filteredOptions: Observable<string[]>;
  filters: Array<Object> = [];
  inventoryCount = [];
  factoryControl = new FormControl('');
  isFactory:boolean = false;
  selectedCountry: string = "";
  selectedYear: string = "";
  selectedMonthOrQuarter: string = "";
  yearsToShow = [];
  monthsToShow = [];
  firstInventoryDate;
  latestInventoryDate;
  isMonth: boolean;
  isQuarter: boolean;
  isYear: boolean;
  inventoryStatus = false;
  monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  quarterNames = ['Quarter 1', 'Quarter 2', 'Quarter 3', 'Quarter 4'];
  monthIndexMap = { 'January': '01', 'February': '02', 'March': '03', 'April': '04', 'May': '05', 'June': '06', 'July': '07', 'August': '08', 'September': '09', 'October': '10', 'November': '11', 'December': '12' };
  quarterIndexMap = { 'Quarter 1': '1', 'Quarter 2': '2', 'Quarter 3': '3', 'Quarter 4': '4' };
  countriesToShow = [];
  suppliersToShow = [];
  inventoriesToShow: Inventory[] = [];
  summary = {};

  isLoading = false;
  errorList = [];

  constructor(private cubejsService: CubejsService, private exportService: ExportDataService, private globalsService: GlobalsService, private http: HttpClient, private sessionStateService: SessionStateService, private route: ActivatedRoute) { }



  uniqueChemicalProductCountUploadedInventory:number;
  chemicalProductCountUploadedInventory:number;
  isLoadingChemicalProductCountUploadedInventory = false;

  chemicalCompliance:number;
  isLoadingChemicalCompliance = false;

  chemicalMinMaxStock:number;
  chemicalMinMaxStockPerc:number = 0;
  isLoadingChemicalMinMaxStock = false;

  chemicalCountApplication_UsedAreas:number;
  chemicalCountApplication_UsedAreasPerc:number = 0;
  isLoadingChemicalCountApplication_UsedAreas = false;

  chemicalCountStorageLocation:number;
  chemicalCountStorageLocationPerc:number = 0;
  isLoadingChemicalCountStorageLocation = false;

  chemicalCountProductCasComp:number;
  chemicalCountProductCasCompPerc:number = 0;
  isLoadingChemicalCountProductCasComp = false;

  chemicalCountSubstComposition:number;
  chemicalCountSubstCompositionPerc:number = 0;
  isLoadingChemicalCountSubstComposition = false;

  isLoadingSubstanceCount = false;
  substanceCount:number;

  chemicalCountGHSclassification:number;
  chemicalCountGHSclassificationPerc:number = 0;
  isLoadingChemicalCountGHSclassification = false;

  chemicalCountH_Phrases:number;
  chemicalCountH_PhrasesPerc:number = 0;
  isLoadingChemicalCountH_Phrases = false;

  chemicalCountP_Phrases:number;
  chemicalCountP_PhrasesPerc:number = 0;
  isLoadingChemicalCountP_Phrases = false;

  isLoadingCards = false;
  isLoadingDownload = false;
  dashboardData =new MatTableDataSource<any>();

  ngOnInit(): void {
    this.getMySupplierPartners();
  }


  searchThis(searchText) {
    if (searchText.length > 0) {
      this.selectedFactoryName = searchText
      this.suggestedList = this.globalsService.brands.filter(res => {
        if (res.sharedWith == true && res.organizationType == 2 && res.relation === "Connected" && res.id != this.globalsService.profile['id']) {
          return res.name.toLowerCase().match(this.selectedFactoryName.toLocaleLowerCase())
            || res.identification.toLowerCase().match(this.selectedFactoryName.toLocaleLowerCase())
            || res.countryName.toLowerCase().match(this.selectedFactoryName.toLocaleLowerCase())
            || res.Higg.toLowerCase().match(this.selectedFactoryName.toLocaleLowerCase())
            || res.Oar.toLowerCase().match(this.selectedFactoryName.toLocaleLowerCase())
            || res.activity.toLowerCase().match(this.selectedFactoryName.toLocaleLowerCase())
            || res.FFC.toLowerCase().match(this.selectedFactoryName.toLocaleLowerCase())
            ;
        }
      }

      );
    } else {
      this.getMySupplierPartners();
    }


  }

  getMySupplierPartners() {
    this.suggestedList = this.globalsService.brands.filter(res => {
      return res.sharedWith == true && res.organizationType == 2 && res.relation === "Connected" && res.id != this.globalsService.profile['id'];
    })
  }

  onSearchFactorySelect() {

    this.selectedFactory = '';
    let sp = this.suggestedList.find(supplier => this.selectedFactoryName === supplier.name);
    this.selectedFactory = sp.id;
    this.selectedCountry = sp.countryName

    this.yearsToShow = []
    this.fetchDatesAndYears();
    this.fetchMonthsAndQuarters()

    if (this.selectedYear != "none" && this.selectedMonthOrQuarter != "none") {

      this.onMonthOrQuarterSelect();
    }



  }


  fetchDatesAndYears() {
    this.isLoading = true;
    this.http.get(this.globalsService.baseHref + "/inventories/getDatesAndYears/" + this.selectedFactory).subscribe(
      (response) => {
        this.firstInventoryDate = response["firstInventoryDate"];
        this.latestInventoryDate = response["latestInventoryDate"];
        this.yearsToShow = response["years"];
        let isYearExist = this.yearsToShow.find(yr => yr == this.selectedYear);
        if (isYearExist == null) {
          this.selectedYear = null;
          this.isYear = false;
          this.isMonth = false
        }
        this.isLoading = false;
      },
      (err) => {
        console.log(err);
        this.isLoading = false;
        if (err["status"] == 404) {
          this.showErrorNotFound();
        }
      }
    );
  }

  fetchMonthsAndQuarters() {
    if(this.selectedYear != null && this.selectedYear.length > 0) {
      this.isLoading = true;
      this.http.get<number[]>(this.globalsService.baseHref + "/inventories/getMonthsAndQuarters/" + this.selectedYear + "/" + this.selectedFactory).subscribe(
        (response) => {
          this.monthsToShow = [];
          let months = response;
          for (let month of months) {
            this.monthsToShow.push(this.monthNames[month - 1]);
          }

          for (let i = 1; i <= 4; i++) {
            let quarter = (element) => element / 3.0 <= i && element / 3.0 > i - 1;
            if (months.some(quarter)) { this.monthsToShow.push(this.quarterNames[i - 1]); }
          }

          this.monthsToShow.push("Summary " + this.selectedYear);
          this.isLoading = false;
        },
        (err) => {
          console.log(err);
          this.isLoading = false;
        }
      );
    }
  }

  onYearSelect() {
    this.clearOnYearChange();
    if (this.selectedYear != "none") {
      this.fetchMonthsAndQuarters();
    }
  }

  clearOnYearChange() {
    this.monthsToShow = [];
    // this.inventoriesToShow = [];
    // this.summary = {};
    this.selectedMonthOrQuarter = "none";
  }

  onMonthOrQuarterSelect() {
    if (this.monthNames.includes(this.selectedMonthOrQuarter)) {
      this.isMonth = true;
      this.isQuarter = false;
      this.isYear = false
      // get all inventories of month

    } else if (this.quarterNames.includes(this.selectedMonthOrQuarter)) {
      this.isMonth = false;
      this.isQuarter = true;
      this.isYear = false;
      // get quarter summary
    } else {
      this.isMonth = false;
      this.isQuarter = false;
      this.isYear = true;
      // get yearly summary
    }
  }

  ifSupplierAlreadySelected() {
    this.yearsToShow = []
    this.fetchDatesAndYears();
    this.fetchMonthsAndQuarters()

    if (this.selectedYear != "none" && this.selectedMonthOrQuarter != "none") {

      this.onMonthOrQuarterSelect();
    }

  }


  applyFilters() {
    let startDate: string;
    let endDate: string;
    if (this.selectedFactoryName != "" && this.selectedYear != "none" && this.selectedMonthOrQuarter != "none") {
      this.isFactory = true;
      if (this.isQuarter) {
        switch (this.quarterIndexMap[this.selectedMonthOrQuarter]) {
          case '1': {
            startDate = Number(this.selectedYear)+'-01-01';
            endDate = Number(this.selectedYear)+'-03-31';
            break;
          }
          case '2': {
            startDate = Number(this.selectedYear)+'-04-01';
            endDate = Number(this.selectedYear)+'-06-30'; 
            break;
          }
          case '3': {
            startDate = Number(this.selectedYear)+'-07-01';
            endDate = Number(this.selectedYear)+'-09-30';
            break;
          }
          case '4': {
            startDate = Number(this.selectedYear)+'-10-01';
            endDate = Number(this.selectedYear)+'-12-31';
            break;
          }
        }
      }
      this.filters = this.selectedFactoryName != null && this.isMonth ? [{
        "member": "Formulas.year_month_scan_date",
        "operator": "equals",
        "values": [
          this.selectedYear+"-"+this.monthIndexMap[this.selectedMonthOrQuarter]
        ]
      },
      {
        "member": "Formulas.organization_name",
        "operator": "contains",
        "values": [
          this.selectedFactoryName
        ]
      }
      ]
        :  this.selectedFactoryName != null && this.isYear ? [{
          "member": "Formulas.scan_date",
          "operator": "inDateRange",
          "values": [
              this.selectedYear+"-01-01",
              this.selectedYear+"-12-31"

          ]
        },
        {
          "member": "Formulas.organization_name",
          "operator": "contains",
          "values": [
            this.selectedFactoryName
          ]
        }
        ] :
          [{
            "member": "Formulas.scan_date",
            "operator": "inDateRange",
            "values": [
              startDate,
              endDate
            ]
          },
          {
            "member": "Formulas.organization_name",
            "operator": "contains",
            "values": [
              this.selectedFactoryName
            ]
          }
          ]
      this.getallDashboardData();
      this.getSubstanceCount();
    }
    else this.isFactory = false;
  }

  changeKey(originalKey: string, newKey: string, arr: Array<Object>) {
    var newArr = [];
    for (var i = 0; i < arr.length; i++) {
      var obj = arr[i];
      obj[newKey] = (newKey == "value" && obj[originalKey] === null) ? 0 : obj[originalKey];
      delete (obj[originalKey]);
      newArr.push(obj);
    }
    return newArr;
  }

  ShowErrorMessage(msg) {
    this.isLoading = false;
    if (!this.errorList.includes(msg)) {
      this.errorList.push(msg);
      alert(msg);
    }
  }

  showErrorNotFound() {
    this.showErrorMessage = true;
    setTimeout(
      () => {
        this.showErrorMessage = false;
      }, 1500
    );
  }

  getLastDayOfMonth(year, month) {
    return new Date(year, month + 1, 0);
  }

  getSubstanceCount() {
    this.isLoadingSubstanceCount = true;
    const query = {
      "measures": [
        "Substances.substances_count",
        "Substances.composition_chem_count"
      ],
      "timeDimensions": [],
      "order": {},
      "dimensions": [],
      "filters":this.filters
    }

    this.cubejsService.getCubeJSData(query, null, null, true, false).then(data => {
      data = data['loadResponse']['results'][0]['data'];
      this.substanceCount = data[0]['Substances.substances_count'];
      this.chemicalCountSubstComposition = data[0]['Substances.composition_chem_count'];
      this.chemicalCountSubstComposition = data[0]['Substances.composition_chem_count'];
      this.chemicalCountSubstCompositionPerc = this.create_percentages(this.chemicalCountSubstComposition, this.substanceCount)
      this.isLoadingSubstanceCount = false;
    })
      .catch(error => {
        this.isLoadingSubstanceCount = false;
        this.ShowErrorMessage(error.message);
      })
  }

  getallDashboardData() {
    this.isLoadingCards = true;
    const query = {
      "measures": [
        "Formulas.h_phrases_count",
        "Formulas.p_phrases_count",
        "Formulas.user_application_count",
        "Formulas.storage_location_count",
        "Formulas.total_count",
        "Formulas.formula_distinct_count",
        "Formulas.min_max_stock_count",
        "Formulas.ghs_classification_count",
        "Formulas.detox_compliance_count",
        "Formulas.detox_compliance_count_pct",
        "Substances.composition_cas_chem_count",
        "Substances.composition_chem_count"
      ],
      "timeDimensions": [],
      "order": {},
      "dimensions": [],
      "filters": this.filters

    }

    this.cubejsService.getCubeJSData(query, null, null, true, false).then(data => {
      let tempData = data['loadResponse']['results'][0]['data'];
      tempData = this.changeKey("Formulas.organization_name", "Factory Name", tempData)
      tempData = this.changeKey("Formulas.h_phrases_count", "H_Phrases", tempData)
      tempData = this.changeKey("Formulas.p_phrases_count", "P_Phrases", tempData)
      tempData = this.changeKey("Formulas.user_application_count", "Application/ Used Areas (ZDHC use)", tempData)
      tempData = this.changeKey("Formulas.storage_location_count", "Stock Location Count", tempData)
      tempData = this.changeKey("Formulas.total_count", "Inventory Count", tempData)
      tempData = this.changeKey("Formulas.formula_distinct_count", "Inventory Unique Count", tempData)
      tempData = this.changeKey("Formulas.min_max_stock_count", "Min and Max Stock Count", tempData)
      tempData = this.changeKey("Formulas.ghs_classification_count", "GHS Classificatios Count", tempData)
      tempData = this.changeKey("Formulas.detox_compliance_count", "Detox to Zero Compliance Count", tempData)
      tempData = this.changeKey("Formulas.detox_compliance_count_pct", "Detox Compliance Count Percentage", tempData)
      tempData = this.changeKey("Substances.composition_cas_chem_count", "Product/Cas Composition Count", tempData)
      tempData = this.changeKey("Substances.composition_chem_count", "Substance Composition Count", tempData)
      this.dashboardData.data = tempData;
      this.getCardsData(tempData);
      this.isLoadingCards = false;

    })
      .catch(error => {
        this.isLoadingCards = false;
        this.ShowErrorMessage(error.message);
      })
  }

  getCardsData(data) {
    this.chemicalProductCountUploadedInventory = data[0]['Inventory Count']
    this.uniqueChemicalProductCountUploadedInventory = data[0]['Inventory Unique Count']
    if (data[0]['Detox Compliance Count Percentage']!=null) { 
      this.chemicalCompliance = Number(data[0]['Detox Compliance Count Percentage'].toFixed(2));}
    else { this.chemicalCompliance = 0 }

    this.chemicalMinMaxStock = data[0]['Min and Max Stock Count']
    this.chemicalMinMaxStockPerc = this.create_percentages(this.chemicalMinMaxStock, this.chemicalProductCountUploadedInventory )

    this.chemicalCountApplication_UsedAreas = data[0]['Application/ Used Areas (ZDHC use)'];
    this.chemicalCountApplication_UsedAreasPerc = this.create_percentages(this.chemicalCountApplication_UsedAreas, this.chemicalProductCountUploadedInventory)

    this.chemicalCountStorageLocation = data[0]['Stock Location Count'];
    this.chemicalCountStorageLocationPerc = this.create_percentages(this.chemicalCountStorageLocation, this.chemicalProductCountUploadedInventory)

    this.chemicalCountProductCasComp = data[0]['Product/Cas Composition Count']
    this.chemicalCountProductCasCompPerc =  this.create_percentages(this.chemicalCountProductCasComp, this.chemicalProductCountUploadedInventory)

    this.chemicalCountGHSclassification = data[0]['GHS Classificatios Count'];
    this.chemicalCountGHSclassificationPerc = this.create_percentages(this.chemicalCountGHSclassification, this.chemicalProductCountUploadedInventory)

    this.chemicalCountH_Phrases = data[0]['H_Phrases'];
    this.chemicalCountH_PhrasesPerc = this.create_percentages(this.chemicalCountH_Phrases, this.chemicalProductCountUploadedInventory)

    this.chemicalCountP_Phrases = data[0]['P_Phrases'];
    this.chemicalCountP_PhrasesPerc = this.create_percentages(this.chemicalCountP_Phrases, this.chemicalProductCountUploadedInventory)

  }

  create_percentages(number1,number2) {
    if (number1!=0 && number1!=null) { 
      return Number(((number1/number2)*100).toFixed(2))}
      else { return  0 }

  }

  getGridTemplateColumns(number: number = 0) {
    var chemicalCompliance1= String(number+'%');
    var chemicalCompliance2 = String(Number(100-number).toFixed(2))+'%';
    var result = chemicalCompliance1.concat(" ", chemicalCompliance2);
    return result;

  }

  chemicalData =new MatTableDataSource<any>();
  substanceData =new MatTableDataSource<any>();
  dashboardDataDownload =new MatTableDataSource<any>();

  substanceDataExcel(substanceData) {
    const {Chemical_Name, Manufacturer, Factory_Name, CAS_Number, Substance_Name, Percentage} = substanceData
    return {Chemical_Name, Manufacturer, Factory_Name, CAS_Number, Substance_Name, Percentage}
  }

  dashboardDataExcel(dashboardDataDownload) {
    const {Factory_Name,Inventory_Count, Detox_Compliance_Count_Percentage, Detox_to_Zero_Compliance_Count, Min_and_Max_Stock_Count, Application_Used_Areas_ZDHC_use, Stock_Location_Count, Product_Cas_Composition_Count, GHS_Classificatios_Count, H_Phrases, P_Phrases} = dashboardDataDownload
    return {Factory_Name, Inventory_Count, Detox_Compliance_Count_Percentage, Detox_to_Zero_Compliance_Count, Min_and_Max_Stock_Count, Application_Used_Areas_ZDHC_use, Stock_Location_Count, Product_Cas_Composition_Count, GHS_Classificatios_Count, H_Phrases, P_Phrases}
  }

  exportData() {
    this.isLoadingDownload = true;
    const dashboard_data_query = {
      "measures": [
        "Formulas.h_phrases_count",
        "Formulas.p_phrases_count",
        "Formulas.user_application_count",
        "Formulas.storage_location_count",
        "Formulas.total_count",
        "Formulas.min_max_stock_count",
        "Formulas.ghs_classification_count",
        "Formulas.detox_compliance_count",
        "Formulas.detox_compliance_count_pct",
        "Substances.composition_cas_chem_count",
        "Substances.composition_chem_count"
      ],
      "timeDimensions": [
        {
          "dimension": "Formulas.scan_date"
        }
      ],
      "order": {
        "Formulas.h_phrases_count": "desc"
      },

      "dimensions": [
        "Formulas.organization_name"
      ],
      "filters":this.filters         
    }
    const detailed_data_query = {
      "measures": [
      ],
      "timeDimensions": [],
      "order": { "Factories.total_count": "desc"},
      "dimensions": [
        "Formulas.chemical_name",
        "Formulas.chemical_manufacturer",
        "Formulas.basic_chemical",
        "Formulas.country",
        "Formulas.scan_date",
        "Formulas.consumption",
        "Formulas.minimum_stock",
        "Formulas.maximum_stock",
        "Formulas.stock_location",
        "Formulas.user_application",
        "Formulas.factory_usage",
        "Formulas.step_self_declaration",
        "Formulas.zdhc_mrsl",
        "Formulas.eco_passport",
        "Formulas.gateway_level",
        "Formulas.ghs_classification",
        "Formulas.precautionary_statement",
        "Formulas.hazard_statement",
        "Formulas.organization_name"
      ],
      "filters": this.filters
    }
    const substance_data_query = {
      "measures": [
      ],
      "timeDimensions": [],
      "order": { "Factories.total_count": "desc"},
      "dimensions": [
        "Formulas.formula_id",
        "Factories.id",
        "Substances.organization_id",
        "Formulas.chemical_name",
        "Formulas.chemical_manufacturer",
        "Substances.casnr",
        "Substances.substance_name",
        "Substances.percentage",
        "Formulas.organization_name"
      ],
      "filters": this.filters,
      "ungrouped": true
    }

    forkJoin([
      this.cubejsService.getCubeJSData(dashboard_data_query, null, null, true, false),
      this.cubejsService.getCubeJSData(detailed_data_query, null, null, true, false),
      this.cubejsService.getCubeJSData(substance_data_query, null, null, true, false),
    ])
      .subscribe((response) => {
        let data1 = response[0]
        let dashboard_Data = data1['loadResponse']['results'][0]['data'];
        if (dashboard_Data.length > 0) {
          dashboard_Data = this.changeKey("Formulas.organization_name", "Factory_Name", dashboard_Data)
          dashboard_Data = this.changeKey("Formulas.h_phrases_count", "H_Phrases", dashboard_Data)
          dashboard_Data = this.changeKey("Formulas.p_phrases_count", "P_Phrases", dashboard_Data)
          dashboard_Data = this.changeKey("Formulas.user_application_count", "Application_Used_Areas_ZDHC_use", dashboard_Data)
          dashboard_Data = this.changeKey("Formulas.storage_location_count", "Stock_Location_Count", dashboard_Data)
          dashboard_Data = this.changeKey("Formulas.total_count", "Inventory_Count", dashboard_Data)
          dashboard_Data = this.changeKey("Formulas.min_max_stock_count", "Min_and_Max_Stock_Count", dashboard_Data)
          dashboard_Data = this.changeKey("Formulas.ghs_classification_count", "GHS_Classificatios_Count", dashboard_Data)
          dashboard_Data = this.changeKey("Formulas.detox_compliance_count", "Detox_to_Zero_Compliance_Count", dashboard_Data)
          dashboard_Data = this.changeKey("Formulas.detox_compliance_count_pct", "Detox_Compliance_Count_Percentage", dashboard_Data)
          dashboard_Data = this.changeKey("Substances.composition_cas_chem_count", "Product_Cas_Composition_Count", dashboard_Data)
          dashboard_Data = this.changeKey("Substances.composition_chem_count", "Substance_Composition_Count", dashboard_Data)
          this.dashboardDataDownload.data = dashboard_Data;

        }
        else this.dashboardDataDownload.data = [];

        let data2 = response[1];
        let chemical_Data = data2['loadResponse']['results'][0]['data'];
        if (chemical_Data.length > 0) {
          chemical_Data = this.changeKey("Formulas.organization_name", "Factory Name", chemical_Data)
          chemical_Data = this.changeKey("Formulas.chemical_name", "Chemical Name", chemical_Data)
          chemical_Data = this.changeKey("Formulas.chemical_manufacturer", "Manufacturer", chemical_Data)
          chemical_Data = this.changeKey("Formulas.basic_chemical", "Commodities", chemical_Data)
          chemical_Data = this.changeKey("Formulas.country", "Country", chemical_Data)
          chemical_Data = this.changeKey("Formulas.scan_date", "Scan_Date", chemical_Data)
          chemical_Data = this.changeKey("Formulas.consumption", "Consumption", chemical_Data)
          chemical_Data = this.changeKey("Formulas.minimum_stock", "Minimum Stock", chemical_Data)
          chemical_Data = this.changeKey("Formulas.maximum_stock", "Maximum Stock", chemical_Data)
          chemical_Data = this.changeKey("Formulas.stock_location", "Stock Location", chemical_Data)
          chemical_Data = this.changeKey("Formulas.user_application", "User Application", chemical_Data)
          chemical_Data = this.changeKey("Formulas.factory_usage", "Factory Usage", chemical_Data)
          chemical_Data = this.changeKey("Formulas.step_self_declaration", "Step_Self_Declaration", chemical_Data)
          chemical_Data = this.changeKey("Formulas.zdhc_mrsl", "ZDHC_MRSL", chemical_Data)
          chemical_Data = this.changeKey("Formulas.eco_passport", "Eco_Passport", chemical_Data)
          chemical_Data = this.changeKey("Formulas.gateway_level", "Gateway Level", chemical_Data)
          chemical_Data = this.changeKey("Formulas.ghs_classification", "GHS Classifications", chemical_Data)
          chemical_Data = this.changeKey("Formulas.precautionary_statement", "Precautionary Statement", chemical_Data)
          chemical_Data = this.changeKey("Formulas.hazard_statement", "Hazard Statement", chemical_Data)
          chemical_Data = chemical_Data.map(obj => {
          obj.Commodities = obj.Commodities == 1   ? 'Yes' :  'No';
          obj.ZDHC_MRSL = obj.ZDHC_MRSL == 1   ? 'Yes' :  'No';
          obj.Eco_Passport = obj.Eco_Passport == 1   ? 'Yes' :  'No';
          obj.Step_Self_Declaration = obj.Step_Self_Declaration == 1   ? 'Yes' :  'No';
            obj.Scan_Date = obj.Scan_Date.split('T')[0];
            return obj;
          })
          this.chemicalData.data = chemical_Data;
        }
        else
          this.chemicalData.data = []


        let data3 = response[2];
        let substance_data = data3['loadResponse']['results'][0]['data'];
        if (substance_data.length > 0) {
          substance_data = this.changeKey("Formulas.chemical_name", "Chemical_Name", substance_data)
          substance_data = this.changeKey("Formulas.organization_name", "Factory_Name", substance_data)
          substance_data = this.changeKey("Formulas.chemical_manufacturer", "Manufacturer", substance_data)
          substance_data = this.changeKey("Substances.casnr", "CAS_Number", substance_data)
          substance_data = this.changeKey("Substances.substance_name", "Substance_Name", substance_data)
          substance_data = this.changeKey("Substances.percentage", "Percentage", substance_data)
        this.substanceData.data= substance_data;
        }
        else this.substanceData.data = [];

      const substancedata = this.substanceData.data.map (this.substanceDataExcel);
        const dashboarddata = this.dashboardDataDownload.data.map(this.dashboardDataExcel);
        this.exportService.exportStepDashboardAsExcelFile(dashboarddata, this.chemicalData.data, substancedata, this.selectedMonthOrQuarter, this.selectedYear, this.selectedFactoryName);
        this.isLoadingDownload = false;
      })

  }

}
