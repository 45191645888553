<div class="flex flex-col flex-auto min-w-0">
    <!-- Main -->
    <div class="flex-auto p-6 sm:p-10">
        <!-- CONTENT GOES HERE -->

        <div>
            <div class="flex items-center justify-between w-full mb-4">

                <div>
                    <h3 *ngIf="!isWPU" class="text-3xl font-semibold tracking-tight leading-8" i18n="
                            Wet Processing Unit Details Headline | Headline of the
                            Wet Processing Unit Details page.
                        ">
                        {{ "WPU_COMPONENT.SUPPLIER_VIEW_TITLE" | translate }}
                    </h3>

                    <h3 *ngIf="isWPU" class="text-3xl font-semibold tracking-tight leading-8" i18n="
                            Wet Processing Unit Details Headline | Headline of the
                            Wet Processing Unit Details page.
                        ">
                        {{ "WPU_COMPONENT.TITLE" | translate }}
                    </h3>

                    <div *ngIf="isWPU">
                        <h2 class="page-sub-header">
                            {{ "WPU_COMPONENT.PAGE_SUB_HEADER" | translate }}
                        </h2>
                    </div>

                </div>

                <!-- <a  mat-flat-button="" class="back-button"

                    routerLink="/profile"
                    *ngIf="isWPU"
                    i18n="
                        Edit Button | Wet Processing Unit Details page edit
                        button.
                    "
                    >{{ "WPU_COMPONENT.EDIT_BUTTON" | translate }}</a> -->
                
            </div>

            <div class="content">


                <div class="row">
                    <div class="col-sm-12">
                        <div class="col-sm-6">
                            <h4 class="text-xl mt-2 mb-2 font-medium">
                                {{ factoryProfile["name"] }}
                            </h4>
                            <br />
                            <address i18n="
                                    Profile phone and fax | The phone and fax
                                    fields on the profile page
                                ">
                                <span *ngIf="
                                        factoryProfile['companyAddress'] != null
                                    " [innerHTML]="
                                        factoryProfile['companyAddress'] | nl2br
                                    "></span><br />
                                {{ "WPU_COMPONENT.ADDRESS.PHONE" | translate }}:
                                {{ factoryProfile["phone"] }}<br />


                                <br /><br />
                                <span>{{
                                    "WPU_COMPONENT.SUBSCRIPTION.ACCOUNT_TYPE"
                                    | translate
                                    }}
                                    :
                                    <strong>{{
                                        factoryProfile["AccountType"]
                                        }}</strong></span><br />
                                <span>{{
                                    "WPU_COMPONENT.SUBSCRIPTION.BHIVE_SUBSCRIPTION"
                                    | translate
                                    }}
                                    :
                                    <strong>{{
                                        factoryProfile["BHiveSubscription"]
                                        }}</strong></span><br />
                                <span>{{
                                    "WPU_COMPONENT.SUBSCRIPTION.ZDHCINCHECK_SUBSCRIPTION"
                                    | translate
                                    }}
                                    :
                                    <strong>{{
                                        factoryProfile[
                                        "ZDHCInCheckSubscriptionStart"
                                        ]
                                        }}</strong></span>
                            </address>
                        </div>

                        <div class="col-sm-6">
                            <h4 class="text-xl mt-2 mb-2 font-medium" i18n="
                                    Factory Details Header | The 'Factory'
                                    headline on the Wet Processing Unit Details
                                    page
                                ">
                                {{ "WPU_COMPONENT.FACTORY.TITLE" | translate }}
                            </h4>
                            <address *ngIf="factoryProfile['factoryAddress']">
                                <span [innerHTML]="
                                        factoryProfile['factoryAddress'] | nl2br
                                    "></span>
                                    <br>
                                    <span class="text-md font-semibold">{{ factoryProfile['countryName']}}</span>
                            </address>

                            <h4 class="text-xl mt-2 mb-2 font-medium" i18n="
                                    General Information Header | The 'General
                                    Information' headline on the Wet Processing
                                    Unit Details page
                                ">
                                {{
                                "WPU_COMPONENT.FACTORY.INFO_HEADER"
                                | translate
                                }}
                            </h4>
                            <address i18n="
                                    Profile Office Email header | The 'Office'
                                    header for the email address
                                ">
                                <!-- {{
                                "WPU_COMPONENT.FACTORY.ADDRESS.OFFICE_HEADER"
                                | translate
                                }}<br /> -->

                                <span >{{"WPU_COMPONENT.FACTORY.ADDRESS.EMAIL"
                                    | translate}}: &nbsp; {{factoryProfile['factoryMailAddress']}}</span>

                                <!-- <a
                                    href="mailto:{{
                                        factoryProfile['contactEmail']
                                    }}'"
                                    >{{
                                        "WPU_COMPONENT.FACTORY.ADDRESS.EMAIL"
                                            | translate
                                    }}</a
                                > -->
                            </address>


                            <div *ngIf="factoryActivites!=null && factoryActivites.length>0">
                                <div>
                                    <strong class="text-xl mt-2 mb-2 font-medium" i18n="">{{
                                        "PROFILE_COMPONENT.FACTORY_ACTIVITY.TITLE"
                                        | translate
                                        }} </strong>
                                    <br>

                                    <div *ngFor="let fa of factoryActivites">
                                        {{fa.activity}}
                                    </div>
                                </div>

                            </div>

                            <div *ngIf="businessLicenseData != null">
                                <h4 i18n="Business License title" class="text-xl mt-2 mb-2 font-medium">
                                    {{
                                    "WPU_COMPONENT.FACTORY.BUSINESS_LICENSE.TITLE"
                                    | translate
                                    }}
                                </h4>

                                <a (click)="downloadBusinessLicense()">{{
                                    businessLicenseData.fileName
                                    }}</a>

                                <br />

                                <div *ngIf="businessLicenseData.issue != 'null'">
                                    <strong>{{
                                        "WPU_COMPONENT.FACTORY.BUSINESS_LICENSE.ISSUE_DATE"
                                        | translate
                                        }}
                                        :</strong>
                                    <span>{{
                                        businessLicenseData.issue
                                        | date: "dd-MM-yyyy"
                                        }}</span>
                                    &nbsp;&nbsp;
                                </div>

                                <div *ngIf="businessLicenseData.expiry != 'null'">
                                    <strong>{{
                                        "WPU_COMPONENT.FACTORY.BUSINESS_LICENSE.EXPIRY_DATE"
                                        | translate
                                        }}
                                        :</strong>
                                    <span>{{
                                        businessLicenseData.expiry
                                        | date: "MMM, dd yyyy"
                                        }}</span>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <h2 *ngIf="factoryProfile['name'] == null">
                        Unauthorized Access
                    </h2>
                </div>

            </div>

            <br />
            <br />

            <div *ngIf="!brandMode">
                <app-profile-sub [isSubComponent]=true></app-profile-sub>
            </div>



            <br />
            <br />
            <div *ngIf="brandMode">
                <app-documentcenter [dataSensitivity]="dataSensitivity"></app-documentcenter>
            </div>
        </div>
    </div>
</div>