import { Component, Input, OnInit, ViewChild } from '@angular/core';


@Component({
  selector: 'app-inventories-report',
  templateUrl: './inventories-report.component.html',
  styleUrls: ['./inventories-report.component.scss']
})
export class InventoriesReportComponent implements OnInit {
  @Input() hideTitle:boolean =false;
  @Input() hideFactory:boolean =false;

  isCubejsConnected: boolean = true;
  

  constructor() { }

  ngOnInit(): void {
    

  }
  

}
