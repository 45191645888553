 <div class="flex flex-col flex-auto min-w-0">
    <!-- Main -->
    <div class="flex-auto p-6 sm:p-10">
        <!-- CONTENT GOES HERE -->
        <div><div class="content" i18n="Inventory Input Component">
  <div *ngIf="showSuccessMessage" style="background-color: greenyellow;z-index:1000; position: fixed; padding: 10px; height: 40px; display: block; right: 30px; top: 70px;">
    {{"INPUT_INVENTORY_COMPONENT.SAVED_SUCCESSFULLY_MESSAGE" | translate}}
  </div>
  <!-- <h2><b>{{"INPUT_INVENTORY_COMPONENT.STEP1.HEADER.TITLE" | translate}}</b></h2> -->
  <h2 class="flex-auto text-xl font-semibold text-slate-900">{{"INPUT_INVENTORY_COMPONENT.STEP1.HEADER.TITLE" | translate}}</h2>
  <h5  class="mt-2 mb-2">{{"INPUT_INVENTORY_COMPONENT.STEP1.HEADER.SUBTITLE" | translate}}</h5>
  <div *ngIf="isChineseSystem()">
    <iframe height=498 width=510 src='https://player.youku.com/embed/XNDY1MDczMDg4OA==' frameborder=0 allowfullscreen></iframe>
  </div>
  <div *ngIf="!isChineseSystem()">
    <iframe width="560" height="315" src="https://www.youtube.com/embed/-zxAL5E7J9c" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </div>
  <p [innerHTML]="'INPUT_INVENTORY_COMPONENT.STEP1.VIDEO_SUBTITLE' | translate"></p>
    <br><br>
  <!-- <h2><b>{{"INPUT_INVENTORY_COMPONENT.STEP2.HEADER.TITLE" | translate}}</b></h2> -->

  <h2  class="flex-auto text-xl font-semibold text-slate-900">{{"INPUT_INVENTORY_COMPONENT.STEP2.HEADER.TITLE" | translate}}</h2>


  <h5 class="mt-2 mb-2" [innerHTML]="'INPUT_INVENTORY_COMPONENT.STEP2.HEADER.SUBTITLE' | translate"></h5>
  <div *ngIf="isChineseSystem()">
    <iframe height=498 width=510 src='https://player.youku.com/embed/XNDQ2OTIzNTg3Ng==' frameborder=0 allowfullscreen></iframe>
  </div>
  <div *ngIf="!isChineseSystem()">
    <iframe width="600" height="400"
      src="https://www.youtube.com/embed/zR1VTPnbSlw">
    </iframe>
  </div>
    <!--<img src="/assets/Input_Inventory_Assets/asset_copy.png" style="max-width:300px">-->
     <p> {{"INPUT_INVENTORY_COMPONENT.STEP2.VIDEO_SUBTITLE" | translate}}</p>
    <!-- <br>
      Factories can now enter their previously app-scanned chemical products through the <i>desktop platform</i>:
      <li>Login to your BHive account on <a href="https://login.thebhive.net">https://login.thebhive.net </a></li>
      <li>Go to “<b>Data Input</b>” in the navigation buttons</li>
      <li>Under “Step 2 Copy scanned Chemicals”, click “<b>Copy Inventory</b>” (An input window will pop up)</li>
      <li>Type in the first letters of the previously scanned chemical product you would like to include in the current month’s inventory. The system will show you a list of the previously scanned products, from which you can choose the regarding product. The manufacturer will be automatically filled in.</li>
      <li> Add the custom information </li>
        <ul>
          <li type="square">Stock Volume (kg) </li>
          <li type="square">Consumption (kg/month)</li>
          <li type="square">Purchase date</li>
          <li type="square">Expiration date</li>
          <li type="square">Stock Location – system will remember previous entries</li>
          <li type="square">Batch/Lot Number </li>
          <li type="square">Used for Customer – system will remember previous entries</li>
        </ul>-->
    <br>
    <button type="button" class="btn btn-primary plusbutton" (click)="openInputBox()">{{"INPUT_INVENTORY_COMPONENT.COPY_INVENTORY_BUTTON" | translate}}</button>
</div>
</div>
		</div>
		</div>
