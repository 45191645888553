export abstract class OrganizationConst {

        static readonly OTTO = 'OTTO_GROUP_BHIVE_ID';

        static readonly KONTOOR = 'KONTOOR_BHIVE_ID';

        static readonly WALMART = 'WALMART_BHIVE_ID';

        static readonly ALDI = 'ALDI_NORTH_BHIVE_ID';

        static readonly HOFER = 'ALDI_HOFER_BHIVE_ID';

        static readonly NIKE = 'NIKE_BHIVE_ID';

        static readonly ALDI_SOURCING = 'ALDI_SOURCING_ASIA_LTD'; //china server

        static readonly ALDI_IMPORTER = 'E_TEXTIL_GLOBAL_BHIVE_ID';

        static readonly CandA = 'C_AND_A_BHIVE_ID';

        static readonly TCP = 'THE_CHILDREN_PLACE_BHIVE_ID';

        static readonly RED_NICE = 'RED_NICE_BHIVE_ID';

        static readonly DPP_BRAND = 'DPP_BRAND_BHIVE_ID';

        static readonly DPP_BRAND_CHINA = 'DPP_BRAND_CHINA_BHIVE_ID';

        static readonly LINDEX_BRAND = 'LINDEX_BHIVE_ID';

        static readonly MILES = 'MILES_GMBH_BHIVE_ID';

        static readonly DUMMY = 'FASHION_BLU_BHIVE_ID';

        static readonly TAKKO_BANGLADESH_BHIVE_ID = 'TAKKO_BANGLADESH_BHIVE_ID';
        static readonly TAKKO_CHINA_BHIVE_ID = 'TAKKO_CHINA_BHIVE_ID';
        static readonly TAKKO_GLOBAL_BHIVE_ID = 'TAKKO_GLOBAL_BHIVE_ID';
        static readonly TAKKO_INDIA_BHIVE_ID = 'TAKKO_INDIA_BHIVE_ID';

        static readonly HM = 'H_AND_M_BHIVE_ID';


        static readonly OTB_BHIVE_ID = 'OTB_BHIVE_ID';
        static readonly STAFF_INTERNATIONAL_BHIVE_ID = 'STAFF_INTERNATIONAL_BHIVE_ID';
        static readonly DIESEL_BHIVE_ID = 'DIESEL_BHIVE_ID';
        static readonly BRAVE_KID_BHIVE_ID = 'BRAVE_KID_BHIVE_ID';

        static readonly BESTSELLER_DPP_DUMMY_BRAND_BHIVE_ID = 'BESTSELLER_DPP_DUMMY_BRAND_BHIVE_ID';

        static readonly SDS_SCREENING_BRAND = 'SDS_SCREENING_BRAND_BHIVE_ID';


}
