import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { RouteNavigationService } from 'app/globals-services/navigation-service';
import { Inventory } from '../../globals-interface/inventory';
import { GlobalsService } from '../../globals-services/globals.service';
import { SessionStateService } from '../../globals-services/session-state-service/session-state.service';

@Component({
  selector: 'app-supplier-inventories-overview',
  templateUrl: './supplier-inventories-overview.component.html',
  styleUrls: ['./supplier-inventories-overview.component.css']
})
export class SupplierInventoriesOverviewComponent implements OnInit {
  showErrorMessage: boolean = false;
  isLoading: boolean;
  supplierIsSelected: boolean = false;

  firstInventoryDate;
  latestInventoryDate;
  isMonth: boolean;
  isQuarter: boolean;
  isYear: boolean;
  inventoryStatus = false;
  monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  quarterNames = ['Quarter 1', 'Quarter 2', 'Quarter 3', 'Quarter 4'];
  monthIndexMap = { 'January': '1', 'February': '2', 'March': '3', 'April': '4', 'May': '5', 'June': '6', 'July': '7', 'August': '8', 'September': '9', 'October': '10', 'November': '11', 'December': '12' };
  quarterIndexMap = { 'Quarter 1': '1', 'Quarter 2': '2', 'Quarter 3': '3', 'Quarter 4': '4' };
  countriesToShow = [];
  suppliersToShow = [];
  yearsToShow = [];
  monthsToShow = [];
  inventoriesToShow: Inventory[] = [];
  summary = {};

  selectedCountry: string = "";
  selectedSupplier: string = "";
  selectedSupplierName: string = "";
  selectedYear: string = "";
  selectedMonthOrQuarter: string = "";

  supplierIDAvailable = false;
  supplierID = '';
  // new


  supplierName: String = "";
  suggestedList: any = []
  startDate: any;
  endDate: any;
  factoryName: any;
  amISupplier = true;
  isSharedSupplier=false

  constructor(
    private http: HttpClient,
    private globalsService: GlobalsService,
    private sessionStateService: SessionStateService,
    private route: ActivatedRoute,
    private routeNavigationService: RouteNavigationService
  ) {
    this.isLoading = false;


  }

  ngOnInit() {
    // console.log(this.globalsService.brands)
    this.route.queryParams.subscribe(
      (state) => {
        if (state['supplierId'] != null) {
          this.amISupplier = false
          this.selectedSupplier = state['supplierId']

          let sp = this.globalsService.brands.find(supplier => supplier.id == this.selectedSupplier);
 

          this.selectedSupplierName =state['name']
          this.ifSupplierAlreadySelected()

          if (state['year'] && state['month']) {

            this.selectedYear = state['year']
            this.onYearSelect()
            this.selectedMonthOrQuarter = this.monthNames[Number(state['month']) - 1]
            this.fetchMonthsAndQuarters()
            this.onMonthOrQuarterSelect()

          }

        }


        if (state['accState'] == 'open') {

          this.selectedCountry = this.sessionStateService.getChemInvCountry();

          this.selectedSupplier = this.sessionStateService.getChemInvSupplier();
          this.selectedYear = this.sessionStateService.getChemInvYear();
          this.selectedMonthOrQuarter = this.sessionStateService.getChemInvMonthOrQuarter();
          //    console.info(this.selectedCountry, this.selectedSupplier, this.selectedYear, this.selectedMonthOrQuarter)
          this.fetchAll();
        } else {
          // fetch all Countries
          this.fetchCountries();
        }
        if (state['sharedSupplier']) {
          this.isSharedSupplier = state['sharedSupplier'] === 'true' ? true : false
        }
      }
    );
    this.getMySupplierPartners();

  }

  onCountrySelect() {
    this.clearOnCountryChange();
    if (this.selectedCountry != "none") {
      this.fetchSuppliers();
    }
    this.rememberSelectedCountry();
  }

  onSupplierSelect() {

    const brandID = this.globalsService.profile['id'];
    //  const brandID = this.globalsService.profile['organization_id'];

    let owner = '';
    this.clearOnSupplierChange();
    if (this.selectedSupplier != "none") {
      this.selectedSupplierName = this.suppliersToShow.find(supplier => {
        owner = supplier.id;
        return (supplier.id === Number(this.selectedSupplier));
      })["name"];
      this.fetchDatesAndYears();
      this.supplierIsSelected = true;
    } else {
      this.supplierIsSelected = false;
    }
    this.rememberSelectedSupplier();

    console.log('start')
    this.http.post<any>(this.globalsService.baseHref + '/user/getSupplierID', { 'brand': brandID, 'owner': owner }).subscribe((object) => {
      if (object['idValue'] !== undefined) {
        console.log('1')
        this.supplierIDAvailable = true;
        this.supplierID = object['idValue'];
      } else {
        console.log('2')
        this.supplierIDAvailable = false;
      }
    });

  }

  onYearSelect() {
    this.clearOnYearChange();
    if (this.selectedYear != "none") {
      this.fetchMonthsAndQuarters();
    }
    this.rememberSelectedYear();
  }

  onMonthOrQuarterSelect() {
    this.clearOnMonthChange();
    if (this.monthNames.includes(this.selectedMonthOrQuarter)) {
      this.isMonth = true;
      this.isQuarter = false;
      this.isYear = false
      // get all inventories of month
      this.fetchInventoriesByMonth();

    } else if (this.quarterNames.includes(this.selectedMonthOrQuarter)) {
      this.isMonth = false;
      this.isQuarter = true;
      this.isYear = false;
      // get quarter summary
      this.fetchInventoriesByQuarter();
    } else {
      this.isMonth = false;
      this.isQuarter = false;
      this.isYear = true;
      // get yearly summary
      this.fetchInventoriesByYear();
    }
    this.rememberSelectedMonthOrQuarter();
  }

  fetchAll() {
    if (!this.selectedCountry) {
      return
    }
    this.isLoading = true;
    // fetch countries
    this.http.get<string[]>(this.globalsService.baseHref + '/inventories/getCountries').subscribe(
      (response) => {
        this.countriesToShow = response["countries"];
        // On success fetch suppliers
        this.http.get<string[]>(this.globalsService.baseHref + "/inventories/getSuppliers/" + this.selectedCountry).subscribe(
          (response) => {
            this.suppliersToShow = response;
            this.selectedSupplierName = this.suppliersToShow.find(supplier => {
              return (supplier.id === Number(this.selectedSupplier));
            })["name"];
            this.supplierIsSelected = true;
            // On success fetch dates and years
            this.http.get(this.globalsService.baseHref + "/inventories/getDatesAndYears/" + this.selectedSupplier).subscribe(
              (response) => {
                this.firstInventoryDate = response["firstInventoryDate"];
                this.latestInventoryDate = response["latestInventoryDate"];
                this.yearsToShow = response["years"];
                // On success fetch months and quarters
                this.http.get<number[]>(this.globalsService.baseHref + "/inventories/getMonthsAndQuarters/" + this.selectedYear + "/" + this.selectedSupplier).subscribe(
                  (response) => {
                    this.monthsToShow = [];
                    let months = response;
                    for (let month of months) {
                      this.monthsToShow.push(this.monthNames[month - 1]);
                    }

                    for (let i = 1; i <= 4; i++) {
                      let quarter = (element) => element / 3.0 <= i && element / 3.0 > i - 1;
                      if (months.some(quarter)) { this.monthsToShow.push(this.quarterNames[i - 1]); }
                    }

                    this.monthsToShow.push("Summary " + this.selectedYear);
                    // On success fetch inventories
                    if (this.monthNames.includes(this.selectedMonthOrQuarter)) {
                      this.isMonth = true;
                      this.isQuarter = false;
                      this.isYear = false;
                      // get all inventories of month
                      this.http.get<Inventory[]>(this.globalsService.baseHref + '/inventories/showInventoriesByMonth/' + this.selectedYear + '/' + this.monthIndexMap[this.selectedMonthOrQuarter] + '/' + this.selectedSupplier).subscribe(
                        (response) => {
                          this.inventoriesToShow = response;
                          this.createSummary();
                          this.isLoading = false;
                          this.checkCompletenessStatus();
                        },
                        (err) => {
                          console.log(err);
                          this.isLoading = false;
                        }
                      );
                    } else if (this.quarterNames.includes(this.selectedMonthOrQuarter)) {
                      this.isMonth = false;
                      this.isQuarter = true;
                      this.isYear = false;
                      // get quarter summary
                      this.http.get<Inventory[]>(this.globalsService.baseHref + '/inventories/showInventoriesByQuarter/' + this.selectedYear + '/' + this.quarterIndexMap[this.selectedMonthOrQuarter] + '/' + this.selectedSupplier).subscribe(
                        (response) => {
                          this.inventoriesToShow = response;
                          this.createSummary().then(() => {
                            this.isLoading = false;
                          });
                        },
                        (err) => {
                          console.log(err);
                          this.isLoading = false;
                        }
                      );
                    } else {
                      this.isMonth = false;
                      this.isQuarter = false;
                      this.isYear = true;
                      this.http.get<Inventory[]>(this.globalsService.baseHref + '/inventories/showInventoriesByYear/' + this.selectedYear + '/' + this.selectedSupplier).subscribe(
                        (response) => {
                          this.inventoriesToShow = response;
                          this.createSummary().then(() => {
                            this.isLoading = false;
                          });
                        },
                        (err) => {
                          console.log(err);
                          this.isLoading = false;
                        }
                      );
                    }
                  },
                  (err) => {
                    console.log(err);
                    this.isLoading = false;
                  }
                );
              },
              (err) => {
                console.log(err);
                this.isLoading = false;
                if (err["status"] == 404) {
                  this.showErrorNotFound();
                }
              }
            );
          },
          (err) => {
            console.log(err);
            this.isLoading = false;
          }
        )
      },
      (err) => {
        console.log(err);
        this.isLoading = false;
      }
    )
  }

  fetchCountries() {
    this.isLoading = true;
    this.http.get<string[]>(this.globalsService.baseHref + '/inventories/getCountries').subscribe(
      (response) => {
        this.countriesToShow = response["countries"];
        this.isLoading = false;
      },
      (err) => {
        console.log(err);
        this.isLoading = false;
      }
    )
  }

  fetchSuppliers() {
    this.isLoading = true;
    this.http.get<string[]>(this.globalsService.baseHref + "/inventories/getSuppliers/" + this.selectedCountry).subscribe(
      (response) => {
        this.suppliersToShow = response;
        this.isLoading = false;
      },
      (err) => {
        console.log(err);
        this.isLoading = false;
      }
    )
  }

  fetchDatesAndYears() {
    this.isLoading = true;
    this.http.get(this.globalsService.baseHref + "/inventories/getDatesAndYears/" + this.selectedSupplier).subscribe(
      (response) => {
        this.firstInventoryDate = response["firstInventoryDate"];
        this.latestInventoryDate = response["latestInventoryDate"];
        this.yearsToShow = response["years"];
        console.info(this.yearsToShow);


        let isYearExist = this.yearsToShow.find(yr => yr == this.selectedYear);
        if (isYearExist == null) {
          this.selectedYear = null;
          this.isYear = false;
          this.isMonth = false
        }
        this.isLoading = false;
      },
      (err) => {
        console.log(err);
        this.isLoading = false;
        if (err["status"] == 404) {
          this.showErrorNotFound();
        }
      }
    );
  }

  fetchMonthsAndQuarters() {
    if (this.selectedYear != null && this.selectedYear.length > 0) {
      this.isLoading = true;
      this.http.get<number[]>(this.globalsService.baseHref + "/inventories/getMonthsAndQuarters/" + this.selectedYear + "/" + this.selectedSupplier).subscribe(
        (response) => {
          this.monthsToShow = [];
          let months = response;
          for (let month of months) {
            this.monthsToShow.push(this.monthNames[month - 1]);
          }

          for (let i = 1; i <= 4; i++) {
            let quarter = (element) => element / 3.0 <= i && element / 3.0 > i - 1;
            if (months.some(quarter)) { this.monthsToShow.push(this.quarterNames[i - 1]); }
          }

          this.monthsToShow.push("Summary " + this.selectedYear);
          this.isLoading = false;
        },
        (err) => {
          console.log(err);
          this.isLoading = false;
        }
      );
    }
  }

  fetchInventoriesByMonth() {
    this.isLoading = true;
    this.http.get<Inventory[]>(this.globalsService.baseHref + '/inventories/showInventoriesByMonth/' + this.selectedYear + '/' + this.monthIndexMap[this.selectedMonthOrQuarter] + '/' + this.selectedSupplier).subscribe(
      (response) => {
        this.inventoriesToShow = response;
        this.createSummary();
        this.isLoading = false;
      },
      (err) => {
        console.log(err);
        this.isLoading = false;
      }
    );
    this.checkCompletenessStatus();
  }

  fetchInventoriesByQuarter() {
    this.isLoading = true;
    this.http.get<Inventory[]>(this.globalsService.baseHref + '/inventories/showInventoriesByQuarter/' + this.selectedYear + '/' + this.quarterIndexMap[this.selectedMonthOrQuarter] + '/' + this.selectedSupplier).subscribe(
      (response) => {
        this.inventoriesToShow = response;
        this.createSummary().then(() => {
          this.isLoading = false;
        });
      },
      (err) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }

  fetchInventoriesByYear() {
    if (this.selectedYear == null || this.selectedYear == undefined || this.selectedYear == "") {
      return;
    }
    this.isLoading = true;
    this.http.get<Inventory[]>(this.globalsService.baseHref + '/inventories/showInventoriesByYear/' + this.selectedYear + '/' + this.selectedSupplier).subscribe(
      (response) => {
        this.inventoriesToShow = response;
        this.createSummary().then(() => {
          this.isLoading = false;
        });
      },
      (err) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }

  createSummary() {
    let summaryReady = new Promise<void>((resolve, reject) => {
      let numberOfFormulas: number = 0;
      let numberOfManuallyAddedFormulas: number = 0;

      this.summary = {};
      for (let inventory of this.inventoriesToShow) {
        numberOfFormulas += parseInt(inventory["numberOfFormulas"]);
        numberOfManuallyAddedFormulas += parseInt(inventory["numberOfManuallyAddedFormulas"]);
      }
      this.summary["numberOfFormulas"] = numberOfFormulas;
      this.summary["numberOfManuallyAddedFormulas"] = numberOfManuallyAddedFormulas;
      this.summary["date"] = this.selectedMonthOrQuarter;
      this.summary["creatorId"] = this.inventoriesToShow[0]["organization"]["id"];
      resolve();
    });
    return summaryReady;
  }

  yearAndMonthOrQuarterIsSet() {
    return (this.selectedMonthOrQuarter != "none" && this.selectedYear != "none");
  }

  clearOnCountryChange() {
    this.suppliersToShow = [];
    this.yearsToShow = [];
    this.monthsToShow = [];
    this.inventoriesToShow = [];
    this.summary = {};

    this.supplierIsSelected = false;
    this.selectedSupplier = "none";
    this.selectedYear = "none";
    this.selectedMonthOrQuarter = "none";
  }

  clearOnSupplierChange() {
    this.supplierID = '';
    this.supplierIDAvailable = false;

    this.yearsToShow = [];
    this.monthsToShow = [];
    this.inventoriesToShow = [];
    this.summary = {};

    this.selectedYear = "none";
    this.selectedMonthOrQuarter = "none";
  }

  clearOnYearChange() {
    this.monthsToShow = [];
    this.inventoriesToShow = [];
    this.summary = {};

    this.selectedMonthOrQuarter = "none";
  }

  clearOnMonthChange() {
    this.inventoriesToShow = [];
    this.summary = {};
  }

  allFieldsSelected() {
    return (this.selectedMonthOrQuarter != "none" && this.selectedYear != "none" && this.selectedSupplier != "none" && this.selectedYear != "none");
  }

  rememberSelectedCountry() {
    this.sessionStateService.setChemInvCountry(this.selectedCountry);
  }

  rememberSelectedSupplier() {
    this.sessionStateService.setChemInvSupplier(this.selectedSupplier);
  }

  rememberSelectedYear() {
    this.sessionStateService.setChemInvYear(this.selectedYear);
  }

  rememberSelectedMonthOrQuarter() {
    this.sessionStateService.setChemInvMonthOrQuarter(this.selectedMonthOrQuarter);
  }

  showErrorNotFound() {
    this.showErrorMessage = true;
    setTimeout(
      () => {
        this.showErrorMessage = false;
      }, 1500
    );
  }

  checkCompletenessStatus() {
    const yearMonth = '' + this.selectedYear + this.monthIndexMap[this.selectedMonthOrQuarter];
    const userId = this.selectedSupplier;

    this.http.post<any[]>(this.globalsService.baseHref + '/checkConfirmationOfInventories', { 'yearMonth': yearMonth, 'userId': userId }).subscribe(
      (response) => {
        this.inventoryStatus = (response['inventoryStatus'] === 'true');
      },
      (err) => {
        this.inventoryStatus = false;
        console.log(err);
        this.isLoading = false;
      }
    );
  }


  searchThis(searchText) {

    if (searchText.length > 0) {
      this.selectedSupplierName = searchText
      this.suggestedList = this.globalsService.brands.filter(res => {
        if (res.sharedWith == true && res.organizationType == 2 && res.relation === "Connected" && res.id != this.globalsService.profile['id']) {
          return res.name.toLowerCase().match(this.selectedSupplierName.toLocaleLowerCase())
            || res.identification.toLowerCase().match(this.selectedSupplierName.toLocaleLowerCase())
            || (res.countryName != null && res.countryName.toLowerCase().match(this.selectedSupplierName.toLocaleLowerCase()))
            || res.Higg.toLowerCase().match(this.selectedSupplierName.toLocaleLowerCase())
            || res.Oar.toLowerCase().match(this.selectedSupplierName.toLocaleLowerCase())
            || (res.activity != null && res.activity.toLowerCase().match(this.selectedSupplierName.toLocaleLowerCase()))
            || res.FFC.toLowerCase().match(this.selectedSupplierName.toLocaleLowerCase())
            ;
        }

      }

      );
    } else {
      this.getMySupplierPartners();
    }


  }

  foo(selectedSupplier: any) {


  }
  getMySupplierPartners() {
    this.suggestedList = this.globalsService.brands.filter(res => {
      return res.sharedWith == true && res.organizationType == 2 && res.relation === "Connected" && res.id != this.globalsService.profile['id'];
    })
  }
  onSearchSupplierSelect() {


    this.selectedSupplier = '';

    let sp = this.suggestedList.find(supplier => this.selectedSupplierName === supplier.name);

    this.selectedSupplier = sp.id;
    this.selectedCountry = sp.countryName
    this.rememberSelectedSupplier();
    this.rememberSelectedCountry();

    this.yearsToShow = []
    this.fetchDatesAndYears();
    this.fetchMonthsAndQuarters()

    if (this.selectedYear != "none" && this.selectedMonthOrQuarter != "none") {

      this.onMonthOrQuarterSelect();

    }



  }

  ifSupplierAlreadySelected() {
    this.rememberSelectedSupplier();
    this.rememberSelectedCountry();

    this.yearsToShow = []
    this.fetchDatesAndYears();
    this.fetchMonthsAndQuarters()

    if (this.selectedYear != "none" && this.selectedMonthOrQuarter != "none") {

      this.onMonthOrQuarterSelect();

    }

  }

  goBack() {
    this.routeNavigationService.goBack()
  }
}
