import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { MyConnectionListComponent } from './my-connection-list/my-connection-list.component';
import { ReceivedRequestListComponent } from './received-request-list/received-request-list.component';
import { SearchedListComponent } from './searched-list/searched-list.component';
import { SentRequestListComponent } from './sent-request-list/sent-request-list.component';




@NgModule({
  declarations: [
    ReceivedRequestListComponent,
    SentRequestListComponent,
    SearchedListComponent,
    MyConnectionListComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    TranslateModule,
    MatTooltipModule,
    MatAutocompleteModule

  ],
  exports: [
    ReceivedRequestListComponent,
    SentRequestListComponent,
    SearchedListComponent,
    MyConnectionListComponent
  ]
})
export class BrandFactoryConnCommonModule { }
