import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TableFilterService {
    private originalData: any = [];
    private dataSubject = new BehaviorSubject<any[]>([]);
    private filtersSubject = new BehaviorSubject<{ [key: string]: any }>({});
    private selectionSubject = new BehaviorSubject<{ [key: string]: Set<any> }>({});
    public uniqueValuesCache = new Map<string, any[]>(); // Cache for unique values

    data$ = this.dataSubject.asObservable();
    filters$ = this.filtersSubject.asObservable();
    selection$ = this.selectionSubject.asObservable();

    setData(data: any[]) {
        this.originalData = data;
        this.dataSubject.next(data);
        this.preCalculateUniqueValues();
    }

    constructor() {
    }

    private preCalculateUniqueValues() {
        // Assuming we know the columns or we can infer them from data keys
        const columns = this.originalData.length > 0 ? Object.keys(this.originalData[0]) : [];
        columns.forEach(column => {
            const uniqueValues = new Set(this.originalData.map((item:any) => item[column]));
            this.uniqueValuesCache.set(column, Array.from(uniqueValues).map(value => ({
                value,
                selected: false
            })));
        });
    }

    // Get unique values for a specific column
    getUniqueValuesForColumn(column: string): any[] {
        return this.uniqueValuesCache.get(column) || [];
    }

    updateFilters(tableData: any, column: string, filter: any) {
        const currentFilters = this.filtersSubject.value;
        currentFilters[column] = filter;
        this.filtersSubject.next(currentFilters);
        this.originalData = [...tableData]
        this.applyFilters();
    }

    updateSelections(column: string, selections: Set<any>) {
        const currentSelections = { ...this.selectionSubject.value };

        if (selections.size > 0) {
            currentSelections[column] = selections;
        } else {
            delete currentSelections[column];
        }
        this.selectionSubject.next(currentSelections);
        this.applyFilters();
    }

    applyFilters() {
        const filters = this.filtersSubject.value;
        const selections = this.selectionSubject.value;
        let filteredData = [...this.originalData]; // Start with the current full data

        if (Object.keys(filters).length === 0 && Object.keys(selections).every(key => selections[key].size === 0)) {
            this.dataSubject.next([...this.originalData]);
            return;
        }

        // Apply text and condition filters
        Object.keys(filters).forEach(column => {
            const filter = filters[column];
            if (filter) {
                const filterFunction = this.filterFunctions[filter.condition];
                if (filterFunction && filter.value) {
                    filteredData = filteredData.filter(item => filterFunction(item[column], filter.value));
                }
            }
        });

        // Apply selection filters
        Object.keys(selections).forEach(column => {
            const selectedValues = selections[column];
            if (selectedValues && selectedValues.size > 0) {
                filteredData = filteredData.filter(item => selectedValues.has(item[column]));
            }
        });

        this.dataSubject.next(filteredData);
    }

    doesNotContain = (value: any, filteredValue: any) => {
        if (typeof value === 'string' && typeof filteredValue === 'string') {
            return !value.toLowerCase().includes(filteredValue.toLowerCase());
        }
        return false;
    };
    
    filterFunctions: any = {
        "contains": function (value: any, filteredValue: any) {
            if (typeof value === 'string' && typeof filteredValue === 'string') {
                return value.toLowerCase().includes(filteredValue.toLowerCase());
            }
            return false;
        },
        "does-not-contain": function (value: any, filteredValue: any) {
            if (typeof value === 'string' && typeof filteredValue === 'string') {
                return !value.toLowerCase().includes(filteredValue.toLowerCase());
            }
            return false;
        },
        "is-equal": function (value: any, filteredValue: any) {
            if (typeof value === 'string' && typeof filteredValue === 'string') {
                return value.toLowerCase() === filteredValue.toLowerCase();
            }
            return false;
        },
        "is-not-equal": function (value: any, filteredValue: any) {
            if (typeof value === 'string' && typeof filteredValue === 'string') {
                return value.toLowerCase() !== filteredValue.toLowerCase();
            }
            return false;
        }
    };



    clearFilter(column: string) {
        const filters = this.filtersSubject.value;
        const selections = this.selectionSubject.value;
    
        if (filters.hasOwnProperty(column)) {
            delete filters[column];
            this.filtersSubject.next(filters);
        }
        if (selections.hasOwnProperty(column)) {
            selections[column].clear();
            this.selectionSubject.next(selections);
        }
    
        this.applyFilters();  // Reapply remaining filters or reset if none
    }

    sortData(columnName: string, sortDirection: string) {

        this.dataSubject['_value'].sort((a: any, b: any) => {
        const valueA = a[columnName];
        const valueB = b[columnName];

        // Handling null values
        if (valueA === null && valueB !== null) {
            return sortDirection === 'asc' ? -1 : 1;
        } else if (valueA !== null && valueB === null) {
            return sortDirection === 'asc' ? 1 : -1;
        }

        // Handling numbers
        if (typeof valueA === 'number' && typeof valueB === 'number') {
            if (valueA === valueB) return 0;
            if (sortDirection === 'asc') {
            return valueA - valueB;
            } else {
            return valueB - valueA;
            }
        }

      // Handling strings
      if (typeof valueA === 'string' && typeof valueB === 'string') {
        const lowercaseA = valueA.toLowerCase();
        const lowercaseB = valueB.toLowerCase();
        if (lowercaseA === lowercaseB) return 0;
        if (sortDirection === 'asc') {
          if (lowercaseA < lowercaseB) return -1;
          return 1;
        } else {
          if (lowercaseA > lowercaseB) return -1;
          return 1;
        }
      }

      // Handle other types here, if necessary
      return 0;
    });
    }
    
}
