<div class="header-general  flex justify-between">
    <h1 class="h2 m-0 p-2  ml-4" mat-dialog-title i18n="Analytics Comment">{{data['supplierName']}}</h1>
    <div class="w3-large mr-4">
        <a (click)="onNoClick()">
            <mat-icon>close</mat-icon>
        </a>
    </div>
</div>

<div class="content">
    <p style="font-size:100%"><b>Comments</b></p>
    <br />
    <div id="comments" *ngIf="data['commentDetails'].length>0" style="overflow-y:scroll; height:200px;">
        <div *ngFor="let comment of data['commentDetails']">
            <label>{{comment['userName']}}/ {{comment['createdOn'] | date: 'dd/MM/yyyy'}} : </label>
            <p>{{comment['comment']}}</p>
        </div>
    </div>
    <div id="comments" *ngIf="data['commentDetails'].length<=0">
        <div style="text-align: center;">
            <span>No comment found</span>
        </div>
    </div>
    <textarea matInput class="mt-2" required placeholder="Leave a comment..." [(ngModel)]="comment"></textarea>
    <hr>

    <div mat-dialog-actions i18n="Edit Data Dialog Save and Cancel Buttons">
        <button class="btn btn-primary" (click)="publish()">Publish</button>
        <button class="ml-4 btn btn-secondary" (click)="onNoClick()">{{"EDIT_DATA_DIALOG_COMPONENT.CANCEL_BUTTON" |
            translate}}</button>
    </div>
</div>