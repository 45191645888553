import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GlobalsService } from '../globals-services/globals.service';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  defaultLanguage;
  selectedLanguage;
  availableLanguages = [];

  constructor(
    private http: HttpClient,
    private globalsService: GlobalsService
  ) {
    if (this.globalsService.serverLocation == "Europe") {
      this.defaultLanguage = { languageCode: "en", englishName: "English", name: "English" };
    } else if (this.globalsService.serverLocation == "China") {
      this.defaultLanguage = { languageCode: "zh-Hans", englishName: "Chinese", name: "简体中文" };
    }
  }

  getAvailableLanguages: Observable<any[]> = new Observable((observer) => {
    this.http.get<any>(this.globalsService.baseHref + '/getAvailableLanguages').subscribe(
      (response) => {
        if (response == 'undefinded' || response == null || response.length == 0) {
          observer.next([{ languageCode: "en", englishName: "English", name: "English" }]);
        } else {
          observer.next(response);
        }
      },
      (err) => {
        console.log(err);
        observer.next([{ languageCode: "en", englishName: "English", name: "English" }]);
      }
    );
  });

   
}
