<div class="p-10">
    <div class="flex items-center justify-between w-full ">
        <h2 class="text-3xl font-semibold tracking-tight leading-8">
            {{ "BRAND_DASHBOARD_COMPONENT.PAGE_HAEDER" | translate }}
        </h2>
    </div>

    <div>
        <h2 class="page-sub-header">
            {{ "BRAND_DASHBOARD_COMPONENT.PAGE_SUB_HEADER" | translate }}
        </h2>
    </div>


</div>

<div *ngIf="isCubejsConnected" class="container p-10">
    <mat-grid-list cols="5" rowHeight="16vh">
        <mat-grid-tile class="dates-tile" [colspan]="5" [rowspan]="1">
            <mat-card class="start_date">
                <div class="info-cards">
                    <mat-card-title>{{startDate | date }}</mat-card-title>
                    <mat-card-subtitle>Reporting Start Date</mat-card-subtitle>
                </div>
            </mat-card>
            <mat-card class="end_date">
                <div class="info-cards">
                    <mat-card-title>{{endDate | date }}</mat-card-title>
                    <mat-card-subtitle>Reporting End Date</mat-card-subtitle>
                </div>
            </mat-card>



            <mat-card class="compliance">
                <div class="info-cards">
                    <mat-card-title id="complianceName">{{complianceName}}</mat-card-title>
                    <mat-card-subtitle>Compliance Name</mat-card-subtitle>
                </div>
            </mat-card>


            <mat-card>
                <div class="info-cards">
                    <mat-card-title>{{connectedBrands}}</mat-card-title>
                    <mat-card-subtitle>Connected Partners</mat-card-subtitle>
                </div>
            </mat-card>
            <mat-card>
                <div class="info-cards">
                    <mat-card-title>{{distinctFormulaCount}}</mat-card-title>
                    <mat-card-subtitle>Distinct Formula Count</mat-card-subtitle>
                </div>
            </mat-card>




        </mat-grid-tile>
        <mat-grid-tile [colspan]="3" [rowspan]="4">
            <mat-card>
                <button mat-icon-button id="more_button" [matMenuTriggerFor]="capMenu">
                    <mat-icon svgIcon="mat_outline:more_vert"></mat-icon>
                </button>
                <mat-menu #capMenu="matMenu" id="more_menu">
                    <button mat-menu-item (click)="exportData(this.tabIndex,'')">
                        <mat-icon svgIcon="mat_outline:file_download"></mat-icon>
                        <span>Export Data</span>
                    </button>
                </mat-menu>
                <mat-card-content>
                    <mat-tab-group mat-stretch-tabs [backgroundColor]=""
                        (selectedTabChange)="selectedTabChanged($event);">
                        <mat-tab class="mat-card-subtitle" label="Unverified Chemicals">
                            <ng-template matTabContent>
                                <!-- <mat-card> -->

                                <div class="unverified_chemicals">
                                    <table mat-table [dataSource]="unverifiedCapFormulaList"
                                        #unverifiedMatSort="matSort" matSort>

                                        <ng-container matColumnDef="factory">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Factory</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.factory}}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="name">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Chemical Name</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.name}}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="count">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Count</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.count}}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="consumption">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Consumption</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.consumption | number: '1.0-2'}}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="manufacturer">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Manufacturer</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.manufacturer}}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="source">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Source</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.source}}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="remediation">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Remediation</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.remediation}}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="deadline">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Deadline</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.deadline | date:'yyyy-MM-dd'}}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="usage">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Usage</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.usage}}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="cap_status">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>CAP Status</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.cap_status}}
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="unverifiedTableColumns; sticky: true;">
                                        </tr>
                                        <tr mat-row *matRowDef="let row; columns: unverifiedTableColumns;"
                                            [class.make-yellow]='row.type.startsWith("man")'></tr>

                                    </table>
                                </div>
                                <!-- <ngx-loading [show]="isLoadingUnverifiedChemicals"></ngx-loading> -->
                                <!-- <loading-spinner *ngIf="isLoadingUnverifiedChemicals"></loading-spinner> -->
                                <mat-paginator #unverifiedMatPaginator="matPaginator" [pageSizeOptions]="[15]"
                                    showFirstLastButtons aria-label="Select page of periodic elements">
                                </mat-paginator>

                                <!-- </mat-card-content> -->
                                <!-- </mat-card> -->
                            </ng-template>
                        </mat-tab>
                        <mat-tab class="mat-card-subtitle" label="Verified Chemicals">
                            <ng-template matTabContent>
                                <!-- <mat-card> -->
                                <!-- <mat-card-content> -->
                                <div class="unverified_chemicals">
                                    <table mat-table [dataSource]="verifiedCapFormulaList" #verifiedMatSort="matSort"
                                        matSort>

                                        <ng-container matColumnDef="factory">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Factory</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.factory}}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="name">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Chemical Name</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.name}}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="count">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Count</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.count}}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="consumption">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Consumption</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.consumption | number: '1.0-2'}}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="manufacturer">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Manufacturer</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.manufacturer}}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="source">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Source</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.source}}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="remediation">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Remediation</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.remediation}}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="deadline">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Deadline</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.deadline | date:'yyyy-MM-dd'}}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="usage">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Usage</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.usage}}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="cap_status">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>CAP Status</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.cap_status}}
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="verifiedTableColumns; sticky: true;"></tr>
                                        <tr mat-row *matRowDef="let row; columns: verifiedTableColumns;"
                                            [class.make-yellow]='row.type.startsWith("man")'></tr>

                                    </table>
                                </div>
                                <!-- <ngx-loading [show]="isLoadingVerifiedChemicals"></ngx-loading> -->
                                <!-- <loading-spinner *ngIf="isLoadingVerifiedChemicals"></loading-spinner> -->
                                <mat-paginator #verifiedMatPaginator="matPaginator" [pageSizeOptions]="[15]"
                                    showFirstLastButtons aria-label="Select page of periodic elements">
                                </mat-paginator>

                                <!-- </mat-card-content> -->
                                <!-- </mat-card> -->
                            </ng-template>
                        </mat-tab>
                        <mat-tab class="mat-card-subtitle" label="Commodity Chemicals">
                            <ng-template matTabContent>
                                <!-- <mat-card>
                                <mat-card-content> -->
                                <div class="unverified_chemicals">
                                    <table mat-table [dataSource]="basicCapFormulaList" #basicMatSort="matSort" matSort>

                                        <ng-container matColumnDef="factory">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Factory</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.factory}}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="name">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Chemical Name</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.name}}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="count">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Count</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.count}}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="consumption">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Consumption</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.consumption | number: '1.0-2'}}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="manufacturer">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Manufacturer</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.manufacturer}}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="source">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Source</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.source}}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="remediation">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Remediation</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.remediation}}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="deadline">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Deadline</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.deadline | date:'yyyy-MM-dd'}}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="usage">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Usage</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.usage}}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="cap_status">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>CAP Status</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.cap_status}}
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="basicTableColumns; sticky: true;"></tr>
                                        <tr mat-row *matRowDef="let row; columns: basicTableColumns;"
                                            [class.make-yellow]='row.type.startsWith("man")'></tr>

                                    </table>
                                </div>
                                <!-- <ngx-loading [show]="isLoadingBasicChemicals"></ngx-loading> -->
                                <!-- <loading-spinner *ngIf="isLoadingBasicChemicals"></loading-spinner> -->
                                <mat-paginator #basicMatPaginator="matPaginator" [pageSizeOptions]="[15]"
                                    showFirstLastButtons aria-label="Select page of periodic elements">
                                </mat-paginator>

                                <!-- </mat-card-content>
                            </mat-card> -->
                            </ng-template>
                        </mat-tab>
                    </mat-tab-group>
                </mat-card-content>

            </mat-card>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="2" [rowspan]="4">
            <mat-card class="charts">
                <button mat-icon-button id="more_button"  [matMenuTriggerFor]="performanceMenu">
                    <mat-icon svgIcon="mat_outline:more_vert"></mat-icon>
                </button>
                <mat-menu #performanceMenu="matMenu" id="more_menu">
                    <button mat-menu-item (click)="exportData(this.timeSeriesPerformance,'OverallPerformance')">
                        <mat-icon svgIcon="mat_outline:file_download"></mat-icon>
                        <span>Export Data</span>
                    </button>
                </mat-menu>
                <mat-card-subtitle>Overall Performance</mat-card-subtitle>
                <mat-card-content>
                    <!-- <ngx-loading [show]="isLoadingOverallPerformance"></ngx-loading> -->
                    <!-- <loading-spinner *ngIf="isLoadingOverallPerformance"></loading-spinner> -->
                    <div class="performanceChart">
                        <ngx-charts-bar-vertical (window:resize)="onResize($event)" [scheme]="{domain: ['#88bf4d']}"
                            [view]="view" [results]="timeSeriesPerformance" [xAxis]="true" [yAxis]="true"
                            [legend]="false" [showXAxisLabel]="true" [showYAxisLabel]="true" [xAxisLabel]="'Months'"
                            [yAxisLabel]="'Compliance'" [showDataLabel]="true" [showGridLines]="true"
                            [yAxisTickFormatting]="myYAxisTickFormatting" [roundDomains]="true">
                            <ng-template #tooltipTemplate let-model="model">
                                <div style="padding: 3px 8px; font-size: 13px;">
                                    <label style="color: #aeb4c7;">Month: </label><label style="color: white">
                                        &nbsp;{{model.name | date: 'yyyy-MM'}}</label><br>
                                    <label style="color: #aeb4c7;">Compliance: </label><label style="color: white;">
                                        &nbsp;{{model.value }}%</label>
                                </div>
                            </ng-template>
                        </ngx-charts-bar-vertical>
                    </div>
                </mat-card-content>
            </mat-card>
        </mat-grid-tile>
        <!-- <mat-grid-tile class="counts-tile" [colspan]="2" [rowspan]="2">
            <mat-card>
                <div class="info-cards">
                    <mat-card-title>{{connectedBrands}}</mat-card-title>
                    <mat-card-subtitle>Connected Brands</mat-card-subtitle>
                </div>
            </mat-card>
            <mat-card>
                <div class="info-cards">
                    <mat-card-title>{{distinctFormulaCount}}</mat-card-title>
                    <mat-card-subtitle>Distinct Formula Count</mat-card-subtitle>
                </div>
            </mat-card>
        </mat-grid-tile> -->
    </mat-grid-list>
    <ngx-loading [show]="isLoading" [config]="{fullScreenBackdrop: false}"></ngx-loading>
</div>

<div *ngIf="!isCubejsConnected" class="flex flex-col flex-auto min-w-0">
    <div class="flex-auto p-6 sm:p-10">
        <div class="content">
            <div class="flex flex-auto items-center justify-center w-full mb-2 min-h-32">
                    <span class="text-3xl font-bold leading-8" >
                        Analytics are currently not available due to maintenance. Will be back soon!
                    </span>
            </div>
        </div>
    </div>
</div>