import { SelectionModel } from '@angular/cdk/collections';
import { AfterContentInit, AfterViewInit, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSelect } from '@angular/material/select';
import { BhiveDashboardService } from '../bhive-dashboard.service';
import { TableFilterService } from './table-filter.service';


export const CONDITIONS_LIST = [
  { value: "is-equal", label: "Is equal" },
  { value: "is-not-equal", label: "Is not equal" },
  { value: "contains", label: "Contains" },
  { value: "does-not-contain", label: "Does not contain" }
];

export const CONDITIONS_FUNCTIONS = {
  // search method base on conditions list value
  "is-equal": function (value: any, filterdValue: any) {
    return value.toLowerCase() == filterdValue.toLowerCase();
  },
  "is-not-equal": function (value: any, filterdValue: any) {
    return value.toLowerCase() != filterdValue.toLowerCase();
  },
  "contains": function (value: any, filterdValue: any) {
    if (typeof value === 'string' && typeof filterdValue === 'string') {
      return value.toLowerCase().includes(filterdValue.toLowerCase());
    }
    return false;
  },
  "does-not-contain": function (value: any, filterdValue: any) {
    if (typeof value === 'string' && typeof filterdValue === 'string') {
      return !value.toLowerCase().includes(filterdValue.toLowerCase());
    }
    return false;
  },
};

@Component({
  selector: 'lib-table-filter-headers',
  templateUrl: './table-filter-headers.component.html',
})
export class TableFilterHeadersComponent implements OnInit {

  @Input() columnName: string = '';
  @Input() tableData: any;
  @Output() filterApplied = new EventEmitter<any[]>();
  public conditionsList = CONDITIONS_LIST;
  public filterValue: any ;
  public filterCondition: string = '';
  private _filterMethods = CONDITIONS_FUNCTIONS;
  // filteredItems: any[] = []; //results
  searchText: string = '';
  uniqueItems: any[]= [] ;
  selection = new SelectionModel<any>(true, []); //???
  // displayedColumns: string[] = ['select', 'value'];
  selectedValues: Set<any> = new Set<any>(); 
  // originalData: any[] = [];
  @ViewChild('select') select: MatSelect;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  currentSortDirection: string = '';
  isfilterApplied: boolean = false;
  selectAll: boolean = false;

  constructor(private cubejs: BhiveDashboardService, private filterService: TableFilterService) { }

  ngOnInit() {
    this.filterService.data$.subscribe(data => {
      if (data.length > 0 && data[0].hasOwnProperty(this.columnName)) {
        this.uniqueItems = this.getUniqueValues(data, this.columnName, this.selectedValues);
        this.synchronizeSelection();
      }
      // this.uniqueItems = this.filterService.getUniqueValuesForColumn(this.columnName);
    });
  }

  synchronizeSelection() {
    this.uniqueItems.forEach(item => {
      if (item.selected) {
        this.selection.select(item);
      }
    });
  }

  getUniqueValues(data: any[], column: string, currentSelections: Set<any>): any[] {
    const values = new Set(data.map(item => item[column]));
    return Array.from(values).map(value => ({ value, selected: currentSelections.has(value) }));
  }
  applyFilters() {
    // Update text/condition filters if any
    if (this.filterCondition && this.filterValue) {
      this.filterService.updateFilters(this.tableData, this.columnName, { condition: this.filterCondition, value: this.filterValue });
      this.isfilterApplied = true;
    } else {
      this.filterService.updateFilters(this.tableData, this.columnName, null);  // Clear filter for this column if condition or value is empty
    }

    // Update selections
    this.filterService.updateSelections(this.columnName, this.selectedValues);
    this.filterService.data$.subscribe(data => {
      this.filterApplied.emit(data);
      if (this.selectedValues.size > 0)
      this.isfilterApplied = this.selectedValues.size > 0 ? true : false;
      this.closeMenu();
    });
  }

  toggleSelection(value: any, isChecked: boolean) {
    if (isChecked) {
      this.selectedValues.add(value);
      const item = this.uniqueItems.find(item => item.value === value);
      if (item) {
        item.selected = true;
      }
    } else {
      this.selectedValues.delete(value);
      const item = this.uniqueItems.find(item => item.value === value);
      if (item) {
        item.selected = false;
      }
    }
  }

  clearFilters() {
    this.isfilterApplied = false;
    this.filterCondition = '';
    this.filterValue = null;
    this.selectedValues.clear();
    this.searchText = '';
    this.uniqueItems.forEach(v => v.selected = false);
    this.filterService.clearFilter(this.columnName);
    this.filterService.updateSelections(this.columnName, this.selectedValues);
    this.selection.clear(); 
  }

  onInputChange(event: any) {
    const searchInput = event.target.value.toLowerCase();
    if (searchInput == "") {
      this.uniqueItems = this.filterService.getUniqueValuesForColumn(this.columnName);
    }
    else {
      this.uniqueItems = this.filterService.getUniqueValuesForColumn(this.columnName).filter(({ value }) => {

        const prov = typeof (value) == 'number' ? value : value?.toLowerCase();
        return prov ? (typeof (value) == 'number' ? prov.toString().includes(searchInput) : prov.includes(searchInput)) : false;
      });
    }
  }

  isAllSelected() {
    return this.uniqueItems.length > 0 && this.uniqueItems.every(item => item.selected);
  }

  hasPartialSelection() {
    const numSelected = this.uniqueItems.filter(item => item.selected).length;
    return numSelected > 0 && numSelected < this.uniqueItems.length;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.uniqueItems.forEach((item: any) => {
        item.selected = false;
        this.selectedValues.delete(item.value);
      });
    } else {
      this.uniqueItems.forEach((item: any) => {
        item.selected = true;
        this.selectedValues.add(item.value);
      });
    }
  }
  

  
  closeMenu() {
    if (this.trigger) {
      this.trigger.closeMenu();
    }
  }

  sortData(direction: 'asc' | 'desc', columnName: string): void {
    this.currentSortDirection = direction;
    const sortDirection = this.currentSortDirection;

    this.filterService.sortData(columnName, sortDirection);
    this.filterService.data$.subscribe(data => {
      this.filterApplied.emit(data);
      this.closeMenu();

    })

    // this.dataSource._updateChangeSubscription();
  }

}


