import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

import { TranslateModule } from '@ngx-translate/core';
import { NgxLoadingModule } from 'ngx-loading';
import { OrganizationFfcNumberDialogComponent } from '../organization-ffc-number-dialog/organization-ffc-number-dialog.component';
import { ProductTypeOptionsComponent } from '../product-type-options/product-type-options.component';
import { ProfileSubComponent } from './profile-sub/profile-sub.component';
import { BrandFactoryConnCommonModule } from 'app/brand-factory-conn/brand-factory-conn-common/brand-factory-conn-common.module';



@NgModule({
  declarations: [
    ProfileSubComponent, 
    ProductTypeOptionsComponent,
    OrganizationFfcNumberDialogComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatTableModule,
    MatPaginatorModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatSelectModule,
    MatTabsModule,
    FormsModule,
    TranslateModule,
    MatButtonModule,
    NgxLoadingModule.forRoot({ fullScreenBackdrop: true }),
    MatAutocompleteModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    BrandFactoryConnCommonModule
  ],
  exports:
  [
    ProfileSubComponent
  ]
})
export class ProfileSubModule { }
