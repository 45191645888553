<div style="width: 100%;text-align: center;" [ngClass]="{'scrollable-overflow': isScrollable}" id="chartContainer">
  <div [ngStyle]="{width: setPieChartContainerWidth()}">
      <div *ngFor="let chart of chartData" class="container-fixed" [ngStyle]="{'width': containerWidth}">
        <div *ngIf="hasData(chart['chartData'])" style="text-align: left;">
          <ngx-charts-advanced-pie-chart (select)="onPieSliceSelect($event, chart.name, chart.id, commodities, startDate, endDate, chart.overallFilters, chart.brandFilters, supplierID, hasScopeCommodities)" class="chart-container" [view]="chartSize" [results]="chart['chartData']" [labels]="true" [customColors]="customColors"></ngx-charts-advanced-pie-chart>
        </div>
        <div *ngIf="!hasData(chart['chartData'])"  style="text-align: center; vertical-align: middle; display: table-cell;" [ngStyle]="{'height': containerWidth, 'width': containerWidth}">
          <div style="text-align: center;" i18n="Pie chart component no data message">
            {{"FORMULA_PIE_CHARTS_COMPONENT.DATA_UNAVAILABLE" | translate}} <br>
          </div>
        </div>
        <!-- <br /> -->
        <!-- <div class="numbers-div" i18n="Pie Charts Component pie chart subtitles">
          
          <small *ngIf="chart['chartType'] == 'yes-no'">
              {{"FORMULA_PIE_CHARTS_COMPONENT.YES" | translate}} / 
              {{"FORMULA_PIE_CHARTS_COMPONENT.NO" | translate}}
          </small>
          
          <small *ngIf="chart['chartType'] == 'yes-no-basic'">
              {{"FORMULA_PIE_CHARTS_COMPONENT.YES" | translate}} / 
              {{"FORMULA_PIE_CHARTS_COMPONENT.NO" | translate}} / 
              {{"FORMULA_PIE_CHARTS_COMPONENT.BASIC" | translate}}*
         </small>
          
          <small *ngIf="chart['chartType'] == 'in-check'"> 
              {{"FORMULA_PIE_CHARTS_COMPONENT.REGISTERED" | translate}} / 
              {{"FORMULA_PIE_CHARTS_COMPONENT.LEVEL_1" | translate}} / 
              {{"FORMULA_PIE_CHARTS_COMPONENT.LEVEL_2" | translate}} / 
              {{"FORMULA_PIE_CHARTS_COMPONENT.LEVEL_3" | translate}} / 
              {{"FORMULA_PIE_CHARTS_COMPONENT.ZDHC_V11" | translate}} / 
              {{"FORMULA_PIE_CHARTS_COMPONENT.OTHERS" | translate}}
          </small>
          
          <br />
          <small *ngIf="chart['chartType'] == 'yes-no'">
            <b>{{chart["chartData"][0]["value"]}}</b>  / 
            <b>{{chart["chartData"][1]["value"] }}**</b> 
            <br>
            {{chart["chartData"][0]["value"]/chart["totalValue"] | percent:'1.0-0' }} /
            {{chart["chartData"][1]["value"]/chart["totalValue"] | percent:'1.0-0'}}**
         </small>


          <small *ngIf="chart['chartType'] == 'yes-no-basic'">
            <b>{{chart["chartData"][0]["value"]}}</b> / 
            <b>{{chart["chartData"][1]["value"]}}</b> / 
            <b>{{chart["chartData"][2]["value"]}}</b> **
            <br>
            {{chart["chartData"][0]["value"]/chart["totalValue"] | percent:'1.0-0'}} /
            {{chart["chartData"][1]["value"]/chart["totalValue"] | percent:'1.0-0'}} /
            {{chart["chartData"][2]["value"]/chart["totalValue"] | percent:'1.0-0'}}**
          </small>   


          <small *ngIf="chart['chartType'] == 'in-check'">
            <b>{{chart["chartData"][0]["value"]}}</b> / 
            <b>{{chart["chartData"][1]["value"]}}</b> / 
            <b>{{chart["chartData"][2]["value"]}}</b> / 
            <b>{{chart["chartData"][3]["value"]}}</b> / 
            <b>{{chart["chartData"][4]["value"]}}</b> / 
            <b>{{chart["chartData"][5]["value"]}}</b> 
            <br>
            {{chart["chartData"][0]["value"]/chart["totalValue"] | percent:'1.0-0'}} /
            {{chart["chartData"][1]["value"]/chart["totalValue"] | percent:'1.0-0'}} /
            {{chart["chartData"][2]["value"]/chart["totalValue"] | percent:'1.0-0'}} /
            {{chart["chartData"][3]["value"]/chart["totalValue"] | percent:'1.0-0'}} /
            {{chart["chartData"][4]["value"]/chart["totalValue"] | percent:'1.0-0'}} /
            {{chart["chartData"][5]["value"]/chart["totalValue"] | percent:'1.0-0'}}
         </small>   

        </div> -->
        <!-- <br /> -->
        <span><strong>{{chart["complianceName"]}} <br> {{chart.id ? '(Scope is: ' + (chart.scope ? chart.scope : 'not defined') + ')' : ''}}</strong></span>

      </div>
  </div>
</div>
<div *ngIf="showBhiveDisclaimers">
    <br />
    <small *ngIf="!inCheck" i18n="Basic Chemical footnote | Pie chart component footnote for Basic Chemicals">* {{"FORMULA_PIE_CHARTS_COMPONENT.BASIC_CHEMICALS_FOOTNOTE" | translate}}</small>
    <br />
    <small i18n="Duplicate formulas footnote | Pie chart componentn footnote for duplicate formulas">** {{"FORMULA_PIE_CHARTS_COMPONENT.DUPLICATE_FORMULAS_FOOTNOTE" | translate}}</small>
    <br />
    <small *ngIf="isGateway">*** {{"FORMULA_PIE_CHARTS_COMPONENT.GATEWAY_LEVEL_FOOTNOTE" | translate}}</small>
</div>
<div *ngIf="showIncheckDisclaimers">
    <br />
    <small i18n="Basic Chemical footnote | Pie chart component footnote for Basic Chemicals">*{{"FORMULA_PIE_CHARTS_COMPONENT.ZDHC_FOOTNOTE" | translate}} </small>

</div>