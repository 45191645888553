import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AppComponent } from '../../app.component';
import { AuthenticationService } from '../../globals-services/authentication.service';
import { GlobalsService } from '../../globals-services/globals.service';
import { OrganizationType } from '../../globals-classes/OrganizationType';

@Injectable()
export class MtsLoginGuard implements CanActivate {

    constructor(private authenticationService: AuthenticationService,
        public globalsService: GlobalsService,
        private router: Router

    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        if (this.isMTSBrand) {
            return true;
        }
        this.router.navigate(['/dashboard'])
        return false;
    }
    get isMTSBrand(): boolean {


        if (this.globalsService.profile['chemIq'] === "mts" && this.isBrand) {

            return true;

        } else {

            return false;
        }
    }
    
  get isBrand(): boolean {

    //console.log(this.authenticationService.authenticationState);
    return this.globalsService.profile['organization_type'] == OrganizationType.BRAND;  //BRAND ORganization
  }
}
