<div class="flex flex-col flex-auto min-w-0">
    <!-- Main -->
    <div class="flex-auto p-6 sm:p-10">
        <!-- CONTENT GOES HERE -->
        <div>

            <div class="flex items-center justify-between w-full mb-4">
                <h2 class="text-3xl font-semibold tracking-tight leading-8"
                    i18n="Supplier Inventories Overview Component header">
                    {{
                    "SUPPLIER_INVENTORIES_OVERVIEW_COMPONENT.HEADER"
                    | translate
                    }}
                </h2>


                <button *ngIf="!amISupplier" class="choose-button" (click)="goBack()">
                    {{'HIGG_CERTIFICATION_LIST_COMPONENT.BUTTONS.BACK' | translate }} </button>

            </div>
            <div class="content">
                <div *ngIf="showErrorMessage" style="
                        background-color: tomato;
                        z-index: 1000;
                        position: fixed;
                        padding: 10px;
                        height: 40px;
                        display: block;
                        right: 58px;
                        top: 75px;
                    " i18n="
                        Supplier Inventories Overview Component no inventories
                        found message
                    ">
                    {{
                    "SUPPLIER_INVENTORIES_OVERVIEW_COMPONENT.NO_INVENTORIES_FOUND_MESSAGE"
                    | translate
                    }}
                </div>


                

                <!-- New -->

                <!-- Search Filter By Rajendra -->
                <div id="search-supplier" i18n="
                        Supplier Inventories Overview Component supplier select
                    ">
                    {{
                    "SUPPLIER_INVENTORIES_OVERVIEW_COMPONENT.SUPPLIER_SELECT"
                    | translate
                    }}

                    <input class="form-control" [matAutocomplete]="auto" (keyup)="searchThis($event.target.value)"
                        [(ngModel)]="selectedSupplierName" placeholder="{{
                            'SUPPLIER_INVENTORIES_OVERVIEW_COMPONENT.SUPPLIER_SELECT_PLACEHOLDER'
                                | translate
                        }}" />

                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSearchSupplierSelect()">
                        <mat-option *ngFor="let option of suggestedList | slice: 0:50" [value]="option.name">
                            <!-- {{option.name}} /{{option.countryName}}/{{option.identification}} -->
                            <li>
                                <strong>{{ option.name }}</strong>
                            </li>
                            <li>{{ option.countryName }}</li>
                            <li *ngIf="
                                    option.Higg != '' ||
                                    option.Oar != '' ||
                                    option.identification != ''
                                ">
                                <span *ngIf="option.Higg != ''">Higg :{{ option.Higg }} </span>&nbsp;
                                <span *ngIf="option.Oar != ''">OAR :{{ option.Oar }}</span>&nbsp;
                                <span *ngIf="option.identification != ''">Supplier:{{ option.identification
                                    }}</span>&nbsp;
                                <span *ngIf="option.FFC != ''">FFC:{{ option.FFC }}</span>&nbsp;
                            </li>
                            <li *ngIf="option.activity != ''">
                                Factory Type : {{ option.activity }}
                            </li>

                        </mat-option>

                        <mat-option></mat-option>
                    </mat-autocomplete>
                </div>

                <div id="year-select" i18n="Supplier Inventories Overview Component year select">
                    {{
                    "SUPPLIER_INVENTORIES_OVERVIEW_COMPONENT.YEAR_SELECT"
                    | translate
                    }}
                    <select class="form-control" (change)="onYearSelect()" [(ngModel)]="selectedYear">
                        <option value="none"></option>
                        <option *ngFor="let year of yearsToShow" [value]="year">
                            {{ year }}
                        </option>
                    </select>
                </div>
                <div id="month-select" i18n="Supplier Inventories Overview Component period select">
                    {{
                    "SUPPLIER_INVENTORIES_OVERVIEW_COMPONENT.PERIOD_SELECT"
                    | translate
                    }}
                    <select class="form-control" (change)="onMonthOrQuarterSelect()"
                        [(ngModel)]="selectedMonthOrQuarter">
                        <option value="none"></option>
                        <option *ngFor="let month of monthsToShow" [value]="month">
                            {{ month }}
                        </option>
                    </select>
                </div>

                <!-- New End -->

                <br /><br />
                <small i18n="
                        Basic Chemical footnote | Inventory component pie chart
                        footnote for Basic Chemicals
                    " [innerHTML]="
                        'SUPPLIER_INVENTORIES_OVERVIEW_COMPONENT.SELECT_FOOTNOTE'
                            | translate
                    "></small>
                <br />
                <hr />
                <div style="display: inline-block; width: 100%">
                    <h3 style="float: left" *ngIf="supplierIsSelected">
                        {{ selectedSupplierName }}
                    </h3>
                    <h3 *ngIf="
                            isMonth &&
                            inventoryStatus &&
                            allFieldsSelected() &&
                            !isLoading
                        " style="float: right" i18n="
                            Supplier Inventories Overview Component month
                            complete indicator
                        ">
                        <span class="greendot"></span>
                        {{
                        "SUPPLIER_INVENTORIES_OVERVIEW_COMPONENT.INVENTORY_COMPLETENESS.COMPLETE"
                        | translate
                        }}
                        <a #tooltip="matTooltip" matTooltip="{{
                                'SUPPLIER_INVENTORIES_OVERVIEW_COMPONENT.INVENTORY_COMPLETENESS.COMPLETE_TOOLTIP'
                                    | translate
                            }}" matTooltipPosition="below" style="text-decoration: none; font-size: xx-small">
                            <mat-icon class="info-icon">info_outline</mat-icon>
                        </a>
                    </h3>
                    <h3 *ngIf="
                            isMonth &&
                            !inventoryStatus &&
                            allFieldsSelected() &&
                            !isLoading
                        " style="float: right" i18n="
                            Supplier Inventories Overview Component month
                            incomplete indicator
                        ">
                        <span class="reddot"></span> &nbsp;
                        {{
                        "SUPPLIER_INVENTORIES_OVERVIEW_COMPONENT.INVENTORY_COMPLETENESS.INCOMPLETE"
                        | translate
                        }}
                        <a #tooltip="matTooltip" matTooltip="{{
                                'SUPPLIER_INVENTORIES_OVERVIEW_COMPONENT.INVENTORY_COMPLETENESS.INCOMPLETE_TOOLTIP'
                                    | translate
                            }}" matTooltipPosition="below" style="text-decoration: none; font-size: xx-small">
                            <mat-icon class="info-icon">info_outline</mat-icon>
                        </a>
                    </h3>
                </div>
                <h4 *ngIf="supplierIDAvailable" i18n="Supplier Inventories Supplier ID">
                    {{
                    "SUPPLIER_INVENTORIES_OVERVIEW_COMPONENT.SUPPLIER_ID"
                    | translate
                    }}
                    {{ supplierID }}
                </h4>
                <h4 *ngIf="supplierIsSelected" i18n="
                        Supplier Inventories Overview Component first scan last
                        scan
                    ">
                    {{
                    "SUPPLIER_INVENTORIES_OVERVIEW_COMPONENT.FIRST_SCAN"
                    | translate
                    }}
                    {{ firstInventoryDate | date }} -
                    {{
                    "SUPPLIER_INVENTORIES_OVERVIEW_COMPONENT.LATEST_SCAN"
                    | translate
                    }}
                    {{ latestInventoryDate | date }}
                </h4>
                <br />
                <div>
                    <table class="table table-striped tablefixed" id="formulaTable">
                        <thead i18n="
                                Supplier Inventories Overview Component formula
                                table header
                            ">
                            <tr>
                                <th>
                                    {{
                                    "SUPPLIER_INVENTORIES_OVERVIEW_COMPONENT.OVERVIEW_TABLE.HEADER.INVENTORY"
                                    | translate
                                    }}
                                </th>
                                <th>
                                    {{
                                    "SUPPLIER_INVENTORIES_OVERVIEW_COMPONENT.OVERVIEW_TABLE.HEADER.FORMULAS"
                                    | translate
                                    }}
                                </th>
                                <th>
                                    {{
                                    "SUPPLIER_INVENTORIES_OVERVIEW_COMPONENT.OVERVIEW_TABLE.HEADER.MAN_FORMULAS"
                                    | translate
                                    }}
                                </th>
                                <th>
                                    {{
                                    "SUPPLIER_INVENTORIES_OVERVIEW_COMPONENT.OVERVIEW_TABLE.HEADER.OVERALL_FORMULAS"
                                    | translate
                                    }}
                                </th>
                            </tr>
                        </thead>
                        <tbody *ngIf="isYear && allFieldsSelected() && !isLoading">
                            <tr>
                                <td>
                                    <a [routerLink]="
                                            '/inventories/yearSummary'
                                        " [queryParams]="{
                                            year: selectedYear,
                                            name: selectedSupplierName,
                                            creatorID: summary.creatorId,
                                            sharedSupplier:isSharedSupplier
                                        }" class="summary">{{
                                        "SUPPLIER_INVENTORIES_OVERVIEW_COMPONENT.OVERVIEW_TABLE.BODY.SUMMARY"
                                        | translate
                                        }}
                                        {{ selectedYear }}</a>
                                </td>
                                <td>{{ summary.numberOfFormulas }}</td>
                                <td>
                                    {{ summary.numberOfManuallyAddedFormulas }}
                                </td>
                                <td>
                                    {{
                                    summary.numberOfFormulas +
                                    summary.numberOfManuallyAddedFormulas
                                    }}
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="
                                isQuarter && allFieldsSelected() && !isLoading
                            ">
                            <tr>
                                <td>
                                    <a [routerLink]="
                                            '/inventories/quarterSummary'
                                        " [queryParams]="{
                                            year: selectedYear,
                                            name: selectedSupplierName,
                                            monthOrQuarter:
                                                quarterIndexMap[
                                                    selectedMonthOrQuarter
                                                ],
                                            creatorID: summary.creatorId,
                                            sharedSupplier:isSharedSupplier
                                        }" class="summary">{{
                                        "SUPPLIER_INVENTORIES_OVERVIEW_COMPONENT.OVERVIEW_TABLE.BODY.SUMMARY"
                                        | translate
                                        }}
                                        {{ selectedMonthOrQuarter }}
                                        {{ selectedYear }}</a>
                                </td>
                                <td>{{ summary.numberOfFormulas }}</td>
                                <td>
                                    {{ summary.numberOfManuallyAddedFormulas }}
                                </td>
                                <td>
                                    {{
                                    summary.numberOfFormulas +
                                    summary.numberOfManuallyAddedFormulas
                                    }}
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="isMonth && allFieldsSelected() && !isLoading">
                            <tr>
                                <td>
                                    <a [routerLink]="'/inventories/summary'" [queryParams]="{
                                            year: selectedYear,
                                            name: selectedSupplierName,
                                            monthOrQuarter:
                                                monthIndexMap[
                                                    selectedMonthOrQuarter
                                                ],
                                            creatorID: summary.creatorId,
                                            isSearchForm: true,
                                            sharedSupplier:isSharedSupplier
                                        }" class="summary">{{
                                        "SUPPLIER_INVENTORIES_OVERVIEW_COMPONENT.OVERVIEW_TABLE.BODY.SUMMARY"
                                        | translate
                                        }}
                                        {{ selectedMonthOrQuarter }}
                                        {{ selectedYear }}</a>
                                </td>
                                <td>{{ summary.numberOfFormulas }}</td>
                                <td>
                                    {{ summary.numberOfManuallyAddedFormulas }}
                                </td>
                                <td>
                                    {{
                                    summary.numberOfFormulas +
                                    summary.numberOfManuallyAddedFormulas
                                    }}
                                </td>
                            </tr>
                            <tr *ngFor="let inventory of inventoriesToShow">
                                <td>
                                    <a [routerLink]="'/inventories/inventory'" [queryParams]="{
                                            inventoryid: inventory.id,
                                            name: selectedSupplierName,
                                            creatorID: inventory.organization.id, 
                                            sharedSupplier:isSharedSupplier
                                        }">{{
                                        "SUPPLIER_INVENTORIES_OVERVIEW_COMPONENT.OVERVIEW_TABLE.BODY.INVENTORY_FROM"
                                        | translate
                                        }}
                                        {{ inventory.date | date }}</a>
                                </td>
                                <td>{{ inventory.numberOfFormulas }}</td>
                                <td>
                                    {{
                                    inventory.numberOfManuallyAddedFormulas
                                    }}
                                </td>
                                <td>
                                    {{
                                    inventory.numberOfFormulas +
                                    inventory.numberOfManuallyAddedFormulas
                                    }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <ngx-loading [show]="isLoading"></ngx-loading>
            </div>
        </div>
    </div>
</div>