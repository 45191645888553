import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastService } from 'app/common/toast-alert/toast.service';
import { GlobalsService } from 'app/globals-services/globals.service';
import { LanguageService } from 'app/language/language.service';

@Component({
  selector: 'app-brand-side-new-launch-dialog',
  templateUrl: './brand-side-new-launch-dialog.component.html',
  styleUrls: ['./brand-side-new-launch-dialog.component.scss']
})
export class BrandSideNewLaunchDialogComponent implements OnInit {
 

  constructor(
    public dialogRef: MatDialogRef<BrandSideNewLaunchDialogComponent>,
    private globalsService: GlobalsService,  
  ) { }


 


  ngOnInit(): void {
 

  }
  isChineseSystem() {
    return this.globalsService.serverLocation === 'China' ? true : false;
  }
 
}
