import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { GlobalsService } from '../../globals-services/globals.service';
import { DocumentcenterComponent } from '../documentcenter.component';

export interface ChemicalDocumentData {
    id?: number;
    issueDate?: Date;
    expiryDate?: Date;
    name?: string;
    comment?: string;
    category?: string;
    file?: File;
}

export interface SDSData {
    CHEMICAL_NAME?: string;
    MANUFACTURER_NAME?: string;
    CAS_NUMBERS?: String[];
    PHYSICAL_HAZARD_CODE?: String[];
    HEALTH_HAZARD_CODE?: String[];
    ADD_HAZARD_CODE?: String[];
    ENV_HAZARD_CODE?: String[];
    PREVENTION_P_CODE?: String[];
    GENERAL_P_CODE?: String[];
    STORAGE_P_CODE?: String[];
    DISPOSAL_P_CODE?: String[];
    RESPONSE_P_CODE?: String[];
}
@Component({
    selector: 'app-chemical-document-list',
    templateUrl: './chemical-document-list.component.html',
    styleUrls: ['./chemical-document-list.component.css'],
})
export class ChemicalDocumentListComponent implements OnInit {
    loading: boolean;
    documents: ChemicalDocumentData[] = [];

    documentTypes = [];
    documentTypesEnglish = {
        SDS: 'SDS',
        TDS: 'TDS',
        TEST: 'Test Report',
        SELF_DECLARATION: 'STeP Declaration Letter',
        CERTIFICATE: 'Certificate',
        AUDIT: 'Audit Report',
        POLICY: 'Policy',
        SOP: 'SOP',
        OTHER: 'Other',
    };


    isVerficationDoc = false



    constructor(
        public dialogRef: MatDialogRef<DocumentcenterComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
        private translateService: TranslateService,
        private http: HttpClient,
        private globalsService: GlobalsService,
        private fb: FormBuilder
    ) {
        // console.log(data);
        this.fetchTranslations();
    }

    ngOnInit() {
        // console.log(this.data)

        this.fetchDocuments();
    }



    close() {
        this.dialogRef.close(null);
    }

    addDocument() {
        this.dialogRef.close(this.data);
    }

    downloadDocument(id, viewPdfInViewer) {
        // console.log(id);
        this.http
            .get(this.globalsService.baseHref + '/getDocument/' + id, {
                responseType: 'blob',
            })
            .subscribe(
                (response) => {
                    // console.log(response);
                    let blob = new Blob([response], {
                        type: 'application/pdf',
                    });

                    var downloadURL = window.URL.createObjectURL(blob);
                    // if (viewPdfInViewer) {
                    //     this.pdfSrc = downloadURL;
                    // } else {
                    window.open(downloadURL);
                    // }
                },
                (err) => {
                    console.log(err);
                    alert('Error: Could not download document!');
                }
            );
    }

    deleteDocument(id) {
        let reqObj = { documentID: id };
        this.loading = true;
        // console.log(reqObj);

        this.http
            .post(this.globalsService.baseHref + '/deleteDocument', reqObj)
            .subscribe(
                (response) => {
                    // console.log(response);
                    this.fetchDocuments();
                },
                (err) => {
                    console.log(err);
                    alert('Error: Could not delete document!');
                    this.loading = false;
                }
            );
    }

    fetchDocuments() {
        this.loading = true;
        this.isVerficationDoc = this.data['isVerificationDoc']

        if (this.data['sdsOnly']) {
            this.http
                .post(this.globalsService.baseHref + '/getSdsDocumentList', {
                    masterId: this.data['masterid'],
                    msdsIdentifier: this.data['msdsIdentifier'],
                    creatorID: this.data['creatorID'],
                })
                .subscribe(
                    (response) => {
                        // console.log(response['data']);
                        this.loading = false;
                        this.documents = response['data'];
                        this.loading = false;
                    },
                    (err) => {
                        console.log(err);
                        this.loading = false;
                    }
                );
        } else {
            this.http
                .post(this.globalsService.baseHref + '/getCILDocumentList', {
                    masterId: this.data['masterid'],
                    msdsIdentifier: this.data['msdsIdentifier'],
                    creatorID: this.data['creatorID'],
                })
                .subscribe(
                    (response) => {
                        // console.log(response['data']);
                        this.loading = false;
                        this.documents = this.isVerficationDoc ? response['data'].filter(f => f.documentVerification == 1) : response['data'];

                    },
                    (err) => {
                        console.log(err);
                        this.loading = false;
                    }
                );
        }
    }

    translateDocumentType(value) {
        let documentType = this.documentTypes.find(
            (type) => type['english'] == value
        );
        // console.log(documentType);
        return documentType != null ? documentType['translation'] : "";
    }





    fetchTranslations() {
        this.translateService
            .get('DOCUMENT_UPLOAD_COMPONENT.TYPE_SELECT')
            .subscribe((response) => {
                // console.log(response);
                this.documentTypes = [
                    {
                        english: this.documentTypesEnglish['AUDIT'],
                        translation: response['AUDIT'],
                    },
                    {
                        english: this.documentTypesEnglish['CERTIFICATE'],
                        translation: response['CERTIFICATE'],
                    },
                    {
                        english: this.documentTypesEnglish['TEST'],
                        translation: response['TEST'],
                    },
                    {
                        english: this.documentTypesEnglish['POLICY'],
                        translation: response['POLICY'],
                    },
                    {
                        english: this.documentTypesEnglish['SOP'],
                        translation: response['SOP'],
                    },
                    {
                        english: this.documentTypesEnglish['SDS'],
                        translation: response['SDS'],
                    },
                    {
                        english: this.documentTypesEnglish['TDS'],
                        translation: response['TDS'],
                    },
                    {
                        english: this.documentTypesEnglish['SELF_DECLARATION'],
                        translation: response['SELF_DECLARATION'],
                    },
                    {
                        english: this.documentTypesEnglish['OTHER'],
                        translation: response['OTHER'],
                    },
                ];
            });
    }





}
