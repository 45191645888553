<div class="content">
    <div *ngIf="infoPage" class="confirmationForm">
        <div>
            <h1 class="text-3xl font-semibold tracking-tight leading-8" style="text-align: center;">{{ "GATEWAY_MODULE.SUBSCRIPTION_FORM_COMPONENT.INFO_HEADER" | translate }}</h1>
            <br><br>
            {{ "GATEWAY_MODULE.SUBSCRIPTION_FORM_COMPONENT.USER_AGREEMENT.FIRSTLINE" | translate }}
            <br><br>
            <i>{{ "GATEWAY_MODULE.SUBSCRIPTION_FORM_COMPONENT.USER_AGREEMENT.SECONDLINE" | translate }}</i>
            <br><br>
            <i>{{ "GATEWAY_MODULE.SUBSCRIPTION_FORM_COMPONENT.USER_AGREEMENT.THIRDLINE" | translate }}</i>
            <br><br>
            <i>{{ "GATEWAY_MODULE.SUBSCRIPTION_FORM_COMPONENT.USER_AGREEMENT.FOURTHLINE" | translate }}</i>
            <br><br>
            <i>{{ "GATEWAY_MODULE.SUBSCRIPTION_FORM_COMPONENT.USER_AGREEMENT.FIFTHLINE" | translate }}</i>
        </div>
        <br><br>
        <div>
            <button (click)="continue()" class="btn btn-lg btn-primary btn-block btn-sub centered-button">{{ "GATEWAY_MODULE.SUBSCRIPTION_FORM_COMPONENT.BUTTONS.CONTINUE" | translate }}</button>
            <button (click)="cancel()" class="btn btn-lg btn-block btn-sub centered-button">{{ "GATEWAY_MODULE.SUBSCRIPTION_FORM_COMPONENT.BUTTONS.CANCEL" | translate }}</button>
        </div>
    </div>
    <div *ngIf="!infoPage" class="subscriptionForm">
        <h1>{{ "GATEWAY_MODULE.SUBSCRIPTION_FORM_COMPONENT.FORM_HEADER" | translate }}</h1>
        <br><br>
        <form [formGroup]="subscriptionForm">
            <mat-form-field class="question">
                <mat-label>{{ "GATEWAY_MODULE.SUBSCRIPTION_FORM_COMPONENT.FORM_FIELDS.ZDHC_REGISTERED_ORGANIZATION_NAME" | translate }}</mat-label>
                <mat-select formControlName="organizationGUID" (selectionChange)="copyOrganizationName()">
                    <mat-option *ngFor="let org of gatewayOrganisations" [value]="org.OrgGUID">
                        {{org.Name}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="this.subscriptionForm.controls['organizationGUID'].invalid">{{ "GATEWAY_MODULE.SUBSCRIPTION_FORM_COMPONENT.FORM_FIELDS.OGRANIZATION_ERROR" | translate }}.</mat-error>
            </mat-form-field>
            <br><br>
            <mat-form-field class="question">
                <mat-label>{{ "GATEWAY_MODULE.SUBSCRIPTION_FORM_COMPONENT.FORM_FIELDS.CONTACT_EMAIL" | translate }}</mat-label>
                <input matInput placeholder="{{ 'GATEWAY_MODULE.SUBSCRIPTION_FORM_COMPONENT.FORM_FIELDS.CONTACT_EMAIL_PLACEHOLDER' | translate }}" formControlName="emailAddress" required>
            </mat-form-field>
            <br>
            <mat-form-field class="question">
                <mat-label>{{ "GATEWAY_MODULE.SUBSCRIPTION_FORM_COMPONENT.FORM_FIELDS.BILLING_ORGANIZATION" | translate }}</mat-label>
                <input matInput placeholder="{{ 'GATEWAY_MODULE.SUBSCRIPTION_FORM_COMPONENT.FORM_FIELDS.BILLING_ORGANIZATION_PLACEHOLDER' | translate }}" formControlName="billingOrganization" required>
            </mat-form-field>
            <br>
            <mat-form-field class="question">
                <mat-label>{{ "GATEWAY_MODULE.SUBSCRIPTION_FORM_COMPONENT.FORM_FIELDS.STREET_ADDRESS" | translate }}</mat-label>
                <input matInput placeholder="{{ 'GATEWAY_MODULE.SUBSCRIPTION_FORM_COMPONENT.FORM_FIELDS.STREET_ADDRESS_PLACEHOLDER' | translate }}" formControlName="billingAddressStreet" required>
            </mat-form-field>
            <br>
            <mat-form-field class="question">
                <mat-label>{{ "GATEWAY_MODULE.SUBSCRIPTION_FORM_COMPONENT.FORM_FIELDS.CITY" | translate }}</mat-label>
                <input matInput placeholder="{{ 'GATEWAY_MODULE.SUBSCRIPTION_FORM_COMPONENT.FORM_FIELDS.CITY_PLACEHOLDER' | translate }}" formControlName="billingAddressCity" required>
            </mat-form-field>
            <br>
            <mat-form-field class="question">
                <mat-label>{{ "GATEWAY_MODULE.SUBSCRIPTION_FORM_COMPONENT.FORM_FIELDS.STATE" | translate }}</mat-label>
                <input matInput placeholder="{{ 'GATEWAY_MODULE.SUBSCRIPTION_FORM_COMPONENT.FORM_FIELDS.STATE_PLACEHOLDER' | translate }}" formControlName="billingAddressState" required>
            </mat-form-field>
            <br>
            <mat-form-field class="question">
                <mat-label>{{ "GATEWAY_MODULE.SUBSCRIPTION_FORM_COMPONENT.FORM_FIELDS.ZIP_CODE" | translate }}</mat-label>
                <input matInput placeholder="{{ 'GATEWAY_MODULE.SUBSCRIPTION_FORM_COMPONENT.FORM_FIELDS.ZIP_CODE_PLACEHOLDER' | translate }}" formControlName="billingAddressZipcode" required>
            </mat-form-field>
            <br>
            <mat-form-field class="question">
                <mat-label>{{ "GATEWAY_MODULE.SUBSCRIPTION_FORM_COMPONENT.FORM_FIELDS.COUNTRY" | translate }}</mat-label>
                <mat-select formControlName="billingAddressCountry" required>
                    <mat-option *ngFor="let country of countries" [value]="country.name">{{country.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </form>
        <button (click)="apply()" class="btn btn-lg btn-primary btn-block btn-sub" [disabled]="!subscriptionForm.valid || subscriptionForm.untouched">{{ "GATEWAY_MODULE.SUBSCRIPTION_FORM_COMPONENT.BUTTONS.APPLY" | translate }}</button>
        <button (click)="cancel()"  class="btn btn-lg btn-block btn-sub">{{ "GATEWAY_MODULE.SUBSCRIPTION_FORM_COMPONENT.BUTTONS.CANCEL" | translate }}</button>
    </div>
</div>
