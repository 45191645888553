import { HttpClient } from '@angular/common/http';
import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { FormulaDetailsComponent } from '../formula-details/formula-details.component';
import { DistinctFormula } from '../globals-interface/distinctformula';
import { AuthenticationService } from '../globals-services/authentication.service';
import { GlobalsService } from '../globals-services/globals.service';
import { ChemicalDocumentListComponent } from './chemical-document-list/chemical-document-list.component';
import { ConsumptionDataUploadComponent } from './consumption-data-upload/consumption-data-upload.component';
import { DocumentUploadComponent } from './document-upload/document-upload.component';
import { WasteWaterComponent } from './waste-water/waste-water.component';

@Component({
    selector: 'app-documentcenter',
    templateUrl: './documentcenter.component.html',
    styleUrls: ['./documentcenter.component.css'],
})
export class DocumentcenterComponent implements OnInit, OnChanges {
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild('factoryChemicals', { static: true })
    factoryChemicals: MatExpansionPanel;
    @Input() dataSensitivity: number;

    fileToUpload: File;
    formula: any;
    dataSourceFormula = new MatTableDataSource<DistinctFormula>();

    private activatedRouteSubscription: Subscription;

    formOffset = -5;

    Files: string[] = [];
    UploadIDs: string[] = [];
    uploadForm: FormGroup;
    formBuilder: FormBuilder;
    showDataSaved = false;
    showSuccessMessage = false;
    showErrorMessage = false;
    showDeleteMessage = false;
    currentUploadID = -1;
    allowedUploadID = 0;
    currentFormulaName = null;
    currentFormulaManufacturer = null;
    creatorID = '';
    searchText = '';
    loading: boolean;
    documents = [];
    consumptionData = [];

    viewMode = false;

    selectedWaterSources = [];
    selectedWasteWaterTreatments = [];
    selectedEnergySources = [];
    waterSources = [];
    waterSourcesEnglish = {
        MUNICIPALITY: 'Municipality',
        SURFACE_WATER: 'Surface Water (River / Lake / etc)',
        GROUND_WATER: 'Groundwater',
        RECYCLED_WATER: 'Recycled Water',
    };
    wasteWaterTreatments = [];
    wasteWaterTreatmentsEnglish = {
        EXTERNALLY: 'Externally',
        INTERNALLY: 'Internally',
        PARTIALLY_INTERNALLY: 'Partially Internally & Externally',
    };
    energySources = [];


    energySourcesEnglish = {

        /*
        PURCHASED_ELECTRICITY: 'Purchased electricity/grid',
        NATURAL_GAS: 'Natural Gas',
        PURCHASED_STEAM: 'Purchased steam',
        OIL: 'Oil',
        LPG: 'LPG/ LNG',
        COAL: 'Coal',
        WOOD: 'Wood/ Wood Husk',
        DIESEL: 'Diesel',
        METHANE: 'Methane',
        BIOMASS: 'Biomass',
        SOLAR: 'Solar Power',
        WIND: 'Wind Power',
        */



        COAL: 'Coal',
        BIOMASS: 'Biomass',
        WOOD: 'Wood',
        DIESEL: 'Diesel',
        DIESEL_MOBILE: 'Diesel (Mobile)',
        BIO_DIESEL: 'Biodiesel',
        BIO_DIESEL_MOBILE: 'Biodiesel (Mobile)',
        PETROL: 'Petrol',
        PETROL_MOBILE: 'Petrol (Mobile)',
        FUEL_OIL: 'Fuel Oil',
        LPG_NEW : 'LPG',
        LPG_MOBILE: 'LPG (Mobile)',
        LIQUIFIED_NATURAL_GAS: 'Liquified Natural Gas',
        NATURAL_GAS: 'Natural Gas',
        PURCHASED_STEAM: 'Purchased steam',
        PURCHASED_ELECTRICITY_NON_RE: 'Purchased Electricity (non-Renewable)',
        PURCHASED_ELECTRICITY_RE: 'Purchased Electricity (Renewable)',
        PURCHASED_CHILLED_WATER: 'Purchased Chilled Water',
        PURCHASED_HOT_WATER: 'Purchased Hot Water',
        SOLAR_ON_SITE: 'Onsite Solar',
        WIND_ON_SITE: 'Onsite Wind',
        FABRIC_WASTE: 'Fabric Waste'





    };
    alertMsgs = {};

    displayedColumns = [
        'Name',
        'Manufacturer',
        'Source',
        'Document',
        'Actions',
    ];
    documentTypes = [];
    documentTypesEnglish = {
        SDS: 'SDS',
        TEST: 'Test Report',
        SELF_DECLARATION: 'STeP Declaration Letter',
        CERTIFICATE: 'Certificate',
        AUDIT: 'Audit Report',
        POLICY: 'Policy',
        SOP: 'SOP',
        OTHER: 'Other',
        INCHECK_REPORT: 'Performance InCheck Report',
    };

    selectedSubTabIndex = 0;
    isToShowTestWater: boolean = true;

    applyFilterFormula(event: any) {
        this.clearContent();
        this.searchText = event;
        this.dataSourceFormula.filter = event.trim().toLowerCase();
    }

    setupFilter(column1: string, column2: string) {
        // console.log(column1);
        this.dataSourceFormula.filterPredicate = (d: any, filter: string) => {
            const textToSearch1 =
                (d[column1] && d[column1].toLowerCase()) || '';
            const textToSearch2 =
                (d[column2] && d[column2].toLowerCase()) || '';
            return (
                textToSearch1.indexOf(filter) !== -1 ||
                textToSearch2.indexOf(filter) !== -1
            );
        };
    }
    step = 0;

    constructor(
        private globalsService: GlobalsService,
        private activatedRoute: ActivatedRoute,
        private authenticationService: AuthenticationService,
        private http: HttpClient,
        public dialog: MatDialog,
        private fb: FormBuilder,
        private translateService: TranslateService
    ) {
        this.uploadForm = this.fb.group({
            creatorid: '',
            masterid: '',
            pdf: '',
            manufacturer: '',
            name: '',
        });

        this.loading = true;

        this.fetchTranslations();
        this.translateService.onLangChange.subscribe(() => {
            // this.loading = true;
            this.fetchTranslations();
        });
    }
    ngOnChanges(changes: SimpleChanges): void {
        console.log(this.dataSensitivity);
    }

    ngOnInit() {
        console.log(this.globalsService.profile['energySource']);
        this.dataSourceFormula.paginator = this.paginator;

        this.activatedRouteSubscription = this.activatedRoute.params.subscribe(
            (params) => {
                this.creatorID = params.id;
                //console.log(this.creatorID)
                if (this.creatorID == null || this.creatorID == 'undefined') {
                    this.creatorID = this.globalsService.profile['id'];
                    //  console.log(this.creatorID)
                }
                this.checkBrandView();
                this.http
                    .get<DistinctFormula>(
                        this.globalsService.baseHref +
                        `/getAllFormula/${this.creatorID}`
                    )
                    .subscribe(
                        (distinctFormula) => {
                            this.formula = distinctFormula;
                            // console.log(this.formula);
                            this.dataSourceFormula = new MatTableDataSource(
                                this.formula.content
                            );
                            this.dataSourceFormula.paginator = this.paginator;
                            this.getQueryParams();
                            this.loading = false;
                        },
                        (err) => {
                            this.loading = false;
                            alert(err);
                        }
                    );
            }
        );

        if (this.dataSensitivity != null || this.dataSensitivity == undefined) {
            this.dataSensitivity =
                this.globalsService.profile['dataSensitivity'];
        }
    }
    setStep(index: number) {
        this.step = index;
    }
    getQueryParams() {
        this.activatedRouteSubscription =
            this.activatedRoute.queryParams.subscribe((params) => {
                const status = params['expandedChemicals'];
                if (status == 1) {
                    this.factoryChemicals.expanded = true;
                }
                if (params['subTabIndex'] != null) {
                    this.selectedSubTabIndex = params.subTabIndex;
                    //this.watermep.open();
                }
            });
    }

    checkBrandView() {
        // this.viewMode = !(this.authenticationService.authenticationState.jwtAuthenticationToken.roles.indexOf('WET_PROCESSING_UNIT') >= 0)

        this.viewMode = !(
            this.globalsService.profile['organization_type'] == 2
        ); //Supplier)

        if (this.viewMode) {
            this.displayedColumns = this.displayedColumns.filter(
                (column) => column != 'Actions'
            );

            this.http
                .get(
                    this.globalsService.baseHref +
                    '/getProfile/' +
                    this.creatorID
                )
                .subscribe(
                    (response) => {
                        // console.log(response);
                        if (response['energySource'] != null) {
                            this.selectedEnergySources = response[
                                'energySource'
                            ]
                                .toString()
                                .split(',')
                                .filter((source) => {
                                    return source;
                                }); // eliminate empty strings from array
                        }
                        if (response['wasteWaterTreatment'] != null) {
                            this.selectedWasteWaterTreatments = response[
                                'wasteWaterTreatment'
                            ]
                                .toString()
                                .split(',')
                                .filter((treatment) => {
                                    return treatment;
                                }); // eliminate empty strings from array
                            this.checkForWastewaterExpToShow();
                        }


                        if (response['waterSource']) {
                            this.selectedWaterSources = response['waterSource']
                                .toString()
                                .split(',')
                                .filter((source) => {
                                    return source;
                                }); // eliminate empty strings from array

                        }


                    },
                    (err) => {
                        console.log(err);
                        this.loading = false;
                    }
                );
        } else {
            if (this.globalsService.profile['energySource'] != null) {
                this.selectedEnergySources = this.globalsService.profile[
                    'energySource'
                ]
                    .toString()
                    .split(',')
                    .filter((source) => {
                        return source;
                    }); // eliminate empty strings from array
            }
            if (this.globalsService.profile['wasteWaterTreatment'] != null) {
                this.selectedWasteWaterTreatments = this.globalsService.profile[
                    'wasteWaterTreatment'
                ]
                    .toString()
                    .split(',')
                    .filter((treatment) => {
                        return treatment;
                    }); // eliminate empty strings from array
                this.checkForWastewaterExpToShow()
            }
            if (this.globalsService.profile['waterSource']) {
                this.selectedWaterSources = this.globalsService.profile[
                    'waterSource'
                ]
                    .toString()
                    .split(',')
                    .filter((source) => {
                        return source;
                    }); // eliminate empty strings from array

            }
        }
    }
    checkForWastewaterExpToShow() {

        if (this.selectedWasteWaterTreatments.includes('Externally') || this.selectedWasteWaterTreatments.includes('Partially Internally & Externally')) {
            this.isToShowTestWater = false;
        }
    }

    loadMSDS(masterid: number, name: string, manufacturer: string, event: any) {
        this.showSuccessMessage = false;
        this.showDeleteMessage = false;
        this.showErrorMessage = false;
        var f = event.target.files[0]; // FileList object
        var reader = new FileReader();
        let size = event.target.files[0].size;
        this.currentUploadID = masterid;
        this.currentFormulaName = name;
        this.currentFormulaManufacturer = manufacturer;

        //check for file size
        if (size > 2097152) {
            this.showDeleteMessage = false;
            this.showErrorMessage = true;
            this.allowedUploadID = -1;
            const elements = document.getElementsByTagName('input');
            for (var ii = 0; ii < elements.length; ii++) {
                if (elements[ii].type.toLowerCase() == 'file') {
                    elements[ii].value = '';
                    this.currentUploadID = -1;
                    this.currentFormulaName = null;
                    this.currentFormulaManufacturer = null;
                    this.allowedUploadID = 0;
                }
            }
        } else {
            this.showDeleteMessage = false;
            this.showErrorMessage = false;

            //Ready for upload
            this.allowedUploadID = 0;
            //    console.log(masterid);
            //    console.log(this.creatorID);
            this.uploadForm.get('creatorid').setValue(this.creatorID);
            this.uploadForm.get('masterid').setValue(masterid);
            this.uploadForm.get('manufacturer').setValue(manufacturer);
            this.uploadForm.get('name').setValue(name);
            reader.readAsDataURL(f);
            //convert the file once it is fully read
            reader.onload = (e) => {
                const arraybuffer = e['target']['result'];
                this.uploadForm.get('pdf').setValue(arraybuffer);
            };
        }
    }

    uploadMSDS(element: any) {
        this.showSuccessMessage = false;
        this.showDeleteMessage = false;
        this.showErrorMessage = false;
        // console.log(this.uploadForm.get('creatorid').value);
        // console.log(this.uploadForm.get('masterid').value);
        // console.log(this.uploadForm.value);
        // tslint:disable-next-line: max-line-length
        this.http
            .put(
                this.globalsService.baseHref + '/uploadMSDSPDF',
                this.uploadForm.value
            )
            .subscribe(
                (res) => {
                    this.showSuccessMessage = true;
                    this.showDeleteMessage = false;
                    this.currentUploadID = -1;
                    this.currentFormulaName = null;
                    this.currentFormulaManufacturer = null;
                    const data = this.dataSourceFormula.data;
                    const index = this.formula.content.indexOf(element);
                    // console.log(index);
                    data[index].containsmsds = 'true';
                    this.dataSourceFormula.data = data;
                },
                (err) => {
                    console.log(err);
                }
            );
        this.clearContent();
    }

    downloadMSDS(masterid: number, name: string, manufacturer: string) {
        this.showSuccessMessage = false;
        this.showDeleteMessage = false;
        this.showErrorMessage = false;
        this.http
            .get(
                this.globalsService.baseHref +
                '/getMSDSpdf/' +
                masterid +
                '/' +
                this.creatorID +
                '/' +
                this.removeSpecialChars(name) +
                '/' +
                this.removeSpecialChars(manufacturer),
                { responseType: 'blob' }
            )
            .subscribe(
                (res) => {
                    let blob = new Blob([res], { type: 'application/pdf' });

                    var downloadURL = window.URL.createObjectURL(blob);
                    window.open(downloadURL);
                    // const base64 = 'data:application/pdf;base64,' + res.content;
                    // const linkSource = base64;
                    // const downloadLink = document.createElement("a");
                    // let type = "pdf";

                    // const fileName =  name + "_SDS_Document." + type;
                    // //open new window
                    // downloadLink.href = linkSource;
                    // downloadLink.download = fileName;
                    // downloadLink.click();
                },
                (err) => console.log(err)
            );
    }

    deleteMSDS(element: any) {
        this.showSuccessMessage = false;
        this.showDeleteMessage = false;
        this.showErrorMessage = false;
        this.http
            .delete(
                this.globalsService.baseHref +
                '/deleteMSDSpdf/' +
                element.masterid +
                '/' +
                this.creatorID +
                '/' +
                this.removeSpecialChars(element.name) +
                '/' +
                this.removeSpecialChars(element.manufacturer)
            )
            .subscribe((res) => {
                // console.log(res);
                this.showDeleteMessage = true;
                const data = this.dataSourceFormula.data;
                const index = this.formula.content.indexOf(element);
                data[index].containsmsds = 'false';
                this.dataSourceFormula.data = data;
            });
    }

    checkMSDS(value: string) {
        if (value === 'true') {
            return false;
        } else {
            return true;
        }
    }

    checkFormID(id: number, name: string) {
        if (id === this.currentUploadID) {
            // checks if this is the current formula where the document is loaded
            return false;
        } else if (
            this.currentUploadID === -1 &&
            this.currentFormulaName === null
        ) {
            // checks if this is the initial state
            return false;
        } else {
            return true;
        }
    }

    checkUpload(id: number, name: string, manufacturer: string) {
        // checks if this is the current formula where the document is loaded
        if (
            id === this.currentUploadID &&
            this.allowedUploadID === 0 &&
            this.currentFormulaName === name &&
            this.currentFormulaManufacturer === manufacturer
        ) {
            return true;
        } else {
            return false;
        }
    }

    clearContent() {
        this.showSuccessMessage = false;
        this.showDeleteMessage = false;
        this.showErrorMessage = false;
        this.currentUploadID = -1;
        this.currentFormulaName = null;
        this.currentFormulaManufacturer = null;
        this.allowedUploadID = 0;
        this.showErrorMessage = false;
        var elements = document.getElementsByTagName('input');
        for (var ii = 0; ii < elements.length; ii++) {
            if (elements[ii].type.toLowerCase() == 'file') {
                elements[ii].value = '';
            }
        }
    }

    deleteDocument(id, topic) {
        let reqObj = { documentID: id };
        this.loading = true;
        // console.log(reqObj);

        this.http
            .post(
                this.globalsService.baseHref + '/deleteFacilityDocument',
                reqObj
            )
            .subscribe(
                (response) => {
                    // console.log(response);
                    this.fetchDocuments(false, true, topic);
                },
                (err) => {
                    console.log(err);
                    alert(this.alertMsgs['DELETE_ERROR']);
                    this.loading = false;
                }
            );
    }

    deleteConsumptionDocument(id, topic) {
        let reqObj = { higgID: id };
        this.loading = true;
        // console.log(reqObj);

        this.http
            .post(this.globalsService.baseHref + '/deleteHiggDocument', reqObj)
            .subscribe(
                (response) => {
                    // console.log(response);
                    this.fetchConsumptionData(topic);
                },
                (err) => {
                    console.log(err);
                    alert(this.alertMsgs['DELETE_ERROR']);
                    this.loading = false;
                }
            );
    }

    downloadDocument(id) {
        this.http
            .get(this.globalsService.baseHref + '/getFacilityDocument/' + id, {
                responseType: 'blob',
            })
            .subscribe(
                (response) => {
                    // console.log(response);
                    let blob = new Blob([response], {
                        type: 'application/pdf',
                    });

                    var downloadURL = window.URL.createObjectURL(blob);
                    window.open(downloadURL);
                },
                (err) => {
                    console.log(err);
                    alert(this.alertMsgs['DOWNLOAD_ERROR']);
                }
            );
    }

    downloadConsumptionDocument(id) {
        this.http
            .get(
                this.globalsService.baseHref +
                '/getHiggDocument/' +
                id +
                '/' +
                this.creatorID,
                { responseType: 'blob' }
            )
            .subscribe(
                (response) => {
                    // console.log(response);
                    let blob = new Blob([response], {
                        type: 'application/pdf',
                    });

                    var downloadURL = window.URL.createObjectURL(blob);
                    window.open(downloadURL);
                },
                (err) => {
                    console.log(err);
                    alert(this.alertMsgs['DOWNLOAD_ERROR']);
                }
            );
    }

    fetchDocuments(startLoading: boolean, dismissLoading: boolean, topic) {
        let reqObj = { topic: topic, creatorID: this.creatorID };
        this.loading = startLoading ? true : this.loading;
        if (topic !== 'WasteWater') {
            this.http
                .post(
                    this.globalsService.baseHref + '/getFacilityDocumentList',
                    reqObj
                )
                .subscribe(
                    (documents) => {
                        this.documents[topic] = documents['data'];
                        this.loading = dismissLoading ? false : this.loading;
                        // console.log(this.documents);
                    },
                    (err) => {
                        console.log(err);
                        this.loading = dismissLoading ? false : this.loading;
                    }
                );
        } else if (topic == 'WasteWater') {
            this.http
                .get(
                    this.globalsService.baseHref +
                    '/getWasteWaterReport/' +
                    this.creatorID
                )
                .subscribe(
                    (documents) => {
                        this.documents[topic] = documents['data'];
                        this.loading = dismissLoading ? false : this.loading;
                        // console.log(this.documents);
                    },
                    (err) => {
                        console.log(err);
                        this.loading = dismissLoading ? false : this.loading;
                    }
                );
        }
    }

    fetchConsumptionData(topic) {
        let reqObj = { topic: topic, creatorID: this.creatorID };
        this.loading = true;

        this.http
            .post(this.globalsService.baseHref + '/getHiggDocumentList', reqObj)
            .subscribe(
                (documents) => {
                    // console.log(documents);
                    this.consumptionData[topic] = documents['data'];
                    this.loading = false;
                    // console.log(this.consumptionData);
                },
                (err) => {
                    console.log(err);
                    this.loading = false;
                }
            );
    }

    openConsumptionUploadDialog(topic) {
        let consumptionData = this.initConsumption();
        let sources = [];

        if (topic == 'Water') {
            sources = this.waterSources;
        } else if (topic == 'Energy') {
            sources = this.energySources;
        }

        consumptionData['topic'] = topic;
        const dialogRef = this.dialog.open(ConsumptionDataUploadComponent, {
            width: '60%',
            height: '70%',
            data: {
                consumption: consumptionData,
                sources: sources,
            },
        });

        dialogRef.afterClosed().subscribe((consumptionData) => {
            // console.log(document);
            // console.log(document["issueDate"].getTime(), document["expiryDate"].getTime());
            if (consumptionData) {
                let formData = new FormData();
                this.loading = true;

                formData.append('year', consumptionData['year']);
                formData.append('consumption', consumptionData['amount']);
                formData.append(
                    'consumptionUnit',
                    consumptionData['amountUnit']
                );
                formData.append('timeUnit', consumptionData['timeUnit']);
                formData.append('source', consumptionData['source']['english']);
                formData.append('topic', consumptionData['topic']);
                formData.append('comment', consumptionData['comment']);
                formData.append('file', consumptionData['file']);

                // console.log(formData);
                // formData.forEach( (value, key, parent) => {
                //   console.log( key, value);
                // });

                this.http
                    .post<any>(
                        this.globalsService.baseHref + '/uploadHiggDocument',
                        formData
                    )
                    .subscribe(
                        (response) => {
                            // console.log(response);
                            this.fetchConsumptionData(consumptionData['topic']);
                        },
                        (err) => {
                            console.log(err);
                            this.loading = false;
                        }
                    );

                this.loading = false;
            }
        });
    }

    openWasteWaterUploadDialog() {
        let consumptionData = this.initConsumption();

        consumptionData['topic'] = 'WasteWater';
        const dialogRef = this.dialog.open(WasteWaterComponent, {
            width: '95%',
            height: '95%',
            disableClose: true,
            data: {
                consumption: consumptionData,
                viewMode: false,
                id: null, //if on view mode the document id must be provided
                page: null, //if the page the user want to see
            },
        });

        dialogRef.afterClosed().subscribe((document) => {
            if (document) {
                let formData = new FormData();
                this.loading = true;

                console.log(document);

                //header information
                formData.append('user_id', this.creatorID);
                formData.append('topic', 'WasteWater');
                formData.append('file', document['file']);

                //data information
                formData.append('name', document['name']);
                formData.append('expiry', document['expiry']);
                formData.append('testingLab', document['testingLab']);
                formData.append(
                    'testingLabOthers',
                    document['testingLabOthers']
                );
                formData.append(
                    'samplingLocation',
                    document['samplingLocation']
                );
                formData.append(
                    'typeOfWaterDischarge',
                    document['typeOfWaterDischarge']
                );
                formData.append('samplingDate', document['samplingDate']);
                formData.append('testingDate', document['testingDate']);
                formData.append('issueDate', document['issueDate']);
                formData.append('passfail', document['passfail']);
                formData.append('comment', document['comment']);
                formData.append('documentID', document['documentID']);
                formData.append(
                    'conventionalSumParameters',
                    JSON.stringify(document['conventionalSumParameters'])
                );
                formData.append(
                    'conventionalAnions',
                    JSON.stringify(document['conventionalAnions'])
                );
                formData.append(
                    'conventionalMetals',
                    JSON.stringify(document['conventionalMetals'])
                );
                formData.append(
                    'zdhcMrslParameters',
                    JSON.stringify(document['zdhcMrslParameters'])
                );

                // console.log(JSON.stringify(document["conventionalSumParameters"]))

                this.http
                    .post<any>(
                        this.globalsService.baseHref +
                        '/createWasteWaterReport',
                        formData
                    )
                    .subscribe(
                        (response) => {
                            this.fetchDocuments(false, true, document['topic']);
                        },
                        (err) => {
                            console.log(err);
                            this.loading = false;
                        }
                    );
            }
        });
    }

    openDocumentUploadDialog(topic) {
        let document = this.initDocument();
        // console.log(document);
        document['topic'] = topic;
        let testWater = false;
        if (topic == 'Water') {
            testWater = true;
        }

        const dialogRef = this.dialog.open(DocumentUploadComponent, {
            width: '50%',
            height: '50%',
            data: {
                document: document,
                testWater: testWater,
            },
        });

        dialogRef.afterClosed().subscribe((document) => {
            // console.log(document);
            // console.log(document["issueDate"].getTime(), document["expiryDate"].getTime());
            if (document) {
                let formData = new FormData();
                this.loading = true;

                formData.append('name', document['name']);
                formData.append('category', document['category']);
                formData.append('topic', document['topic']);
                formData.append('passfail', document['passFail']);
                formData.append('issue', document['issueDate'].getTime());
                // formData.append('expiry', document["expiryDate"] ? document["expiry"].getTime(): '');
                formData.append(
                    'expiry',
                    document['expiryDate']
                        ? document['expiryDate'].getTime()
                        : ''
                );
                formData.append('comment', document['comment']);
                formData.append('file', document['file']);

                // formData.forEach( (value, key, parent) => {
                //   console.log( key, value);
                // });

                this.http
                    .post<any>(
                        this.globalsService.baseHref +
                        '/uploadFacilityDocument',
                        formData
                    )
                    .subscribe(
                        (response) => {
                            // console.log(response);
                            this.fetchDocuments(false, true, document['topic']);
                        },
                        (err) => {
                            console.log(err);
                            this.loading = false;
                        }
                    );
            }
        });
    }

    openChemicalDocumentUploadDialog(data) {
        let document = this.initDocument();
        document.masterId = data['masterid'];
        document.msdsIdentifier = data['msdsIdentifier'];
        // console.log(document);

        console.log(document);
        const dialogRef = this.dialog.open(DocumentUploadComponent, {
            width: '80%',
            height: '80%',
            data: {
                document: document,
                testWater: false,
            },
        });

        dialogRef.afterClosed().subscribe((document) => {
            // console.log(document);
            // console.log(document["issueDate"].getTime(), document["expiryDate"].getTime());
            if (document) {
                let formData = new FormData();
                this.loading = true;

                formData.append('name', document['name']);
                formData.append('category', document['category']);

                if (document['msdsIdentifier'] != 'none') {
                    formData.append(
                        'msdsIdentifier',
                        document['msdsIdentifier']
                    );
                } else if (document['masterId'] != '-1') {
                    formData.append('masterId', document['masterId']);
                }

                formData.append('issue', document['issueDate'].getTime());
                formData.append(
                    'expiry',
                    document['expiryDate']
                        ? document['expiryDate'].getTime()
                        : ''
                );
                formData.append('comment', document['comment']);
                formData.append('file', document['file']);
                formData.append('documentUploadedFrom', 'document center');
                // formData.forEach( (value, key, parent) => {
                //   console.log( key, value);
                // });

                this.http
                    .post<any>(
                        this.globalsService.baseHref + '/uploadCILDocument',
                        formData
                    )
                    .subscribe(
                        (response) => {
                            // console.log(response);
                            this.viewChemicalDocuments(data);
                            this.loading = false;
                        },
                        (err) => {
                            console.log(err);
                            this.loading = false;
                        }
                    );
                // this.loading = false;
            }
        });
    }

    openDialog(element: any) {
        this.showSuccessMessage = false;
        const dialogRef = this.dialog.open(FormulaDetailsComponent, {
            width: '90%',
            height: '100%',
            disableClose: true,
            autoFocus: true,
            data: {
                name: element.name,
                manufacturer: element.manufacturer,
                cas_nr: element.cas_nr,
                containsmsds: element.containsmsds,
                ghs_classification: element.ghs_classification,
                ghs_hazard_statement: element.ghs_hazard_statement,
                masterid: element.masterid,
                notes: element.notes,
                precautionary_statement: element.precautionary_statement,
                useofppe: element.useofppe,
                refcas_nr: element.cas_nr,
                refnotes: element.notes,
                refprecautionary_statement: element.precautionary_statement,
                refuseofppe: element.useofppe,
                refghs_classification: element.ghs_classification,
                refghs_hazard_statement: element.ghs_hazard_statement,
                refghs_msds_available: element.ghs_msds_available,
                user_cas_nr: element.user_cas_nr,
                user_notes: element.user_notes,
                user_precautionary_statement:
                    element.user_precautionary_statement,
                user_use_ofppe: element.user_use_ofppe,
                userghsclassification: element.userghsclassification,
                userghshazard_statement: element.userghshazard_statement,
                userghsmsds_available: element.userghsmsds_available,
                ghs_msds_available: element.ghs_msds_available,
                application: element.application,
                user_application: element.user_application,
                msds_identifier: element.msds_identifier,
                creatorID: this.creatorID,
                readonly: false,
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result !== null) {
                this.showSuccessMessage = true;
                const data = this.dataSourceFormula.data;
                const index = this.formula.content.indexOf(element);
                //Assignment of returned value to the current Mat Table
                element.user_cas_nr = result.user_cas_nr;
                element.user_notes = result.user_notes;
                element.user_precautionary_statement =
                    result.user_precautionary_statement;
                element.user_use_ofppe = result.user_use_ofppe;
                element.userghsclassification = result.userghsclassification;
                element.userghshazard_statement =
                    result.userghshazard_statement;
                element.ghs_msds_available = result.ghs_msds_available;
                element.userghsmsds_available = result.userghsmsds_available;
                element.user_application = result.user_application;
                data[index] = element;
                this.dataSourceFormula.data = data;
            }
        });
    }

    viewChemicalDocuments(chemical) {
        // console.log(chemical);
        const dialogRef = this.dialog.open(ChemicalDocumentListComponent, {
            width: '90%',
            height: '90%',
            data: {
                id: chemical['id'],
                name: chemical['name'],
                manufacturer: chemical['manufacturer'],
                masterid: chemical['masterid'],
                msdsIdentifier: chemical['msds_identifier']
                    ? chemical['msds_identifier']
                    : chemical['msdsIdentifier'],
                readonly: this.viewMode,
                creatorID: this.creatorID,
            },
        });

        dialogRef.afterClosed().subscribe((data) => {
            // console.log(data);

            if (data) {
                this.openChemicalDocumentUploadDialog(data);
            }
        });
    }

    removeSpecialChars(string: String) {
        return string
            .replace('/', '')
            .replace('-', '')
            .replace('%', '')
            .replace('(', '')
            .replace(')', '');
    }

    saveWaterData() {
        let reqObj = {};

        reqObj = { waterSource: this.selectedWaterSources.join(',') };

        this.http
            .put(this.globalsService.baseHref + '/saveWaterData', reqObj)
            .subscribe((response) => {
                this.globalsService.whoAmI().subscribe(
                    (response) => {
                        this.globalsService.profile = response;

                        this.loading = false;
                        this.showDataSaved = true;

                        setTimeout(() => (this.showDataSaved = false), 1000);
                    },

                    (err) => {
                        console.log(err);
                        this.loading = false;
                    }
                );
            });
    }

    saveWastewaterData() {
        let reqObj = {};
        this.checkForWastewaterExpToShow();
        alert()
        reqObj = {
            wasteWaterTreatment: this.selectedWasteWaterTreatments.join(','),
        };

        this.http
            .put(this.globalsService.baseHref + '/saveWastewaterData', reqObj)
            .subscribe((response) => {
                this.globalsService.whoAmI().subscribe(
                    (response) => {
                        this.globalsService.profile = response;

                        this.loading = false;
                        this.showDataSaved = true;

                        setTimeout(() => (this.showDataSaved = false), 1000);
                    },
                    (err) => {
                        console.log(err);
                        this.loading = false;
                    }
                );
            });
    }

    saveEnergyData() {
        let reqObj = {};

        reqObj = { energySource: this.selectedEnergySources.join(',') };

        this.http
            .put(this.globalsService.baseHref + '/saveEnergyData', reqObj)
            .subscribe((response) => {
                this.globalsService.whoAmI().subscribe(
                    (response) => {
                        this.globalsService.profile = response;

                        this.loading = false;

                        this.showDataSaved = true;

                        setTimeout(() => (this.showDataSaved = false), 1000);
                    },

                    (err) => {
                        console.log(err);
                        this.loading = false;
                    }
                );
            });
    }

    initDocument() {
        return {
            issueDate: undefined,
            expiryDate: undefined,
            name: '',
            passFail: undefined,
            comment: '',
            category: '',
            topic: '',
            masterId: '-1',
            msdsIdentifier: 'none',
            file: null,
        };
    }

    initConsumption() {
        return {
            year: '',
            amount: '',
            amountUnit: '',
            timeUnit: '',
            source: '',
            comment: '',
            topic: '',
            file: null,
        };
    }

    convertPassfail(value) {
        if (value == true) {
            return 'Passed';
        } else if (value == false) {
            return 'Failed';
        } else if (value == null) {
            return 'N.A.';
        }
    }

    translateDocumentType(value) {
        let documentType = this.documentTypes.find(
            (type) => type['english'] == value
        );
        // console.log(documentType);
        return documentType['translation'];
    }

    fetchTranslations() {
        this.translateService
            .get('DOCUMENT_CENTER_COMPONENT.WATER_DATA')
            .subscribe((response) => {
                this.waterSources = [
                    {
                        english: this.waterSourcesEnglish['MUNICIPALITY'],
                        translation: response['WATER_SOURCE']['MUNICIPALITY'],
                    },
                    {
                        english: this.waterSourcesEnglish['SURFACE_WATER'],
                        translation: response['WATER_SOURCE']['SURFACE_WATER'],
                    },
                    {
                        english: this.waterSourcesEnglish['GROUND_WATER'],
                        translation: response['WATER_SOURCE']['GROUND_WATER'],
                    },
                    {
                        english: this.waterSourcesEnglish['RECYCLED_WATER'],
                        translation: response['WATER_SOURCE']['RECYCLED_WATER'],
                    },
                ];
                this.wasteWaterTreatments = [
                    {
                        english: this.wasteWaterTreatmentsEnglish['EXTERNALLY'],
                        translation: response['WASTE_WATER']['EXTERNALLY'],
                    },
                    {
                        english: this.wasteWaterTreatmentsEnglish['INTERNALLY'],
                        translation: response['WASTE_WATER']['INTERNALLY'],
                    },
                    {
                        english:
                            this.wasteWaterTreatmentsEnglish[
                            'PARTIALLY_INTERNALLY'
                            ],
                        translation:
                            response['WASTE_WATER']['PARTIALLY_INTERNALLY'],
                    },
                ];
            });
        this.translateService
            .get('DOCUMENT_CENTER_COMPONENT.ENERGY_DATA.ENERGY_SOURCE')
            .subscribe((response) => {
                this.energySources = [
                    {
                        english:
                            this.energySourcesEnglish['PURCHASED_ELECTRICITY'],
                        translation: response['PURCHASED_ELECTRICITY'],
                    },
                    {
                        english: this.energySourcesEnglish['NATURAL_GAS'],
                        translation: response['NATURAL_GAS'],
                    },
                    {
                        english: this.energySourcesEnglish['PURCHASED_STEAM'],
                        translation: response['PURCHASED_STEAM'],
                    },
                    {
                        english: this.energySourcesEnglish['OIL'],
                        translation: response['OIL'],
                    },
                    {
                        english: this.energySourcesEnglish['LPG'],
                        translation: response['LPG'],
                    },
                    {
                        english: this.energySourcesEnglish['COAL'],
                        translation: response['COAL'],
                    },
                    {
                        english: this.energySourcesEnglish['WOOD'],
                        translation: response['WOOD'],
                    },
                    {
                        english: this.energySourcesEnglish['DIESEL'],
                        translation: response['DIESEL'],
                    },
                    {
                        english: this.energySourcesEnglish['METHANE'],
                        translation: response['METHANE'],
                    },
                    {
                        english: this.energySourcesEnglish['BIOMASS'],
                        translation: response['BIOMASS'],
                    },
                    {
                        english: this.energySourcesEnglish['SOLAR'],
                        translation: response['SOLAR'],
                    },
                    {
                        english: this.energySourcesEnglish['WIND'],
                        translation: response['WIND'],
                    },
                ];
            });
        this.translateService
            .get('DOCUMENT_UPLOAD_COMPONENT.TYPE_SELECT')
            .subscribe((response) => {
                // console.log(response);
                this.documentTypes = [
                    {
                        english: this.documentTypesEnglish['AUDIT'],
                        translation: response['AUDIT'],
                    },
                    {
                        english: this.documentTypesEnglish['CERTIFICATE'],
                        translation: response['CERTIFICATE'],
                    },
                    {
                        english: this.documentTypesEnglish['TEST'],
                        translation: response['TEST'],
                    },
                    {
                        english: this.documentTypesEnglish['POLICY'],
                        translation: response['POLICY'],
                    },
                    {
                        english: this.documentTypesEnglish['SOP'],
                        translation: response['SOP'],
                    },
                    {
                        english: this.documentTypesEnglish['SDS'],
                        translation: response['SDS'],
                    },
                    {
                        english: this.documentTypesEnglish['SELF_DECLARATION'],
                        translation: response['SELF_DECLARATION'],
                    },
                    {
                        english: this.documentTypesEnglish['OTHER'],
                        translation: response['OTHER'],
                    },
                    {
                        english: this.documentTypesEnglish['INCHECK_REPORT'],
                        translation: response['INCHECK_REPORT'],
                    },
                ];
            });
        this.translateService
            .get('DOCUMENT_CENTER_COMPONENT.ALERTS')
            .subscribe((response) => {
                this.alertMsgs = response;
            });
    }

    openWasteWaterViewDialog(documentid, page) {
        let consumptionData = this.initConsumption();

        consumptionData['topic'] = 'WasteWater';
        const dialogRef = this.dialog.open(WasteWaterComponent, {
            width: '95%',
            height: '95%',
            disableClose: true,
            data: {
                consumption: consumptionData,
                viewMode: true,
                id: documentid, //if on view mode the document id must be provided
                page: page, //if the page the user want to see
            },
        });
    }

    checkWasteWaterResultConventional(result: Number) {
        let informationShown = '';
        switch (result) {
            case 0:
                informationShown =
                    'DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.WASTEWATER_RESULTS.ASPIRATIONAL';
                break;

            case 1:
                informationShown =
                    'DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.WASTEWATER_RESULTS.PROGRESSIVE';
                break;
            case 2:
                informationShown =
                    'DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.WASTEWATER_RESULTS.FOUNDATIONAL';
                break;
            case 3:
                informationShown =
                    'DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.WASTEWATER_RESULTS.FAILED';
                break;
            case -1:
                informationShown =
                    'DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.WASTEWATER_RESULTS.INSUFFICIENT_INFORMATION';
                break;
            default:
                informationShown = '';
                break;
        }
        return informationShown;
    }

    checkWasteWaterResultZDHCParameters(result: Number) {
        let informationShown = '';
        switch (result) {
            case 0:
                informationShown =
                    'DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.WASTEWATER_RESULTS.PASS';
                break;

            case 1:
                informationShown =
                    'DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.WASTEWATER_RESULTS.FAILED';
                break;

            case -1:
                informationShown =
                    'DOCUMENT_CENTER_COMPONENT.FACILITY_SPECIFIC.TOPICS.WASTEWATER_RESULTS.INSUFFICIENT_INFORMATION';
                break;

            case -2:
                informationShown = '';
                break;

            default:
                informationShown = '';
                break;
        }

        return informationShown;
    }
}
