import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { GlobalsService } from '../globals-services/globals.service';
import { FactoryTagComponent } from './factory-tag/factory-tag.component';

@Component({
    selector: 'app-wet-processing-unit',
    templateUrl: './wpu-list.component.html',
    styleUrls: ['./wpu-list.component.css'],
})
export class WpuListComponent implements OnInit {
    suppliers = [];
    // sharedSuppliers = [];
    totalCount: any;
    page: number = 0;
    pageSize: number = 10;
    //displayedColumns = ['name', 'ids', 'lastScanDate', 'inCheck', 'higgDoc', 'other', 'wasteWater', 'inventory']
    //sharedSuppliersDisplayedColumns = ['name', 'ids', 'lastScanDate', 'inCheck', 'higgDoc', 'other', 'wasteWater', 'inventory']

    displayedColumns = [
        'name',
        'ids',
        'lastScanDate',
        //  'inCheck',
        'inCheckExternal',
        'inCheckInternal',

        'higgDoc',
        'wasteWaterResult',
        // 'wasteWater',
        'chemicals',
        'env_data',
        'inventory',
        'cap'
    ];
    sharedSuppliersDisplayedColumns = [
        'name',
        'ids',
        'lastScanDate',
        'inCheck',
        'higgDoc',
        'wasteWater',
        'inventory',
    ];

    @ViewChild('paginator') paginator: MatPaginator;
    //  @ViewChild(MatSort) sort: MatSort;
    dataSource: MatTableDataSource<any>;
    sharedSuppliers: MatTableDataSource<any>;
    sharedSuppliersCount: any;
    supplierCount: any;
    isLoading: boolean = false;
    searchText = '';

   // iswalmartSupplier: Boolean = false;

    constructor(
        private http: HttpClient,
        private globalsService: GlobalsService,
        private router: Router,
        private dialog: MatDialog
    ) {
     //   this.iswalmartSupplier = GlobalsService.userRole.uniqueIdentifier === 'WALMART_BHIVE_ID' ? true : false;
    }

    ngOnInit() {
        this.getMySuppliers();
    }
    ngAfterViewInit() {
        this.dataSource = new MatTableDataSource(this.suppliers);
        this.dataSource.paginator = this.paginator;
    }
    onTabChanged(index) {
        switch (index) {
            case 0:
                this.getMySuppliers();
                break;
            case 1:
                this.getSharedSuppliers();
                break;
        }
    }
    getMySuppliers() {
        this.isLoading = true;
        this.http
            .get<any[]>(
                this.globalsService.baseHref + '/shared/getMyAllSuppliers'
            )
            .subscribe(
                (suppliers) => {
                    this.suppliers = suppliers;
                    this.suppliers.sort(function (a, b) {
                        const x = a['name'].trim();
                        const y = b['name'].trim();
                        return x > y ? 1 : x < y ? -1 : 0;
                    });
                    this.dataSource = new MatTableDataSource(this.suppliers);
                    this.dataSource.paginator = this.paginator;
                    // this.dataSource.sort = this.sort;
                    this.supplierCount = this.dataSource.data.length;
                    setTimeout(() => {
                        this.isLoading = false;
                    }, 2000);
                },
                (err) => { }
            );
    }
    getSharedSuppliers() {
        this.isLoading = true;
        this.http
            .get<any[]>(
                this.globalsService.baseHref + '/shared/getSharedSuppliers'
            )
            .subscribe(
                (sharedSuppliers) => {
                    sharedSuppliers.sort(function (a, b) {
                        const x = a['name'];
                        const y = b['name'];
                        return x > y ? 1 : x < y ? -1 : 0;
                    });
                    this.sharedSuppliers = new MatTableDataSource(
                        sharedSuppliers
                    );
                    this.sharedSuppliers.paginator = this.paginator;
                    this.sharedSuppliersCount =
                        this.sharedSuppliers.data.length;
                    setTimeout(() => {
                        this.isLoading = false;
                    }, 2000);
                },
                (err) => { }
            );
    }
    paginatorEvent(event) { }
    showInventory(supplier) {
        let dateObj = new Date(supplier.lastScanDate);
        console.log(dateObj);
        let month = dateObj.getUTCMonth() + 1;

        let year = dateObj.getUTCFullYear();
        let queryParams = {
            year: year,
            monthOrQuarter: month,
            creatorID: supplier.id,
            isLastScanDate: true,
        };
        this.router.navigate(['/inventories/summary'], {
            queryParams: queryParams,
        });
    }

    openDialog() {
        const dialogRef = this.dialog.open(FactoryTagComponent, {
            width: '50%',
            height: '50%',
            data: {
                valid_walmart_mill_id: true,
                valid_walmart_vendor_id: true,
            },
        });
    }

    applyFilterFormula(event: any) {
        //  this.clearContent();
        this.searchText = event;
        this.dataSource.filter = event.trim().toLowerCase();
    }

    setupFilter(column1: string, column2: string) {
        // console.log(column1);
        this.dataSource.filterPredicate = (d: any, filter: string) => {
            const textToSearch1 =
                (d[column1] && d[column1].toLowerCase()) || '';
            const textToSearch2 =
                (d[column2] && d[column2].toLowerCase()) || '';
            return (
                textToSearch1.indexOf(filter) !== -1 ||
                textToSearch2.indexOf(filter) !== -1
            );
        };
    }
}
