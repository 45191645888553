<!-- Navigation -->
<fuse-vertical-navigation class="dark bg-gray-900 print:hidden" [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'" [navigation]="selectedNavigation" [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <!-- *ngIf="selectedNavigation!=null && selectedNavigation.length > 0" -->
    <ng-container fuseVerticalNavigationContentHeader>
        <div class="flex items-center w-full p-4 pl-6">
            <!-- Logo -->
            <!-- <div class="flex items-center justify-center">
                <img class="w-8" src="assets/images/logo/logo.svg" />
            </div> -->
            <!-- Components -->
            <!-- <div class="flex items-center ml-auto">
                <notifications></notifications>
                <user [showAvatar]="false"></user>
            </div> -->
        </div>
        <!-- User -->
        <div class="flex flex-col items-center w-full p-4">
            <div class="relative w-24 h-24">
                <img class="w-full h-full rounded-full" src="/assets/images/logo/leftmenulogo.png" alt="GoBlu" />
                <mat-icon class="icon-size-24" *ngIf="!user.avatar" [svgIcon]="'heroicons_solid:user-circle'">
                </mat-icon>
            </div>
            <div class="flex flex-col items-center justify-center w-full mt-6">
                <div class="
                        w-full
                        whitespace-nowrap
                        overflow-ellipsis overflow-hidden
                        text-center
                        leading-normal
                        font-medium
                    ">
                    {{ globalsService.profile['name'] }}
                </div>
                <div class="
                        w-full
                        mt-0.5
                        whitespace-nowrap
                        overflow-ellipsis overflow-hidden
                        text-center text-md
                        leading-normal
                        font-medium
                        text-secondary
                    ">
                    {{ globalsService.profile['username'] }}

                </div>
            </div>
        </div>
    </ng-container>
    <!-- Navigation footer hook -->
    <ng-container fuseVerticalNavigationContentFooter>
        <div class="
                flex flex-0
                items-center
                justify-center
                h-16
                pr-6
                pl-2
                mt-2
                mb-4
                opacity-12
            ">
            <!-- <img class="max-w-36" src="assets/images/logo/logo-text-on-dark.svg" /> -->
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
    <!-- Header -->
    <div class="
            relative
            flex flex-0
            items-center
            w-full
            h-12
            px-4
            md:px-6
            shadow
            dark:shadow-none dark:border-b
            bg-card
            dark:bg-transparent
            print:hidden
        ">
        <!-- Navigation toggle button -->
        <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>
        <button mat-icon-button (click)="showDashboard()">
            <mat-icon [svgIcon]="'heroicons_outline:home'"></mat-icon>
        </button>

        <!-- Messages Count -->
        <!-- <button mat-icon-button>
            <mat-icon matBadge={{unreadMessagesTotal}} matBadgeSize="small" matBadgeColor="warn"
                [svgIcon]="'heroicons_outline:mail'"></mat-icon>
        </button> -->

        <!-- Notification Pop up Dialog Box -->
        <!-- <button mat-icon-button>
            <mat-icon matBadge={{unreadNotificationTotal}} matBadgeSize="small" matBadgeColor="warn"
                [matMenuTriggerFor]="showNotificationMenu" [svgIcon]="'feather:bell'"></mat-icon>
        </button>

        <mat-menu #showNotificationMenu="matMenu" yPosition="below" class="max-w-lg ">
            <app-notification-drawer></app-notification-drawer>
        </mat-menu> -->

         
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <ul class="nav navbar-nav pull-right ">
                <li>
                    <div style="padding-right: 10px">
                        <!-- <b>Language: </b> -->
                        <mat-form-field appearance="standard" style="
                                width: 75px;
                                margin-top: -15px;
                                height: 50px;
                                text-align: center;
                            ">
                            <mat-select matNativeControl [(ngModel)]="
                                    languageService['selectedLanguage']
                                " (selectionChange)="changeLanguage()">
                                <mat-option *ngFor="
                                        let language of languageService[
                                            'availableLanguages'
                                        ]
                                    " [value]="language['id']">
                                    {{ language["name"] }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </li>

                <li>

                    <button 
                        class="navbar-icon" 
                        type="button" 
                        (click)="showSettings()" 
                    >
                        <mat-icon>settings</mat-icon>
                    </button>

                    <!-- <mat-menu #menu="matMenu" xPosition="before">
                        <button 
                            mat-menu-item
                            type="button" 
                            (click)="showSettings()" 
                            i18n="App component Settings | Settings link in the upper navigation panel."
                        >
                          <mat-icon svgIcon="heroicons_outline:user-circle"></mat-icon> 
                          <span>Profile</span>
                        </button>

                        <button mat-menu-item (click)="showPoliciesData()">
                            <mat-icon svgIcon="heroicons_outline:document-text"></mat-icon>
                            <span>Policy Data</span>
                        </button>

                    </mat-menu> -->




                </li>
                &nbsp;
                &nbsp;
                <li>
                    <button type="button" class="navbar-icon" *ngIf="this.globalsService.isLoggedIn" (click)="logout()"
                        i18n="
                            App component Logout | Logout button in the
                            upper navigation panel.
                        ">
                        <!-- {{ "APP_COMPONENT.LOGOUT_BUTTON" | translate }} -->
                        <mat-icon>logout</mat-icon>
                    </button>
                </li>
                &nbsp;
                &nbsp;
                <li>
                    <button type="button" class="navbar-icon" id="faq-btn" (click)="openFAQ()" i18n="
                            App component FAQ | FAQ button in the upper
                            navigation panel.
                        ">
                        <!-- {{ "APP_COMPONENT.FAQ_BUTTON" | translate }} -->
                        <mat-icon>help</mat-icon>
                    </button>
                </li>
            </ul>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <!--<div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Fuse &copy; {{currentYear}}</span>
    </div>-->
</div>

<!-- Quick chat -->
<!-- <quick-chat #quickChat="quickChat"></quick-chat> -->