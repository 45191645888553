<div class="flex flex-col flex-auto min-w-0">
    <!-- Main -->
    <div class="flex-auto p-6 sm:p-10"> 
        <!-- CONTENT GOES HERE -->
        <div>
            <div class="content header">
                <div class="flex items-center justify-between w-full ">
                    <b style="font-size:1.5vw">{{ "STEP_DASHBOARD_COMPONENT.PAGE_HEADER" | translate }}</b>                 
                </div>            

                <div style="font-size:0.90vw">                  
                        {{ "STEP_DASHBOARD_COMPONENT.PAGE_SUB_HEADER" | translate }}
                </div>
            </div> 
            <div class="content mt-4">
                <div style="text-align:center; margin-bottom:30px">
                    <b style="font-size:1.5vw">{{"STEP_DASHBOARD_COMPONENT.PERFORMANCE_ON_FACTORY_LEVEL"| translate }}</b>
                </div>

                      <div
                      id="search-supplier"
                      i18n="
                          Supplier Inventories Overview Component supplier select
                      "
                  >
                      {{
                          "SUPPLIER_INVENTORIES_OVERVIEW_COMPONENT.SUPPLIER_SELECT"
                              | translate
                      }}
  
                      <input
                          class="form-control"
                          [matAutocomplete]="auto"
                          (keyup)="searchThis($event.target.value)"
                          [(ngModel)]="selectedFactoryName"
                          placeholder="{{
                              'SUPPLIER_INVENTORIES_OVERVIEW_COMPONENT.SUPPLIER_SELECT_PLACEHOLDER'
                                  | translate
                          }}"
                      />
  
                      <mat-autocomplete
                          #auto="matAutocomplete"
                          (optionSelected)="onSearchFactorySelect()"
                      >
                          <mat-option
                              *ngFor="let option of suggestedList | slice: 0:50"
                              [value]="option.name"
                          >
                              <!-- {{option.name}} /{{option.countryName}}/{{option.identification}} -->
                              <li>
                                  <strong>{{ option.name }}</strong>
                              </li>
                                <li>{{ option.countryName }}</li>
                              <li
                                  *ngIf="
                                      option.Higg != '' ||
                                      option.Oar != '' ||
                                      option.identification != ''
                                  "
                              >
                                  <span *ngIf="option.Higg != ''"
                                      >Higg :{{ option.Higg }} </span
                                  >&nbsp;
                                  <span *ngIf="option.Oar != ''"
                                      >OAR :{{ option.Oar }}</span
                                  >&nbsp;
                                  <span *ngIf="option.identification != ''"
                                      >Supplier:{{ option.identification }}</span
                                  >&nbsp;
                                  <span *ngIf="option.FFC != ''"
                                      >FFC:{{ option.FFC }}</span
                                  >&nbsp;
                                </li>
                                <li *ngIf="option.activity != ''">
                                    Factory Type : {{ option.activity }}
                                </li>
  
                            </mat-option>
                        </mat-autocomplete>
                  </div>
  
                  <div
                      id="year-select"
                      i18n="Supplier Inventories Overview Component year select"
                  >
                      {{
                          "SUPPLIER_INVENTORIES_OVERVIEW_COMPONENT.YEAR_SELECT"
                              | translate
                      }}
                      <select
                          class="form-control"
                          (change)="onYearSelect()"
                          [(ngModel)]="selectedYear"
                      >
                          <option value="none"></option>
                          <option
                              *ngFor="let year of yearsToShow"
                              value="{{ year }}"
                          >
                              {{ year }}
                          </option>
                      </select>
                  </div>
                  <div
                      id="month-select"
                      i18n="Supplier Inventories Overview Component period select"
                  >
                      {{
                          "SUPPLIER_INVENTORIES_OVERVIEW_COMPONENT.PERIOD_SELECT"
                              | translate
                      }}
                      <select
                          class="form-control"
                          (change)="onMonthOrQuarterSelect()"
                          [(ngModel)]="selectedMonthOrQuarter"
                      >
                          <option value="none"></option>
                          <option
                              *ngFor="let month of monthsToShow"
                              value="{{ month }}"
                          >
                              {{ month }}
                          </option>
                      </select>
                  </div>
                  <div
                  id="month-select">
                      <button class="btn btn-primary ml-4 button" (click)="applyFilters()">
                        {{'STEP_DASHBOARD_COMPONENT.SEARCH_BUTTON' | translate}}
                    </button>
                </div>
                
            </div>  
            <div class="mt-4" *ngIf="isFactory && (isYear || isMonth || isQuarter)">
                <mat-grid-list cols="7" rowHeight="16vh">
                    <mat-grid-tile [colspan]="1" [rowspan]="2">
                        <div class="main-div">
                            <mat-card>                           
                                    <loading-spinner *ngIf="isLoadingCards"></loading-spinner>
                                    <br><mat-card-title>{{chemicalProductCountUploadedInventory}}</mat-card-title>
                                    <mat-card-subtitle style="line-height:1.3"><b>chemical products</b> have been uploaded in the inventory summary of the selected time span.</mat-card-subtitle><br>
                                    <mat-card-footer *ngIf="isMonth">
                                        <a class="btn btn-primary button"  [routerLink]="['/inventories/summary']"[queryParams]="{year: selectedYear,monthOrQuarter:monthIndexMap[selectedMonthOrQuarter],creatorID:selectedFactory,isLastScanDate:true}">
                                            {{'STEP_DASHBOARD_COMPONENT.VIEW_INVNTORY' | translate}}    
                                        </a>                                  
                                    </mat-card-footer>                           
                                    <mat-card-footer *ngIf="isQuarter">
                                        <a class="btn btn-primary button"  [routerLink]="['/inventories/quarterSummary']"[queryParams]="{year: selectedYear,monthOrQuarter:quarterIndexMap[selectedMonthOrQuarter],creatorID:selectedFactory,isLastScanDate:true}">
                                            {{'STEP_DASHBOARD_COMPONENT.VIEW_INVNTORY' | translate}}    
                                        </a>                                  
                                    </mat-card-footer>                           
                                    <mat-card-footer *ngIf="isYear">
                                        <a class="btn btn-primary button"  [routerLink]="['/inventories/yearSummary']"[queryParams]="{year: selectedYear, creatorID:selectedFactory,isLastScanDate:true}">
                                            {{'STEP_DASHBOARD_COMPONENT.VIEW_INVNTORY' | translate}}    
                                        </a>                                  
                                    </mat-card-footer>                           
                            </mat-card>
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="3" [rowspan]="2">
                        <div class="main-div">
                            <mat-card>
                                <div id="grid-container-element_chemCompl" [ngStyle]="{'grid-template-columns':getGridTemplateColumns(chemicalCompliance)}">
                                    <div><mat-progress-bar mode="determinate" class="progress-bar-1" [ngStyle]="{'margin-left':chemicalCompliance!=0 ? '-3px' : '0px'}" value=100></mat-progress-bar></div>
                                    <div><mat-progress-bar mode="determinate" class="progress-bar-2" [ngStyle]="{'margin-left':chemicalCompliance!=0 ? '3px' : '0px'}" value=100></mat-progress-bar></div>
                                </div>
                                <div class="main-content">
                                    <loading-spinner *ngIf="isLoadingCards"></loading-spinner>
                                    <mat-card-title style="font-weight:unset"><b style="font-size:1.5vw">{{chemicalCompliance}}%</b> compliance</mat-card-title>
                                    <mat-card-subtitle>of <b>{{uniqueChemicalProductCountUploadedInventory}}</b> unique chemical products</mat-card-subtitle><br><br>
                                    <mat-card-content>
                                    <b> STeP MRSL compliance </b> (compliant with ZDHC MSRL and/ or ECO PASSPORT certified and/or STeP MRSL Self-Declaration)
                                    </mat-card-content>  
                                </div>    
                            </mat-card>
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile  [colspan]="2" [rowspan]="2">
                        <div class="main-div" style="width:100%">
                            <mat-card>
                                <div id="grid-container-element_ProdCasCompPerc" [ngStyle]="{'grid-template-columns':getGridTemplateColumns(chemicalCountProductCasCompPerc)}">
                                    <div><mat-progress-bar mode="determinate" class="progress-bar-1" [ngStyle]="{'margin-left':chemicalCompliance!=0 ? '-3px' : '0px'}" value=100></mat-progress-bar></div>
                                    <div><mat-progress-bar mode="determinate" class="progress-bar-2" [ngStyle]="{'margin-left':chemicalCompliance!=0 ? '3px' : '0px'}"  value=100></mat-progress-bar></div>
                                </div>
                                <div class="main-content">
                                    <loading-spinner *ngIf="isLoadingCards"></loading-spinner>
                                    <mat-card-title>{{chemicalCountProductCasCompPerc}}%</mat-card-title>
                                    <mat-card-subtitle><b>{{chemicalCountProductCasComp}}/{{chemicalProductCountUploadedInventory}}</b> chemical products<br>({{substanceCount}}) substances</mat-card-subtitle><br>
                                    <mat-card-content>
                                    Composition of the products & CAS numbers 
                                    </mat-card-content>
                                    <mat-card-footer *ngIf="isMonth">
                                    <a class="btn btn-primary button" [routerLink]="selectedFactoryName == undefined || selectedFactoryName == null ? ['/cas-screener/brand'] : ['/cas-screener/brand']"[queryParams]="selectedFactoryName == null ? {} : {year: selectedYear, month:monthIndexMap[selectedMonthOrQuarter], name:selectedFactoryName}" >
                                        {{'STEP_DASHBOARD_COMPONENT.LINK_TO_CAS_SCREENER' | translate}}
                                    </a>
                                    </mat-card-footer> 
                                    <mat-card-footer *ngIf="isQuarter">
                                    <a class="btn btn-primary button" [routerLink]="selectedFactoryName == undefined || selectedFactoryName == null ? ['/cas-screener/brand'] : ['/cas-screener/brand']"[queryParams]="selectedFactoryName == null ? {} : {year: selectedYear, quarter:quarterIndexMap[selectedMonthOrQuarter], name:selectedFactoryName}" >
                                        {{'STEP_DASHBOARD_COMPONENT.LINK_TO_CAS_SCREENER' | translate}}
                                    </a>
                                    </mat-card-footer> 
                                    <mat-card-footer *ngIf="isYear">
                                    <a class="btn btn-primary button" [routerLink]="selectedFactoryName == undefined || selectedFactoryName == null ? ['/cas-screener/brand'] : ['/cas-screener/brand']"[queryParams]="selectedFactoryName == null ? {} : {year: selectedYear, wholeYear: selectedYear,  name:selectedFactoryName}" >
                                        {{'STEP_DASHBOARD_COMPONENT.LINK_TO_CAS_SCREENER' | translate}}
                                    </a>
                                    </mat-card-footer> 
                                </div>                                        
                            </mat-card>
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile  [colspan]="1" [rowspan]="2">
                        <div class="main-div">
                            <mat-card class="compliance">
                                <div id="grid-container-element_subsCompPerc" [ngStyle]="{'grid-template-columns':getGridTemplateColumns(chemicalCountSubstCompositionPerc)}">
                                    <div><mat-progress-bar mode="determinate" class="progress-bar-1" [ngStyle]="{'margin-left':chemicalCompliance!=0 ? '-3px' : '0px'}" value=100></mat-progress-bar></div>
                                    <div><mat-progress-bar mode="determinate" class="progress-bar-2" [ngStyle]="{'margin-left':chemicalCompliance!=0 ? '3px' : '0px'}"  value=100></mat-progress-bar></div>
                                </div>
                                <div class="main-content">
                                    <loading-spinner *ngIf="isLoadingSubstanceCount"></loading-spinner>
                                    <mat-card-title >{{chemicalCountSubstCompositionPerc}}%</mat-card-title>
                                    <mat-card-subtitle><b>{{chemicalCountSubstComposition}}/{{substanceCount}}</b> substances</mat-card-subtitle><br><br>
                                    <mat-card-content>
                                    <b>Composition of the substance</b>
                                    </mat-card-content>
                                </div>
                            </mat-card>
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile  [colspan]="1" [rowspan]="2">
                        <div class="main-div">                   
                            <mat-card>
                                
                                    <loading-spinner *ngIf="isLoadingDownload"></loading-spinner>
                                    <div>
                                        <mat-card-title>Download Options</mat-card-title>
                                        <mat-card-subtitle style="line-height:1.3">Download this factory's performance overview in an Excel file.</mat-card-subtitle>
                                        <mat-card-footer>
                                            <button class="btn btn-primary  button" style="margin-top: 10px;" (click)="exportData()">
                                                {{'STEP_DASHBOARD_COMPONENT.DOWNLOAD_BUTTON' | translate}}
                                            </button>
                                        </mat-card-footer>
                                    </div>
                                
                            </mat-card> 
                        </div>                 
                </mat-grid-tile>
                    <mat-grid-tile [colspan]="1" [rowspan]="2">
                        <div class="main-div">
                        <mat-card>
                            <div id="grid-container-element_minMax" [ngStyle]="{'grid-template-columns':getGridTemplateColumns(chemicalMinMaxStockPerc)}">
                                <div><mat-progress-bar mode="determinate" class="progress-bar-1" [ngStyle]="{'margin-left':chemicalCompliance!=0 ? '-3px' : '0px'}" value=100></mat-progress-bar></div>
                                <div><mat-progress-bar mode="determinate" class="progress-bar-2" [ngStyle]="{'margin-left':chemicalCompliance!=0 ? '3px' : '0px'}"  value=100></mat-progress-bar></div>
                            </div>
                            <div class="main-content">
                                <loading-spinner *ngIf="isLoadingCards"></loading-spinner>
                                <mat-card-title>{{chemicalMinMaxStockPerc}}%</mat-card-title>
                                <mat-card-subtitle><b>{{chemicalMinMaxStock}}/{{chemicalProductCountUploadedInventory}}</b> chemical products</mat-card-subtitle><br>            
                                <mat-card-content>
                                <b>Minimum and maximum stock </b>
                                </mat-card-content>
                            </div>       
                        </mat-card>
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile   [colspan]="1" [rowspan]="2">
                        <div class="main-div">
                            <mat-card>
                                <div id="grid-container-element_usedArea" [ngStyle]="{'grid-template-columns':getGridTemplateColumns(chemicalCountApplication_UsedAreasPerc)}">
                                    <div><mat-progress-bar mode="determinate" class="progress-bar-1" [ngStyle]="{'margin-left':chemicalCompliance!=0 ? '-3px' : '0px'}" value=100></mat-progress-bar></div>
                                    <div><mat-progress-bar mode="determinate" class="progress-bar-2" [ngStyle]="{'margin-left':chemicalCompliance!=0 ? '3px' : '0px'}"  value=100></mat-progress-bar></div>
                                </div>
                                <div class="main-content">
                                    <loading-spinner *ngIf="isLoadingCards"></loading-spinner>
                                    <mat-card-title>{{chemicalCountApplication_UsedAreasPerc}}%</mat-card-title>
                                    <mat-card-subtitle><b>{{chemicalCountApplication_UsedAreas}}/{{chemicalProductCountUploadedInventory}}</b> chemical products</mat-card-subtitle><br>
                                    <mat-card-content>
                                    <b>Application/Used Areas </b> (ZDHC use category)  
                                    </mat-card-content>
                                </div>
                            </mat-card>
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile  [colspan]="1" [rowspan]="2">
                        <div class="main-div">
                            <mat-card>
                                <div id="grid-container-element_StorageLoc"[ngStyle]="{'grid-template-columns':getGridTemplateColumns(chemicalCountStorageLocationPerc)}">
                                    <div><mat-progress-bar mode="determinate" class="progress-bar-1" [ngStyle]="{'margin-left':chemicalCompliance!=0 ? '-3px' : '0px'}"value=100></mat-progress-bar></div>
                                    <div><mat-progress-bar mode="determinate" class="progress-bar-2" [ngStyle]="{'margin-left':chemicalCompliance!=0 ? '3px' : '0px'}"  value=100></mat-progress-bar></div>
                                </div>
                                <div class="main-content">
                                    <loading-spinner *ngIf="isLoadingCards"></loading-spinner>
                                    <mat-card-title>{{chemicalCountStorageLocationPerc}}%</mat-card-title>
                                    <mat-card-subtitle><b>{{chemicalCountStorageLocation}}/{{chemicalProductCountUploadedInventory}}</b> chemical products</mat-card-subtitle><br>
                                    <mat-card-content> <b>
                                        Storage Location </b> 
                                    </mat-card-content>
                                </div>
                            </mat-card>
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile  [colspan]="1" [rowspan]="2">
                        <div class="main-div">
                            <mat-card class="compliance">
                                <div id="grid-container-element_ghs" [ngStyle]="{'grid-template-columns':getGridTemplateColumns(chemicalCountGHSclassificationPerc)}">
                                    <div><mat-progress-bar mode="determinate" class="progress-bar-1" [ngStyle]="{'margin-left':chemicalCompliance!=0 ? '-3px' : '0px'}" value=100></mat-progress-bar></div>
                                    <div><mat-progress-bar mode="determinate" class="progress-bar-2" [ngStyle]="{'margin-left':chemicalCompliance!=0 ? '3px' : '0px'}"  value=100></mat-progress-bar></div>
                                </div>
                                <div class="main-content">
                                    <loading-spinner *ngIf="isLoadingCards"></loading-spinner>
                                    <mat-card-title>{{chemicalCountGHSclassificationPerc}}%</mat-card-title>
                                    <mat-card-subtitle><b>{{chemicalCountGHSclassification}}/{{chemicalProductCountUploadedInventory}}</b> chemical products</mat-card-subtitle><br>
                                    <mat-card-content>
                                        <b>GHS Classifications</b>
                                    </mat-card-content>
                                    <br>
                                </div>
                            </mat-card>
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="1" [rowspan]="2">
                        <div class="main-div">
                            <mat-card class="compliance">
                                <div id="grid-container-element_hPhrases" [ngStyle]="{'grid-template-columns':getGridTemplateColumns(chemicalCountH_PhrasesPerc)}">
                                    <div><mat-progress-bar mode="determinate" class="progress-bar-1" [ngStyle]="{'margin-left':chemicalCompliance!=0 ? '-3px' : '0px'}" value=100></mat-progress-bar></div>
                                    <div><mat-progress-bar mode="determinate" class="progress-bar-2" [ngStyle]="{'margin-left':chemicalCompliance!=0 ? '3px' : '0px'}"  value=100></mat-progress-bar></div>
                                </div>   
                                <div class="main-content">     
                                    <loading-spinner *ngIf="isLoadingCards"></loading-spinner>
                                    <mat-card-title>{{chemicalCountH_PhrasesPerc}}%</mat-card-title>
                                    <mat-card-subtitle><b>{{chemicalCountH_Phrases}}/{{chemicalProductCountUploadedInventory}}</b> chemical products</mat-card-subtitle><br>
                                    <mat-card-content>
                                            <b>H-Phrases</b>
                                    </mat-card-content>
                                    <br>
                                </div> 
                            </mat-card>
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile  [colspan]="1" [rowspan]="2">
                        <div class="main-div">
                            <mat-card class="compliance">
                                <div id="grid-container-element_pPhrases" [ngStyle]="{'grid-template-columns':getGridTemplateColumns(chemicalCountP_PhrasesPerc)}">
                                    <div><mat-progress-bar mode="determinate" class="progress-bar-1" [ngStyle]="{'margin-left':chemicalCompliance!=0 ? '-3px' : '0px'}" value=100></mat-progress-bar></div>
                                    <div><mat-progress-bar mode="determinate" class="progress-bar-2" [ngStyle]="{'margin-left':chemicalCompliance!=0 ? '3px' : '0px'}"  value=100></mat-progress-bar></div>
                                </div>
                                <div class="main-content">
                                    <loading-spinner *ngIf="isLoadingCards"></loading-spinner>
                                    <mat-card-title>{{chemicalCountP_PhrasesPerc}}%</mat-card-title>
                                    <mat-card-subtitle><b>{{chemicalCountP_Phrases}}/{{chemicalProductCountUploadedInventory}}</b> chemical products </mat-card-subtitle><br>
                                    <mat-card-content>
                                        <b>P-Phrases</b>
                                    </mat-card-content>
                                    <br>
                                </div>
                            </mat-card>
                        </div>
                    </mat-grid-tile>
                </mat-grid-list>
            </div>

        </div>
    </div>
</div>

