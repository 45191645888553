<div class="flex flex-col flex-auto min-w-0">
    <div class="flex-auto p-2 sm:p-10">

        <div class="content mt-4">       
            <div class="embed-container">
                <iframe
                     id ="iframe"
                    [src]="sanitizedURL"
                    frameborder="0" 
                    allowfullscreen="" 
                    webkitallowfullscreen="true" 
                    mozallowfullscreen="true" 
                    oallowfullscreen="true" 
                    msallowfullscreen="true"
                ></iframe>
            </div>
        </div>
    </div>
</div>