import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { allCountries } from 'app/user-signup/signupOptions';
import { GlobalsService } from '../../globals-services/globals.service';
import { GatewayAuthenticationService, GatewayOrganization } from '../authentication/gateway-authentication.service';

@Component({
  selector: 'app-subscription-form',
  templateUrl: './subscription-form.component.html',
  styleUrls: ['./subscription-form.component.css']
})
export class SubscriptionFormComponent implements OnInit {

  infoPage = true;
  zdhcLogin = false;
  gatewayOrganisations = [];
  queryParams = {};
  chosenGatewayOrg: GatewayOrganization;

  countries = allCountries;
  subscriptionForm: FormGroup;

  constructor(
    public globalsService: GlobalsService,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private gatewayAuthenticationService: GatewayAuthenticationService,
    private router: Router,
    private route: ActivatedRoute, ){
        this.route.queryParams.subscribe(queryParams => {
            this.queryParams = queryParams;
          });

        this.subscriptionForm = formBuilder.group({
            organizationGUID: ['', Validators.required],
            billingAddressStreet: ['', Validators.required],
            billingAddressCity: ['', Validators.required],
            billingAddressState: ['', Validators.required],
            billingAddressZipcode: ['', Validators.required],
            billingAddressCountry: ['', Validators.required],
            billingOrganization: ['', Validators.required],
            emailAddress: ['', [Validators.required, Validators.email]]
        });
    }

  ngOnInit() {
    this.checkZDHCLoginStatus();
    this.subscriptionForm.controls['emailAddress'].setValue(this.globalsService.profile['contactEmail']);
  }

  checkZDHCLoginStatus(){
      if (this.queryParams['source_path'] === 'gatewayLogin'){
          this.infoPage = false;
      }
      if (this.gatewayAuthenticationService.authenticationState !== undefined){
        this.zdhcLogin = this.gatewayAuthenticationService.authenticationState.authenticated;
        this.gatewayOrganisations = this.gatewayAuthenticationService.gatewayOrganisations;
      } else {
        this.zdhcLogin = false;
      }

      //add error message if the user is logged in but there's no registered gateway organization
      if (!this.infoPage && this.gatewayOrganisations.length ===0){
          alert("We cannot get your registered organization in the ZDHC Gateway. Please try again.");
          this.router.navigate(['/profile']);
      }
  }

  continue(){
      const newQueryParams = {
        'source_path' : 'subscription',
        'redirect_path': '/profile'
      };

      this.checkZDHCLoginStatus();

      // if not yet login, navigate to the login page.
      if (!this.zdhcLogin){
          this.router.navigate(['/gateway/prepare-incheck-report'], {
            queryParams: newQueryParams
          });
      };

      // show the subscription from
      if (this.zdhcLogin){
          this.infoPage = false;
      }
  }

  cancel(){
    this.router.navigate(['/profile']);
  }

  apply(){
    const data = {
        gatewayOrgId: this.subscriptionForm.value['organizationGUID'],
        gatewayOrgName: this.chosenGatewayOrg.Name,
        street: this.subscriptionForm.value['billingAddressStreet'],
        state: this.subscriptionForm.value['billingAddressState'],
        zipCode: this.subscriptionForm.value['billingAddressZipcode'],
        city: this.subscriptionForm.value['billingAddressCity'],
        country: this.subscriptionForm.value['billingAddressCountry'],
        factoryName: this.subscriptionForm.value['billingOrganization'],
        emailAddress: this.subscriptionForm.value['emailAddress'],
        userId: this.globalsService.profile["id"] //organization id
    };

    this.http.post(this.globalsService.bhiveIncheckServiceUrl + '/subscription/create', data).subscribe((status)=>{

            this.router.navigate(['/profile']);

            },

            (err) => {

                console.log(err);
                alert('There was an error while submitting the form. Please try again later. If the problem persists Please contact Bhive Team' + JSON.stringify(err));
            });
  }

  copyOrganizationName(){
    const orgGUID = this.subscriptionForm.value['organizationGUID'];
    this.chosenGatewayOrg = this.gatewayOrganisations.filter(org => org.OrgGUID === orgGUID)[0];
    this.subscriptionForm.controls['billingOrganization'].setValue(this.chosenGatewayOrg.Name);
  }

}
