import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AppComponent } from '../../app.component';
import { AuthenticationService } from '../../globals-services/authentication.service';
import { GlobalsService } from '../../globals-services/globals.service';
import { OrganizationType } from '../../globals-classes/OrganizationType';
import { KeycloakService } from 'keycloak-angular';

@Injectable()
export class SupplierLoginGuard implements CanActivate {

    constructor(private authenticationService: AuthenticationService,
        public globalsService: GlobalsService,
        private router: Router
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {


        if (this.isVfSupplier) {
            return true;
        }
        this.router.navigate(['/dashboard'])
        return false;
    }


    get isVfSupplier(): boolean {


        if (this.globalsService.profile['chemIq'] === "vfSupplier") {

            return true;

        } else {

            return false;
        }
    }
}
