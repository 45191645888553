export const environment = {
  production: false,
  server: 'EU',
  serverLocation: "Europe",

  baseHref: 'https://gw.test.europe.thebhive.net',
  homeUrl: 'https://login.test.europe.thebhive.net',
  cubeAPI: "https://analytics.test.europe.thebhive.net/cubejs-api/v1",
  incheckServiceUrl: 'https://gs.test.europe.thebhive.net/gateway-services/api/gateway',
  metaBaseURL: 'https://get.test.europe.thebhive.net',
  poServiceUrl :  'https://po.test.europe.thebhive.net/po-services/api',
  sdsAPI: 'https://po.test.europe.thebhive.net/po-services',

  gatewayUrl: 'https://www.my-aip.com/Gateway/rest',
  faqUrl: "https://www.test.europe.thebhive.net/membersfaq",

  gatewayAuthenticationKey: "B3EB14D9068472DCE0530100007FFB5A",
  recaptchaKey: '6Le6OrMaAAAAAJ1BkUHRczoHdbJ4ID6_AoDcGLDs',

  // China Server Test
 // otherServer: 'https://gw.test.china.thebhive.net', // this should be china or europe to transfer request between servers.
  otherServer: 'https://api.test.china.thebhive.net', // this should be china or europe to transfer request between servers.
  otherHomeUrl: 'https://login.test.china.thebhive.net',

  authentication: {
    enabled: true,
    url: 'http://bluhive.slashcube.de:8080/api/login'
  },
  keycloakURL:'https://auth.thebhive.cn/auth'
};
