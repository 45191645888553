import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastService } from 'app/common/toast-alert/toast.service';
import { GlobalsService } from 'app/globals-services/globals.service';
import { LanguageService } from 'app/language/language.service';

@Component({
  selector: 'app-new-launch-dialog',
  templateUrl: './new-launch-dialog.component.html',
  styleUrls: ['./new-launch-dialog.component.scss']
})
export class NewLaunchDialogComponent implements OnInit {
  languages;


  constructor(
    public dialogRef: MatDialogRef<NewLaunchDialogComponent>,
    private globalsService: GlobalsService,
    private languageService: LanguageService,
    private toastService: ToastService,
    private router: Router
  ) { }



  navigateByUrl( ) {
    this.router.navigateByUrl("/environmental-data/water?tab=1" );
    this.dialogRef.close()
  }


  ngOnInit(): void {

    this.languages = this.languageService.getAvailableLanguages.subscribe(res => {
      this.languages = res
      console.info(res)
    });

  }
  isChineseSystem() {
    return this.globalsService.serverLocation === 'China' ? true : false;
  }
 
}
