export interface ZdhcParameters {
    substanceGroup?: string;
    substance?: string;
    result?: number;
    unit?: string;
    aboveLimit?: string;
    table?: string;
    evaluation?:number;
    otherEvaluations?:any;
    comment?:string;
    name?:string;
}


export interface conventionalParameters {
    name?: string;
    result?: number;
    limits?: number[];
    limitReached?: string;
    unit?: string;
    controlName?: string;
    aboveLimit?: string;
    fail?: boolean;
    comment?: string;
    evaluation?: number;
    otherEvaluations?: any;
}

export const persistentFoamOptions = [
    { "option": "Pass", "value": 0 },
    { "option": "Fail", "value": 1 }
];

export const samplingPoint = [
    { "name": "Raw Wastewater", "value": "Raw Wastewater" },
    { "name": "Discharged Wastewater", "value": "Discharged Wastewater" },
    { "name": "Sludge", "value": "Sludge" }
]

export const typeOfDischarge = [
    { "name": "Direct", "value": "Direct" },
    { "name": "Indirect", "value": "Indirect" }
]

export interface ChemicalDocumentData {
    name?: string;
    expiry?: string;
    testingLab?: string;
    samplingLocation?: string;
    typeOfWaterDischarge?: string;
    samplingDate?: string;
    testingDate?: string;
    issueDate?: string;
    passfail?: string;
    comment?: string;
    conventionalSumParameters?: any;
    conventionalAnions?: any;
    conventionalMetals?: any;
    zdhcMrslParameters?: any;
    file?: File;
}


//order is used to determine the limits. The lower the index, the better.
// Index 0- Aspirational, 1- progressive, 2- Foundational

export const sumParameters = [

    {
        "name": "Temperature Delta",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.SUM_PARAMETERS.TEMPERATURE_DELTA",
        "limits": [5, 10, 15],
        "unit": "°C",
        "result": null,
        "limitReached": '',
        "aboveLimit": '',
        "required": 1,
        "controlName": "tempDelta"
    },

    {
        "name": "Temperature of Water Discharged",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.SUM_PARAMETERS.TEMPERATURE_OF_WATER_DISCHARGED",
        "limits": [25, 30, 35],
        "unit": "°C",
        "result": null,
        "limitReached": '',
        "aboveLimit": '',
        "required": 1,
        "controlName": "temp"
    },

    {
        "name": "Color 436",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.SUM_PARAMETERS.COLOR_436",
        "limits": [2, 5, 7],
        "unit": "mg/L",
        "result": null,
        "limitReached": '',
        "aboveLimit": '',
        "required": 1,
        "controlName": "color436"
    },

    {
        "name": "Color 525",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.SUM_PARAMETERS.COLOR_525",
        "limits": [1, 3, 5],
        "unit": "mg/L",
        "result": null,
        "limitReached": '',
        "aboveLimit": '',
        "required": 1,
        "controlName": "color525"
    },

    {
        "name": "Color 620",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.SUM_PARAMETERS.COLOR_620",
        "limits": [1, 2, 3],
        "unit": "mg/L",
        "result": null,
        "limitReached": '',
        "aboveLimit": '',
        "required": 1,
        "controlName": "color620"
    },


    {
        "name": "TSS",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.SUM_PARAMETERS.TSS",
        "limits": [5, 15, 50],
        "unit": "mg/L",
        "result": null,
        "limitReached": '',
        "aboveLimit": '',
        "required": 1,
        "controlName": "tss"
    },

    {
        "name": "COD",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.SUM_PARAMETERS.COD",
        "limits": [40, 80, 150],
        "unit": "mg/L",
        "result": null,
        "limitReached": '',
        "aboveLimit": '',
        "required": 1,
        "controlName": "cod"
    },

    {
        "name": "Total-N",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.SUM_PARAMETERS.TOTAL-N",
        "limits": [5, 10, 20],
        "unit": "mg/L",
        "result": null,
        "limitReached": '',
        "aboveLimit": '',
        "required": 1,
        "controlName": "total"
    },

    {
        "name": "ph",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.SUM_PARAMETERS.PH",
        "limits": [6, 9],
        "unit": "ph",
        "result": null,
        "limitReached": '',
        "aboveLimit": '',
        "required": 1,
        "controlName": "ph"
    },

    {
        "name": "BOD5",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.SUM_PARAMETERS.BOD5",
        "limits": [5, 15, 30],
        "unit": "mg/L",
        "result": null,
        "limitReached": '',
        "aboveLimit": '',
        "required": 1,
        "controlName": "bod5"
    },

    {
        "name": "Ammonion-N",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.SUM_PARAMETERS.AMMONION-N",
        "limits": [0.5, 1, 10],
        "unit": "mg/L",
        "result": null,
        "limitReached": '',
        "aboveLimit": '',
        "required": 1,
        "controlName": "ammonionN"
    },

    {
        "name": "Total-P",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.SUM_PARAMETERS.TOTAL-P",
        "limits": [0.1, 0.5, 3],
        "unit": "mg/L",
        "result": null,
        "limitReached": '',
        "aboveLimit": '',
        "required": 1,
        "controlName": "totalP"
    },

    {
        "name": "AOX",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.SUM_PARAMETERS.AOX",
        "limits": [0.1, 1, 5],
        "unit": "mg/L",
        "result": null,
        "limitReached": '',
        "aboveLimit": '',
        "required": 1,
        "controlName": "aox"
    },

    {
        "name": "Oil And Grease",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.SUM_PARAMETERS.OIL_AND_GREASE",
        "limits": [0.5, 2, 10],
        "unit": "mg/L",
        "result": null,
        "limitReached": '',
        "aboveLimit": '',
        "required": 1,
        "controlName": "oilAndGrease"
    },

    {
        "name": "Phenol",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.SUM_PARAMETERS.PHENOL",
        "limits": [0.001, 0.01, 0.5],
        "unit": "mg/L",
        "result": null,
        "limitReached": '',
        "aboveLimit": '',
        "required": 1,
        "controlName": "phenol"
    },

    {
        "name": "Coliform",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.SUM_PARAMETERS.COLIFORM",
        "limits": [25, 100, 400],
        "unit": "Coliform [bacteria/100 ml]",
        "result": null,
        "limitReached": '',
        "aboveLimit": '',
        "required": 1,
        "controlName": "coliform"
    },

    {
        "name": "Persistent Foam",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.SUM_PARAMETERS.PERSISTENT_FOAM",
        "limits": [0, 0, 0],
        "unit": "Pass or Fail",
        "result": null,
        "required": 1,
        "limitReached": '',
        "aboveLimit": '',
        "controlName": "persistentFoam"
    }

]


export const anions = [

    {
        "name": "Cyanide-Total",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ANIONS.CYANIDE-TOTAL",
        "limits": [0.05, 0.1, 0.2],
        "unit": "mg/L",
        "result": null,
        "limitReached": '',
        "aboveLimit": '',
        "required": 1,
        "controlName": "cyanide"
    },

    {
        "name": "Sulfide",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ANIONS.SULFIDE",
        "limits": [0.01, 0.05, 0.5],
        "unit": "mg/L",
        "result": null,
        "limitReached": '',
        "aboveLimit": '',
        "required": 1,
        "controlName": "sulfide"
    },

    {
        "name": "Sulfite",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ANIONS.SULFITE",
        "limits": [0.2, 0.5, 2],
        "unit": "mg/L",
        "result": null,
        "limitReached": '',
        "aboveLimit": '',
        "required": 1,
        "controlName": "sulfite"
    }

]


export const metals = [
    {
        "name": "Antimony",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.METALS.ANTIMONY",
        "limits": [0.01, 0.05, 0.1],
        "unit": "mg/L",
        "result": null,
        "limitReached": '',
        "aboveLimit": '',
        "required": 1,
        "controlName": "antimony"
    },

    {
        "name": "Chromium, total",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.METALS.CHROMIUM_TOTAL",
        "limits": [0.05, 0.1, 0.2],
        "unit": "mg/L",
        "result": null,
        "limitReached": '',
        "aboveLimit": '',
        "required": 1,
        "controlName": "chromiumTotal"
    },

    {
        "name": "Cobalt",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.METALS.COBALT",
        "limits": [0.01, 0.02, 0.05],
        "unit": "mg/L",
        "result": null,
        "limitReached": '',
        "aboveLimit": '',
        "required": 1,
        "controlName": "cobalt"
    },
    {
        "name": "Copper",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.METALS.COPPER",
        "limits": [0.25, 0.5, 1],
        "unit": "mg/L",
        "result": null,
        "limitReached": '',
        "aboveLimit": '',
        "required": 1,
        "controlName": "copper"
    },

    {
        "name": "Nickle",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.METALS.NICKLE",
        "limits": [0.05, 0.1, 0.2],
        "unit": "mg/L",
        "result": null,
        "limitReached": '',
        "aboveLimit": '',
        "required": 1,
        "controlName": "nickle"
    },

    {
        "name": "Silver",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.METALS.SILVER",
        "limits": [0.005, 0.05, 0.1],
        "unit": "mg/L",
        "result": null,
        "limitReached": '',
        "aboveLimit": '',
        "required": 1,
        "controlName": "silver"
    },
    {
        "name": "Zinc",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.METALS.ZINC",
        "limits": [0.5, 1, 5],
        "unit": "mg/L",
        "result": null,
        "limitReached": '',
        "aboveLimit": '',
        "required": 1,
        "controlName": "zinc"
    },

    {
        "name": "Arsenic",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.METALS.ARSENIC",
        "limits": [0.005, 0.01, 0.05],
        "unit": "mg/L",
        "result": null,
        "limitReached": '',
        "aboveLimit": '',
        "required": 1,
        "controlName": "arsenic"
    },

    {
        "name": "Cadmium",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.METALS.CADMIUM",
        "limits": [0.01, 0.05, 0.1],
        "unit": "mg/L",
        "result": null,
        "limitReached": '',
        "aboveLimit": '',
        "required": 1,
        "controlName": "cadmium"
    },
    {
        "name": "Chromium (VI)",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.METALS.CHROMIUM_VI",
        "limits": [0.001, 0.005, 0.05],
        "unit": "mg/L",
        "result": null,
        "limitReached": '',
        "aboveLimit": '',
        "required": 1,
        "controlName": "chromiumvi"
    },

    {
        "name": "Lead",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.METALS.LEAD",
        "limits": [0.01, 0.05, 0.1],
        "unit": "mg/L",
        "result": null,
        "limitReached": '',
        "aboveLimit": '',
        "required": 1,
        "controlName": "_lead"
    },

    {
        "name": "Mercury",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.METALS.MERCURY",
        "limits": [0.001, 0.005, 0.01],
        "unit": "mg/L",
        "result": null,
        "limitReached": '',
        "aboveLimit": '',
        "required": 1,
        "controlName": "mercury"
    }
]



export const zdhcParametersOptions = [

    //table 2A
    {
        "group": "Alkylphenol (AP) and Alkylphenol Ethoxylates (APEOs)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.ALKYLPHENOL_AP_AND_ALKYLPHENOL_ETHOXYLATES_APEOS",
        "table": "2A",
        "name": "Nonylphenol (NP), mixed isomers",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.NONYLPHENOL_NP_MIXED_ISOMERS",
        "unit": "μg/L",
        "cas": "104-40-5 11066-49-2 25154-52-3 84852-15-3",
        "result": null,
        "limit": 5
    },

    {
        "group": "Alkylphenol (AP) and Alkylphenol Ethoxylates (APEOs)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.ALKYLPHENOL_AP_AND_ALKYLPHENOL_ETHOXYLATES_APEOS",
        "table": "2A",
        "name": "Octylphenol (OP), mixed isomers",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.OCTYLPHENOL_OP_MIXED_ISOMERS",
        "unit": "μg/L",
        "cas": "140-66-9 1806-26-4 27193-28-8",
        "result": null,
        "limit": 5
    },

    {
        "group": "Alkylphenol (AP) and Alkylphenol Ethoxylates (APEOs)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.ALKYLPHENOL_AP_AND_ALKYLPHENOL_ETHOXYLATES_APEOS",
        "table": "2A",
        "name": "Octylphenol ethoxylates (OPEO)",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.OCTYLPHENOL_ETHOXYLATES_OPEO",
        "unit": "μg/L",
        "cas": "9002-93-1 9036-19-5 68987-90-6",
        "result": null,
        "limit": 5
    },

    {
        "group": "Alkylphenol (AP) and Alkylphenol Ethoxylates (APEOs)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.ALKYLPHENOL_AP_AND_ALKYLPHENOL_ETHOXYLATES_APEOS",
        "table": "2A",
        "name": "Nonylphenol ethoxylates (NPEO)",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.NONYLPHENOL_ETHOXYLATES_NPEO",
        "unit": "μg/L",
        "cas": "9016-45-9 26027-38-3 37205-87-1 68412-54-4 127087-87-0",
        "result": null,
        "limit": 5
    },


    //table 2B
    {
        "group": "Chlorobenzenes and Chlorotoluenes",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.CHLOROBENZENES_AND_CHLOROTOLUENES",
        "table": "2B",
        "name": "Monochlorobenzene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.MONOCHLOROBENZENE",
        "unit": "μg/L",
        "cas": "108-90-7",
        "result": null,
        "limit": 0.2
    },

    {
        "group": "Chlorobenzenes and Chlorotoluenes",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.CHLOROBENZENES_AND_CHLOROTOLUENES",
        "table": "2B",
        "name": "1,2-Dichlorobenzene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.1-2-DICHLOROBENZENE",
        "unit": "μg/L",
        "cas": "95-50-1",
        "result": null,
        "limit": 0.2
    },

    {
        "group": "Chlorobenzenes and Chlorotoluenes",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.CHLOROBENZENES_AND_CHLOROTOLUENES",
        "table": "2B",
        "name": "1,3-Dichlorobenzene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.1-3-DICHLOROBENZENE",
        "unit": "μg/L",
        "cas": "541-73-1",
        "result": null,
        "limit": 0.2
    },

    {
        "group": "Chlorobenzenes and Chlorotoluenes",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.CHLOROBENZENES_AND_CHLOROTOLUENES",
        "table": "2B",
        "name": "1,4-Dichlorobenzene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.1-4-DICHLOROBENZENE",
        "unit": "μg/L",
        "cas": "106-46-7",
        "result": null,
        "limit": 0.2
    },

    {
        "group": "Chlorobenzenes and Chlorotoluenes",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.CHLOROBENZENES_AND_CHLOROTOLUENES",
        "table": "2B",
        "name": "1,2,3-Trichlorobenzene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.1-2-3-TRICHLOROBENZENE",
        "unit": "μg/L",
        "cas": "87-61-6",
        "result": null,
        "limit": 0.2
    },

    {
        "group": "Chlorobenzenes and Chlorotoluenes",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.CHLOROBENZENES_AND_CHLOROTOLUENES",
        "table": "2B",
        "name": "1,2,4-Trichlorobenzene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.1-2-4-TRICHLOROBENZENE",
        "unit": "μg/L",
        "cas": "120-82-1",
        "result": null,
        "limit": 0.2
    },

    {
        "group": "Chlorobenzenes and Chlorotoluenes",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.CHLOROBENZENES_AND_CHLOROTOLUENES",
        "table": "2B",
        "name": "1,3,5-Trichlorobenzene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.1-3-5-TRICHLOROBENZENE",
        "unit": "μg/L",
        "cas": "108-70-3",
        "result": null,
        "limit": 0.2
    },

    {
        "group": "Chlorobenzenes and Chlorotoluenes",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.CHLOROBENZENES_AND_CHLOROTOLUENES",
        "table": "2B",
        "name": "1,2,3,4-Tetrachlorobenzene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.1-2-3-4-TETRACHLOROBENZENE",
        "unit": "μg/L",
        "cas": "634-66-2",
        "result": null,
        "limit": 0.2
    },

    {
        "group": "Chlorobenzenes and Chlorotoluenes",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.CHLOROBENZENES_AND_CHLOROTOLUENES",
        "table": "2B",
        "name": "1,2,3,5-Tetrachlorobenzene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.1-2-3-5-TETRACHLOROBENZENE",
        "unit": "μg/L",
        "cas": "634-90-2",
        "result": null,
        "limit": 0.2
    },

    {
        "group": "Chlorobenzenes and Chlorotoluenes",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.CHLOROBENZENES_AND_CHLOROTOLUENES",
        "table": "2B",
        "name": "1,2,4,5-Tetrachlorobenzene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.1-2-4-5-TETRACHLOROBENZENE",
        "unit": "μg/L",
        "cas": "95-94-3",
        "result": null,
        "limit": 0.2
    },

    {
        "group": "Chlorobenzenes and Chlorotoluenes",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.CHLOROBENZENES_AND_CHLOROTOLUENES",
        "table": "2B",
        "name": "Pentachlorobenzene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.PENTACHLOROBENZENE",
        "unit": "μg/L",
        "cas": "608-93-5",
        "result": null,
        "limit": 0.2
    },

    {
        "group": "Chlorobenzenes and Chlorotoluenes",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.CHLOROBENZENES_AND_CHLOROTOLUENES",
        "table": "2B",
        "name": "Hexachlorobenzene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.HEXACHLOROBENZENE",
        "unit": "μg/L",
        "cas": "118-74-1",
        "result": null,
        "limit": 0.2
    },

    {
        "group": "Chlorobenzenes and Chlorotoluenes",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.CHLOROBENZENES_AND_CHLOROTOLUENES",
        "table": "2B",
        "name": "2-Chlorotoluene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.2-CHLOROTOLUENE",
        "unit": "μg/L",
        "cas": "95-49-8",
        "result": null,
        "limit": 0.2
    },

    {
        "group": "Chlorobenzenes and Chlorotoluenes",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.CHLOROBENZENES_AND_CHLOROTOLUENES",
        "table": "2B",
        "name": "3-Chlorotoluene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.3-CHLOROTOLUENE",
        "unit": "μg/L",
        "cas": "108-41-8",
        "result": null,
        "limit": 0.2
    },

    {
        "group": "Chlorobenzenes and Chlorotoluenes",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.CHLOROBENZENES_AND_CHLOROTOLUENES",
        "table": "2B",
        "name": "4-Chlorotoluene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.4-CHLOROTOLUENE",
        "unit": "μg/L",
        "cas": "106-43-4",
        "result": null,
        "limit": 0.2
    },

    {
        "group": "Chlorobenzenes and Chlorotoluenes",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.CHLOROBENZENES_AND_CHLOROTOLUENES",
        "table": "2B",
        "name": "2,3-Dichlorotoluene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.2-3-DICHLOROTOLUENE",
        "unit": "μg/L",
        "cas": "32768-54-0",
        "result": null,
        "limit": 0.2
    },

    {
        "group": "Chlorobenzenes and Chlorotoluenes",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.CHLOROBENZENES_AND_CHLOROTOLUENES",
        "table": "2B",
        "name": "2,4-Dichlorotoluene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.2-4-DICHLOROTOLUENE",
        "unit": "μg/L",
        "cas": "95-73-8",
        "result": null,
        "limit": 0.2
    },

    {
        "group": "Chlorobenzenes and Chlorotoluenes",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.CHLOROBENZENES_AND_CHLOROTOLUENES",
        "table": "2B",
        "name": "2,5-Dichlorotoluene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.2-5-DICHLOROTOLUENE",
        "unit": "μg/L",
        "cas": "19398-61-9",
        "result": null,
        "limit": 0.2
    },

    {
        "group": "Chlorobenzenes and Chlorotoluenes",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.CHLOROBENZENES_AND_CHLOROTOLUENES",
        "table": "2B",
        "name": "2,6-Dichlorotoluene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.2-6-DICHLOROTOLUENE",
        "unit": "μg/L",
        "cas": "118-69-4",
        "result": null,
        "limit": 0.2
    },

    {
        "group": "Chlorobenzenes and Chlorotoluenes",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.CHLOROBENZENES_AND_CHLOROTOLUENES",
        "table": "2B",
        "name": "3,4-Dichlorotoluene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.3-4-DICHLOROTOLUENE",
        "unit": "μg/L",
        "cas": "95-75-0",
        "result": null,
        "limit": 0.2
    },

    {
        "group": "Chlorobenzenes and Chlorotoluenes",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.CHLOROBENZENES_AND_CHLOROTOLUENES",
        "table": "2B",
        "name": "3,5-Dichlorotoluene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.3-5-DICHLOROTOLUENE",
        "unit": "μg/L",
        "cas": "25186-47-4",
        "result": null,
        "limit": 0.2
    },

    {
        "group": "Chlorobenzenes and Chlorotoluenes",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.CHLOROBENZENES_AND_CHLOROTOLUENES",
        "table": "2B",
        "name": "2,3,4-Trichlorotoluene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.2-3-4-TRICHLOROTOLUENE",
        "unit": "μg/L",
        "cas": "7359-72-0",
        "result": null,
        "limit": 0.2
    },

    {
        "group": "Chlorobenzenes and Chlorotoluenes",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.CHLOROBENZENES_AND_CHLOROTOLUENES",
        "table": "2B",
        "name": "2,3,6-Trichlorotoluene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.2-3-6-TRICHLOROTOLUENE",
        "unit": "μg/L",
        "cas": "2077-46-5",
        "result": null,
        "limit": 0.2
    },

    {
        "group": "Chlorobenzenes and Chlorotoluenes",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.CHLOROBENZENES_AND_CHLOROTOLUENES",
        "table": "2B",
        "name": "2,4,5-Trichlorotoluene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.2-4-5-TRICHLOROTOLUENE",
        "unit": "μg/L",
        "cas": "6639-30-1",
        "result": null,
        "limit": 0.2
    },

    {
        "group": "Chlorobenzenes and Chlorotoluenes",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.CHLOROBENZENES_AND_CHLOROTOLUENES",
        "table": "2B",
        "name": "2,4,6-Trichlorotoluene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.2-4-6-TRICHLOROTOLUENE",
        "unit": "μg/L",
        "cas": "23749-65-7",
        "result": null,
        "limit": 0.2
    },

    {
        "group": "Chlorobenzenes and Chlorotoluenes",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.CHLOROBENZENES_AND_CHLOROTOLUENES",
        "table": "2B",
        "name": "3,4,5-Trichlorotoluene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.3-4-5-TRICHLOROTOLUENE",
        "unit": "μg/L",
        "cas": "21472-86-6",
        "result": null,
        "limit": 0.2
    },

    {
        "group": "Chlorobenzenes and Chlorotoluenes",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.CHLOROBENZENES_AND_CHLOROTOLUENES",
        "table": "2B",
        "name": "2,3,4,5-Tetrachlorotoluene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.2-3-4-5-TETRACHLOROTOLUENE",
        "unit": "μg/L",
        "cas": "76057-12-0",
        "result": null,
        "limit": 0.2
    },

    {
        "group": "Chlorobenzenes and Chlorotoluenes",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.CHLOROBENZENES_AND_CHLOROTOLUENES",
        "table": "2B",
        "name": "2,3,5,6-Tetrachlorotoluene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.2-3-5-6-TETRACHLOROTOLUENE",
        "unit": "μg/L",
        "cas": "29733-70-8",
        "result": null,
        "limit": 0.2
    },

    {
        "group": "Chlorobenzenes and Chlorotoluenes",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.CHLOROBENZENES_AND_CHLOROTOLUENES",
        "table": "2B",
        "name": "2,3,4,6-Tetrachlorotoluene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.2-3-4-6-TETRACHLOROTOLUENE",
        "unit": "μg/L",
        "cas": "875-40-1",
        "result": null,
        "limit": 0.2
    },

    {
        "group": "Chlorobenzenes and Chlorotoluenes",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.CHLOROBENZENES_AND_CHLOROTOLUENES",
        "table": "2B",
        "name": "Pentachlorotoluene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.PENTACHLOROTOLUENE",
        "unit": "μg/L",
        "cas": "877-11-2",
        "result": null,
        "limit": 0.2
    },

    //table 2C

    {
        "group": "Chlorophenols",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.CHLOROPHENOLS",
        "table": "2C",
        "name": "2-chlorophenol",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.2-CHLOROPHENOL",
        "unit": "μg/L",
        "cas": "95-57-8",
        "result": null,
        "limit": 0.5
    },

    {
        "group": "Chlorophenols",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.CHLOROPHENOLS",
        "table": "2C",
        "name": "3-chlorophenol",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.3-CHLOROPHENOL",
        "unit": "μg/L",
        "cas": "108-43-0",
        "result": null,
        "limit": 0.5
    },

    {
        "group": "Chlorophenols",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.CHLOROPHENOLS",
        "table": "2C",
        "name": "4-chlorophenol",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.4-CHLOROPHENOL",
        "unit": "μg/L",
        "cas": "106-48-9",
        "result": null,
        "limit": 0.5
    },

    {
        "group": "Chlorophenols",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.CHLOROPHENOLS",
        "table": "2C",
        "name": "2,3-dichlorophenol",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.2-3-DICHLOROPHENOL",
        "unit": "μg/L",
        "cas": "576-24-9",
        "result": null,
        "limit": 0.5
    },

    {
        "group": "Chlorophenols",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.CHLOROPHENOLS",
        "table": "2C",
        "name": "2,4-dichlorophenol",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.2-4-DICHLOROPHENOL",
        "unit": "μg/L",
        "cas": "120-83-2",
        "result": null,
        "limit": 0.5
    },

    {
        "group": "Chlorophenols",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.CHLOROPHENOLS",
        "table": "2C",
        "name": "2,5-dichlorophenol",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.2-5-DICHLOROPHENOL",
        "unit": "μg/L",
        "cas": "583-78-8",
        "result": null,
        "limit": 0.5
    },

    {
        "group": "Chlorophenols",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.CHLOROPHENOLS",
        "table": "2C",
        "name": "2,6-dichlorophenol",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.2-6-DICHLOROPHENOL",
        "unit": "μg/L",
        "cas": "87-65-0",
        "result": null,
        "limit": 0.5
    },

    {
        "group": "Chlorophenols",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.CHLOROPHENOLS",
        "table": "2C",
        "name": "3,4-dichlorophenol",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.3-4-DICHLOROPHENOL",
        "unit": "μg/L",
        "cas": "95-77-2",
        "result": null,
        "limit": 0.5
    },

    {
        "group": "Chlorophenols",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.CHLOROPHENOLS",
        "table": "2C",
        "name": "3,5-dichlorophenol",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.3-5-DICHLOROPHENOL",
        "unit": "μg/L",
        "cas": "591-35-5",
        "result": null,
        "limit": 0.5
    },

    {
        "group": "Chlorophenols",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.CHLOROPHENOLS",
        "table": "2C",
        "name": "2,3,4-trichlorophenol",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.2-3-4-TRICHLOROPHENOL",
        "unit": "μg/L",
        "cas": "15950-66-0",
        "result": null,
        "limit": 0.5
    },

    {
        "group": "Chlorophenols",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.CHLOROPHENOLS",
        "table": "2C",
        "name": "2,3,5-trichlorophenol",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.2-3-5-TRICHLOROPHENOL",
        "unit": "μg/L",
        "cas": "933-78-8",
        "result": null,
        "limit": 0.5
    },

    {
        "group": "Chlorophenols",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.CHLOROPHENOLS",
        "table": "2C",
        "name": "2,3,6-trichlorophenol",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.2-3-6-TRICHLOROPHENOL",
        "unit": "μg/L",
        "cas": "933-75-5",
        "result": null,
        "limit": 0.5
    },

    {
        "group": "Chlorophenols",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.CHLOROPHENOLS",
        "table": "2C",
        "name": "2,4,5-trichlorophenol",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.2-4-5-TRICHLOROPHENOL",
        "unit": "μg/L",
        "cas": "95-95-4",
        "result": null,
        "limit": 0.5
    },

    {
        "group": "Chlorophenols",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.CHLOROPHENOLS",
        "table": "2C",
        "name": "2,4,6-trichlorophenol",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.2-4-6-TRICHLOROPHENOL",
        "unit": "μg/L",
        "cas": "88-06-2",
        "result": null,
        "limit": 0.5
    },

    {
        "group": "Chlorophenols",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.CHLOROPHENOLS",
        "table": "2C",
        "name": "3,4,5-trichlorophenol",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.3-4-5-TRICHLOROPHENOL",
        "unit": "μg/L",
        "cas": "609-19-8",
        "result": null,
        "limit": 0.5
    },

    {
        "group": "Chlorophenols",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.CHLOROPHENOLS",
        "table": "2C",
        "name": "2,3,4,5-tetrachlorophenol",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.2-3-4-5-TETRACHLOROPHENOL",
        "unit": "μg/L",
        "cas": "4901-51-3",
        "result": null,
        "limit": 0.5
    },

    {
        "group": "Chlorophenols",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.CHLOROPHENOLS",
        "table": "2C",
        "name": "2,3,4,6-tetrachlorophenol",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.2-3-4-6-TETRACHLOROPHENOL",
        "unit": "μg/L",
        "cas": "58-90-2",
        "result": null,
        "limit": 0.5
    },

    {
        "group": "Chlorophenols",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.CHLOROPHENOLS",
        "table": "2C",
        "name": "2,3,5,6-tetrachlorophenol",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.2-3-5-6-TETRACHLOROPHENOL",
        "unit": "μg/L",
        "cas": "935-95-5",
        "result": null,
        "limit": 0.5
    },

    {
        "group": "Chlorophenols",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.CHLOROPHENOLS",
        "table": "2C",
        "name": "Pentachlorophenol",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.PENTACHLOROPHENOL",
        "unit": "μg/L",
        "cas": "87-86-5",
        "result": null,
        "limit": 0.5
    },


    //table D

    {
        "group": "Dyes – Azo (Forming Restricted Amines)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_AZO_FORMING_RESTRICTED_AMINES",
        "table": "2D",
        "name": "4,4’-methylene-bis- (2-chloro-aniline)",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.4-4-METHYLENE-BIS-2-CHLORO-ANILINE",
        "unit": "μg/L",
        "cas": "101-14-4",
        "result": null,
        "limit": 0.1
    },

    {
        "group": "Dyes – Azo (Forming Restricted Amines)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_AZO_FORMING_RESTRICTED_AMINES",
        "table": "2D",
        "name": "4,4’-methylenedianiline",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.4-4-METHYLENEDIANILINE",
        "unit": "μg/L",
        "cas": "101-77-9",
        "result": null,
        "limit": 0.1
    },

    {
        "group": "Dyes – Azo (Forming Restricted Amines)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_AZO_FORMING_RESTRICTED_AMINES",
        "table": "2D",
        "name": "4,4’-oxydianiline",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.4-4-OXYDIANILINE",
        "unit": "μg/L",
        "cas": "101-80-4",
        "result": null,
        "limit": 0.1
    },

    {
        "group": "Dyes – Azo (Forming Restricted Amines)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_AZO_FORMING_RESTRICTED_AMINES",
        "table": "2D",
        "name": "4-chloroaniline",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.4-CHLOROANILINE",
        "unit": "μg/L",
        "cas": "106-47-8",
        "result": null,
        "limit": 0.1
    },

    {
        "group": "Dyes – Azo (Forming Restricted Amines)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_AZO_FORMING_RESTRICTED_AMINES",
        "table": "2D",
        "name": "3,3’-dimethoxylbenzidine",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.3-3-DIMETHOXYLBENZIDINE",
        "unit": "μg/L",
        "cas": "119-90-4",
        "result": null,
        "limit": 0.1
    },

    {
        "group": "Dyes – Azo (Forming Restricted Amines)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_AZO_FORMING_RESTRICTED_AMINES",
        "table": "2D",
        "name": "3,3’-dimethylbenzidine",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.3-3-DIMETHYLBENZIDINE",
        "unit": "μg/L",
        "cas": "119-93-7",
        "result": null,
        "limit": 0.1
    },

    {
        "group": "Dyes – Azo (Forming Restricted Amines)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_AZO_FORMING_RESTRICTED_AMINES",
        "table": "2D",
        "name": "6-methoxy-m-toluidine",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.6-METHOXY-M-TOLUIDINE",
        "unit": "μg/L",
        "cas": "120-71-8",
        "result": null,
        "limit": 0.1
    },

    {
        "group": "Dyes – Azo (Forming Restricted Amines)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_AZO_FORMING_RESTRICTED_AMINES",
        "table": "2D",
        "name": "2,4,5-trimethylaniline",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.2-4-5-TRIMETHYLANILINE",
        "unit": "μg/L",
        "cas": "137-17-7",
        "result": null,
        "limit": 0.1
    },

    {
        "group": "Dyes – Azo (Forming Restricted Amines)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_AZO_FORMING_RESTRICTED_AMINES",
        "table": "2D",
        "name": "4,4’-thiodianiline",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.4-4-THIODIANILINE",
        "unit": "μg/L",
        "cas": "139-65-1",
        "result": null,
        "limit": 0.1
    },

    {
        "group": "Dyes – Azo (Forming Restricted Amines)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_AZO_FORMING_RESTRICTED_AMINES",
        "table": "2D",
        "name": "4-aminoazobenzene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.4-AMINOAZOBENZENE",
        "unit": "μg/L",
        "cas": "60-09-3",
        "result": null,
        "limit": 0.1
    },

    {
        "group": "Dyes – Azo (Forming Restricted Amines)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_AZO_FORMING_RESTRICTED_AMINES",
        "table": "2D",
        "name": "4-methoxy-m-phenylenediamine",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.4-METHOXY-M-PHENYLENEDIAMINE",
        "unit": "μg/L",
        "cas": "615-05-4",
        "result": null,
        "limit": 0.1
    },

    {
        "group": "Dyes – Azo (Forming Restricted Amines)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_AZO_FORMING_RESTRICTED_AMINES",
        "table": "2D",
        "name": "4,4’-methylenedi-o-toluidine",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.4-4-METHYLENEDI-O-TOLUIDINE",
        "unit": "μg/L",
        "cas": "838-88-0",
        "result": null,
        "limit": 0.1
    },

    {
        "group": "Dyes – Azo (Forming Restricted Amines)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_AZO_FORMING_RESTRICTED_AMINES",
        "table": "2D",
        "name": "2,6-xylidine",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.2-6-XYLIDINE",
        "unit": "μg/L",
        "cas": "87-62-7",
        "result": null,
        "limit": 0.1
    },

    {
        "group": "Dyes – Azo (Forming Restricted Amines)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_AZO_FORMING_RESTRICTED_AMINES",
        "table": "2D",
        "name": "o-anisidine",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.O-ANISIDINE",
        "unit": "μg/L",
        "cas": "90-04-0",
        "result": null,
        "limit": 0.1
    },

    {
        "group": "Dyes – Azo (Forming Restricted Amines)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_AZO_FORMING_RESTRICTED_AMINES",
        "table": "2D",
        "name": "2-naphthylamine",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.2-NAPHTHYLAMINE",
        "unit": "μg/L",
        "cas": "91-59-8",
        "result": null,
        "limit": 0.1
    },

    {
        "group": "Dyes – Azo (Forming Restricted Amines)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_AZO_FORMING_RESTRICTED_AMINES",
        "table": "2D",
        "name": "3,3'-dichlorobenzidine",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.3-3-DICHLOROBENZIDINE",
        "unit": "μg/L",
        "cas": "91-94-1",
        "result": null,
        "limit": 0.1
    },

    {
        "group": "Dyes – Azo (Forming Restricted Amines)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_AZO_FORMING_RESTRICTED_AMINES",
        "table": "2D",
        "name": "4-aminodiphenyl",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.4-AMINODIPHENYL",
        "unit": "μg/L",
        "cas": "92-67-1",
        "result": null,
        "limit": 0.1
    },

    {
        "group": "Dyes – Azo (Forming Restricted Amines)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_AZO_FORMING_RESTRICTED_AMINES",
        "table": "2D",
        "name": "Benzidine",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.BENZIDINE",
        "unit": "μg/L",
        "cas": "92-87-5",
        "result": null,
        "limit": 0.1
    },

    {
        "group": "Dyes – Azo (Forming Restricted Amines)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_AZO_FORMING_RESTRICTED_AMINES",
        "table": "2D",
        "name": "o-toluidine",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.O-TOLUIDINE",
        "unit": "μg/L",
        "cas": "95-53-4",
        "result": null,
        "limit": 0.1
    },

    {
        "group": "Dyes – Azo (Forming Restricted Amines)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_AZO_FORMING_RESTRICTED_AMINES",
        "table": "2D",
        "name": "2,4-xylidine",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.2-4-XYLIDINE",
        "unit": "μg/L",
        "cas": "95-68-1",
        "result": null,
        "limit": 0.1
    },

    {
        "group": "Dyes – Azo (Forming Restricted Amines)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_AZO_FORMING_RESTRICTED_AMINES",
        "table": "2D",
        "name": "4-chloro-o-toluidine",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.4-CHLORO-O-TOLUIDINE",
        "unit": "μg/L",
        "cas": "95-69-2",
        "result": null,
        "limit": 0.1
    },

    {
        "group": "Dyes – Azo (Forming Restricted Amines)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_AZO_FORMING_RESTRICTED_AMINES",
        "table": "2D",
        "name": "4-methyl-m-phenylenediamine",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.4-METHYL-M-PHENYLENEDIAMINE",
        "unit": "μg/L",
        "cas": "95-80-7",
        "result": null,
        "limit": 0.1
    },

    {
        "group": "Dyes – Azo (Forming Restricted Amines)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_AZO_FORMING_RESTRICTED_AMINES",
        "table": "2D",
        "name": "o-aminoazotoluene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.O-AMINOAZOTOLUENE",
        "unit": "μg/L",
        "cas": "97-56-3",
        "result": null,
        "limit": 0.1
    },

    {
        "group": "Dyes – Azo (Forming Restricted Amines)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_AZO_FORMING_RESTRICTED_AMINES",
        "table": "2D",
        "name": "5-nitro-o-toluidine",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.5-NITRO-O-TOLUIDINE",
        "unit": "μg/L",
        "cas": "99-55-8",
        "result": null,
        "limit": 0.1
    },

    //table2E

    {
        "group": "Dyes – Carcinogenic or Equivalent Concern",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_CARCINOGENIC_OR_EQUIVALENT_CONCERN",
        "table": "2E",
        "name": "C.I. Direct Black 38",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.CI_DIRECT_BLACK_38",
        "unit": "μg/L",
        "cas": "1937-37-7",
        "result": null,
        "limit": 500
    },

    {
        "group": "Dyes – Carcinogenic or Equivalent Concern",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_CARCINOGENIC_OR_EQUIVALENT_CONCERN",
        "table": "2E",
        "name": "C.I. Direct Blue 6",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.CI_DIRECT_BLUE_6",
        "unit": "μg/L",
        "cas": "2602-46-2",
        "result": null,
        "limit": 500
    },

    {
        "group": "Dyes – Carcinogenic or Equivalent Concern",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_CARCINOGENIC_OR_EQUIVALENT_CONCERN",
        "table": "2E",
        "name": "C.I. Acid Red 26",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.CI_ACID_RED_26",
        "unit": "μg/L",
        "cas": "3761-53-3",
        "result": null,
        "limit": 500
    },

    {
        "group": "Dyes – Carcinogenic or Equivalent Concern",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_CARCINOGENIC_OR_EQUIVALENT_CONCERN",
        "table": "2E",
        "name": "C.I. Basic Red 9",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.CI_BASIC_RED_9",
        "unit": "μg/L",
        "cas": "569-61-9",
        "result": null,
        "limit": 500
    },

    {
        "group": "Dyes – Carcinogenic or Equivalent Concern",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_CARCINOGENIC_OR_EQUIVALENT_CONCERN",
        "table": "2E",
        "name": "C.I. Direct Red 28",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.CI_DIRECT_RED_28",
        "unit": "μg/L",
        "cas": "573-58-0",
        "result": null,
        "limit": 500
    },

    {
        "group": "Dyes – Carcinogenic or Equivalent Concern",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_CARCINOGENIC_OR_EQUIVALENT_CONCERN",
        "table": "2E",
        "name": "C.I. Basic Violet 14",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.CI_BASIC_VIOLET_14",
        "unit": "μg/L",
        "cas": "632-99-5",
        "result": null,
        "limit": 500
    },

    {
        "group": "Dyes – Carcinogenic or Equivalent Concern",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_CARCINOGENIC_OR_EQUIVALENT_CONCERN",
        "table": "2E",
        "name": "C.I. Disperse Blue 1",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.CI_DISPERSE_BLUE_1",
        "unit": "μg/L",
        "cas": "2475-45-8",
        "result": null,
        "limit": 500
    },

    {
        "group": "Dyes – Carcinogenic or Equivalent Concern",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_CARCINOGENIC_OR_EQUIVALENT_CONCERN",
        "table": "2E",
        "name": "C.I. Disperse Blue 3",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.CI_DISPERSE_BLUE_3",
        "unit": "μg/L",
        "cas": "2475-46-9",
        "result": null,
        "limit": 500
    },

    {
        "group": "Dyes – Carcinogenic or Equivalent Concern",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_CARCINOGENIC_OR_EQUIVALENT_CONCERN",
        "table": "2E",
        "name": "C.I. Basic Blue 26",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.CI_BASIC_BLUE_26",
        "unit": "μg/L",
        "cas": "2580-56-5",
        "result": null,
        "limit": 500
    },

    {
        "group": "Dyes – Carcinogenic or Equivalent Concern",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_CARCINOGENIC_OR_EQUIVALENT_CONCERN",
        "table": "2E",
        "name": "C.I. Basic Green 4 (Malachite Green Chloride)",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.CI_BASIC_GREEN_4_MALACHITE_GREEN_CHLORIDE",
        "unit": "μg/L",
        "cas": "569-64-2",
        "result": null,
        "limit": 500
    },

    {
        "group": "Dyes – Carcinogenic or Equivalent Concern",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_CARCINOGENIC_OR_EQUIVALENT_CONCERN",
        "table": "2E",
        "name": "C.I. Basic Green 4 (Malachite Green Oxalate)",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.CI_BASIC_GREEN_4_MALACHITE_GREEN_OXALATE",
        "unit": "μg/L",
        "cas": "2437-29-8",
        "result": null,
        "limit": 500
    },

    {
        "group": "Dyes – Carcinogenic or Equivalent Concern",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_CARCINOGENIC_OR_EQUIVALENT_CONCERN",
        "table": "2E",
        "name": "C.I. Basic Green 4 (Malachite Green)",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.CI_BASIC_GREEN_4_MALACHITE_GREEN",
        "unit": "μg/L",
        "cas": "10309-95-2",
        "result": null,
        "limit": 500
    },

    {
        "group": "Dyes – Carcinogenic or Equivalent Concern",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_CARCINOGENIC_OR_EQUIVALENT_CONCERN",
        "table": "2E",
        "name": "Disperse Orange 11",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.DISPERSE_ORANGE_11",
        "unit": "μg/L",
        "cas": "82-28-0",
        "result": null,
        "limit": 500
    },

    //table 2F

    {
        "group": "Dyes – Disperse (Sensitising)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_DISPERSE_SENSITISING",
        "table": "2F",
        "name": "Disperse Yellow 1",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.DISPERSE_YELLOW_1",
        "unit": "μg/L",
        "cas": "119-15-3",
        "result": null,
        "limit": 50
    },

    {
        "group": "Dyes – Disperse (Sensitising)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_DISPERSE_SENSITISING",
        "table": "2F",
        "name": "Disperse Blue 102",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.DISPERSE_BLUE_102",
        "unit": "μg/L",
        "cas": "12222-97-8",
        "result": null,
        "limit": 50
    },

    {
        "group": "Dyes – Disperse (Sensitising)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_DISPERSE_SENSITISING",
        "table": "2F",
        "name": "Disperse Blue 106",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.DISPERSE_BLUE_106",
        "unit": "μg/L",
        "cas": "12223-01-7",
        "result": null,
        "limit": 50
    },

    {
        "group": "Dyes – Disperse (Sensitising)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_DISPERSE_SENSITISING",
        "table": "2F",
        "name": "Disperse Yellow 39",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.DISPERSE_YELLOW_39",
        "unit": "μg/L",
        "cas": "12236-29-2",
        "result": null,
        "limit": 50
    },

    {
        "group": "Dyes – Disperse (Sensitising)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_DISPERSE_SENSITISING",
        "table": "2F",
        "name": "Disperse Orange 37/59/76",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.DISPERSE_ORANGE_37-59-76",
        "unit": "μg/L",
        "cas": "13301-61-6",
        "result": null,
        "limit": 50
    },

    {
        "group": "Dyes – Disperse (Sensitising)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_DISPERSE_SENSITISING",
        "table": "2F",
        "name": "Disperse Brown 1",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.DISPERSE_BROWN_1",
        "unit": "μg/L",
        "cas": "23355-64-8",
        "result": null,
        "limit": 50
    },

    {
        "group": "Dyes – Disperse (Sensitising)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_DISPERSE_SENSITISING",
        "table": "2F",
        "name": "Disperse Orange 1",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.DISPERSE_ORANGE_1",
        "unit": "μg/L",
        "cas": "2581-69-3",
        "result": null,
        "limit": 50
    },

    {
        "group": "Dyes – Disperse (Sensitising)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_DISPERSE_SENSITISING",
        "table": "2F",
        "name": "Disperse Yellow 3",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.DISPERSE_YELLOW_3",
        "unit": "μg/L",
        "cas": "2832-40-8",
        "result": null,
        "limit": 50
    },

    {
        "group": "Dyes – Disperse (Sensitising)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_DISPERSE_SENSITISING",
        "table": "2F",
        "name": "Disperse Red 11",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.DISPERSE_RED_11",
        "unit": "μg/L",
        "cas": "2872-48-2",
        "result": null,
        "limit": 50
    },

    {
        "group": "Dyes – Disperse (Sensitising)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_DISPERSE_SENSITISING",
        "table": "2F",
        "name": "Disperse Red 1",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.DISPERSE_RED_1",
        "unit": "μg/L",
        "cas": "2872-52-8",
        "result": null,
        "limit": 50
    },

    {
        "group": "Dyes – Disperse (Sensitising)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_DISPERSE_SENSITISING",
        "table": "2F",
        "name": "Disperse Red 17",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.DISPERSE_RED_17",
        "unit": "μg/L",
        "cas": "3179-89-3",
        "result": null,
        "limit": 50
    },

    {
        "group": "Dyes – Disperse (Sensitising)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_DISPERSE_SENSITISING",
        "table": "2F",
        "name": "Disperse Blue 7",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.DISPERSE_BLUE_7",
        "unit": "μg/L",
        "cas": "3179-90-6",
        "result": null,
        "limit": 50
    },

    {
        "group": "Dyes – Disperse (Sensitising)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_DISPERSE_SENSITISING",
        "table": "2F",
        "name": "Disperse Blue 26",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.DISPERSE_BLUE_26",
        "unit": "μg/L",
        "cas": "3860-63-7",
        "result": null,
        "limit": 50
    },

    {
        "group": "Dyes – Disperse (Sensitising)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_DISPERSE_SENSITISING",
        "table": "2F",
        "name": "Disperse Yellow 49",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.DISPERSE_YELLOW_49",
        "unit": "μg/L",
        "cas": "54824-37-2",
        "result": null,
        "limit": 50
    },

    {
        "group": "Dyes – Disperse (Sensitising)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_DISPERSE_SENSITISING",
        "table": "2F",
        "name": "Disperse Blue 35 (12222-75-2)",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.DISPERSE_BLUE_35",
        "unit": "μg/L",
        "cas": "12222-75-2",
        "result": null,
        "limit": 50
    },

    {
        "group": "Dyes – Disperse (Sensitising)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_DISPERSE_SENSITISING",
        "table": "2F",
        "name": "Disperse Blue 124",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.DISPERSE_BLUE_124",
        "unit": "μg/L",
        "cas": "61951-51-7",
        "result": null,
        "limit": 50
    },

    {
        "group": "Dyes – Disperse (Sensitising)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_DISPERSE_SENSITISING",
        "table": "2F",
        "name": "Disperse Yellow 9",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.DISPERSE_YELLOW_9",
        "unit": "μg/L",
        "cas": "6373-73-5",
        "result": null,
        "limit": 50
    },

    {
        "group": "Dyes – Disperse (Sensitising)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_DISPERSE_SENSITISING",
        "table": "2F",
        "name": "Disperse Orange 3",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.DISPERSE_ORANGE_3",
        "unit": "μg/L",
        "cas": "730-40-5",
        "result": null,
        "limit": 50
    },

    {
        "group": "Dyes – Disperse (Sensitising)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.DYES_DISPERSE_SENSITISING",
        "table": "2F",
        "name": "Disperse Blue 35 (56524-77-7)",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.DISPERSE_BLUE_35_1", //there are two disperse blue 35 with different cas number)
        "unit": "μg/L",
        "cas": "56524-77-7",
        "result": null,
        "limit": 50
    },

    //table 2G
    {
        "group": "Flame Retardants",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.FLAME_RETARDANTS",
        "table": "2G",
        "name": "Tris(2-chloroethyl)phosphate (TCEP)",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.TRIS2-CHLOROETHYLPHOSPHATE_TCEP",
        "unit": "μg/L",
        "cas": "115-96-8",
        "result": null,
        "limit": 5
    },

    {
        "group": "Flame Retardants",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.FLAME_RETARDANTS",
        "table": "2G",
        "name": "Decabromodiphenyl ether (DecaBDE)",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.DECABROMODIPHENYL_ETHER_DECABDE",
        "unit": "μg/L",
        "cas": "1163-19-5",
        "result": null,
        "limit": 5
    },

    {
        "group": "Flame Retardants",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.FLAME_RETARDANTS",
        "table": "2G",
        "name": "Tris(2,3,-dibromopropyl) phosphate (TRIS)",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.TRIS2-3-DIBROMOPROPYL_PHOSPHATE_TRIS",
        "unit": "μg/L",
        "cas": "126-72-7",
        "result": null,
        "limit": 5
    },

    {
        "group": "Flame Retardants",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.FLAME_RETARDANTS",
        "table": "2G",
        "name": "Pentabromodiphenyl ether (PentaBDE)",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.PENTABROMODIPHENYL_ETHER_PENTABDE",
        "unit": "μg/L",
        "cas": "32534-81-9",
        "result": null,
        "limit": 5
    },

    {
        "group": "Flame Retardants",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.FLAME_RETARDANTS",
        "table": "2G",
        "name": "Octabromodiphenyl ether (OctaBDE)",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.OCTABROMODIPHENYL_ETHER_OCTABDE",
        "unit": "μg/L",
        "cas": "32536-52-0",
        "result": null,
        "limit": 5
    },

    {
        "group": "Flame Retardants",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.FLAME_RETARDANTS",
        "table": "2G",
        "name": "Bis(2,3-dibromopropyl) phosphate (BIS)",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.BIS2-3-DIBROMOPROPYL_PHOSPHATE_BIS",
        "unit": "μg/L",
        "cas": "5412-25-9",
        "result": null,
        "limit": 5
    },

    {
        "group": "Flame Retardants",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.FLAME_RETARDANTS",
        "table": "2G",
        "name": "Tris(1-aziridinyl) phosphine oxide (TEPA)",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.TRIS1-AZIRIDINYL_PHOSPHINE_OXIDE_TEPA",
        "unit": "μg/L",
        "cas": "545-55-1",
        "result": null,
        "limit": 5
    },

    {
        "group": "Flame Retardants",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.FLAME_RETARDANTS",
        "table": "2G",
        "name": "Polybromobiphenyls (PBB)",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.POLYBROMOBIPHENYLS_PBB",
        "unit": "μg/L",
        "cas": "59536-65-1",
        "result": null,
        "limit": 5
    },

    {
        "group": "Flame Retardants",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.FLAME_RETARDANTS",
        "table": "2G",
        "name": "Tetrabromobisphenol A (TBBPA)",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.TETRABROMOBISPHENOL_A_TBBPA",
        "unit": "μg/L",
        "cas": "79-94-7",
        "result": null,
        "limit": 5
    },

    {
        "group": "Flame Retardants",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.FLAME_RETARDANTS",
        "table": "2G",
        "name": "Hexabromocyclododecane (HBCDD)",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.HEXABROMOCYCLODODECANE_HBCDD",
        "unit": "μg/L",
        "cas": "3194-55-6",
        "result": null,
        "limit": 5
    },

    {
        "group": "Flame Retardants",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.FLAME_RETARDANTS",
        "table": "2G",
        "name": "2,2-bis(bromomethyl)-1,3-propanediol (BBMP)",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.2-2-BISBROMOMETHYL-1-3-PROPANEDIOL_BBMP",
        "unit": "μg/L",
        "cas": "3296-90-0",
        "result": null,
        "limit": 5
    },

    {
        "group": "Flame Retardants",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.FLAME_RETARDANTS",
        "table": "2G",
        "name": "Tris(1,3-dichloro-isopropyl) phosphate (TDCP)",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.TRIS1-3-DICHLORO-ISOPROPYL_PHOSPHATE_TDCP",
        "unit": "μg/L",
        "cas": "13674-87-8",
        "result": null,
        "limit": 5
    },

    {
        "group": "Flame Retardants",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.FLAME_RETARDANTS",
        "table": "2G",
        "name": "Short-chain chlorinated Paraffins (SCCP) (C10-C13)",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.SHORT-CHAIN_CHLORINATED_PARAFFINS_SCCP_C10-C13",
        "unit": "μg/L",
        "cas": "85535-84-8",
        "result": null,
        "limit": 5
    },

    //table 2H
    {
        "group": "Glycols",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.GLYCOLS",
        "table": "2H",
        "name": "Bis(2-methoxyethyl)-ether",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.BIS2-METHOXYETHYL-ETHER",
        "unit": "μg/L",
        "cas": "111-96-6",
        "result": null,
        "limit": 50
    },

    {
        "group": "Glycols",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.GLYCOLS",
        "table": "2H",
        "name": "2-ethoxyethanol",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.2-ETHOXYETHANOL",
        "unit": "μg/L",
        "cas": "110-80-5",
        "result": null,
        "limit": 50
    },

    {
        "group": "Glycols",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.GLYCOLS",
        "table": "2H",
        "name": "2-ethoxyethyl acetate",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.2-ETHOXYETHYL_ACETATE",
        "unit": "μg/L",
        "cas": "111-15-9",
        "result": null,
        "limit": 50
    },

    {
        "group": "Glycols",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.GLYCOLS",
        "table": "2H",
        "name": "Ethylene glycol dimethyl ether",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.ETHYLENE_GLYCOL_DIMETHYL_ETHER",
        "unit": "μg/L",
        "cas": "110-71-4",
        "result": null,
        "limit": 50
    },

    {
        "group": "Glycols",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.GLYCOLS",
        "table": "2H",
        "name": "2-methoxyethanol",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.2-METHOXYETHANOL",
        "unit": "μg/L",
        "cas": "109-86-4",
        "result": null,
        "limit": 50
    },

    {
        "group": "Glycols",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.GLYCOLS",
        "table": "2H",
        "name": "2-methoxyethylacetate",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.2-METHOXYETHYLACETATE",
        "unit": "μg/L",
        "cas": "110-49-6",
        "result": null,
        "limit": 50
    },

    {
        "group": "Glycols",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.GLYCOLS",
        "table": "2H",
        "name": "2-methoxypropylacetate",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.2-METHOXYPROPYLACETATE",
        "unit": "μg/L",
        "cas": "70657-70-4",
        "result": null,
        "limit": 50
    },

    {
        "group": "Glycols",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.GLYCOLS",
        "table": "2H",
        "name": "Triethylene glycol dimethyl ether",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.TRIETHYLENE_GLYCOL_DIMETHYL_ETHER",
        "unit": "μg/L",
        "cas": "112-49-2",
        "result": null,
        "limit": 50
    },

    //table 2I

    {
        "group": "Halogenated Solvents",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.HALOGENATED_SOLVENTS",
        "table": "2I",
        "name": "1,2-dichloroethane",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.1-2-DICHLOROETHANE",
        "unit": "μg/L",
        "cas": "107-06-2",
        "result": null,
        "limit": 1
    },


    {
        "group": "Halogenated Solvents",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.HALOGENATED_SOLVENTS",
        "table": "2I",
        "name": "Methylene chloride",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.METHYLENE_CHLORIDE",
        "unit": "μg/L",
        "cas": "75-09-2",
        "result": null,
        "limit": 1
    },


    {
        "group": "Halogenated Solvents",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.HALOGENATED_SOLVENTS",
        "table": "2I",
        "name": "Trichloroethylene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.TRICHLOROETHYLENE",
        "unit": "μg/L",
        "cas": "79-01-6",
        "result": null,
        "limit": 1
    },


    {
        "group": "Halogenated Solvents",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.HALOGENATED_SOLVENTS",
        "table": "2I",
        "name": "Tetrachloroethylene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.TETRACHLOROETHYLENE",
        "unit": "μg/L",
        "cas": "127-18-4",
        "result": null,
        "limit": 1
    },

    //Table 2J
    {
        "group": "Organotin Compounds",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.ORGANOTIN_COMPOUNDS",
        "table": "2J",
        "name": "Mono-, di- and tri-methyltin derivatives",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.MONO-DI-AND-TRI-METHYLTIN_DERIVATIVES",
        "unit": "μg/L",
        "cas": "Multiple",
        "result": null,
        "limit": 0.01
    },

    {
        "group": "Organotin Compounds",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.ORGANOTIN_COMPOUNDS",
        "table": "2J",
        "name": "Mono-, di- and tri-butyltin derivatives",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.MONO-DI-AND_TRI-BUTYLTIN_DERIVATIVES",
        "unit": "μg/L",
        "cas": "Multiple",
        "result": null,
        "limit": 0.01
    },

    {
        "group": "Organotin Compounds",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.ORGANOTIN_COMPOUNDS",
        "table": "2J",
        "name": "Mono-, di- and tri-phenyltin derivatives",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.MONO_DI-AND_TRI-PHENYLTIN_DERIVATIVES",
        "unit": "μg/L",
        "cas": "Multiple",
        "result": null,
        "limit": 0.01
    },

    {
        "group": "Organotin Compounds",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.ORGANOTIN_COMPOUNDS",
        "table": "2J",
        "name": "Mono-, di- and tri-octyltin derivatives",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.MONO_DI-AND_TRI-OCTYLTIN_DERIVATIVES",
        "unit": "μg/L",
        "cas": "Multiple",
        "result": null,
        "limit": 0.01
    },

    //table 2K

    {
        "group": "Perfluorinated and Polyfluorinated Chemicals (PFCs)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.PERFLUORINATED_AND_POLYFLUORINATED_CHEMICALS_PFCS",
        "table": "2K",
        "name": "PFOS",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.PFOS",
        "unit": "μg/L",
        "cas": "1763-23-1",
        "result": null,
        "limit": 0.01
    },

    {
        "group": "Perfluorinated and Polyfluorinated Chemicals (PFCs)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.PERFLUORINATED_AND_POLYFLUORINATED_CHEMICALS_PFCS",
        "table": "2K",
        "name": "PFOA",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.PFOA",
        "unit": "μg/L",
        "cas": "335-67-1",
        "result": null,
        "limit": 0.01
    },

    {
        "group": "Perfluorinated and Polyfluorinated Chemicals (PFCs)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.PERFLUORINATED_AND_POLYFLUORINATED_CHEMICALS_PFCS",
        "table": "2K",
        "name": "PFBS",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.PFBS",
        "unit": "μg/L",
        "cas": "375-73-5 29420-49-3 29420-43-3",
        "result": null,
        "limit": 0.01
    },

    {
        "group": "Perfluorinated and Polyfluorinated Chemicals (PFCs)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.PERFLUORINATED_AND_POLYFLUORINATED_CHEMICALS_PFCS",
        "table": "2K",
        "name": "PFHxA",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.PFHxA",
        "unit": "μg/L",
        "cas": "307-24-4",
        "result": null,
        "limit": 0.01
    },

    {
        "group": "Perfluorinated and Polyfluorinated Chemicals (PFCs)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.PERFLUORINATED_AND_POLYFLUORINATED_CHEMICALS_PFCS",
        "table": "2K",
        "name": "8:2 FTOH",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.8-2_FTOH",
        "unit": "μg/L",
        "cas": "678-39-7",
        "result": null,
        "limit": 1
    },

    {
        "group": "Perfluorinated and Polyfluorinated Chemicals (PFCs)",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.PERFLUORINATED_AND_POLYFLUORINATED_CHEMICALS_PFCS",
        "table": "2K",
        "name": "6:2 FTOH",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.6-2_FTOH",
        "unit": "μg/L",
        "cas": "647-42-7",
        "result": null,
        "limit": 1
    },


    //table 2L
    {
        "group": "Ortho-Phthalates",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.ORTHO-PHTHALATES",
        "table": "2L",
        "name": "Di(ethylhexyl) phthalate (DEHP)",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.DIETHYLHEXYL_PHTHALATE_DEHP",
        "unit": "μg/L",
        "cas": "117-81-7",
        "result": null,
        "limit": 10
    },

    {
        "group": "Ortho-Phthalates",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.ORTHO-PHTHALATES",
        "table": "2L",
        "name": "Bis(2-methoxyethyl) phthalate (DMEP)",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.BIS2-METHOXYETHYL_PHTHALATE_DMEP",
        "unit": "μg/L",
        "cas": "117-82-8",
        "result": null,
        "limit": 10
    },

    {
        "group": "Ortho-Phthalates",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.ORTHO-PHTHALATES",
        "table": "2L",
        "name": "Di-n-octyl phthalate (DNOP)",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.DI-N-OCTYL_PHTHALATE_DNOP",
        "unit": "μg/L",
        "cas": "117-84-0",
        "result": null,
        "limit": 10
    },

    {
        "group": "Ortho-Phthalates",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.ORTHO-PHTHALATES",
        "table": "2L",
        "name": "Di-iso-decyl phthalate (DIDP)",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.DI-ISO-DECYL_PHTHALATE_DIDP",
        "unit": "μg/L",
        "cas": "26761-40-0",
        "result": null,
        "limit": 10
    },

    {
        "group": "Ortho-Phthalates",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.ORTHO-PHTHALATES",
        "table": "2L",
        "name": "Di-isononyl phthalate (DINP)",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.DI-ISONONYL_PHTHALATE_DINP",
        "unit": "μg/L",
        "cas": "28553-12-0",
        "result": null,
        "limit": 10
    },

    {
        "group": "Ortho-Phthalates",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.ORTHO-PHTHALATES",
        "table": "2L",
        "name": "Di-n-hexyl phthalate (DnHP)",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.DI-N-HEXYL_PHTHALATE_DNHP",
        "unit": "μg/L",
        "cas": "84-75-3",
        "result": null,
        "limit": 10
    },

    {
        "group": "Ortho-Phthalates",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.ORTHO-PHTHALATES",
        "table": "2L",
        "name": "Dibutyl phthalate (DBP)",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.DIBUTYL_PHTHALATE_DBP",
        "unit": "μg/L",
        "cas": "84-74-2",
        "result": null,
        "limit": 10
    },

    {
        "group": "Ortho-Phthalates",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.ORTHO-PHTHALATES",
        "table": "2L",
        "name": "Butyl benzyl phthalate (BBP)",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.BUTYL_BENZYL_PHTHALATE_BBP",
        "unit": "μg/L",
        "cas": "85-68-7",
        "result": null,
        "limit": 10
    },

    {
        "group": "Ortho-Phthalates",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.ORTHO-PHTHALATES",
        "table": "2L",
        "name": "Dinonyl phthalate (DNP)",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.DINONYL_PHTHALATE_DNP",
        "unit": "μg/L",
        "cas": "84-76-4",
        "result": null,
        "limit": 10
    },

    {
        "group": "Ortho-Phthalates",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.ORTHO-PHTHALATES",
        "table": "2L",
        "name": "Diethyl phthalate (DEP)",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.DIETHYL_PHTHALATE_DEP",
        "unit": "μg/L",
        "cas": "84-66-2",
        "result": null,
        "limit": 10
    },

    {
        "group": "Ortho-Phthalates",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.ORTHO-PHTHALATES",
        "table": "2L",
        "name": "Di-n-propyl phthalate (DPRP)",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.DI-N-PROPYL_PHTHALATE_DPRP",
        "unit": "μg/L",
        "cas": "131-16-8",
        "result": null,
        "limit": 10
    },

    {
        "group": "Ortho-Phthalates",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.ORTHO-PHTHALATES",
        "table": "2L",
        "name": "Di-isobutyl phthalate (DIBP)",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.DI-ISOBUTYL_PHTHALATE_DIBP",
        "unit": "μg/L",
        "cas": "84-69-5",
        "result": null,
        "limit": 10
    },

    {
        "group": "Ortho-Phthalates",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.ORTHO-PHTHALATES",
        "table": "2L",
        "name": "Di-cyclohexyl phthalate (DCHP)",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.DI-CYCLOHEXYL_PHTHALATE_DCHP",
        "unit": "μg/L",
        "cas": "84-61-7",
        "result": null,
        "limit": 10
    },

    {
        "group": "Ortho-Phthalates",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.ORTHO-PHTHALATES",
        "table": "2L",
        "name": "Di-iso-octyl phthalate (DIOP)",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.DI-ISO-OCTYL_PHTHALATE_DIOP",
        "unit": "μg/L",
        "cas": "27554-26-3",
        "result": null,
        "limit": 10
    },

    {
        "group": "Ortho-Phthalates",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.ORTHO-PHTHALATES",
        "table": "2L",
        "name": "1,2-benzenedicarboxylic acid, di-C7-11- branched and linear alkyl esters (DHNUP)",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.1-2-BENZENEDICARBOXYLIC_ACID_DI-C7-11-_BRANCHED_AND_LINEAR_ALKYL_ESTERS_DHNUP",
        "unit": "μg/L",
        "cas": "68515-42-4",
        "result": null,
        "limit": 10
    },

    {
        "group": "Ortho-Phthalates",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.ORTHO-PHTHALATES",
        "table": "2L",
        "name": "1,2-benzenedicarboxylic acid, di-C6-8- branched alkyl esters, C7-rich (DIHP)",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.1-2-BENZENEDICARBOXYLIC_ACID_DI-C6-8-_BRANCHED_ALKYL_ESTERS_C7-RICH_DIHP",
        "unit": "μg/L",
        "cas": "71888-89-6",
        "result": null,
        "limit": 10
    },


    //table 2M
    {
        "group": "Polycyclic Aromatic Hydrocarbons",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.POLYCYCLIC_AROMATIC_HYDROCARBONS",
        "table": "2M",
        "name": "Benzo[a]pyrene (BaP)",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.BENZO-A-PYRENE_BAP",
        "unit": "μg/L",
        "cas": "50-32-8",
        "result": null,
        "limit": 1
    },

    {
        "group": "Polycyclic Aromatic Hydrocarbons",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.POLYCYCLIC_AROMATIC_HYDROCARBONS",
        "table": "2M",
        "name": "Anthracene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.ANTHRACENE",
        "unit": "μg/L",
        "cas": "120-12-7",
        "result": null,
        "limit": 1
    },

    {
        "group": "Polycyclic Aromatic Hydrocarbons",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.POLYCYCLIC_AROMATIC_HYDROCARBONS",
        "table": "2M",
        "name": "Pyrene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.PYRENE",
        "unit": "μg/L",
        "cas": "129-00-0",
        "result": null,
        "limit": 1
    },

    {
        "group": "Polycyclic Aromatic Hydrocarbons",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.POLYCYCLIC_AROMATIC_HYDROCARBONS",
        "table": "2M",
        "name": "Benzo[ghi]perylene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.BENZO-GHI-PERYLENE",
        "unit": "μg/L",
        "cas": "191-24-2",
        "result": null,
        "limit": 1
    },

    {
        "group": "Polycyclic Aromatic Hydrocarbons",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.POLYCYCLIC_AROMATIC_HYDROCARBONS",
        "table": "2M",
        "name": "Benzo[e]pyrene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.BENZO-E-PYRENE",
        "unit": "μg/L",
        "cas": "192-97-2",
        "result": null,
        "limit": 1
    },

    {
        "group": "Polycyclic Aromatic Hydrocarbons",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.POLYCYCLIC_AROMATIC_HYDROCARBONS",
        "table": "2M",
        "name": "Indeno[1,2,3-cd]pyrene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.INDENO-1-2-3-CD-PYRENE",
        "unit": "μg/L",
        "cas": "193-39-5",
        "result": null,
        "limit": 1
    },

    {
        "group": "Polycyclic Aromatic Hydrocarbons",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.POLYCYCLIC_AROMATIC_HYDROCARBONS",
        "table": "2M",
        "name": "Benzo[j]fluoranthene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.BENZO-J-FLUORANTHENE",
        "unit": "μg/L",
        "cas": "205-82-3",
        "result": null,
        "limit": 1
    },

    {
        "group": "Polycyclic Aromatic Hydrocarbons",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.POLYCYCLIC_AROMATIC_HYDROCARBONS",
        "table": "2M",
        "name": "Benzo[b]fluoranthene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.BENZO-B-FLUORANTHENE",
        "unit": "μg/L",
        "cas": "205-99-2",
        "result": null,
        "limit": 1
    },

    {
        "group": "Polycyclic Aromatic Hydrocarbons",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.POLYCYCLIC_AROMATIC_HYDROCARBONS",
        "table": "2M",
        "name": "Fluoranthene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.FLUORANTHENE",
        "unit": "μg/L",
        "cas": "206-44-0",
        "result": null,
        "limit": 1
    },

    {
        "group": "Polycyclic Aromatic Hydrocarbons",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.POLYCYCLIC_AROMATIC_HYDROCARBONS",
        "table": "2M",
        "name": "Benzo[k]fluoranthene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.BENZO-K-FLUORANTHENE",
        "unit": "μg/L",
        "cas": "207-08-9",
        "result": null,
        "limit": 1
    },

    {
        "group": "Polycyclic Aromatic Hydrocarbons",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.POLYCYCLIC_AROMATIC_HYDROCARBONS",
        "table": "2M",
        "name": "Acenaphthylene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.ACENAPHTHYLENE",
        "unit": "μg/L",
        "cas": "208-96-8",
        "result": null,
        "limit": 1
    },

    {
        "group": "Polycyclic Aromatic Hydrocarbons",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.POLYCYCLIC_AROMATIC_HYDROCARBONS",
        "table": "2M",
        "name": "Chrysene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.CHRYSENE",
        "unit": "μg/L",
        "cas": "218-01-9",
        "result": null,
        "limit": 1
    },

    {
        "group": "Polycyclic Aromatic Hydrocarbons",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.POLYCYCLIC_AROMATIC_HYDROCARBONS",
        "table": "2M",
        "name": "Dibenz[a,h]anthracene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.DIBENZ-A-H-ANTHRACENE",
        "unit": "μg/L",
        "cas": "53-70-3",
        "result": null,
        "limit": 1
    },

    {
        "group": "Polycyclic Aromatic Hydrocarbons",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.POLYCYCLIC_AROMATIC_HYDROCARBONS",
        "table": "2M",
        "name": "Benzo[a]anthracene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.BENZO-A-ANTHRACENE",
        "unit": "μg/L",
        "cas": "56-55-3",
        "result": null,
        "limit": 1
    },

    {
        "group": "Polycyclic Aromatic Hydrocarbons",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.POLYCYCLIC_AROMATIC_HYDROCARBONS",
        "table": "2M",
        "name": "Acenaphthene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.ACENAPHTHENE",
        "unit": "μg/L",
        "cas": "83-32-9",
        "result": null,
        "limit": 1
    },

    {
        "group": "Polycyclic Aromatic Hydrocarbons",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.POLYCYCLIC_AROMATIC_HYDROCARBONS",
        "table": "2M",
        "name": "Phenanthrene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.PHENANTHRENE",
        "unit": "μg/L",
        "cas": "85-01-8",
        "result": null,
        "limit": 1
    },

    {
        "group": "Polycyclic Aromatic Hydrocarbons",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.POLYCYCLIC_AROMATIC_HYDROCARBONS",
        "table": "2M",
        "name": "Fluorene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.FLUORENE",
        "unit": "μg/L",
        "cas": "86-73-7",
        "result": null,
        "limit": 1
    },

    {
        "group": "Polycyclic Aromatic Hydrocarbons",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.POLYCYCLIC_AROMATIC_HYDROCARBONS",
        "table": "2M",
        "name": "Naphthalene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.NAPHTHALENE",
        "unit": "μg/L",
        "cas": "91-20-3",
        "result": null,
        "limit": 1
    },

    //table 2N
    {
        "group": "Volatile Organic Compounds",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.VOLATILE_ORGANIC_COMPOUNDS",
        "table": "2N",
        "name": "Benzene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.BENZENE",
        "unit": "μg/L",
        "cas": "71-43-2",
        "result": null,
        "limit": 1
    },

    {
        "group": "Volatile Organic Compounds",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.VOLATILE_ORGANIC_COMPOUNDS",
        "table": "2N",
        "name": "Xylene",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.XYLENE",
        "unit": "μg/L",
        "cas": "1330-20-7",
        "result": null,
        "limit": 1
    },

    {
        "group": "Volatile Organic Compounds",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.VOLATILE_ORGANIC_COMPOUNDS",
        "table": "2N",
        "name": "o-cresol",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.O-CRESOL",
        "unit": "μg/L",
        "cas": "95-48-7",
        "result": null,
        "limit": 1
    },

    {
        "group": "Volatile Organic Compounds",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.VOLATILE_ORGANIC_COMPOUNDS",
        "table": "2N",
        "name": "p-cresol",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.P-CRESOL",
        "unit": "μg/L",
        "cas": "106-44-5",
        "result": null,
        "limit": 1
    },

    {
        "group": "Volatile Organic Compounds",
        "group_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.GROUP.VOLATILE_ORGANIC_COMPOUNDS",
        "table": "2N",
        "name": "m-cresol",
        "name_trans": "WASTE_WATER_UPLOAD_COMPONENT.OPTIONS.ZDHC_PARAMETERS.CHEMICALS.M-CRESOL",
        "unit": "μg/L",
        "result": null,
        "cas": "108-39-4"
    }

]