import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CubeClientModule } from '@cubejs-client/ngx';
import { TranslateModule } from '@ngx-translate/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NewLaunchDialogComponent } from 'app/new-launch-dialog/new-launch-dialog.component';
// import { FormulaPieChartTemplateComponent } from 'app/visualizations/formula-pie-chart-template/formula-pie-chart-template.component';
import { NgxLoadingModule } from 'ngx-loading';
import { FormulaPieChartsComponent } from '../visualizations/formula-pie-charts/formula-pie-charts.component';
import { DashboardBrandComponent } from './dashboard-brand/dashboard-brand.component';
import { DashboardFactoryComponent } from './dashboard-factory/dashboard-factory.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        NgxChartsModule,
        TranslateModule,
        CubeClientModule,
        MatTableModule,
        MatCardModule,
        MatPaginatorModule,
        MatSortModule,
        MatGridListModule,
        MatIconModule,
        MatTooltipModule,
        MatTabsModule,
        NgxLoadingModule,
        MatMenuModule
        
    ],
    declarations: [
        FormulaPieChartsComponent,
         DashboardFactoryComponent,
         DashboardBrandComponent,
         NewLaunchDialogComponent,
         LoadingSpinnerComponent,
         MessageDialogComponent,
        //  FormulaPieChartTemplateComponent
        ],
    exports: [
        FormulaPieChartsComponent,
        DashboardFactoryComponent,
        DashboardBrandComponent,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        LoadingSpinnerComponent,
        // FormulaPieChartTemplateComponent,  

    ],
})
export class SharedModule {}
