import { Component, OnInit } from '@angular/core';
import { AddInventoryScanComponent } from '../add-inventory-scan/add-inventory-scan.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { GlobalsService } from '../../globals-services/globals.service';

@Component({
    selector: 'app-input-inventory',
    templateUrl: './input-inventory.component.html',
    styleUrls: ['./input-inventory.component.css'],
})
export class InputInventoryComponent implements OnInit {
    showSuccessMessage: boolean;
    creatorID = '';
    creatorName = '';
    private activatedRouteSubscription: Subscription;

    constructor(
        private globalsService: GlobalsService,
        public dialog: MatDialog,
        private activatedRoute: ActivatedRoute,
        private http: HttpClient
    ) {}

    ngOnInit() {
        this.activatedRouteSubscription = this.activatedRoute.params.subscribe(
            (params) => {
                this.creatorID = params.id;
                if (
                    this.creatorID == null ||
                    this.creatorID === 'undefined' ||
                    this.creatorID === undefined
                ) {
                    this.creatorID = this.globalsService.profile['id'];
                    // console.log(this.creatorID)
                }
                this.http
                    .get(
                        this.globalsService.baseHref +
                            '/getProfile/' +
                            this.creatorID
                    )
                    .subscribe((profile) => {
                        this.creatorName = profile['name'];
                    });
            }
        );
    }

    openInputBox() {
        this.showSuccessMessage = false;
        const dialogRef = this.dialog.open(AddInventoryScanComponent, {
            width: '95%',
            height: '80%',
            autoFocus: true,
            data: { creatorID: this.creatorID, creatorName: this.creatorName },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result === true) {
                this.showSuccessMessage = true;
            }
        });
    }

    isChineseSystem() {
        return this.globalsService.serverLocation === 'China' ? true : false;
    }
}
