
<div class="content">
    <div class="form-group pie-chart-form">
        <mat-form-field *ngIf="(isBrand || isImporter) && !hideFactory" style="width: 30vw;" appearance="fill">
            <mat-label i18n="Inventories report supplier label">{{
                'INVENTORIES_REPORT_COMPONENT.CHART.FACTORIES' | translate }}</mat-label>
            <input matInput type="text" [matAutocomplete]="auto"
                placeholder="{{'INVENTORIES_REPORT_COMPONENT.CHART.SUPPLIER_SELECT_PLACEHOLDER' | translate }}"
                (keyup)="searchThis($event.target.value)" [(ngModel)]="selectedSupplierName" />
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSearchSupplierSelectCharts()">
                <mat-option *ngFor="let option of suggestedList | slice: 0:50" [value]="option.name">
                    <li><strong>{{ option.name }}</strong></li>
                    <li>{{ option.countryName }}</li>
                    <li *ngIf="option.Higg != '' || option.Oar != '' || option.identification != '' ">
                        <span *ngIf="option.Higg != ''">Higg :{{ option.Higg }} </span>&nbsp;
                        <span *ngIf="option.Oar != ''">OAR :{{ option.Oar }}</span>&nbsp;
                        <span *ngIf="option.identification != ''">Supplier:{{ option.identification
                            }}</span>&nbsp;
                        <span *ngIf="option.FFC != ''">FFC:{{ option.FFC }}</span>&nbsp;
                    </li>
                    <li *ngIf="option.activity != ''">
                        Factory Type : {{ option.activity }}
                    </li>
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label i18n="Inventories report standards label">{{
                'INVENTORIES_REPORT_COMPONENT.CHART.STANDARDS' | translate }}</mat-label>
            <mat-select multiple #mySelect [formControl]="selectedStandards">
                <mat-option *ngFor="let standard of standardsList"
                    (onSelectionChange)="showStandardChart($event)" [value]="standard">
                    {{standard.displayName}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="!hideDate" appearance="fill">
            <mat-label i18n="Inventories report date range label">{{
                'INVENTORIES_REPORT_COMPONENT.CHART.DATE_RANGE' | translate }}</mat-label>
            <mat-date-range-input [rangePicker]="picker">
                <input matStartDate placeholder="Start date" [formControl]="startDate">
                <input matEndDate placeholder="End date" [formControl]="endDate"
                    (dateChange)="changeEndDate($event)">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
        <div class="pie-chart-form">
        <mat-slide-toggle  color="primary" [formControl]="commoditiesControl"  (change)="onChangeCommodities($event)" i18n="Inventories report standards label">Commodities </mat-slide-toggle>
        </div>
    </div>
    


    <div *ngIf="chartData.length >0"> 
        <app-formula-pie-charts [origin]="inventory_report" [chartData]="chartData" [commodities]="commoditiesControl.value" [startDate]="sdate"  [endDate]="edate" [supplierID]="this.isSingleInventory == undefined ? selectedSupplier:factoryID['creatorID']" [chartSize]="[500, 320]"
            [containerWidthMultiplier]="500" [isScrollable]="true" [showBhiveDisclaimers]="true" [hasScopeCommodities]="hasScopeCommodities" [isGateway]="iszdhcGateway" [inCheck]="false">
        </app-formula-pie-charts>

    </div>
    <ngx-loading [show]="isLoading"></ngx-loading>
</div>
