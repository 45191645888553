import { ChangeDetectionStrategy, OnChanges, SimpleChanges } from '@angular/core';
import { ViewChild } from '@angular/core';
import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormulaPieChartDialogComponent } from '../formula-pie-chart-dialog/formula-pie-chart-dialog.component';

@Component({
  selector: 'app-formula-pie-charts',
  //changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './formula-pie-charts.component.html',
  styleUrls: ['./formula-pie-charts.component.scss']
})
export class FormulaPieChartsComponent implements OnInit, OnChanges {
  _chartData: Object[] = [];
  filters: Array<Object> = [];

  get chartData(): Object[] {
    return this._chartData;
  }
  @Input() set chartData(newArr: Object[]) {
    this._chartData = newArr;
  }
  @Input() commodities: boolean;
  @Input() startDate: string;
  @Input() endDate: string;
  @Input() supplierID: string;
  @Input() chartSize: number[];
  @Input() isScrollable: boolean;
  @Input() containerWidthMultiplier: number;
  @Input() showBhiveDisclaimers: boolean;
  @Input() showIncheckDisclaimers: boolean;
  @Input() origin: string;
  @Input() hasScopeCommodities: boolean;
  @Input() isGateway: boolean;
  @Input() inCheck: boolean;


  pieChartContainerWidth: number;
  containerWidth: string;
  customColors = [
    {
      name: 'ZDHC MRSL(Crowd-sourced) Verified',
      value: '#389D82'
    },
    {
      name: 'ZDHC MRSL(Gateway) Verified',
      value: '#3953AF'
    },
    {
      name: 'Verified',
      value: '#38A093'
    },
    {
      name: 'Unverified',
      value: '#FF8000'
    },
    {
      name: 'Commodities',
      value: '#a9a9a9'
    },
    {
      name: 'Registered',
      value: '#26716F'
    },
    {
      name: 'Level 1',
      value: '#5B84C4'
    },

    {
      name: 'Level 2',
      value: '#1E65A7'
    },

    {
      name: 'Level 3',
      value: '#192E5B'
    },

    {
      name: 'ZDHC v1.1',
      value: '#40B395'
    },
    {
      name: 'Others',
      value: '#FFB900'
    }

  ];

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
    this.containerWidth = this.containerWidthMultiplier.toString() + 'px';
  }

  ngOnChanges(changes: SimpleChanges) {
    this.getPercentages()
  }


  setPieChartContainerWidth(): String {
    const containerWitdh = document.getElementById('chartContainer').getBoundingClientRect().width
    this.pieChartContainerWidth = this.chartData.length * this.containerWidthMultiplier;


    //center align the pie charts if the length is less than the view length of the user.
    if (containerWitdh >= this.pieChartContainerWidth) {
      return "100%"
    } else {
      return String(this.pieChartContainerWidth) + 'px';
    }

  }

  onPieSliceSelect(e, name: string, id: string, commodities: boolean, sDate: string, eDate: string, overall_filters: any[], brand_filters: any[], supplierID: number, hasScopeCommodities: boolean) {
    if (this.origin == 'Inventory Report') {
      this.filters = [];
      this.openVerifiedDialog(e, this.chartData, name, sDate, eDate, commodities, id, overall_filters, brand_filters, supplierID, hasScopeCommodities);
    } 
    else return false;
  } 


  hasData(chartData) {
    let numberOfChemicals: number = 0;

    for (let data of chartData) {
      numberOfChemicals += data["value"];

    }

    return (numberOfChemicals > 0) ? true : false;
  }

  getPercentages() {
    let chartDataWithTotal = [];
    for (let data of this.chartData) {
      if (!data.hasOwnProperty('totalValue')) {
        let totalValue = 0
        for (const category of data['chartData']) {
          totalValue += Number(category['value'])
        }
        data["totalValue"] = totalValue;
        chartDataWithTotal.push(data)

        this.chartData = chartDataWithTotal;
      }
    }
  }

  openVerifiedDialog(e, chartData: any, name: string, sDate: string, eDate: string, commodities: boolean, id: string, overall_filters: any[], brand_filters: any[], supplierID: number, hasScopeCommodities: boolean ) {
      const dialogRef = this.dialog.open(FormulaPieChartDialogComponent, {
        width:'80vw', height:'fit-content',
        data: { event: e, chartData: chartData, nameStandard: name, start_Date: sDate, end_Date: eDate, commodities: commodities, standard_id: id, overall_filters: overall_filters, brand_filters: brand_filters, supplierID: supplierID, hasScopeCommodities: hasScopeCommodities }

      });
  
      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
      });
    }
    
}


