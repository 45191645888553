import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { GlobalsService } from 'app/globals-services/globals.service';
import { CubejsService } from 'app/shared/cubejs/cubejs.service';
import { ExportDataService } from 'app/shared/services/export-data.service';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { MessageDialogComponent } from 'app/shared/message-dialog/message-dialog.component';


const moment = _rollupMoment || _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-monthly-report',
  templateUrl: './monthly-report.component.html',
  styleUrls: ['./monthly-report.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})

export class MonthlyReportComponent implements OnInit {

  startDate = new FormControl(moment().subtract(2, 'months').startOf('month').format('YYYY-MM-DD'));
  endDate = new FormControl(moment().endOf('month').format('YYYY-MM-DD'));
  startDateString: string;
  endDateString: string;
  filters: Array<Object> = [];
  organization_name: string;
  tabIndex: number = 0;
  @ViewChild('monthlyDataPaginator') monthlyDataPaginator: MatPaginator;
  @ViewChild('monthlyDataMatSort') monthlyDataMatSort: MatSort;
  @ViewChild('monthlyDataAldiHofferPaginator') monthlyDataAldiHofferPaginator: MatPaginator;
  @ViewChild('monthlyDataAldiHofferMatSort') monthlyDataAldiHofferMatSort: MatSort;
  @ViewChild('monthlyDataAldiImporterBrandPaginator') monthlyDataAldiImporterBrandPaginator: MatPaginator;
  @ViewChild('monthlyDataAldiImporterBrandMatSort') monthlyDataAldiImporterBrandMatSort: MatSort;
  @ViewChild('monthlyDataAldiSourcingPaginator') monthlyDataAldiSourcingPaginator: MatPaginator;
  @ViewChild('monthlyDataAldiSourcingMatSort') monthlyDataAldiSourcingMatSort: MatSort;
  @ViewChild('monthlyDataAldiImporterPaginator') monthlyDataAldiImporterPaginator: MatPaginator;
  @ViewChild('monthlyDataAldiImporterMatSort') monthlyDataAldiImporterMatSort: MatSort;
  @ViewChild('monthlyDataKontoorPaginator') monthlyDataKontoorPaginator: MatPaginator;
  @ViewChild('monthlyDataKontoorMatSort') monthlyDataKontoorMatSort: MatSort;
  @ViewChild('monthlyDataInditexPaginator') monthlyDataInditexPaginator: MatPaginator;
  @ViewChild('monthlyDataInditexMatSort') monthlyDataInditexMatSort: MatSort;
  @ViewChild('monthlyDataMilesPaginator') monthlyDataMilesPaginator: MatPaginator;
  @ViewChild('monthlyDataListHmMatSort') monthlyDataListHmMatSort: MatSort;
  @ViewChild('monthlyDataListHmPaginator')monthlyDataListHmPaginator: MatPaginator;
  @ViewChild('monthlyDataMilesMatSort') monthlyDataMilesMatSort: MatSort;
  @ViewChild('exportNikeCSVPaginator') exportNikeCSVPaginator: MatPaginator;
  @ViewChild('exportNikeCSVMatSort') exportNikeCSVMatSort: MatSort;
  isAldi: Boolean = GlobalsService.isAldi;
  isHofer: Boolean = GlobalsService.isHofer;
  isKontoor: Boolean = GlobalsService.isKontoor;
  isAldiSourcing: Boolean = GlobalsService.isAldiSourcing;
  // isAldiImporter: Boolean = GlobalsService.isAldiImporter;
  isInditex: Boolean = GlobalsService.isInditex;
  isDummy: Boolean = GlobalsService.isDummy;
  isAldiImporterBrand: Boolean = GlobalsService.isAldiImporterBrand;
  date = new FormControl(moment().subtract(1, 'month'));
  hasNotData: boolean = false;
  isCubejsConnected: boolean = true;
  isLoading: boolean = false;
  errorList = [];
  isNike: Boolean = GlobalsService.isNike;
  isMiles: Boolean = GlobalsService.isMiles;
  isHm: Boolean = GlobalsService.isHm;
  readonly d = new Date();
  pageSizeOptions: number[] = [10, 50, 100];

  constructor(private cubejsService: CubejsService, private exportService: ExportDataService, public dialog: MatDialog, private globalsService: GlobalsService) { }
  ngOnInit(): void {
    this.filters = [];
    this.organization_name = this.globalsService.profile["name"].toString().toUpperCase();
    let std = new Date(this.startDate.value);
    let edd = new Date(this.endDate.value);
    this.startDateString = new Date(std.getFullYear(), std.getMonth(), std.getDate() + 1).toISOString().slice(0, 10);
    this.endDateString = new Date(edd.getFullYear(), edd.getMonth(), edd.getDate() + 1).toISOString().slice(0, 10);
    if (this.isAldi || this.isHofer) {
      this.filters.push(
        {
          "member": "AldiMonthlyReport.date_of_inventory",
          "operator": "equals",
          "values": [
            this.date.value.format('YYYY-MM')
          ]
        }

      )
      this.getAldiHofferMonthlyData();
    }

    else {
      if (this.isAldiImporterBrand) {
        this.filters.push(
          {
            "member": "AldiImportersReport.date_of_inventory",
            "operator": "equals",
            "values": [
              this.date.value.format('YYYY-MM')
            ]
          }

        )
        this.getAldiImporterBrandMonthlyData();
      }
      else {
        if (this.isKontoor) {
          this.filters.push(
            {
              "member": "KontoorReport.Scan_date",
              "operator": "equals",
              "values": [
                this.date.value.format('YYYY-MM')
              ]
            }

          )
          this.getMonthlyKontoorData();

        }
        else {
          if (this.isAldiSourcing) {
            this.filters.push(
              {
                "member": "AldiSourcing.date",
                "operator": "equals",
                "values": [
                  this.date.value.format('YYYY-MM')
                ]
              }

            )
            this.getAldiSourcingMonthlyData();
          }
          else {
            // if (this.isAldiImporter) {
            //   this.filters.push(
            //     {
            //       "member": "AldiImporter.date",
            //       "operator": "equals",
            //       "values": [
            //         this.date.value.format('YYYY-MM')
            //       ]
            //     }

            //   )
            //   this.getAldiImporterMonthlyData();
            // }

            if (this.isNike)
              this.exportNikeReport()
            else {
              if (this.isInditex) {
                this.filters.push(
                  {
                    "member": "InditexReport.month_of_inventory",
                    "operator": "equals",
                    "values": [
                      this.date.value.format('YYYY-MM')
                    ]
                  }

                )
                this.getInditexMonthlyData()
              }
              else {
                if (this.isMiles) {
                  this.filters.push(
                    {
                      "member": "MilesMonthlyReport.date",
                      "operator": "in",
                      "values": [
                        this.date.value.format('YYYY-MM'), null
                      ]
                    }
                  )
                  this.getMilesMonthlyData();
                  this.getMilesMonthlyDataTotal();
                }
                else {
                  if (this.isHm) {
                    this.filters.push(
                      {
                        "member": "Formulas.scan_date",
                        "operator": "inDateRange",
                        "values": [
                          this.date.value.format('YYYY-MM') + '-01',
                          this.date.value.format('YYYY-MM') + '-' + this.lastday(this.date.value.format('YYYY'), this.date.value.format('MM'))
                        ]
                      }
                    )
                    this.getHmMonthlyData();
                  }
                  else {
                    if (this.isDummy) {
                      this.filters.push(
                        {
                          "member": "Formulas.year_month_scan_date",
                          "operator": "equals",
                          "values": [
                            this.date.value.format('YYYY-MM')
                          ]
                        },
                        // {
                        //   "member": "WasteWater.document_date",
                        //   "operator": "afterDate",
                        //   "values": [
                        //     this.date.value.format('YYYY')+'-01-01'
                        //   ]
                        // },
                        // {
                        //   "member": "Higg.higg_last_upload_date",
                        //   "operator": "gte",
                        //   "values": [
                        //     this.date.value.format('YYYY')+'-01-01'
                        //   ]
                        // }
                      )
                      this.getMonthlyData();
                    }
                  }
                }
              }
            }
            // }
          }
        }
      }

    }
  }


  setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    this.filters = [];
    const ctrlValue = this.date.value!;
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.date.setValue(ctrlValue);
    datepicker.close();
    if (this.isAldi || this.isHofer) {
      this.filters.push(
        {
          "member": "AldiMonthlyReport.date_of_inventory",
          "operator": "equals",
          "values": [
            this.date.value.format('YYYY-MM')
          ]
        }

      )
      this.getAldiHofferMonthlyData();
    }
    else {
      if (this.isAldiImporterBrand) {
        this.filters.push(
          {
            "member": "AldiImportersReport.date_of_inventory",
            "operator": "equals",
            "values": [
              this.date.value.format('YYYY-MM')
            ]
          }

        )
        this.getAldiImporterBrandMonthlyData();
      }
      else {
        // if (this.isAldiImporter) {
        //   this.filters.push(
        //     {
        //       "member": "AldiImporter.date",
        //       "operator": "equals",
        //       "values": [
        //         this.date.value.format('YYYY-MM')
        //       ]
        //     }

        //   )
        //   this.getAldiImporterMonthlyData();
        // }

        if (this.isAldiSourcing) {
          this.filters.push(
            {
              "member": "AldiSourcing.date",
              "operator": "equals",
              "values": [
                this.date.value.format('YYYY-MM')
              ]
            }
          )
          this.getAldiSourcingMonthlyData();
        }
        else if (this.isKontoor) {
          this.filters.push(
            {
              "member": "KontoorReport.Scan_date",
              "operator": "equals",
              "values": [
                this.date.value.format('YYYY-MM')
              ]
            }

          )
          this.getMonthlyKontoorData();
        }
        else {
          if (this.isInditex) {
            this.filters.push(
              {
                "member": "InditexReport.month_of_inventory",
                "operator": "equals",
                "values": [
                  this.date.value.format('YYYY-MM')
                ]
              }

            )
            this.getInditexMonthlyData()
          }
          else {
            if (this.isMiles) {
              this.filters.push(
                {
                  "member": "MilesMonthlyReport.date",
                  "operator": "in",
                  "values": [
                    this.date.value.format('YYYY-MM'), null
                  ]
                }
              )
              this.getMilesMonthlyData();
              this.getMilesMonthlyDataTotal();
            }
            else {
              if (this.isHm) {
                this.filters.push(
                  {
                    "member": "Formulas.scan_date",
                    "operator": "inDateRange",
                    "values": [
                      this.date.value.format('YYYY-MM') + '-01',
                      this.date.value.format('YYYY-MM') + '-' + this.lastday(this.date.value.format('YYYY'), this.date.value.format('MM'))
                    ]
                  }
                )
                this.getHmMonthlyData();
              }
              else {
                if (this.isDummy) {
                  this.filters.push(
                    {
                      "member": "Formulas.year_month_scan_date",
                      "operator": "equals",
                      "values": [
                        this.date.value.format('YYYY-MM')
                      ]
                    },
                    // {
                    //   "member": "WasteWater.document_date",
                    //   "operator": "afterDate",
                    //   "values": [
                    //     this.date.value.format('YYYY') + '-01-01'
                    //   ]
                    // },
                    // {
                    //   "member": "Higg.higg_last_upload_date",
                    //   "operator": "gte",
                    //   "values": [
                    //     this.date.value.format('YYYY') + '-01-01'
                    //   ]
                    // }
                  )
                  this.getMonthlyData();
                }
              }
            }
          }
        }


      }
    }
  }

  changeKey(originalKey: string, newKey: string, arr: Array<Object>) {
    var newArr = [];
    for (var i = 0; i < arr.length; i++) {
      var obj = arr[i];
      obj[newKey] = (newKey == "value" && obj[originalKey] === null) ? 0 : obj[originalKey];
      delete (obj[originalKey]);
      newArr.push(obj);
    }
    return newArr;
  }


  monthlyData = [

    "zdhc_aid",
    "factory_name",
    "contact_person",
    "email",
    "phone",
    "vendor_id",
    "higg_id",
    "mill_id",
    "activity",
    "country_name",
    "inventory_date",
    "nr_of_chemicals",
    "cap_for_chemicals",
    "compliance_pct",
    "has_uploaded_waste_water",
    "has_uploaded_incheck",
    "has_uploaded_higg",
    "last_upload_date",
  ]

  monthlyDataList = new MatTableDataSource<any>()
  isLoadingMonthlyDataInfo = false;

  getMonthlyData() {
    this.isLoadingMonthlyDataInfo = true;
    const query = {
      "dimensions": [
        "Formulas.organization_name",
        "Factories.contact_person",
        "Factories.email",
        "Factories.phone",
        "Vendors.partner_id_value",
        "Factories.activity",
        "Factories.country_name",
        "Formulas.last_scan_month",
        "Formulas.year_month_scan_date"


      ],
      "timeDimensions": [],
      "order": {
      },
      "filters": this.filters,
      "measures": [
        "SupplierIdentification.zdhc_aid",
        "SupplierIdentification.higg_id",
        "SupplierIdentification.supplier_identification",
        "Formulas.formula_distinct_count",
        "Formulas.in_cap",
        "Formulas.factory_compliant_pct",
        "WasteWater.total_count",
        "DocumentCenter.incheck_last_month",
        "Higg.total_count",

      ]
    }
    this.cubejsService.getCubeJSData(query, null, null, true, false)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        if (tempData.length > 0) {
          this.hasNotData = false;
          tempData = this.changeKey("SupplierIdentification.zdhc_aid", "zdhc_aid", tempData)
          tempData = this.changeKey("Formulas.organization_name", "factory_name", tempData)
          tempData = this.changeKey("Factories.contact_person", "contact_person", tempData)
          tempData = this.changeKey("Factories.email", "email", tempData)
          tempData = this.changeKey("Factories.phone", "phone", tempData)
          tempData = this.changeKey("Vendors.partner_id_value", "vendor_id", tempData)
          tempData = this.changeKey("SupplierIdentification.higg_id", "higg_id", tempData)
          tempData = this.changeKey("SupplierIdentification.supplier_identification", "mill_id", tempData)
          tempData = this.changeKey("Factories.activity", "activity", tempData)
          tempData = this.changeKey("Factories.country_name", "country_name", tempData)
          tempData = this.changeKey("Formulas.year_month_scan_date", "inventory_date", tempData)
          tempData = this.changeKey("Formulas.formula_distinct_count", "nr_of_chemicals", tempData)
          tempData = this.changeKey("Formulas.in_cap", "cap_for_chemicals", tempData)
          tempData = this.changeKey("Formulas.factory_compliant_pct", "compliance_pct", tempData)
          tempData = this.changeKey("WasteWater.total_count", "has_uploaded_waste_water", tempData)
          tempData = this.changeKey("DocumentCenter.incheck_last_month", "has_uploaded_incheck", tempData)
          tempData = this.changeKey("Higg.total_count", "has_uploaded_higg", tempData)
          tempData = this.changeKey("Formulas.last_scan_month", "last_upload_date", tempData)

          tempData = tempData.map(obj => {
            if (obj.has_uploaded_incheck == this.date.value.format('YYYY-MM'))
              obj.has_uploaded_incheck = 'Yes';
            else obj.has_uploaded_incheck = 'No';
            if (obj.has_uploaded_higg > 0)
              obj.has_uploaded_higg = 'Yes';
            else obj.has_uploaded_higg = 'No';
            if (obj.has_uploaded_waste_water > 0)
              obj.has_uploaded_waste_water = 'Yes';
            else obj.has_uploaded_waste_water = 'No';
            if (obj.cap_for_chemicals == 'Not in CAP')
              obj.cap_for_chemicals = 'No';
            else obj.cap_for_chemicals = 'Yes';
            return obj

          });
        }
        else this.hasNotData = true;
        this.monthlyDataList.data = tempData;
        this.monthlyDataList.paginator = this.monthlyDataPaginator;
        this.monthlyDataList.sort = this.monthlyDataMatSort;
        this.isLoadingMonthlyDataInfo = false;
        // }
        // else this.isLoadingMonthlyDataInfo = false;
      })
  }

  aldi_hoffer_monthlyData = [
    "factory_name",
    "brand_id",
    "date_of_inventory",
    "total_number_of_chemicals",
    "consumption",
    "total_production_facility",
    // "aldi_number_of_chemicals",
    "brand_production"
  ]

  monthlyDataListAldiHoffer = new MatTableDataSource<any>()
  isLoadingMonthlyDataInfoAldiHoffer = false;

  getAldiHofferMonthlyData() {
    this.isLoadingMonthlyDataInfoAldiHoffer = true;
    const query = {
      "dimensions": [
        "AldiMonthlyReport.factory_name",
        "AldiMonthlyReport.brand_id",
        "AldiMonthlyReport.date_of_inventory",
        "AldiMonthlyReport.total_number_of_chemicals",
        "AldiMonthlyReport.consumption",
        "AldiMonthlyReport.total_production_facility",
        // "AldiMonthlyReport.aldi_number_of_chemicals",
        "AldiMonthlyReport.aldi_group_production"
      ],
      "timeDimensions": [],
      "order": {},
      "filters": this.filters

    }
    this.cubejsService.getCubeJSData(query, null, null, true, false)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        if (tempData.length > 0) {
          this.hasNotData = false;
          tempData = this.changeKey("AldiMonthlyReport.factory_name", "factory_name", tempData)
          tempData = this.changeKey("AldiMonthlyReport.brand_id", "brand_id", tempData)
          tempData = this.changeKey("AldiMonthlyReport.date_of_inventory", "date_of_inventory", tempData)
          tempData = this.changeKey("AldiMonthlyReport.total_number_of_chemicals", "total_number_of_chemicals", tempData)
          tempData = this.changeKey("AldiMonthlyReport.consumption", "consumption", tempData)
          tempData = this.changeKey("AldiMonthlyReport.total_production_facility", "total_production_facility", tempData)
          // tempData = this.changeKey("AldiMonthlyReport.aldi_number_of_chemicals", "aldi_number_of_chemicals", tempData)
          tempData = this.changeKey("AldiMonthlyReport.aldi_group_production", "brand_production", tempData)
          tempData = tempData.map(obj => {
            obj.date_of_inventory = moment(obj.date_of_inventory).format('YYYY-MM');
            obj.consumption = Number(parseFloat(obj.consumption).toFixed(2));
            obj.total_production_facility = Number(parseFloat(obj.total_production_facility).toFixed(2));
            if (obj.brand_production)
              obj.brand_production = Number(parseFloat(obj.brand_production).toFixed(2));
            return obj

          });
        }
        else this.hasNotData = true;
        this.monthlyDataListAldiHoffer.data = tempData;
        this.monthlyDataListAldiHoffer.paginator = this.monthlyDataAldiHofferPaginator;
        this.monthlyDataListAldiHoffer.sort = this.monthlyDataAldiHofferMatSort;
        this.isLoadingMonthlyDataInfoAldiHoffer = false;
      })
  }
  aldi_importer_brands_monthlyData = [
    "factory_name",
    "brand_id",
    "date_of_inventory",
    "total_number_of_chemicals",
    "consumption",
    "total_production_facility",
    "aldi_number_of_chemicals",
    "aldi_group_production",
    "brand_chemical_count",
    "brand_production",
    "connected_to_aldi"
  ]

  monthlyDataListAldiImporterBrand = new MatTableDataSource<any>()
  isLoadingMonthlyDataInfoAldiImporterBrand = false;

  getAldiImporterBrandMonthlyData() {
    this.isLoadingMonthlyDataInfoAldiImporterBrand = true;
    const query = {
      "dimensions": [
        "AldiImportersReport.organization_id",
        "AldiImportersReport.factory_name",
        "AldiImportersReport.brand_id",
        "AldiImportersReport.date_of_inventory",
        "AldiImportersReport.total_number_of_chemicals",
        "AldiImportersReport.consumption",
        "AldiImportersReport.total_production_facility",
        "AldiImportersReport.aldi_number_of_chemicals",
        "AldiImportersReport.aldi_group_production",
        "AldiImportersReport.brand_chemical_count",
        "AldiImportersReport.brand_production",
        "AldiImportersReport.connected_to_aldi"
      ],
      "timeDimensions": [],
      "order": {},
      "filters": this.filters

    }
    this.cubejsService.getCubeJSData(query, null, null, true, false)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        if (tempData.length > 0) {
          this.hasNotData = false;
          tempData = this.changeKey("AldiImportersReport.organization_id", "organization_id", tempData)
          tempData = this.changeKey("AldiImportersReport.factory_name", "factory_name", tempData)
          tempData = this.changeKey("AldiImportersReport.brand_id", "brand_id", tempData)
          tempData = this.changeKey("AldiImportersReport.date_of_inventory", "date_of_inventory", tempData)
          tempData = this.changeKey("AldiImportersReport.total_number_of_chemicals", "total_number_of_chemicals", tempData)
          tempData = this.changeKey("AldiImportersReport.consumption", "consumption", tempData)
          tempData = this.changeKey("AldiImportersReport.total_production_facility", "total_production_facility", tempData)
          tempData = this.changeKey("AldiImportersReport.aldi_number_of_chemicals", "aldi_number_of_chemicals", tempData)
          tempData = this.changeKey("AldiImportersReport.aldi_group_production", "aldi_group_production", tempData)
          tempData = this.changeKey("AldiImportersReport.brand_chemical_count", "brand_chemical_count", tempData)
          tempData = this.changeKey("AldiImportersReport.brand_production", "brand_production", tempData)
          tempData = this.changeKey("AldiImportersReport.connected_to_aldi", "connected_to_aldi", tempData)
          tempData = tempData.map(obj => {
            obj.date_of_inventory = moment(obj.date_of_inventory).format('YYYY-MM');
            obj.consumption = Number(parseFloat(obj.consumption).toFixed(2));
            obj.total_production_facility = Number(parseFloat(obj.total_production_facility).toFixed(2));
            if (obj.brand_production)
              obj.brand_production = Number(parseFloat(obj.brand_production).toFixed(2));
            return obj

          });
        }
        else this.hasNotData = true;
        this.monthlyDataListAldiImporterBrand.data = tempData;
        this.monthlyDataListAldiImporterBrand.paginator = this.monthlyDataAldiImporterBrandPaginator;
        this.monthlyDataListAldiImporterBrand.sort = this.monthlyDataAldiImporterBrandMatSort;
        this.isLoadingMonthlyDataInfoAldiImporterBrand = false;
      })
  }

  monthlyDataListAldiSourcing = new MatTableDataSource<any>()
  isLoadingMonthlyDataInfoAldiSourcing = false;

  aldi_sourcing_monthlyData = [
    "factory_name",
    "email",
    "country_name",
    "last_scan_date",
    "has_uploaded_last_month",
    "gatewaySubscription",
    "connected_to_ALDI_Sourcing",
    "connected_to_ALDI_Eink",
    "connected_to_Hofer",
    "chemicals_for_ALDI_Sourcing",
    "chemicals_for_ALDI_Prod",
    "compliance_aldi",
    "total_uploaded",
    "compliance_overall"

  ]

  getAldiSourcingMonthlyData() {
    this.isLoadingMonthlyDataInfoAldiSourcing = true;
    const query = {
      "dimensions": [
        "AldiSourcing.factory_name",
        "AldiSourcing.email",
        "AldiSourcing.country_name",
        "AldiSourcing.last_scan_date",
        "AldiSourcing.has_uploaded_last_month",
        "AldiSourcing.gatewaySubscription",
        "AldiSourcing.connected_to_ALDI_Sourcing",
        "AldiSourcing.connected_to_ALDI_Eink",
        "AldiSourcing.connected_to_Hofer",
        "AldiSourcing.chemicals_for_ALDI_Sourcing",
        "AldiSourcing.chemicals_for_ALDI_Prod",
        "AldiSourcing.compliance_aldi",
        "AldiSourcing.total_uploaded",
        "AldiSourcing.compliance_overall",

      ],
      "timeDimensions": [],
      "order": {},
      "filters": this.filters

    }
    this.cubejsService.getCubeJSData(query, null, null, true, false)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        if (tempData.length > 0) {
          this.hasNotData = false;
          tempData = this.changeKey("AldiSourcing.factory_name", "factory_name", tempData)
          tempData = this.changeKey("AldiSourcing.email", "email", tempData)
          tempData = this.changeKey("AldiSourcing.country_name", "country_name", tempData)
          tempData = this.changeKey("AldiSourcing.last_scan_date", "last_scan_date", tempData)
          tempData = this.changeKey("AldiSourcing.has_uploaded_last_month", "has_uploaded_last_month", tempData)
          tempData = this.changeKey("AldiSourcing.gatewaySubscription", "gatewaySubscription", tempData)
          tempData = this.changeKey("AldiSourcing.connected_to_ALDI_Sourcing", "connected_to_ALDI_Sourcing", tempData)
          tempData = this.changeKey("AldiSourcing.connected_to_ALDI_Eink", "connected_to_ALDI_Eink", tempData)
          tempData = this.changeKey("AldiSourcing.connected_to_Hofer", "connected_to_Hofer", tempData)
          tempData = this.changeKey("AldiSourcing.chemicals_for_ALDI_Sourcing", "chemicals_for_ALDI_Sourcing", tempData)
          tempData = this.changeKey("AldiSourcing.chemicals_for_ALDI_Prod", "chemicals_for_ALDI_Prod", tempData)
          tempData = this.changeKey("AldiSourcing.compliance_aldi", "compliance_aldi", tempData)
          tempData = this.changeKey("AldiSourcing.total_uploaded", "total_uploaded", tempData)
          tempData = this.changeKey("AldiSourcing.compliance_overall", "compliance_overall", tempData)
          tempData = tempData.map(obj => {
            if (obj.last_scan_date)
              obj.last_scan_date = obj.last_scan_date.substring(0, 10);
            obj.compliance_aldi = (obj.compliance_aldi * 100).toFixed(2);
            obj.compliance_overall = (obj.compliance_overall * 100).toFixed(2);
            return obj;
          })
        }
        else this.hasNotData = true;
        this.monthlyDataListAldiSourcing.data = tempData;
        this.monthlyDataListAldiSourcing.paginator = this.monthlyDataAldiSourcingPaginator;
        this.monthlyDataListAldiSourcing.sort = this.monthlyDataAldiSourcingMatSort;
        this.isLoadingMonthlyDataInfoAldiSourcing = false;
      })
  }

  aldi_importer_monthlyData = [
    "factory_name",
    "date",
    "chem_used_for_aldi",
    "chem_used_for_fl_textil",
    "total_chem",
    "general_compliance",
    "aldi_compliance"
  ]

  monthlyDataListAldiImporter = new MatTableDataSource<any>()
  isLoadingMonthlyDataInfoAldiImporter = false;

  // getAldiImporterMonthlyData() {
  //   this.isLoadingMonthlyDataInfoAldiImporter = true;
  //   const query = {
  //     "dimensions": [
  //       "AldiImporter.factory_name",
  //       "AldiImporter.inventory_month",
  //       "AldiImporter.chem_used_for_aldi",
  //       "AldiImporter.chem_used_for_fl_textil",
  //       "AldiImporter.total_chem",
  //       "AldiImporter.general_compliance",
  //       "AldiImporter.aldi_compliance"
  //     ],
  //     "timeDimensions": [],
  //     "order": {},
  //     "filters": this.filters

  //   }
  //   this.cubejsService.getCubeJSData(query, null, null, true, false)
  //     .then(data => {
  //       let tempData = data['loadResponse']['results'][0]['data'];
  //       if (tempData.length > 0) {
  //         this.hasNotData = false;
  //         tempData = this.changeKey("AldiImporter.factory_name", "factory_name", tempData)
  //         tempData = this.changeKey("AldiImporter.inventory_month", "date", tempData)
  //         tempData = this.changeKey("AldiImporter.chem_used_for_aldi", "chem_used_for_aldi", tempData)
  //         tempData = this.changeKey("AldiImporter.chem_used_for_fl_textil", "chem_used_for_fl_textil", tempData)
  //         tempData = this.changeKey("AldiImporter.total_chem", "total_chem", tempData)
  //         tempData = this.changeKey("AldiImporter.general_compliance", "general_compliance", tempData)
  //         tempData = this.changeKey("AldiImporter.aldi_compliance", "aldi_compliance", tempData)
  //         tempData = tempData.map(obj => {
  //           const formatNumber = (value) => {
  //             if (value === null || value === 0) {
  //               return '0'; // Default format when the value is null or 0
  //             }
  //             return Number(value).toFixed(2);
  //           };

  //           obj.aldi_compliance = Number(formatNumber(obj.aldi_compliance * 100));
  //           obj.general_compliance = Number(formatNumber(obj.general_compliance * 100));
  //           if (obj.date)
  //             obj.date = obj.date.substring(0, 7);
  //           return obj;
  //         })
  //       }
  //       else this.hasNotData = true;
  //       this.monthlyDataListAldiImporter.data = tempData;
  //       this.monthlyDataListAldiImporter.paginator = this.monthlyDataAldiImporterPaginator;
  //       this.monthlyDataListAldiImporter.sort = this.monthlyDataAldiImporterMatSort;
  //       this.isLoadingMonthlyDataInfoAldiImporter = false;
  //     })
  // }

  kontoorData = [

    "Bhive_ID",
    "Kontoor_ID",
    "Factory_Name",
    "InScope",
    // "Business Partner ID",
    "Country",
    "FFC",
    "BHive_Type",
    "Factory_Type",
    "Email",
    "Walmart_Order",
    "Connected_to_Kontoor",
    "Scan_Date",
    "No_of_Chemicals_from_Last_Scan",
    "No_of_Chemicals_With_Commodities_from_Last_Scan",
    "Compliance_online_only_Connected",
    "Compliance_With_Commodities_only_Connected",
    // "In Check",
    "Higg",
    "Wastewater",
  ]

  monthlyDataListKontoor = new MatTableDataSource<any>()
  isLoadingKontoorDataInfo = false;

  getMonthlyKontoorData() {
    this.isLoadingKontoorDataInfo = true;
    const query = {
      "dimensions": [
        "KontoorReport.Bhive_ID",
        "KontoorReport.Kontoor_ID",
        // "KontoorReport.Business_Partner_ID",
        "KontoorReport.factory_name",
        "KontoorReport.In_Scope_Out_Scope",
        "KontoorReport.Country",
        "KontoorReport.FFC",
        "KontoorReport.Company_Type",
        "KontoorReport.FactoryType",
        "KontoorReport.email",
        "KontoorReport.Brand_Specific_Attribute",
        "KontoorReport.Connected_to_Kontoor",
        "KontoorReport.Scan_date",
        "KontoorReport.count_last_scan",
        "KontoorReport.count_last_scan_with_commodities",
        "KontoorReport.Compliance",
        "KontoorReport.Compliance_with_Comm",
        // "KontoorReport.in_check",
        "KontoorReport.higg",
        "KontoorReport.WasteWater",

      ],
      "timeDimensions": [],
      "order": {
      },
      "filters": this.filters,
      "measures": [
      ]
    }
    this.cubejsService.getCubeJSData(query, null, null, true, false)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        tempData = this.changeKey("KontoorReport.Bhive_ID", "Bhive_ID", tempData)
        tempData = this.changeKey("KontoorReport.Kontoor_ID", "Kontoor_ID", tempData)
        tempData = this.changeKey("KontoorReport.factory_name", "Factory_Name", tempData)
        tempData = this.changeKey("KontoorReport.In_Scope_Out_Scope", "InScope", tempData)
        tempData = this.changeKey("KontoorReport.Country", "Country", tempData)
        tempData = this.changeKey("KontoorReport.FFC", "FFC", tempData)
        tempData = this.changeKey("KontoorReport.Company_Type", "BHive_Type", tempData)
        tempData = this.changeKey("KontoorReport.FactoryType", "Factory_Type", tempData)
        tempData = this.changeKey("KontoorReport.email", "Email", tempData)
        tempData = this.changeKey("KontoorReport.Brand_Specific_Attribute", "Walmart_Order", tempData)
        tempData = this.changeKey("KontoorReport.Connected_to_Kontoor", "Connected_to_Kontoor", tempData)
        tempData = this.changeKey("KontoorReport.Scan_date", "Scan_Date", tempData)
        tempData = this.changeKey("KontoorReport.count_last_scan", "No_of_Chemicals_from_Last_Scan", tempData)
        tempData = this.changeKey("KontoorReport.count_last_scan_with_commodities", "No_of_Chemicals_With_Commodities_from_Last_Scan", tempData)
        tempData = this.changeKey("KontoorReport.Compliance", "Compliance_online_only_Connected", tempData)
        tempData = this.changeKey("KontoorReport.Compliance_with_Comm", "Compliance_With_Commodities_only_Connected", tempData)
        tempData = this.changeKey("KontoorReport.higg", "Higg", tempData)
        tempData = this.changeKey("KontoorReport.WasteWater", "Wastewater", tempData)

        // if (tempData.length > 0) {
        tempData = tempData.map(obj => {
          if (obj.Walmart_Order === 'WALMART_ORDER')
            obj.Walmart_Order = 'Yes';
          else obj.Walmart_Order = 'No';
          return obj;
        })
        this.monthlyDataListKontoor.data = tempData;
        this.monthlyDataListKontoor.paginator = this.monthlyDataKontoorPaginator;
        this.monthlyDataListKontoor.sort = this.monthlyDataKontoorMatSort;
        this.isLoadingKontoorDataInfo = false;
        // }
        // else this.isLoadingKontoorDataInfo = false;
      })
  }

  nikeData = [
    "supplier_ID",
    "CampaignName",
    "questionnaireName",
    "month",
    "vendor",
    "product",
    "solvent_use",
    "index"
  ]

  exportNikeCSV = new MatTableDataSource<any>()
  isLoadingNikeDataInfo = false;

  exportNikeReport() {

    this.isLoadingNikeDataInfo = true;
    const nikeReport = {
      "measures": [
      ],
      "timeDimensions": [
      ],
      "order": {
      },
      "filters": [
        {
          "member": "SolventChemical.scan_date",
          "operator": "inDateRange",
          "values": [
            this.startDateString,
            this.endDateString
          ]
        }
      ],
      "dimensions": [
        "SolventChemical.supplier_identification",
        "SolventChemical.scan_date",
        "SolventChemical.vendor",
        "SolventChemical.chemical_name",
        "SolventChemical.solvent_use",
      ]
    }
    this.cubejsService.getCubeJSData(nikeReport)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        if (tempData.length > 0) {
          tempData = this.changeKey("SolventChemical.supplier_identification", "supplier_ID", tempData)
          tempData = this.changeKey("SolventChemical.scan_date", "scan_date", tempData)
          tempData = this.changeKey("SolventChemical.vendor", "vendor", tempData)
          tempData = this.changeKey("SolventChemical.chemical_name", "product", tempData)
          tempData = this.changeKey("SolventChemical.solvent_use", "solvent_use", tempData)
          tempData = tempData.map(obj => {

            if (obj.supplier_ID == 'undefined' || obj.supplier_ID == null)
              obj.supplier_ID = '';

            if (obj.solvent_use == null || obj.solvent_use == undefined)
              obj.solvent_use = '';

            let scan_date = new Date(obj.scan_date);
            let month = scan_date.getMonth() + 1;
            const year = scan_date.getFullYear();
            obj.month = `${month}/1/${year}`;

            if (month == 1 || month == 2 || month == 12)
              obj.CampaignName = 'SM&S - December ' + (this.d.getFullYear() - 1) + ' - February ' + this.d.getFullYear() + ' Chemistry'
            if (month == 3 || month == 4 || month == 5)
              obj.CampaignName = 'SM&S - March ' + this.d.getFullYear() + ' - May ' + this.d.getFullYear() + ' Chemistry'
            if (month == 6 || month == 7 || month == 8)
              obj.CampaignName = 'SM&S - June ' + this.d.getFullYear() + ' - August ' + this.d.getFullYear() + ' Chemistry'
            if (month == 9 || month == 10 || month == 11)
              obj.CampaignName = 'SM&S - September ' + this.d.getFullYear() + ' - November ' + this.d.getFullYear() + ' Chemistry'
            obj.questionnaireName = 'SM&S Chemistry'
            obj.index = ''


            return {
              'Entity Name': obj.supplier_ID,
              'Campaign Name': obj.CampaignName,
              'Questionnaire Name': obj.questionnaireName,
              'Month': obj.month,
              'Vendor': obj.vendor,
              'Product': obj.product,
              'Solvent Use': obj.solvent_use,
              'Index': obj.index
            };

          });
          this.exportNikeCSV.data = tempData;
          this.exportNikeCSV.paginator = this.exportNikeCSVPaginator;
          this.exportNikeCSV.sort = this.exportNikeCSVMatSort;
          this.isLoadingNikeDataInfo = false;
        }
        else {
          this.exportNikeCSV.data = [];
          this.exportNikeCSV.paginator = this.exportNikeCSVPaginator;
          this.exportNikeCSV.sort = this.exportNikeCSVMatSort;
          this.isLoadingNikeDataInfo = false;
        }
        //
      })
      .catch(error => {
        this.ShowErrorMessage(error.message);
      })

  }


  inditexData = [
    "cil_supplier",
    "factory_name",
    "country_name",
    "zdhc_aid",
    // "inditex_brand",
    "month_of_inventory",
    "chemical_supplier",
    "chemical_supplier_country",
    "chemical_product",
    "zdhc_pid",
    "zdhc_substrate",
    "zdhc_use_category",
    "zdhc_use_type",
    "type_of_manufacturing",
    "consumption",
    "zdhc_certification_result",
    "has_at_least_one_standard",
    "verified_standards",
    "zdhc_grading",
    "inditexBelowThreeYears",
    "inditexAboveThreeYears",
    "substance_name",
    "percentage",
    "cas_nr",
    "ec_number",
    "echa",
    "svhc",
    "toxicity_regulatory_list"

  ]

  monthlyDataListInditex = new MatTableDataSource<any>()
  isLoadingInditexDataInfo = false;

  getInditexMonthlyData() {
    this.isLoadingInditexDataInfo = true;
    const query = {
      "dimensions": [
        "InditexReport.cil_supplier",
        "InditexReport.factory_name",
        "InditexReport.country_name",
        "InditexReport.zdhc_aid",
        // "ConnectedBrands.brands_name",
        "InditexReport.month_of_inventory",
        "InditexReport.chemical_supplier",
        "InditexReport.chemical_supplier_country",
        "InditexReport.chemical_product",
        "InditexReport.zdhc_pid",
        "InditexReport.zdhc_substrate",
        "InditexReport.zdhc_use_category",
        "InditexReport.zdhc_use_type",
        "InditexReport.type_of_manufacturing",
        "InditexReport.consumption",
        "InditexReport.zdhc_certification_result",
        "InditexReport.has_at_least_one_standard",
        "InditexReport.verified_standards",
        "InditexReport.zdhc_grading",
        "InditexReport.inditexBelowThreeYears",
        "InditexReport.inditexAboveThreeYears",
        "InditexReport.substance_name",
        "InditexReport.percentage",
        "InditexReport.cas_nr",
        "InditexReport.ec_number",
        "InditexReport.toxicity_echa",
        "InditexReport.svhc",
        "InditexReport.toxicity_regulatory_list",

      ],
      "timeDimensions": [],
      "order": {
      },
      "filters": this.filters,
      "measures": [
      ]
    }
    this.cubejsService.getCubeJSData(query)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        if (tempData.length > 0) {
          this.hasNotData = false;
          tempData = this.changeKey("InditexReport.cil_supplier", "cil_supplier", tempData)
          tempData = this.changeKey("InditexReport.factory_name", "factory_name", tempData)
          tempData = this.changeKey("InditexReport.country_name", "country_name", tempData)
          tempData = this.changeKey("InditexReport.zdhc_aid", "zdhc_aid", tempData)
          // tempData = this.changeKey("ConnectedBrands.brands_name", "inditex_brand", tempData)
          tempData = this.changeKey("InditexReport.month_of_inventory", "month_of_inventory", tempData)
          tempData = this.changeKey("InditexReport.chemical_supplier", "chemical_supplier", tempData)
          tempData = this.changeKey("InditexReport.chemical_supplier_country", "chemical_supplier_country", tempData)
          tempData = this.changeKey("InditexReport.chemical_product", "chemical_product", tempData)
          tempData = this.changeKey("InditexReport.zdhc_pid", "zdhc_pid", tempData)
          tempData = this.changeKey("InditexReport.zdhc_substrate", "zdhc_substrate", tempData)
          tempData = this.changeKey("InditexReport.zdhc_use_category", "zdhc_use_category", tempData)
          tempData = this.changeKey("InditexReport.zdhc_use_type", "zdhc_use_type", tempData)
          tempData = this.changeKey("InditexReport.type_of_manufacturing", "type_of_manufacturing", tempData)
          tempData = this.changeKey("InditexReport.consumption", "consumption", tempData)
          tempData = this.changeKey("InditexReport.zdhc_certification_result", "zdhc_certification_result", tempData)
          tempData = this.changeKey("InditexReport.has_at_least_one_standard", "has_at_least_one_standard", tempData)
          tempData = this.changeKey("InditexReport.verified_standards", "verified_standards", tempData)
          tempData = this.changeKey("InditexReport.zdhc_grading", "zdhc_grading", tempData)
          tempData = this.changeKey("InditexReport.inditexBelowThreeYears", "inditexBelowThreeYears", tempData)
          tempData = this.changeKey("InditexReport.inditexAboveThreeYears", "inditexAboveThreeYears", tempData)
          tempData = this.changeKey("InditexReport.substance_name", "substance_name", tempData)
          tempData = this.changeKey("InditexReport.percentage", "percentage", tempData)
          tempData = this.changeKey("InditexReport.cas_nr", "cas_nr", tempData)
          tempData = this.changeKey("InditexReport.ec_number", "ec_number", tempData)
          tempData = this.changeKey("InditexReport.toxicity_echa", "toxicity_echa", tempData)
          tempData = this.changeKey("InditexReport.svhc", "svhc", tempData)
          tempData = this.changeKey("InditexReport.toxicity_regulatory_list", "toxicity_regulatory_list", tempData)
          tempData = tempData.map(obj => {
            obj.month_of_inventory = `${obj.month_of_inventory.split("-")[1]}/${obj.month_of_inventory.split("-")[0]}`
            return {
              'cil_supplier': obj.cil_supplier,
              'factory_name': obj.factory_name,
              'country_name': obj.country_name,
              'zdhc_aid': obj.zdhc_aid,
              // 'inditex_brand': obj.inditex_brand,
              'month_of_inventory': obj.month_of_inventory,
              'chemical_supplier': obj.chemical_supplier,
              'chemical_supplier_country': obj.chemical_supplier_country,
              'chemical_product': obj.chemical_product,
              'zdhc_pid': obj.zdhc_pid,
              'zdhc_substrate': obj.zdhc_substrate,
              'zdhc_use_category': obj.zdhc_use_category,
              'zdhc_use_type': obj.zdhc_use_type,
              'type_of_manufacturing': obj.type_of_manufacturing,
              'consumption': obj.consumption,
              'zdhc_certification_result': obj.zdhc_certification_result,
              'has_at_least_one_standard': obj.has_at_least_one_standard,
              'verified_standards': obj.verified_standards,
              'zdhc_grading': obj.zdhc_grading,
              'inditexBelowThreeYears': obj.inditexBelowThreeYears,
              'inditexAboveThreeYears': obj.inditexAboveThreeYears,
              'substance_name': obj.substance_name,
              'percentage': obj.percentage,
              'cas_nr': obj.cas_nr,
              'ec_number': obj.ec_number,
              'echa': obj.toxicity_echa,
              'svhc': obj.svhc,
              'toxicity_regulatory_list': obj.toxicity_regulatory_list,
            };
          })
        }
        else this.hasNotData = true;
        this.monthlyDataListInditex.data = tempData;
        this.monthlyDataListInditex.paginator = this.monthlyDataInditexPaginator;
        this.monthlyDataListInditex.sort = this.monthlyDataInditexMatSort;
        this.isLoadingInditexDataInfo = false;
      })
  }
  hmData = [
    "chemical_identifier",
    "organization_name",
    "scan_date",
    "chemical_name",
    "chemical_manufacturer",
    "_type",
    "gateway_name",
    "gateway_manufacturer",
    "is_mixed_compliant",
    "gateway_level",
    "is_crowedsource_compliant",
    "gots",
    "gots_version",
    "eco_passport",
    "bluesign",
    "inditex_BelowThreeYears",
    "inditex_AboveThreeYears",
    "toxfmd",
    "scivera",
    "test_report",
    "is_incheck_scope",
    "factory_usage",
    "category",
    "is_used_for_brand",
    "consumption",
    "stock_volume",
    "delivered_quantity",
    "manufacture_date",
    "purchase_date",
    "expiry_date",
    "lot_number",
    "cas_nr",
    "notes",
    "use_of_ppe",
    "ghs_msds_available",
    "precautionary_statement",
    "hazard_statement",
    "ghs_classification",
    "stock_location",
    "storage_condition",
    "minimum_stock",
    "maximum_stock"
  ]

  monthlyDataListHm = new MatTableDataSource<any>()
  isLoadingHmDataInfo = false;

  getHmMonthlyData() {
    this.isLoadingHmDataInfo = true;
    const query = {
      "dimensions": [
        "Formulas.chemical_identifier",
        "Formulas.organization_name",
        "Formulas.scan_date",
        "Formulas.chemical_name",
        "Formulas.chemical_manufacturer",
        "Formulas._type",
        "Formulas.gateway_name",
        "Formulas.gateway_manufacturer",
        "Formulas.is_mixed_compliant",
        "Formulas.gateway_level",
        "Formulas.is_crowedsource_compliant",
        "Formulas.gots",
        "Formulas.gots_version",
        "Formulas.eco_passport",
        "Formulas.bluesign",
        "Formulas.inditex_BelowThreeYears",
        "Formulas.inditex_AboveThreeYears",
        "Formulas.toxfmd",
        "Formulas.scivera",
        "Formulas.test_report",
        "Formulas.is_incheck_scope",
        "Formulas.factory_usage",
        "Formulas.category",
        "Formulas.is_used_for_brand",
        "Formulas.consumption",
        "Formulas.stock_volume",
        "Formulas.delivered_quantity",
        "Formulas.manufacture_date",
        "Formulas.purchase_date",
        "Formulas.expiry_date",
        "Formulas.lot_number",
        "Formulas.cas_nr",
        "Formulas.notes",
        "Formulas.use_of_ppe",
        "Formulas.ghs_msds_available",
        "Formulas.precautionary_statement",
        "Formulas.hazard_statement",
        "Formulas.ghs_classification",
        "Formulas.stock_location",
        "Formulas.storage_condition",
        "Formulas.minimum_stock",
        "Formulas.maximum_stock"
      ],
      "timeDimensions": [],
      "order": {
      },
      "filters": this.filters,
      "measures": [],
    }
    this.cubejsService.getCubeJSData(query, null, null, true, false)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        if (tempData.length > 0) {
          this.hasNotData = false;
          tempData = this.changeKey("Formulas.chemical_identifier", "chemical_identifier", tempData);
          tempData = this.changeKey("Formulas.organization_name", "organization_name", tempData);
          tempData = this.changeKey("Formulas.scan_date", "scan_date", tempData);
          tempData = this.changeKey("Formulas.chemical_name", "chemical_name", tempData);
          tempData = this.changeKey("Formulas.chemical_manufacturer", "chemical_manufacturer", tempData);
          tempData = this.changeKey("Formulas._type", "_type", tempData);
          tempData = this.changeKey("Formulas.gateway_name", "gateway_name", tempData);
          tempData = this.changeKey("Formulas.gateway_manufacturer", "gateway_manufacturer", tempData);
          tempData = this.changeKey("Formulas.is_mixed_compliant", "is_mixed_compliant", tempData);
          tempData = this.changeKey("Formulas.gateway_level", "gateway_level", tempData);
          tempData = this.changeKey("Formulas.is_crowedsource_compliant", "is_crowedsource_compliant", tempData);
          tempData = this.changeKey("Formulas.gots", "gots", tempData);
          tempData = this.changeKey("Formulas.gots_version", "gots_version", tempData);
          tempData = this.changeKey("Formulas.eco_passport", "eco_passport", tempData);
          tempData = this.changeKey("Formulas.bluesign", "bluesign", tempData);
          tempData = this.changeKey("Formulas.inditex_BelowThreeYears", "inditex_BelowThreeYears", tempData);
          tempData = this.changeKey("Formulas.inditex_AboveThreeYears", "inditex_AboveThreeYears", tempData);
          tempData = this.changeKey("Formulas.toxfmd", "toxfmd", tempData);
          tempData = this.changeKey("Formulas.scivera", "scivera", tempData);
          tempData = this.changeKey("Formulas.test_report", "test_report", tempData);
          tempData = this.changeKey("Formulas.is_incheck_scope", "is_incheck_scope", tempData);
          tempData = this.changeKey("Formulas.factory_usage", "factory_usage", tempData);
          tempData = this.changeKey("Formulas.category", "category", tempData);
          tempData = this.changeKey("Formulas.is_used_for_brand", "is_used_for_brand", tempData);
          tempData = this.changeKey("Formulas.consumption", "consumption", tempData);
          tempData = this.changeKey("Formulas.stock_volume", "stock_volume", tempData);
          tempData = this.changeKey("Formulas.delivered_quantity", "delivered_quantity", tempData);
          tempData = this.changeKey("Formulas.manufacture_date", "manufacture_date", tempData);
          tempData = this.changeKey("Formulas.purchase_date", "purchase_date", tempData);
          tempData = this.changeKey("Formulas.expiry_date", "expiry_date", tempData);
          tempData = this.changeKey("Formulas.lot_number", "lot_number", tempData);
          tempData = this.changeKey("Formulas.cas_nr", "cas_nr", tempData);
          tempData = this.changeKey("Formulas.notes", "notes", tempData);
          tempData = this.changeKey("Formulas.use_of_ppe", "use_of_ppe", tempData);
          tempData = this.changeKey("Formulas.ghs_msds_available", "ghs_msds_available", tempData);
          tempData = this.changeKey("Formulas.precautionary_statement", "precautionary_statement", tempData);
          tempData = this.changeKey("Formulas.hazard_statement", "hazard_statement", tempData);
          tempData = this.changeKey("Formulas.ghs_classification", "ghs_classification", tempData);
          tempData = this.changeKey("Formulas.stock_location", "stock_location", tempData);
          tempData = this.changeKey("Formulas.storage_condition", "storage_condition", tempData);
          tempData = this.changeKey("Formulas.minimum_stock", "minimum_stock", tempData);
          tempData = this.changeKey("Formulas.maximum_stock", "maximum_stock", tempData);
          tempData = tempData.map(obj => {
            obj.gots = obj.gots == 1 ? 'Yes' : 'No'
            obj.eco_passport = obj.eco_passport == 1 ? 'Yes' : 'No'
            obj.bluesign = obj.bluesign == 1 ? 'Yes' : 'No'
            obj.toxfmd = obj.toxfmd == 1 ? 'Yes' : 'No'
            obj.scivera = obj.scivera == 1 ? 'Yes' : 'No'
            obj.test_report = obj.test_report == 1 ? 'Yes' : 'No'
            obj.scan_date = obj.scan_date != "" && obj.scan_date != null ? moment(obj.scan_date).format('YYYY-MM-DD') : obj.scan_date;
            obj.manufacture_date = obj.manufacture_date != "" && obj.manufacture_date != null ? moment(obj.manufacture_date).format('YYYY-MM-DD') : obj.manufacture_date;
            obj.purchase_date = obj.purchase_date != "" && obj.purchase_date != null ? moment(obj.purchase_date).format('YYYY-MM-DD') : obj.purchase_date;
            obj.expiry_date = obj.expiry_date != "" && obj.expiry_date != null ? moment(obj.expiry_date).format('YYYY-MM-DD') : obj.expiry_date;
            return obj
          });
        }
        else this.hasNotData = true;
        this.monthlyDataListHm.data = tempData;
        this.monthlyDataListHm.paginator = this.monthlyDataListHmPaginator;
        this.monthlyDataListHm.sort = this.monthlyDataListHmMatSort;
        this.isLoadingHmDataInfo = false;
      })
  }

  milesData = [
    "factory_name",
    "country_name",
    "date",
    "compliance_pct",
    "total_uploaded",
    "verified_chem",
    "unverified_chem",
    "commodity_chem",
    "brand_uploaded",
    "aldi_verified_chem",
    "aldi_unverified_chem",
    "aldi_commodity_chem",
    "aldi_compliance_pct",
    "connected_to_miles",
    "sent_on_date",
    "status",
    "connected_to_anord",
    "connected_to_asud",

  ]


  monthlyDataListMiles = new MatTableDataSource<any>()
  isLoadingMilesDataInfo = false;

  getMilesMonthlyData() {
    this.isLoadingMilesDataInfo = true;
    const query = {
      "dimensions": [
        "MilesMonthlyReport.factory_name",
        "MilesMonthlyReport.country_name",
        "MilesMonthlyReport.date",
        "MilesMonthlyReport.compliance_pct",
        "MilesMonthlyReport.total_uploaded",
        "MilesMonthlyReport.verified_chem",
        "MilesMonthlyReport.unverified_chem",
        "MilesMonthlyReport.commodity_chem",
        "MilesMonthlyReport.connected_to_miles",
        "MilesMonthlyReport.sent_on_date",
        "MilesMonthlyReport.status",
        "MilesMonthlyReport.connected_to_anord",
        "MilesMonthlyReport.connected_to_asud",
        "MilesMonthlyReport.aldi_compliance_pct",
        "MilesMonthlyReport.brand_uploaded",
        "MilesMonthlyReport.aldi_verified_chem",
        "MilesMonthlyReport.aldi_unverified_chem",
        "MilesMonthlyReport.aldi_commodity_chem",
      ],
      "timeDimensions": [],
      "order": {
      },
      "filters": this.filters,
      "measures": [
      ]
    }
    this.cubejsService.getCubeJSData(query)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        if (tempData.length > 0) {
          this.hasNotData = false;
          tempData = this.changeKey("MilesMonthlyReport.factory_name", "factory_name", tempData)
          tempData = this.changeKey("MilesMonthlyReport.country_name", "country_name", tempData)
          tempData = this.changeKey("MilesMonthlyReport.date", "date", tempData)
          tempData = this.changeKey("MilesMonthlyReport.compliance_pct", "compliance_pct", tempData)
          tempData = this.changeKey("MilesMonthlyReport.total_uploaded", "total_uploaded", tempData)
          tempData = this.changeKey("MilesMonthlyReport.verified_chem", "verified_chem", tempData)
          tempData = this.changeKey("MilesMonthlyReport.unverified_chem", "unverified_chem", tempData)
          tempData = this.changeKey("MilesMonthlyReport.commodity_chem", "commodity_chem", tempData)
          tempData = this.changeKey("MilesMonthlyReport.brand_uploaded", "brand_uploaded", tempData)
          tempData = this.changeKey("MilesMonthlyReport.aldi_verified_chem", "aldi_verified_chem", tempData)
          tempData = this.changeKey("MilesMonthlyReport.aldi_unverified_chem", "aldi_unverified_chem", tempData)
          tempData = this.changeKey("MilesMonthlyReport.aldi_commodity_chem", "aldi_commodity_chem", tempData)
          tempData = this.changeKey("MilesMonthlyReport.aldi_compliance_pct", "aldi_compliance_pct", tempData)
          tempData = this.changeKey("MilesMonthlyReport.connected_to_miles", "connected_to_miles", tempData)
          tempData = this.changeKey("MilesMonthlyReport.sent_on_date", "sent_on_date", tempData)
          tempData = this.changeKey("MilesMonthlyReport.status", "status", tempData)
          tempData = this.changeKey("MilesMonthlyReport.connected_to_anord", "connected_to_anord", tempData)
          tempData = this.changeKey("MilesMonthlyReport.connected_to_asud", "connected_to_asud", tempData)
          tempData = tempData.map(obj => {
            if (obj.date) {
              const [year, month] = obj.date.split('-').slice(0, 2);
              obj.date = `${year}-${month}`
            }
            if (obj.sent_on_date != "") {
              const [year, month] = obj.sent_on_date.split('-').slice(0, 2);
              obj.sent_on_date = `${year}-${month}`
            }
            if (obj.status == 'New/Pending') {
              obj.connected_to_miles = '';
              obj.aldi_compliance_pct = '';
              obj.aldi_commodity_chem = '';
              obj.aldi_unverified_chem = '';
              obj.aldi_verified_chem = '';
              obj.brand_total = '';
              obj.commodity_chem = '';
              obj.unverified_chem = '';
              obj.verified_chem = '';
              obj.total = '';
              obj.compliance_pct = '';
            };
            return {
              'factory_name': obj.factory_name,
              'country_name': obj.country_name,
              'date': obj.date,
              'compliance_pct': obj.compliance_pct,
              'total_uploaded': obj.total_uploaded,
              'verified_chem': obj.verified_chem,
              'unverified_chem': obj.unverified_chem,
              'commodity_chem': obj.commodity_chem,
              'brand_uploaded': obj.brand_uploaded,
              'aldi_verified_chem': obj.aldi_verified_chem,
              'aldi_unverified_chem': obj.aldi_unverified_chem,
              'aldi_commodity_chem': obj.aldi_commodity_chem,
              'aldi_compliance_pct': obj.aldi_compliance_pct,
              'connected_to_miles': obj.connected_to_miles,
              'sent_on_date': obj.sent_on_date,
              'status': obj.status,
              'connected_to_anord': obj.connected_to_anord,
              'connected_to_asud': obj.connected_to_asud,
            };
          })
        }
        else this.hasNotData = true;
        this.monthlyDataListMiles.data = tempData;
        this.monthlyDataListMiles.paginator = this.monthlyDataMilesPaginator;
        this.monthlyDataListMiles.sort = this.monthlyDataMilesMatSort;
        this.isLoadingMilesDataInfo = false;
      })
  }

  monthlyDataListMilesTotals = new MatTableDataSource<any>()

  getMilesMonthlyDataTotal() {
    const query = {
      "dimensions": [
        "MilesMonthlyReport.total_compliance",
        "MilesMonthlyReport.total_aldi_compliance"
      ],
      "timeDimensions": [],
      "order": {
      },
      "filters": this.filters,
      "measures": [
      ]
    }
    this.cubejsService.getCubeJSData(query)
      .then(data => {
        let tempData = data['loadResponse']['results'][0]['data'];
        let filteredData = tempData.filter(item => item["MilesMonthlyReport.total_compliance"] !== null || item["MilesMonthlyReport.total_aldi_compliance"] !== null);
        if (filteredData.length > 0) {
          filteredData = this.changeKey("MilesMonthlyReport.total_compliance", "total_compliance", filteredData)
          filteredData = this.changeKey("MilesMonthlyReport.total_aldi_compliance", "total_aldi_compliance", filteredData)
        }
        this.monthlyDataListMilesTotals.data = filteredData;
      })
  }



  ShowErrorMessage(msg) {
    this.isLoading = false;
    let customMSg = false;
    if (msg.includes("No id found in Security Context")) {
      msg = 'No factories are connected to this account, so no data can be displayed.\n Ask your factories to connect to you to see their data.',
        customMSg = true;
    }
    if (msg.includes("Network request failed")) {
      this.isCubejsConnected = false;
      throw new Error("CubeJS is not reachable!")
      // return false;
    }
    if (!this.errorList.includes(msg)) {
      this.errorList.push(msg);
      this.dialog.open(MessageDialogComponent, {
        hasBackdrop: true,
        width: "32rem",
        disableClose: true,
        data: {
          message: msg,
          hasLink: customMSg ? true : false,
          goToLink: customMSg ? "How to share inventories" : "",
          URL: customMSg ? "https://cloud.goblu.net/s/4LXyFHrE86Tzx2A" : ""
        },
      });
      // alert(msg);
    }
  }

  exportDataJson() {
    this.exportService.exportJsonInditex(this.monthlyDataListInditex.data, 'Inditex ' + this.date.value.format('MMMM'))
  }


  exportData(): void {
    if (this.isAldi || this.isHofer)
      this.exportService.exportMonthlyReport(this.monthlyDataListAldiHoffer.data, this.isAldi ? 'Aldi Einkauf SE & Co. oHG ' + this.date.value.format('MMMM') + ' Report' : 'Aldi Süd ' + this.date.value.format('MMMM') + ' Report', this.organization_name );
    else {
      if (this.isAldiImporterBrand)
        this.exportService.exportMonthlyReport(this.monthlyDataListAldiImporterBrand.data, this.organization_name + ' ' + this.date.value.format('MMMM') + ' Report', this.organization_name);
      else {
        if (this.isAldiSourcing)
          this.exportService.exportMonthlyReport(this.monthlyDataListAldiSourcing.data, 'Aldi_Sourcing ' + this.date.value.format('MMMM'), this.organization_name);
        else {
          // if (this.isAldiImporter)
          //   this.exportService.exportMonthlyReport(this.monthlyDataListAldiImporter.data, 'FL_Textil ' + this.date.value.format('MMMM'), this.organization_name);
          if (this.isKontoor) {
            this.exportService.exportKontoorReportAsExcelFile(this.monthlyDataListKontoor.data, 'Kontoor Monthly Report');
          }
          else {
            if (this.isNike) {
              this.exportService.export_csvNikeFile(this.exportNikeCSV.data);
            }
            else {
              if (this.isInditex) {
                this.exportService.exportMonthlyReport(this.monthlyDataListInditex.data, 'CIL INFO_v2 GB', this.organization_name);
              }
              else {
                if (this.isMiles) {
                  this.exportService.exportMilesReportAsExcelFile(this.monthlyDataListMiles.data, this.monthlyDataListMilesTotals.data, 'Miles Report ' + this.date.value.format('MMMM'));
                }
                else {
                  if (this.isHm) {
                    this.exportService.exportMonthlyReport(this.monthlyDataListHm.data, 'CIL ' + this.date.value.format('MMMM YY') + '- ' + this.organization_name, this.organization_name);
                  }
                  else if (this.isDummy) {
                    this.exportService.exportMonthlyReport(this.monthlyDataList.data, 'Monthly Report ' + this.date.value.format('MMMM'), this.organization_name);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  lastday = function (y, m) {
    return new Date(y, m, 0).getDate();
  }
}

