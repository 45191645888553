import { Directive, HostListener, Renderer2, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appMakeSticky]'
})
export class MakeStickyDirective {

  basePosition;

  constructor(private element: ElementRef,
              private renderer: Renderer2) {
    // this.basePosition = this.getBasePosition();
  }

  

  @Input() customOffset = 0;
  
  @HostListener("window: scroll") onScrolling () {
    const selectedElement = this.element.nativeElement;
    let scrollPosition = window.pageYOffset;
    // let elementPosition = selectedElement.offsetTop - selectedElement.offsetHeight;
    let elementPosition = Math.ceil(scrollPosition + selectedElement.getBoundingClientRect().top);
    let calculatedTopOffset;   
    let calculatedTopOffsetString;

    if (!this.basePosition) {
      this.basePosition = this.getBasePosition() - selectedElement.offsetHeight;
    }

    // console.log("Base position" + this.basePosition);
    // console.log("Scroll position " + scrollPosition);
    // console.log("Element position: " + elementPosition);
    // console.log("Offset top: " + selectedElement.offsetTop);
/*
    console.log("elementPosition: " + String(elementPosition) + "px"); 
    console.log("Base Position: " + this.basePosition);
    console.log("Scroll Position: " + scrollPosition);
*/
    if ( (scrollPosition >= elementPosition) || (scrollPosition >= this.basePosition) ) {
      // calculatedTopOffset = scrollPosition - elementPosition;
      // calculatedTopOffsetString = String( calculatedTopOffset ) + "px";
//      console.log("Calculated Offset: " + calculatedTopOffset);
      // console.log("triggered");

      // this.renderer.setStyle( selectedElement, "top", calculatedTopOffsetString);

//      console.log("Position after set: " + String(selectedElement.offsetTop - selectedElement.offsetHeight) + "px");
      
      // if( typeof( this.basePosition ) === 'undefined' || this.basePosition === null ) {
        // this.basePosition = selectedElement.offsetTop - selectedElement.offsetHeight - calculatedTopOffset
//        console.log("Base Position: " + String(this.basePosition));
      // }

      calculatedTopOffset = scrollPosition - this.basePosition + this.customOffset;
      calculatedTopOffsetString = String( calculatedTopOffset ) + "px";
//      console.log("Final Offset: " + calculatedTopOffsetString + "px");
      this.renderer.setStyle( selectedElement, "top", calculatedTopOffsetString)
      this.renderer.setStyle( selectedElement, "background-color", "white" );

    } else {
      this.renderer.removeStyle( selectedElement, "top");
      this.renderer.removeStyle( selectedElement, "background-color" );
    }
  }

  getBasePosition() {
    let yPosition = 0;
    let element = this.element.nativeElement;

    while(element) {
      yPosition += element.offsetTop;
      element = element.offsetParent;
    }

    return yPosition;
  }
}
