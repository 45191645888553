import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { DocumentcenterComponent } from '../documentcenter.component';
import { factoryTypeNamesArray } from 'app/environmental-data/production-data/factory_type_data';

export interface ConsumptionData {
  id?: number;
  year?: string;
  amount?: string;
  amountUnit?: string;
  timeUnit?: string;
  source?: Object;
  comment?: string;
  topic?: string;
  file?: File;
  factoryType?: number
  organizationFactoryDivisionId?: number
}

@Component({
  selector: 'app-consumption-data-upload',
  templateUrl: './consumption-data-upload.component.html',
  styleUrls: ['./consumption-data-upload.component.css']
})
export class ConsumptionDataUploadComponent implements OnInit {
  consumption: ConsumptionData;
  alertMsgs = {};
  sources = [];
  factoryTypeNamesArray = factoryTypeNamesArray
  allowedUnit = []
  sourcesWithUnit = [
    { "title": "Coal", allowedUnit: [{ "title": "Grams", "unit": "g" }, { "title": "Kg", "unit": "Kg" }, { "title": "Pounds", "unit": "lb" }, { "title": "Ounces", "unit": "oz" }, { "title": "Tons", "unit": "Ton" }, { "title": "Kilowatt hours", "unit": "KWH" }, { "title": "Mega joule", "unit": "MJ" }, { "title": "BTU", "unit": "btu" }, { "title": "Joule", "unit": "j" }] },
    { "title": "Biomass", allowedUnit: [{ "title": "Grams", "unit": "g" }, { "title": "Kg", "unit": "Kg" }, { "title": "Pounds", "unit": "lb" }, { "title": "Ounces", "unit": "oz" }, { "title": "Tons", "unit": "Ton" }, { "title": "Kilowatt hours", "unit": "KWH" }, { "title": "Mega joule", "unit": "MJ" }, { "title": "BTU", "unit": "btu" }, { "title": "Joule", "unit": "j" }] },
    { "title": "Wood", allowedUnit: [{ "title": "Grams", "unit": "g" }, { "title": "Kg", "unit": "Kg" }, { "title": "Pounds", "unit": "lb" }, { "title": "Ounces", "unit": "oz" }, { "title": "Tons", "unit": "Ton" }, { "title": "Kilowatt hours", "unit": "KWH" }, { "title": "Mega joule", "unit": "MJ" }, { "title": "BTU", "unit": "btu" }, { "title": "Joule", "unit": "j" }] },
    { "title": "Diesel", allowedUnit: [{ "title": "Grams", "unit": "g" }, { "title": "Kg", "unit": "Kg" }, { "title": "Pounds", "unit": "lb" }, { "title": "Ounces", "unit": "oz" }, { "title": "Tons", "unit": "Ton" }, { "title": "Kilowatt hours", "unit": "KWH" }, { "title": "Mega joule", "unit": "MJ" }, { "title": "BTU", "unit": "btu" }, { "title": "Joule", "unit": "j" }, { "title": "Cubic feet", "unit": "cu_ft" }, { "title": "Cubic yards", "unit": "cu_yard" }, { "title": "Liters", "unit": "L" }, { "title": "Cubic meters", "unit": "m3" }, { "title": "Gallons", "unit": "gal" }, { "title": "Centrum cubic feet", "unit": "cf" }] },
    { "title": "Diesel (Mobile)", allowedUnit: [{ "title": "Grams", "unit": "g" }, { "title": "Kg", "unit": "Kg" }, { "title": "Pounds", "unit": "lb" }, { "title": "Ounces", "unit": "oz" }, { "title": "Tons", "unit": "Ton" }, { "title": "Kilowatt hours", "unit": "KWH" }, { "title": "Mega joule", "unit": "MJ" }, { "title": "BTU", "unit": "btu" }, { "title": "Joule", "unit": "j" }, { "title": "Cubic feet", "unit": "cu_ft" }, { "title": "Cubic yards", "unit": "cu_yard" }, { "title": "Liters", "unit": "L" }, { "title": "Cubic meters", "unit": "m3" }, { "title": "Gallons", "unit": "gal" }, { "title": "Centrum cubic feet", "unit": "cf" }] },
    { "title": "Biodiesel", allowedUnit: [{ "title": "Grams", "unit": "g" }, { "title": "Kg", "unit": "Kg" }, { "title": "Pounds", "unit": "lb" }, { "title": "Ounces", "unit": "oz" }, { "title": "Tons", "unit": "Ton" }, { "title": "Kilowatt hours", "unit": "KWH" }, { "title": "Mega joule", "unit": "MJ" }, { "title": "BTU", "unit": "btu" }, { "title": "Joule", "unit": "j" }, { "title": "Cubic feet", "unit": "cu_ft" }, { "title": "Cubic yards", "unit": "cu_yard" }, { "title": "Liters", "unit": "L" }, { "title": "Cubic meters", "unit": "m3" }, { "title": "Gallons", "unit": "gal" }, { "title": "Centrum cubic feet", "unit": "cf" }] },
    { "title": "Biodiesel (Mobile)", allowedUnit: [{ "title": "Grams", "unit": "g" }, { "title": "Kg", "unit": "Kg" }, { "title": "Pounds", "unit": "lb" }, { "title": "Ounces", "unit": "oz" }, { "title": "Tons", "unit": "Ton" }, { "title": "Kilowatt hours", "unit": "KWH" }, { "title": "Mega joule", "unit": "MJ" }, { "title": "BTU", "unit": "btu" }, { "title": "Joule", "unit": "j" }, { "title": "Cubic feet", "unit": "cu_ft" }, { "title": "Cubic yards", "unit": "cu_yard" }, { "title": "Liters", "unit": "L" }, { "title": "Cubic meters", "unit": "m3" }, { "title": "Gallons", "unit": "gal" }, { "title": "Centrum cubic feet", "unit": "cf" }] },
    { "title": "Petrol", allowedUnit: [{ "title": "Grams", "unit": "g" }, { "title": "Kg", "unit": "Kg" }, { "title": "Pounds", "unit": "lb" }, { "title": "Ounces", "unit": "oz" }, { "title": "Tons", "unit": "Ton" }, { "title": "Kilowatt hours", "unit": "KWH" }, { "title": "Mega joule", "unit": "MJ" }, { "title": "BTU", "unit": "btu" }, { "title": "Joule", "unit": "j" }, { "title": "Cubic feet", "unit": "cu_ft" }, { "title": "Cubic yards", "unit": "cu_yard" }, { "title": "Liters", "unit": "L" }, { "title": "Cubic meters", "unit": "m3" }, { "title": "Gallons", "unit": "gal" }, { "title": "Centrum cubic feet", "unit": "cf" }] },
    { "title": "Petrol (Mobile)", allowedUnit: [{ "title": "Grams", "unit": "g" }, { "title": "Kg", "unit": "Kg" }, { "title": "Pounds", "unit": "lb" }, { "title": "Ounces", "unit": "oz" }, { "title": "Tons", "unit": "Ton" }, { "title": "Kilowatt hours", "unit": "KWH" }, { "title": "Mega joule", "unit": "MJ" }, { "title": "BTU", "unit": "btu" }, { "title": "Joule", "unit": "j" }, { "title": "Cubic feet", "unit": "cu_ft" }, { "title": "Cubic yards", "unit": "cu_yard" }, { "title": "Liters", "unit": "L" }, { "title": "Cubic meters", "unit": "m3" }, { "title": "Gallons", "unit": "gal" }, { "title": "Centrum cubic feet", "unit": "cf" }] },
    { "title": "Fuel Oil", allowedUnit: [{ "title": "Grams", "unit": "g" }, { "title": "Kg", "unit": "Kg" }, { "title": "Pounds", "unit": "lb" }, { "title": "Ounces", "unit": "oz" }, { "title": "Tons", "unit": "Ton" }, { "title": "Kilowatt hours", "unit": "KWH" }, { "title": "Mega joule", "unit": "MJ" }, { "title": "BTU", "unit": "btu" }, { "title": "Joule", "unit": "j" }, { "title": "Cubic feet", "unit": "cu_ft" }, { "title": "Cubic yards", "unit": "cu_yard" }, { "title": "Liters", "unit": "L" }, { "title": "Cubic meters", "unit": "m3" }, { "title": "Gallons", "unit": "gal" }, { "title": "Centrum cubic feet", "unit": "cf" }] },
    { "title": "LPG", allowedUnit: [{ "title": "Grams", "unit": "g" }, { "title": "Kg", "unit": "Kg" }, { "title": "Pounds", "unit": "lb" }, { "title": "Ounces", "unit": "oz" }, { "title": "Tons", "unit": "Ton" }, { "title": "Kilowatt hours", "unit": "KWH" }, { "title": "Mega joule", "unit": "MJ" }, { "title": "BTU", "unit": "btu" }, { "title": "Joule", "unit": "j" }, { "title": "Cubic feet", "unit": "cu_ft" }, { "title": "Cubic yards", "unit": "cu_yard" }, { "title": "Liters", "unit": "L" }, { "title": "Cubic meters", "unit": "m3" }, { "title": "Gallons", "unit": "gal" }, { "title": "Centrum cubic feet", "unit": "cf" }] },
    { "title": "LPG (Mobile)", allowedUnit: [{ "title": "Grams", "unit": "g" }, { "title": "Kg", "unit": "Kg" }, { "title": "Pounds", "unit": "lb" }, { "title": "Ounces", "unit": "oz" }, { "title": "Tons", "unit": "Ton" }, { "title": "Kilowatt hours", "unit": "KWH" }, { "title": "Mega joule", "unit": "MJ" }, { "title": "BTU", "unit": "btu" }, { "title": "Joule", "unit": "j" }, { "title": "Cubic feet", "unit": "cu_ft" }, { "title": "Cubic yards", "unit": "cu_yard" }, { "title": "Liters", "unit": "L" }, { "title": "Cubic meters", "unit": "m3" }, { "title": "Gallons", "unit": "gal" }, { "title": "Centrum cubic feet", "unit": "cf" }] },
    { "title": "Liquified Natural Gas", allowedUnit: [{ "title": "Grams", "unit": "g" }, { "title": "Kg", "unit": "Kg" }, { "title": "Pounds", "unit": "lb" }, { "title": "Ounces", "unit": "oz" }, { "title": "Tons", "unit": "Ton" }, { "title": "Kilowatt hours", "unit": "KWH" }, { "title": "Mega joule", "unit": "MJ" }, { "title": "BTU", "unit": "btu" }, { "title": "Joule", "unit": "j" }, { "title": "Cubic feet", "unit": "cu_ft" }, { "title": "Cubic yards", "unit": "cu_yard" }, { "title": "Liters", "unit": "L" }, { "title": "Cubic meters", "unit": "m3" }, { "title": "Gallons", "unit": "gal" }, { "title": "Centrum cubic feet", "unit": "cf" }] },
    { "title": "Natural Gas", allowedUnit: [{ "title": "Kilowatt hours", "unit": "KWH" }, { "title": "Mega joule", "unit": "MJ" }, { "title": "BTU", "unit": "btu" }, { "title": "Joule", "unit": "j" }, { "title": "Cubic feet", "unit": "cu_ft" }, { "title": "Cubic yards", "unit": "cu_yard" }, { "title": "Liters", "unit": "L" }, { "title": "Cubic meters", "unit": "m3" }, { "title": "Gallons", "unit": "gal" }, { "title": "Centrum cubic feet", "unit": "cf" }] },
    { "title": "Purchased Steam", allowedUnit: [{ "title": "Kilowatt hours", "unit": "KWH" }, { "title": "Mega joule", "unit": "MJ" }, { "title": "BTU", "unit": "btu" }, { "title": "Joule", "unit": "j" }] },
    { "title": "Purchased Electricity (non-Renewable)", allowedUnit: [{ "title": "Kilowatt hours", "unit": "KWH" }, { "title": "Mega joule", "unit": "MJ" }, { "title": "BTU", "unit": "btu" }, { "title": "Joule", "unit": "j" }] },
    { "title": "Purchased Electricity (Renewable)", allowedUnit: [{ "title": "Kilowatt hours", "unit": "KWH" }, { "title": "Mega joule", "unit": "MJ" }, { "title": "BTU", "unit": "btu" }, { "title": "Joule", "unit": "j" }] },
    { "title": "Purchased Chilled Water", allowedUnit: [{ "title": "Kilowatt hours", "unit": "KWH" }, { "title": "Mega joule", "unit": "MJ" }, { "title": "BTU", "unit": "btu" }, { "title": "Joule", "unit": "j" }] },
    { "title": "Purchased Hot Water", allowedUnit: [{ "title": "Kilowatt hours", "unit": "KWH" }, { "title": "Mega joule", "unit": "MJ" }, { "title": "BTU", "unit": "btu" }, { "title": "Joule", "unit": "j" }] },
    { "title": "Onsite Solar", allowedUnit: [{ "title": "Kilowatt hours", "unit": "KWH" }, { "title": "Mega joule", "unit": "MJ" }, { "title": "BTU", "unit": "btu" }, { "title": "Joule", "unit": "j" }] },
    { "title": "Onsite Wind", allowedUnit: [{ "title": "Kilowatt hours", "unit": "KWH" }, { "title": "Mega joule", "unit": "MJ" }, { "title": "BTU", "unit": "btu" }, { "title": "Joule", "unit": "j" }] },
    { "title": "Fabric Waste", allowedUnit: [{ "title": "BTU", "unit": "btu" }, { "title": "Joule", "unit": "j" }] }
  ]
  factoryUnitInfo = []

  constructor(
    private translateService: TranslateService,
    public dialogRef: MatDialogRef<DocumentcenterComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.fetchTranslations();
    this.translateService.onLangChange.subscribe(() => {
      // this.loading = true;
      this.fetchTranslations();
    });

    this.factoryUnitInfo = data.availableFactoryUnit
  }

  ngOnInit() {
    // console.log(this.data);
    if (this.data["consumption"]) {
      this.consumption = this.data["consumption"];
    } else {
      this.initConsumption();
    }

    this.sources = this.data["sources"];
    // console.log(this.sources);
  }

  addFile(file) {

    this.consumption.file = file ? file : null;
  }

  // clearContent() {
  //   var elements = document.getElementsByTagName("input");
  //   for (var ii = 0; ii < elements.length; ii++) {
  //     if (elements[ii].type.toLowerCase() == 'file') {
  //       elements[ii].value = "unit";
  //     }
  //   }
  //   this.consumption.file = null;
  // }

  cancel() {
    this.dialogRef.close(null);
  }

  onSourceChange(source) {
    this.allowedUnit = []
    this.allowedUnit = this.sourcesWithUnit.find(t => t.title === source)?.allowedUnit
  }
  upload() {
    // console.log(this.consumption);
    if (this.consumption.year == "") {
      alert(this.alertMsgs["YEAR"]);
    } else if (this.consumption.amount == "") {
      alert(this.alertMsgs["AMOUNT"]);
    } else if (this.consumption.amountUnit == "") {
      alert(this.alertMsgs["AMOUNT_UNIT"]);
    } else if (this.consumption.timeUnit == "") {
      alert(this.alertMsgs["TIME_UNIT"]);
    } else if (this.consumption.source == "") {
      alert(this.alertMsgs["SOURCE"]);
    } else {
      // console.log(this.document);
      this.dialogRef.close(this.consumption);
    }

  }

  initConsumption() {
    this.consumption = { year: "unit", amount: "unit", amountUnit: "unit", timeUnit: "unit", source: { english: "unit", translation: "unit" }, comment: "unit", topic: "unit", file: null };
  }

  fetchTranslations() {
    this.translateService.get("CONSUMPTION_UPLOAD_COMPONENT.ALERTS").subscribe((response) => {
      this.alertMsgs = response;
      // console.log(this.alertMsgs);
    });
  }

  hasFile() {
    // console.log(this.consumption.file);
    return this.consumption.file;
  }

}
