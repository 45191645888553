import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { OrganizationType } from '../../globals-classes/OrganizationType';
import { AuthenticationService } from '../../globals-services/authentication.service';
import { GlobalsService } from '../../globals-services/globals.service';

@Injectable()
export class BrandLoginGuard implements CanActivate {

    constructor(private authenticationService: AuthenticationService,
        public globalsService: GlobalsService,
        private router: Router
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        if (this.isChemIqBrand) {
            return true;
        }
        this.router.navigate(['/dashboard'])
         return false;
    }
    get isChemIqBrand(): boolean {


        if (this.globalsService.profile['chemIq'] === "brand" && this.isBrand) {

            return true;

        } else {

            return false;
        }
    }
    get isBrand(): boolean {

        //console.log(this.authenticationService.authenticationState);
        return this.globalsService.profile['organization_type'] == OrganizationType.BRAND;  //BRAND ORganization
    }
}
