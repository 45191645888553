
<input type="checkbox" id="vehicle1" name="vehicle1" value="Bike">
<label for="vehicle1">  Home</label><br>
<input type="checkbox" id="vehicle2" name="vehicle2" value="Car">
<label for="vehicle2"> Apparel</label><br>
<input type="checkbox" id="vehicle3" name="vehicle3" value="Boat">
<label for="vehicle3"> Footwear</label><br>
<input type="checkbox" id="vehicle3" name="vehicle3" value="Boat">
<label for="vehicle3">  Accessories</label><br>


<br/>

<br/>
<br/>

<button class="btn btn-default" type="submit">Save</button>
