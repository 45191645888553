<!-- <div class="header">  -->
<button mat-button [matMenuTriggerFor]="menu">
    <mat-icon class="filter_icon" [ngStyle]="{ color: isfilterApplied   ? '#05AFF0' : '' }">filter_list</mat-icon>
</button>
<!-- </div> -->
<mat-menu #menu>
    <div class="menu-title">
        {{ columnName }}
    </div>
    <div mat-filter-item class="table-filter-headers">Sort
        <button mat-button class="table-filter-sort-buttons" (click)="sortData('asc',columnName)"
            [class.active]="currentSortDirection === 'asc'"><mat-icon>arrow_upward</mat-icon>A-Z</button>
        <button mat-button class="table-filter-sort-buttons" (click)="sortData('desc', columnName)"
            [class.active]="currentSortDirection === 'desc'"><mat-icon>arrow_downward</mat-icon>Z-A</button>
    </div>
    <mat-divider></mat-divider>
    <div class="table-filter-headers">
        Filter & Search
    </div>
    <div mat-filter-item class="table-filter-conditions">
        <mat-form-field style="margin-right:10px" class="table-filter-smaller-form-field">
            <mat-select [panelClass]="'mat-elevation-z10'" placeholder='Conditions' [(value)]="filterCondition">
                <mat-option *ngFor="let  condition of conditionsList"
                    [value]="condition.value">{{condition.label}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="table-filter-smaller-form-field">
            <input matInput placeholder="Value" [(ngModel)]="filterValue" >
        </mat-form-field>
    </div>
    <div mat-filter-item class="table-filter-search-and-filter">
        <mat-icon class="table-filter-search-and-filter-icon">search</mat-icon>
        <input type="text" [(ngModel)]="searchText" matTooltip="Search By {{ columnName }}"
            class="table-filter-search-and-filter-text" placeholder="Search By {{ columnName }}" (input)="onInputChange($event)"
            
             />
    </div>

    <!-- Search Table -->
    <div mat-filter-item class="table-filter-select-container">
        <mat-checkbox (click)="$event.stopPropagation()"  (change)="masterToggle()"  [checked]="isAllSelected()" [indeterminate]="hasPartialSelection()">Select all</mat-checkbox>
        <div *ngFor="let item of uniqueItems" (click)="$event.stopPropagation()">
            <mat-checkbox class="table-filter-select-container-labels" [(ngModel)]="item.selected" (ngModelChange)="toggleSelection(item.value, $event)">
                {{ item.value }}
            </mat-checkbox>
        </div>
    </div>

    <div mat-filter-item class="table-filter-apply-Search-Buttons">
        <button mat-raised-button (click)="clearFilters()" class="table-filter-select-buttons-Clear">Clear</button>
        <button color="primary" mat-raised-button (click)="applyFilters()"
            class="table-filter-select-buttons-Search">Apply</button>
    </div>
</mat-menu>