import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastService } from 'app/common/toast-alert/toast.service';
import { GlobalsService } from 'app/globals-services/globals.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductionMgtService {


  allMasterProcesses: any = undefined;

  public static allUnitConstArray = [
    { id: 1, name: 'Kg' },
    { id: 2, name: 'Tons' },
    { id: 3, name: 'Meters' },
    { id: 4, name: 'Yards' },
    { id: 5, name: 'Pieces' },
  ];

  public static unitsConstArray = [
    { id: 1, name: 'Kg' },
    { id: 2, name: 'Tons' },
    { id: 3, name: 'Meters' },
    { id: 4, name: 'Yards' },

  ];
  constructor(
    private http: HttpClient,
    private toastService: ToastService,
    private globalService: GlobalsService
  ) { }

  getAllOrgProductionDetails(data) {
    return this.http
      .post<any>(
        environment.baseHref + '/getAllOrgProductionDetails',
        data
      ).pipe(
        catchError(this.globalService.handleError));
  }



  saveMachineData(data) {
    return this.http
      .post<any>(environment.baseHref + '/productOrder/phase2/saveMachineData', data).pipe(
        catchError(this.globalService.handleError));
  }

  deleteMachineEvidenceDocument(data) {
    return this.http
      .post<any>(
        environment.baseHref + '/deleteMachineEvidenceDocument',
        data
      ).pipe(
        catchError(this.globalService.handleError));
  }

  getProductionFactoryTypeDetails(data) {
    return this.http
      .post<any>(
        environment.baseHref + '/getProductionFactoryTypeDetails',
        data
      ).pipe(
        catchError(this.globalService.handleError));
  }

  deleteMachine(machine: any) {
    return this.http.post<any>(
      this.globalService.baseHref + '/deleteMachine',
      machine
    ).pipe(catchError(this.globalService.handleError));
  }

  getAllMasterProcesses() {

    return new Observable((observer) => {
      if (this.allMasterProcesses != undefined && this.allMasterProcesses.length > 0) {
        observer.next(this.allMasterProcesses.slice());
        observer.complete();
      } else {
        this.http
          .get<any[]>(
            environment.baseHref + '/getAllMasterProcesses'
          )
          .subscribe((data: any[]) => {
            this.allMasterProcesses = data;
            observer.next(this.allMasterProcesses.slice());
            observer.complete();
          });
      }
    })
  }
}
