export interface Mail
{
    id?: string;
    type?: string;
    from?: {
        avatar?: string;
        contact?: string;
    };
    to?: string;
    cc?: string[];
    ccCount?: number;
    bcc?: string[];
    bccCount?: number;
    date?: string;
    subject?: string;
    content?: string;
    attachments?: {
        type?: string;
        name?: string;
        size?: number;
        preview?: string;
        downloadUrl?: string;
    }[];
    starred?: boolean;
    important?: boolean;
    unread?: boolean;
    folder?: string;
    labels?: string[];
}

 interface FiltersNotificationList{
    _isSortByReadNotification:boolean,
    _sortByReadNotificationValue:number,

    _isSortBySenderList:boolean,
    _sortBySender:string[],

    _isSortBySearchKeyword:boolean,
    _searchKeyword:string,

    _isSortByDateSpan:boolean,
    _dateSpan:any[],

    _isSortByNotiTypeList:boolean,
    _sortByNotiTypeList:number[]; 
}

export class FiltersNotification implements FiltersNotificationList{
    _isSortByReadNotification: boolean;
    _sortByReadNotificationValue: number;
    _isSortBySenderList: boolean;
    _sortBySender: string[];
    _isSortBySearchKeyword: boolean;
    _searchKeyword: string;
    _isSortByDateSpan: boolean;
    _dateSpan: any[];
    _isSortByNotiTypeList: boolean;
    _sortByNotiTypeList: number[];
 
    

    get isSortByReadNotification(): boolean {
        return this._isSortByReadNotification;
      }
    
    set isSortByReadNotification(value: boolean) {
        this._isSortByReadNotification = value;
      }

    get isSortBySenderList(): boolean {
        return this._isSortBySenderList;
      }
    
    set isSortBySenderList(value: boolean) {
        this._isSortBySenderList = value;
      }

    get isSortBySearchKeyword(): boolean {
        return this._isSortBySearchKeyword;
      }
    
    set isSortBySearchKeyword(value: boolean) {
        this._isSortBySearchKeyword = value;
      }

    get isSortByDateSpan(): boolean {
        return this._isSortByDateSpan;
      }
    
    set isSortByDateSpan(value: boolean) {
        this._isSortByDateSpan = value;
      }

    get isSortByNotiTypeList(): boolean {
      return this._isSortByNotiTypeList;
    }
    
    set isSortByNotiTypeList(value: boolean) {
      this._isSortByNotiTypeList= value;
    }

    get sortBySender(): string[] {
        return this._sortBySender;
      }
    
      
    set sortBySender(value: string[]) {
      this._sortBySender = value;
    }
        
    set sortByReadNotificationValue(value: number) {
      this._sortByReadNotificationValue = value;
    }
    
    get sortByReadNotificationValue(): number {
      return this._sortByReadNotificationValue;
    }
    
    set sortBySearchKeyword(value: string) {
      this._searchKeyword = value;
    }
    
    get sortBySearchKeyword(): string {
      return this._searchKeyword;
    }

    get sortByDateSpan(): Date[] {
      return this._dateSpan;
    }
    
    set sortByDateSpan(value: Date[]) {
      this._dateSpan = value;
    }

    get sortByNotiTypeList(): number[] {
      return this._sortByNotiTypeList;
    }
  
    
  set sortByNotiTypeList(value: number[]) {
    this._sortByNotiTypeList = value;
  }

   

    // Filter By Date Span -  & Set and Get start date and end date also.
    // get sortByDateSpan(): Map<string, any> {
    //   return this._dateSpan;
    // }

    // set setStartDate(value: any) {
    //   console.log("Set Start Date : ", value)
    //   this._dateSpan.set("start_date", value);
    // }
    
    // get getStartDate(): string {
    //   return this._dateSpan.get("start_date");
    // }

    // set setEndDate(value: any) {
    //   this._dateSpan.set("end_date", value);
    // }
    
    // get getEndDate(): string {
    //   return this._dateSpan.get("end_date");
    // }

    

}

export interface MailFolder
{
    id: string;
    title: string;
    slug: string;
    icon: string;
    count?: number;
}

export interface MailFilter
{
    id: string;
    title: string;
    slug: string;
    icon: string;
}

export interface MailLabel
{
    id: string;
    title: string;
    slug: string;
    color: string;
}
