import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { Notification, NotificationType } from './alert.const';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';

@Injectable({
    providedIn: 'root',
})
export class ToastService {
    public subject = new Subject<Notification>();
    public keepAfterRouteChange = true;

    constructor(public router: Router,
        private _fuseNavigationService: FuseNavigationService
    ) {
        // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
        router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterRouteChange) {
                    // only keep for a single route change
                    this.keepAfterRouteChange = false;
                } else {
                    // clear alert messages
                    this.clear();
                }
            }
        });
    }

    getAlert(): Observable<any> {
        return this.subject.asObservable();
    }

    success(message: string, keepAfterRouteChange = false) {
        this.showNotification(
            NotificationType.Success,
            message,
            keepAfterRouteChange
        );
    }

    error(message: string, keepAfterRouteChange = false) {
        this.showNotification(
            NotificationType.Error,
            message,
            keepAfterRouteChange
        );
    }

    info(message: string, keepAfterRouteChange = false) {
        this.showNotification(
            NotificationType.Info,
            message,
            keepAfterRouteChange
        );
    }

    warn(message: string, keepAfterRouteChange = false) {
        this.showNotification(
            NotificationType.Warning,
            message,
            keepAfterRouteChange
        );
    }

    showNotification(
        type: NotificationType,
        message: string,
        keepAfterRouteChange = false
    ) {
        this.keepAfterRouteChange = keepAfterRouteChange;
        this.subject.next(<Notification>{ type: type, message: message });
    }

    clear() {
        this.subject.next(void 0);
    }



    toggleNavigation(isToExpand): void {

        let name = 'mainNavigation'
        // Get the navigation
        const navigation =
            this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
                name
            );

        if (navigation) {
            // Toggle the opened status
            isToExpand ? navigation.close() : navigation.open()
        }
    }
}
