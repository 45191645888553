<div class="flex flex-col flex-auto min-w-0">
    <!-- Main -->
    <div class="flex-auto p-6 sm:p-10">
        <!-- CONTENT GOES HERE -->
        <div>
            <div class="flex items-center justify-between w-full mb-4">
                <h4 class="text-3xl font-semibold tracking-tight leading-8">
                    Overall Analytics
                </h4>
            </div>
            <div class="content">
                <h2 class="text-2xl font-normal tracking-tight leading-8 mb-2" i18n="Analytics component header">
                    Analytics
                </h2>
                <div name="regular-reporting" *ngIf="!subscribedToAdvancedAnalytics">
                    <app-brand-analytics></app-brand-analytics>
                    <!-- <h4 class="text-xl font-normal tracking-tight leading-8" i18n="Analytics component subtitle">
                        Supply Chain Chemical Compliance
                    </h4>
                    <div class="controls-div">
                        <div class="select-div" i18n="Analytics component factory selection">
                            <h5 class="text-lg font-normal tracking-tight leading-8">
                                Factories:
                            </h5>

                            <div *ngFor="
                                    let countryObject of factoriesByCountries
                                " class="country-list-div">
                                <h6>{{ countryObject["country"] }}</h6>
                                <p *ngFor="let factory of countryObject['factories']">
                                    <input type="checkbox" name="factory" (change)="onCheckboxChange($event,factory['id'])" />
                                    {{ factory["name"] }}
                                </p>
                            </div>
                        </div>

                        <div class="flex row-6">
                            <div class="justify-start grid grid-cols-2 gap-2">
                                <div>
                                    <h5 class="text-md">Period:</h5>
                                </div>
                                <div>
                                    <h5 class="text-md"></h5>
                                </div>

                                <div>
                                    <mat-form-field>
                                        <mat-label>Start Date</mat-label>
                                        <input matInput [max]="selectedEndDate" [matDatepicker]="startDatePicker"
                                            [(ngModel)]="selectedStartDate" />
                                        <mat-datepicker-toggle matSuffix [for]="startDatePicker">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #startDatePicker startView="year"></mat-datepicker>
                                    </mat-form-field>
                                </div>

                                <div>
                                    <mat-form-field class="w-48">
                                        <mat-label>Standard</mat-label>
                                        <mat-select [(ngModel)]="selectedStandard">
                                            <mat-option value=""></mat-option>
                                            <mat-option *ngFor="
                                                    let standard of standards
                                                " value="{{ standard['name'] }}">
                                                {{ standard["displayName"] }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div>
                                    <mat-form-field class="outline">
                                        <mat-label>End Date</mat-label>
                                        <input matInput [min]="selectedStartDate" [matDatepicker]="endDatePicker"
                                            [(ngModel)]="selectedEndDate" />
                                        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                                        <mat-datepicker #endDatePicker startView="year"></mat-datepicker>
                                    </mat-form-field>
                                </div>

                                <div>
                                    <!- <h5>Filter by Customer:</h5> ->

                                    <mat-form-field class="w-48">
                                        <mat-label>Filter by Customer:</mat-label>
                                        <mat-select [(ngModel)]="selectedBrand">
                                            <mat-option value="">
                                                Overall Performance
                                            </mat-option>
                                            <mat-option value="{{ myId }}">
                                                Used for {{ myName }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div></div>
                                <div>
                                    <button id="submit-button" class="btn btn-primary" (click)="onSubmit()">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>

                        <!- <div
                            class="column mt-4"
                            i18n="Analytics component date selection"
                        >
                            <div>
                                <mat-form-field>
                                    <mat-label>Start Date</mat-label>
                                    <input
                                        matInput
                                        [max]="selectedEndDate"
                                        [matDatepicker]="startDatePicker"
                                        [(ngModel)]="selectedStartDate"
                                    />
                                    <mat-datepicker-toggle
                                        matSuffix
                                        [for]="startDatePicker"
                                    ></mat-datepicker-toggle>
                                    <mat-datepicker
                                        #startDatePicker
                                        startView="year"
                                    ></mat-datepicker>
                                </mat-form-field>

                                <mat-form-field>
                                    <mat-label>End Date</mat-label>
                                    <input
                                        matInput
                                        [min]="selectedStartDate"
                                        [matDatepicker]="endDatePicker"
                                        [(ngModel)]="selectedEndDate"
                                    />
                                    <mat-datepicker-toggle
                                        matSuffix
                                        [for]="endDatePicker"
                                    ></mat-datepicker-toggle>
                                    <mat-datepicker
                                        #endDatePicker
                                        startView="year"
                                    ></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>

                        <div
                            class="column"
                            id="standard"
                            i18n="
                                Analytics component Standard selection, filter
                                by customer and submit
                            "
                        >
                            <h5>Standard:</h5>
                            <div class="grid grid-cols-2 gap-4">
                                <select
                                    class="form-control"
                                    [(ngModel)]="selectedStandard"
                                >
                                    <option value=""></option>
                                    <option
                                        *ngFor="let standard of standards"
                                        value="{{ standard['name'] }}"
                                    >
                                        {{ standard["displayName"] }}
                                    </option>
                                </select>

                                <h5>Filter by Customer:</h5>

                                <select
                                    class="form-control"
                                    [(ngModel)]="selectedBrand"
                                >
                                    <option value="">
                                        Overall Performance
                                    </option>
                                    <option value="{{ myId }}">
                                        Used for {{ myName }}
                                    </option>
                                </select>
                            </div>
                        </div> ->
                    </div>

                    <hr />

                    <div *ngIf="hasChartData()">
                        <app-formula-pie-charts [chartData]="chartData" [chartSize]="[340, 340]"
                            [containerWidthMultiplier]="350" [isScrollable]="true" [showBhiveDisclaimers]="true">
                        </app-formula-pie-charts>
                    </div> -->
                </div>

                <!-- <div name="advanced-reporting-details" *ngIf="subscribedToAdvancedAnalytics">
                    
                    <div class="panel panel-default" style="height: 2000px">
                        <iframe [src]="advancedAnalyticsMain" frameborder="0" width="100%" height="2000px" right="30px"
                            allowtransparency></iframe>
                    </div>
                    <br />

                    <div *ngIf="panelCount > 1">
                        <h4>Detailed Analytics</h4>
                        <div class="panel panel-default" style="height: 2100px">
                            <iframe [src]="advancedAnalyticsDetails" frameborder="0" width="100%" height="100%"
                                right="30px" allowtransparency></iframe>
                        </div>
                    </div>
                    <br />

                    <div *ngIf="panelCount > 2">
                        <h4>Raw Data</h4>
                        <div class="panel panel-default" style="height: 650px">
                            <iframe [src]="advancedAnalyticsDownload" frameborder="0" width="100%" height="100%"
                                right="30px" allowtransparency></iframe>
                        </div>
                    </div>
                    <br /> 
                </div> -->

                <ngx-loading [show]="isLoading"></ngx-loading>
            </div>
        </div>
    </div>
</div>